import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import cog7 from './cog_7_dark.svg';
import cog10 from './cog_10_dark.svg';

export default function Cogs(props) {
  return (
    <div className={props.className}>
      <div className="loic-cogs">
        <img className="loic-cog-7" alt="" src={cog7} width="44" height="44" />
        <img
          className="loic-cog-10"
          alt=""
          src={cog10}
          width="48"
          height="48"
        />
      </div>
    </div>
  );
}

Cogs.propTypes = {
  className: PropTypes.string
};

import Immutable from 'immutable';

const getOrder = (v, s) => {
  if (v) return v;
  return {
    id: '__new__',
    label: s
  };
};

const getHumanResoruce = (v, s) => {
  if (v) return v;
  return {
    id: '__new__',
    label: s,
    firstname: s,
    lastname: '',
    phone: '',
    mobile: ''
  };
};

const mapTransfer = transfer => {
  if (!transfer) return null;

  const mappedTransfer = { ...transfer };

  mappedTransfer.sourceOrder = getOrder(
    transfer.sourceOrder,
    transfer.sourceOrderString
  );
  mappedTransfer.sourceContact = getHumanResoruce(
    transfer.sourceContact,
    transfer.sourceContactString
  );
  mappedTransfer.sourceContactKutter = getHumanResoruce(
    transfer.sourceContactKutter,
    transfer.sourceContactKutterString
  );
  mappedTransfer.destinationOrder = getOrder(
    transfer.destinationOrder,
    transfer.destinationOrderString
  );
  mappedTransfer.destinationContact = getHumanResoruce(
    transfer.destinationContact,
    transfer.destinationContactString
  );
  mappedTransfer.destinationContactKutter = getHumanResoruce(
    transfer.destinationContactKutter,
    transfer.destinationContactKutterString
  );

  return mappedTransfer;
};

const initialState = Immutable.Map({
  transfers: [],
  chosenTransfer: null,
  orders: [],
  ordersLoading: false,
  transfersLoading: false
});

export default (state = initialState, action) => {
  if (action.type === 'TRANSFERS_FOUND') {
    return state.set('transfers', action.data.map(mapTransfer));
  }

  if (action.type === 'SET_CHOSEN_TRANSFER') {
    return state.set('chosenTransfer', mapTransfer(action.data));
  }

  if (action.type === 'TRANSFER_ADDED') {
    const transfers = state.get('transfers');
    const transfer = mapTransfer(action.data);

    return state
      .set('transfers', transfers.concat(transfer))
      .set('chosenTransfer', transfer);
  }

  if (action.type === 'TRANSFER_UPDATED') {
    const transfers = state.get('transfers');
    const transfer = mapTransfer(action.data);

    const nextTransfers = transfers.map(t => {
      if (t.id === transfer.id) {
        return transfer;
      }
      return t;
    });

    return state
      .set('transfers', nextTransfers)
      .set('chosenTransfer', transfer);
  }

  if (action.type === 'TRANSFER_DELETED') {
    const transfers = state.get('transfers');

    const nextTransfers = transfers.filter(
      transfer => transfer.id !== action.id
    );
    return state.set('transfers', nextTransfers).set('chosenTransfer', null);
  }

  if (action.type === 'SET_TRANSFER_ORDERS_LOADING') {
    return state.set('ordersLoading', true);
  }

  if (action.type === 'SET_TRANSFERS_LOADING') {
    return state.set('transfersLoading', true);
  }

  if (action.type === 'TRANSFER_ORDERS_FOUND') {
    return state.set('orders', action.data).set('ordersLoading', false);
  }

  return state;
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { filterBy, flattenObject } from "../../util/filterBy";
import { toDateString } from "../../util/dateHelper";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";

class List extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      tasks: List.mapTasks(props.tasks),
      filter: "",
      sortKey: null,
      reverseSort: true
    };
    this.state = this.initState;

    this.tableMapping = [
      { key: "title", label: "Aufgabe" },
      { key: "description", label: "Beschreibung" },
      { key: "workspacesLabel", label: "Arbeitsplatz" },
      { key: "workersLabel", label: "Mitarbeiter" },
      { key: "startDate", label: "Arbeitsbeginn" },
      { key: "dueDate", label: "Termin" }
    ];

    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  static mapTasks(tasks) {
    return tasks.map(task => {
      const { resourceType, resource } = task;

      let title = "Sonstiges / Werkstattarbeiten";
      if (resource) {
        switch (resourceType) {
          case "RESOURCE":
            title = `${resource.name} (${resource.number} ${resource.category})`;
            break;
          case "VEHICLE":
            title = `${resource.brand} ${resource.type} ${resource.licensePlate} (${resource.number} ${resource.category})`;
            break;
          default:
            break;
        }
      }

      const workspacesLabel = task.workspaces
        .map(ws =>
          ws.label === "Sonstiges" && task.externalWorkshop !== ""
            ? task.externalWorkshop
            : ws.label
        )
        .join(", ");

      const workersLabel = task.workers
        .map(w => `${w.firstname} ${w.lastname}`)
        .join(", ");

      const startDate = task.startDate ? toDateString(task.startDate) : "";
      const dueDate = task.dueDate ? toDateString(task.dueDate) : "";

      return {
        ...task,
        title,
        workspacesLabel,
        workersLabel,
        startDate,
        dueDate
      };
    });
  }

  static filter(data, filterString) {
    return filterBy(
      data,
      ["title", "description", "workspacesLabel", "workersLabel", "dueDate"],
      filterString,
      flattenObject
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { tasks } = this.props;
    if (prevProps.tasks !== tasks) {
      this.setState({ tasks: List.mapTasks(tasks) });
    }
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  render() {
    const { className } = this.props;
    let { tasks } = this.state;
    const { filter, sortKey, reverseSort } = this.state;

    tasks = List.filter(tasks, filter);

    return (
      <Fragment>
        <div className={className}>
          <TitleBar title="Werkstatt Archiv" showControls={false} />

          <input
            className="form-control mt-3"
            placeholder="Filter..."
            value={filter}
            onChange={this.setFilter}
          />

          <SimpleTable
            className="mt-3"
            selected={null}
            sortKey={sortKey}
            reverseSort={reverseSort}
            data={tasks}
            mapping={this.tableMapping}
            onColSelected={this.setSortKey}
          />
        </div>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  tasks: PropTypes.array,
  write: PropTypes.bool
};

export default List;

import gql from "graphql-tag";
import { OrderDetail } from "./fragments";
import store from "../store";

export default (client, id) => dispatch => {
  const DUPLICATE_ORDER = gql`
    mutation duplicateOrder($requestUser: String!, $id: ID) {
      duplicateOrder(requestUser: $requestUser, id: $id) {
        ...OrderDetail
      }
    }
    ${OrderDetail}
  `;

  client
    .mutate({
      mutation: DUPLICATE_ORDER,
      variables: {
        requestUser: store.getState().main.get("loginName"),
        id
      }
    })
    .then(response => {
      console.log(response);
      dispatch({
        type: "ORDER_ADDED",
        data: response.data.duplicateOrder
      });
    });
};

import gql from 'graphql-tag';

export default (client, costCenterId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteCostCenter($costCenterId: ID) {
          deleteCostCenter(id: $costCenterId) {
            success
          }
        }
      `,
      variables: { costCenterId }
    })
    .then(response => {
      if (response.data.deleteCostCenter.success === true) {
        dispatch({
          type: 'COST_CENTER_DELETED',
          id: costCenterId
        });
        window.history.pushState({}, null, '/cost_center');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteCostCenter.success
        });
      }
    });
};

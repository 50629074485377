import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';
import { CostCenterFrameData } from './fragments';

export default (client) => dispatch => {
  whenModified(client, 'cost_center_frame', () =>
    client
      .query({
        query: gql`
          query {
            getCostCenterFrames {
              ...CostCenterFrameData
            }
          }
          ${CostCenterFrameData}
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({
          type: 'COST_CENTER_FRAMES_FOUND',
          data: response.data.getCostCenterFrames
        });
      })
  );
};

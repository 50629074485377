import gql from 'graphql-tag';

export default (client, userId) => dispatch => {
  const query = `
    mutation removeToken(
      $userId: ID
    ) {
      removeToken(
        id: $userId
      ) {
        token
        registeredMobileDevice
        deviceToken
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        userId
      }
    })
    .then(response => {
      dispatch({
        type: 'HUMAN_RESOURCE_TOKEN_SET',
        data: response.data.removeToken,
        userId
      });
    })
    .catch(err => console.error(err));
};

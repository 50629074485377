import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ScopeOfService from "../../ScopeOfService";
import CostCenterSelect from "../../CostCenterSelect";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const establishmentOptions = [
  {
    label: "Leipzig",
    value: "Leipzig"
  },
  {
    label: "Duben",
    value: "Duben"
  }
];

class OrderData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      costCenter: null,
      noCostCenter: false,
      scopeOfServices: [],
      establishment: this.props.establishment ? this.props.establishment : null
    };

    this.state.scopeOfServices = props.scopeOfServices;

    if (props.scopeOfServices.length === 0) {
      this.state.scopeOfServices.push({
        id: `_0`,
        width: 0,
        depth: 0,
        depthTo: null,
        amount: 0,
        amountUnit: "qm",
        scopeOfServiceTyps: []
      });
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleScopeOfServiceChange = this.handleScopeOfServiceChange.bind(
      this
    );
    this.handleCostCenterChange = this.handleCostCenterChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
  }

  componentDidMount() {
    this.props.onUpdate(this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState !== this.state) this.props.onUpdate(this.state);
  }

  handleInputChange(e) {
    const { costCenter, establishment } = this.state;
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    const nextCostCenter = name === "noCostCenter" && value ? null : costCenter;

    this.setState({
      [name]: value,
      costCenter: nextCostCenter,
      error: !(establishment && (costCenter || value))
    });
  }

  handleScopeOfServiceChange(scopeOfServices) {
    this.setState({ scopeOfServices });
  }

  handleCostCenterChange(value) {
    this.setState({
      costCenter: value,
      establishment: value
        ? { value: value.establishment, label: value.establishment }
        : null,
      error: false
    });
  }

  handleEstablishmentChange(newValue) {
    const { costCenter, noCostCenter } = this.state;
    if (newValue) {
      this.setState({
        establishment: newValue,
        error: !(costCenter || noCostCenter)
      });
    }
  }

  render() {
    const { costCenters, machines } = this.props;
    const {
      noCostCenter,
      costCenter,
      scopeOfServices,
      establishment,
      error
    } = this.state;

    return (
      <Fragment>
        <h4>Maschinen</h4>
        <div className="row">
          <div className="col-md-12">{machines}</div>
        </div>

        <h4>Kostenstelle</h4>
        <div className="row">
          {error ? (
            <div className="col-12">
              <div className="alert alert-warning">
                <FontAwesomeIcon
                  className="mr-3"
                  icon={faExclamationTriangle}
                />
                {!(costCenter || noCostCenter)
                  ? "Bitte wählen Sie eine Kostenstelle aus. "
                  : !establishment &&
                    "Bitte wählen Sie eine Niederlassung aus."}
              </div>
            </div>
          ) : null}
          <div className="col-md-3">
            <Select
              clearable
              isDisabled={!!(this.props.establishment || costCenter)}
              value={establishment}
              onChange={this.handleEstablishmentChange}
              placeholder=""
              noResultsText="keine Übereinstimmung"
              clearAllText="zurücksetzen"
              options={establishmentOptions}
            />
          </div>
          <div className="col-md-5">
            <CostCenterSelect
              clearable
              placeholder=""
              clearAllText="Kostenstelle zurücksetzen"
              noResultsText="keine Übereinstimmung"
              value={costCenter}
              options={costCenters}
              disabled={noCostCenter}
              isLoading={true}
              onChange={this.handleCostCenterChange}
              establishment={establishment}
            />
          </div>
          <div className="col-md-4 pt-2">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="noCostCenter"
                name="noCostCenter"
                checked={noCostCenter}
                onChange={this.handleInputChange}
              />
              <label className="form-check-label small" htmlFor="noCostCenter">
                keine passende Kostenstelle
              </label>
            </div>
          </div>
        </div>

        <ScopeOfService
          scopeOfServices={scopeOfServices}
          onChange={this.handleScopeOfServiceChange}
        />
      </Fragment>
    );
  }
}

OrderData.propTypes = {
  costCenters: PropTypes.array,
  machines: PropTypes.string,
  scopeOfServices: PropTypes.array,
  onUpdate: PropTypes.func,
  establishment: PropTypes.object
};

export default OrderData;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { debounce } from "lodash";
import gql from "graphql-tag";
import {
  faArrowLeft,
  faArrowRight,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import {
  faExternalLink,
  faMapMarkerAlt
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  addDays,
  addYears,
  startOfDay,
  toDateTimeString
} from "../../util/dateHelper";

import TitleBar from "../TitleBar";
import VehicleSelect from "./vehicleSelect";
import HumanResourceSelect from "./humanResourceSelect";
import OrderSelect from "./orderSelect";
import ResourceSelect from "./resourceSelect";
import Button from "../UnlockButton";

import getCostCenters from "../../actions/getCostCenters";
import getUsers from "../../actions/getUsers";
import getResources from "../../actions/getResources";
import getVehicles from "../../actions/getVehicles";

import createTransfer from "../../actions/createTransfer";
import updateTransfer from "../../actions/updateTransfer";
import deleteTransfer from "../../actions/deleteTransfer";
import getCombinations from "../../actions/getCombinations";
import Map2 from "../CostCenter/map2";

const bauhofLeipzig = "Handelsstraße 22A, 04420 Markranstädt";

const setContactOptions = (client, customer, key, cb) => {
  client
    .query({
      query: gql`
        query getContactsByCompanyName($name: String) {
          getContactsByCompanyName(name: $name) {
            id
            lastname
            firstname
            phone
            mobile
          }
        }
      `,
      variables: { name: customer }
    })
    .then(response => {
      cb({
        [key]: response.data.getContactsByCompanyName
      });
    });
};

class Data extends Component {
  constructor(props) {
    super(props);

    const { dispatch, client, write } = props;
    this.initState = {
      // form fields
      costCenter: null,
      vehicle: null,
      combination: null,
      humanResource: null,
      sourceOrder: null,
      sourceDate: "",
      sourceContact: null,
      sourceContactKutter: null,
      sourceNotes: "",
      sourceLocation: "",
      sourceLat: "",
      sourceLng: "",
      additionalMachine: "",
      destinationOrder: null,
      destinationDate: "",
      destinationContact: null,
      destinationContactKutter: null,
      transferResources: [],
      // control
      sourceContactOptions: [],
      sourceHumanResourceOptions: null,
      destinationContactOptions: [],
      destinationHumanResourceOptions: null,
      destinationNotes: "",
      destinationLocation: "",
      destinationLat: "",
      destinationLng: "",
      resourceOptions: null,
      locked: !write,
      errors: {},
      userFromVehicle: true,
      humanResourcesWithVehicles: null
    };
    this.state = this.initState;

    this.setTransfer = this.setTransfer.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleHumanResourceChange = this.handleHumanResourceChange.bind(this);
    this.handleSourceOrderChange = this.handleSourceOrderChange.bind(this);
    this.handleDestinationOrderChange = this.handleDestinationOrderChange.bind(
      this
    );
    this.handleSourceContactChange = this.handleSourceContactChange.bind(this);
    this.handleDestinationContactChange = this.handleDestinationContactChange.bind(
      this
    );
    this.handleSourceContactKutterChange = this.handleSourceContactKutterChange.bind(
      this
    );
    this.handleDestinationContactKutterChange = this.handleDestinationContactKutterChange.bind(
      this
    );
    this.handleResourceChange = this.handleResourceChange.bind(this);
    this.handleSourceAddressChange = this.handleSourceAddressChange.bind(this);
    this.handleDestinationAddressChange = this.handleDestinationAddressChange.bind(
      this
    );
    this.submitRequest = debounce(this.submitRequest, 750);
    this.resetSourceAddress = this.resetSourceAddress.bind(this);
    this.resetDestinationAddress = this.resetDestinationAddress.bind(this);
    this.switchDependencyDirection = this.switchDependencyDirection.bind(this);
    this.searchSourceAddress = this.searchSourceAddress.bind(this);
    this.searchDestinationAddress = this.searchDestinationAddress.bind(this);

    this.validate = this.validate.bind(this);

    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);

    dispatch(getCostCenters(client));
    dispatch(getUsers(client));
    dispatch(getResources(client));
    dispatch(getVehicles(client));
    dispatch(getCombinations(client));
  }

  componentDidMount() {
    const { client, dispatch } = this.props;

    const from = addDays(startOfDay(new Date()), -10);
    const to = addYears(from, 1);

    dispatch({
      type: "SET_TRANSFER_ORDERS_LOADING"
    });

    client
      .query({
        query: gql`
          query getOrdersInRange($from: Date!, $to: Date!) {
            getOrdersInRange(from: $from, to: $to) {
              id
              building_address
              lat
              lng
              directions
              description
              notes
              cost_center {
                id
                value
                establishment
                is_continuance
                building_project
                customer
              }
              resources {
                id
                resource {
                  __typename
                  ... on Resource {
                    id
                    name
                    number
                    category
                    status
                  }
                }
              }
              humanResources {
                id
                humanResource {
                  id
                  lastname
                  firstname
                  position
                  phone
                }
              }
              kutterContact {
                id
                firstname
                lastname
                position
                phone
              }
            }
          }
        `,
        variables: { from: from.toISOString(), to: to.toISOString() }
      })
      .then(response => {
        dispatch({
          type: "TRANSFER_ORDERS_FOUND",
          data: response.data.getOrdersInRange
        });
        if (this.props.orders && this.props.orders.length > 0) {
          const options = this.props.orders[0].resources.map(r => r.resource);
          this.setState({
            transferResources: options.length === 1 ? [options[0]] : [],
            resourceOptions: options.length > 0 ? options : null
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    const { transfer, vehicles, humanResources } = this.props;

    if (prevProps.transfer !== transfer) {
      if (transfer) {
        this.setTransfer(transfer);
      } else {
        this.setState(this.initState);
      }
    }

    if (
      prevProps.vehicles !== vehicles ||
      prevProps.humanResources !== humanResources
    ) {
      if (
        vehicles &&
        vehicles.length > 0 &&
        humanResources &&
        humanResources.length > 0
      ) {
        const humanResourcesWithVehicles = humanResources.map(hr => {
          const hrVehicles = vehicles.filter(v =>
            v.humanResources.find(elem => elem.id === hr.id)
          );

          if (hrVehicles) return { ...hr, vehicles: hrVehicles };

          return hr;
        });

        this.setState({ humanResourcesWithVehicles });
      }
    }
  }

  setTransfer(transfer) {
    if (transfer.sourceOrder.cost_center) {
      setContactOptions(
        this.props.client,
        transfer.sourceOrder.cost_center.customer,
        "sourceContactOptions",
        data => this.setState(data)
      );
    } else {
      setContactOptions(
        this.props.client,
        transfer.sourceOrder.customer,
        "sourceContactOptions",
        data => this.setState(data)
      );
    }
    if (transfer.destinationOrder.cost_center) {
      setContactOptions(
        this.props.client,
        transfer.destinationOrder.cost_center.customer,
        "destinationContactOptions",
        data => this.setState(data)
      );
    } else {
      setContactOptions(
        this.props.client,
        transfer.destinationOrder.customer,
        "destinationContactOptions",
        data => this.setState(data)
      );
    }

    this.setState({
      vehicle: transfer.vehicle || null,
      combination: transfer.combination || null,
      humanResource: transfer.humanResource || null,
      sourceOrder: transfer.sourceOrder,
      sourceDate: toDateTimeString(new Date(transfer.sourceDate)),
      sourceContact: transfer.sourceContact,
      sourceContactKutter: transfer.sourceContactKutter,
      sourceNotes: transfer.sourceNotes,
      sourceLocation:
        transfer.sourceOrder.label === "Bauhof Leipzig"
          ? bauhofLeipzig
          : transfer.sourceLocation || transfer.sourceOrder.building_address,
      sourceLat: transfer.sourceLat || transfer.sourceOrder.lat || "",
      sourceLng: transfer.sourceLng || transfer.sourceOrder.lng || "",
      additionalMachine: transfer.additionalMachine,
      destinationOrder: transfer.destinationOrder,
      destinationDate: toDateTimeString(new Date(transfer.destinationDate)),
      destinationContact: transfer.destinationContact,
      destinationContactKutter: transfer.destinationContactKutter,
      destinationNotes: transfer.destinationNotes,
      destinationLocation:
        transfer.destinationOrder.label === "Bauhof Leipzig"
          ? bauhofLeipzig
          : transfer.destinationLocation ||
            transfer.destinationOrder.building_address,
      transferResources: transfer.resources || [],
      destinationLat:
        transfer.destinationLat || transfer.destinationOrder.lat || "",
      destinationLng:
        transfer.destinationLng || transfer.destinationOrder.lng || "",
      locked: true,
      errors: {}
    });
  }

  handleInputChange(e) {
    const { sourceLat } = this.state;
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  submitRequest(address) {
    this.setState({ sourceLocation: address });
  }

  searchSourceAddress() {
    this.setState({ sourceLat: "", sourceLng: "" });
  }

  searchDestinationAddress() {
    this.setState({ destinationLat: "", destinationLng: "" });
  }

  resetSourceAddress() {
    this.setState({ sourceLocation: "", sourceLat: "", sourceLng: "" });
  }

  resetDestinationAddress() {
    this.setState({
      destinationLocation: "",
      destinationLat: "",
      destinationLng: ""
    });
  }

  handleVehicleChange(value) {
    const { humanResources } = this.props;
    const { userFromVehicle } = this.state;

    if (userFromVehicle && value) {
      const { humanResources: vehicleHumanResources } = value;

      const id =
        vehicleHumanResources.length > 0 ? vehicleHumanResources[0].id : null;

      this.setState({
        humanResource: humanResources.find(hr => hr.id === id) || null
      });
    }

    if (value.isCombi) {
      this.setState({
        combination: { ...value, id: parseInt(value.id.split("_")[1], 10) },
        vehicle: null
      });
    } else {
      this.setState({
        vehicle: value,
        combination: null
      });
    }
  }

  handleHumanResourceChange(value) {
    const { vehicles, combinations } = this.props;
    const { userFromVehicle } = this.state;

    if (!userFromVehicle && value) {
      const combination = combinations.find(v =>
        v.humanResources.find(hr => hr.id === value.id)
      );
      if (combination) {
        this.setState({ combination });
      } else {
        const vehicle = vehicles.find(v =>
          v.humanResources.find(hr => hr.id === value.id)
        );
        if (vehicle) this.setState({ vehicle });
      }
    }

    this.setState({
      humanResource: value
    });
  }

  handleSourceOrderChange(value) {
    const { client } = this.props;
    if (value) {
      switch (value.type) {
        case "ORDER": {
          const {
            cost_center: costCenter,
            resources,
            humanResources,
            kutterContact
          } = value;

          if (costCenter && costCenter.customer && costCenter.customer !== "") {
            client
              .query({
                query: gql`
                  query getContactsByCompanyName($name: String) {
                    getContactsByCompanyName(name: $name) {
                      id
                      lastname
                      firstname
                      phone
                      mobile
                    }
                  }
                `,
                variables: { name: costCenter.customer }
              })
              .then(response => {
                this.setState({
                  sourceContactOptions: response.data.getContactsByCompanyName
                });
              });
          }
          if (resources) {
            const options = resources.map(r => r.resource);
            this.setState({
              transferResources: options.length === 1 ? [options[0]] : [],
              resourceOptions: options.length > 0 ? options : null
            });
          } else {
            this.setState({
              transferResources: null,
              resourceOptions: null
            });
          }

          if (humanResources) {
            const options = humanResources
              .filter(hr => hr.status === "Aktiv")
              .map(hr => hr.humanResource);
            const hrOptions = options
              .concat(kutterContact)
              .filter(o => Boolean(o));

            this.setState({
              sourceHumanResourceOptions: [
                { label: "Am Abruf", options: hrOptions },
                {
                  label: "Alle",
                  options: this.props.humanResources
                    .filter(hr => hr.status === "Aktiv")
                    .filter(hr =>
                      hrOptions.reduce(
                        (acc, val) => acc && val.id !== hr.id,
                        true
                      )
                    )
                }
              ]
            });
          }

          if (kutterContact) {
            this.setState({ sourceContactKutter: kutterContact });
          }
          this.setState({
            sourceLat: value.lat,
            sourceLng: value.lng
          });
          break;
        }
        case "COST_CENTER": {
          const { customer } = value;
          if (customer && customer !== "") {
            client
              .query({
                query: gql`
                  query getContactsByCompanyName($name: String) {
                    getContactsByCompanyName(name: $name) {
                      id
                      lastname
                      firstname
                      phone
                      mobile
                    }
                  }
                `,
                variables: { name: customer }
              })
              .then(response => {
                this.setState({
                  SourceContactOptions: response.data.getContactsByCompanyName
                });
              });
          }
          break;
        }
        default: {
          this.setState({
            sourceLocation:
              value.value === "Bauhof Leipzig" ? bauhofLeipzig : "",
            sourceLat: "",
            sourceLng: ""
          });
          break;
        }
      }
    }

    this.setState({
      sourceOrder: value
    });
  }

  handleDestinationOrderChange(value) {
    const { client } = this.props;

    if (value) {
      switch (value.type) {
        case "ORDER": {
          const {
            cost_center: costCenter,
            humanResources,
            kutterContact
          } = value;

          if (costCenter && costCenter.customer && costCenter.customer !== "") {
            client
              .query({
                query: gql`
                  query getContactsByCompanyName($name: String) {
                    getContactsByCompanyName(name: $name) {
                      id
                      lastname
                      firstname
                      phone
                      mobile
                    }
                  }
                `,
                variables: { name: costCenter.customer }
              })
              .then(response => {
                this.setState({
                  destinationContactOptions:
                    response.data.getContactsByCompanyName
                });
              });
          }

          if (humanResources) {
            const options = humanResources
              .filter(hr => hr.status === "Aktiv")
              .map(hr => hr.humanResource);
            const hrOptions = options
              .concat(kutterContact)
              .filter(o => Boolean(o));

            this.setState({
              destinationHumanResourceOptions: [
                { label: "Am Abruf", options: hrOptions },
                {
                  label: "Alle",
                  options: this.props.humanResources
                    .filter(hr => hr.status === "Aktiv")
                    .filter(hr =>
                      hrOptions.reduce(
                        (acc, val) => acc && val.id !== hr.id,
                        true
                      )
                    )
                }
              ]
            });
          }

          if (kutterContact) {
            this.setState({
              destinationContactKutter: kutterContact
            });
          }
          this.setState({
            destinationLat: value.lat,
            destinationLng: value.lng
          });
          break;
        }
        case "COST_CENTER": {
          const { customer } = value;
          if (customer && customer !== "") {
            client
              .query({
                query: gql`
                  query getContactsByCompanyName($name: String) {
                    getContactsByCompanyName(name: $name) {
                      id
                      lastname
                      firstname
                      phone
                      mobile
                    }
                  }
                `,
                variables: { name: customer }
              })
              .then(response => {
                this.setState({
                  destinationContactOptions:
                    response.data.getContactsByCompanyName
                });
              });
          }
          break;
        }
        default: {
          this.setState({
            destinationLocation:
              value.value === "Bauhof Leipzig" ? bauhofLeipzig : "",
            destinationLat: "",
            destinationLng: ""
          });
          break;
        }
      }
    }

    this.setState({
      destinationOrder: value
    });
  }

  handleSourceContactChange(value) {
    if (value) {
      value.label = value.id;
    }
    this.setState({ sourceContact: value });
  }

  handleDestinationContactChange(value) {
    if (value) {
      value.label = value.id;
    }
    this.setState({ destinationContact: value });
  }

  handleSourceContactKutterChange(value) {
    if (value) {
      value.label = value.id;
    }
    this.setState({ sourceContactKutter: value });
  }

  handleDestinationContactKutterChange(value) {
    if (value) {
      value.label = value.id;
    }
    this.setState({ destinationContactKutter: value });
  }

  handleResourceChange(value) {
    this.setState({
      transferResources: value
    });
  }

  handleSourceAddressChange(lat, lng) {
    if (lat && lng) {
      this.setState({ sourceLat: lat.toString(), sourceLng: lng.toString() });
    }
  }

  handleDestinationAddressChange(lat, lng) {
    if (lat && lng) {
      this.setState({
        destinationLat: lat.toString(),
        destinationLng: lng.toString()
      });
    }
  }

  switchDependencyDirection() {
    const { userFromVehicle } = this.state;

    this.setState({ userFromVehicle: !userFromVehicle });
  }

  validate(data) {
    const errors = {};

    if (!data.vehicle && !data.combination) errors.vehicle = true;
    if (!data.humanResource) errors.humanResource = true;
    if (!data.sourceOrder) errors.sourceOrder = true;
    if (!data.destinationOrder) errors.destinationOrder = true;
    if (!data.resources) errors.transferResources = true;

    const currentDate = startOfDay(new Date());
    let sourceTestDate = null;
    let destinationTestDate = null;

    if (!data.sourceDate || data.sourceDate === "") {
      errors.sourceDate = true;
    } else {
      sourceTestDate = new Date(data.sourceDate);
      if (sourceTestDate.getTime() < currentDate) errors.sourceDate = true;
    }

    if (!data.destinationDate || data.destinationDate === "") {
      errors.destinationDate = true;
    } else {
      destinationTestDate = new Date(data.destinationDate);
      if (destinationTestDate.getTime() < currentDate)
        errors.destinationDate = true;
    }

    if (
      sourceTestDate &&
      destinationTestDate &&
      sourceTestDate.getTime() > destinationTestDate.getTime()
    ) {
      errors.sourceDate = true;
      errors.destinationDate = true;
    }

    let valid = true;

    // eslint-disable-next-line guard-for-in
    for (const key in errors) {
      valid = valid && !errors[key];
    }

    this.setState({ errors });
    return valid;
  }

  lock(reset = true) {
    const { transfer } = this.props;
    if (reset && transfer) {
      this.setTransfer(transfer);
    }
    this.setState({
      locked: true
    });
  }

  unlock() {
    this.setState({
      locked: false
    });
  }

  save() {
    const {
      vehicle,
      combination,
      humanResource,
      sourceOrder,
      sourceDate,
      sourceContact,
      sourceContactKutter,
      sourceNotes,
      sourceLocation,
      sourceLat,
      sourceLng,
      additionalMachine,
      destinationOrder,
      destinationDate,
      destinationContact,
      destinationContactKutter,
      destinationNotes,
      destinationLocation,
      destinationLat,
      destinationLng,
      transferResources
    } = this.state;
    const { client, dispatch, transfer } = this.props;

    const get = val => {
      if (!val) return "STR_";
      switch (val.type) {
        case "ORDER": {
          return `ID_${val.id}`;
        }
        case "COST_CENTER": {
          return `CC_${val.id}`;
        }
        default: {
          break;
        }
      }
      return `STR_${val.label}`;
    };

    const getResource = val => {
      if (!val) return "STR_";
      return val.id !== val.name ? `ID_${val.id}` : `STR_${val.name}`;
    };

    const data = {
      vehicle,
      combination,
      humanResource,
      sourceOrder,
      sourceDate,
      sourceContact,
      sourceContactKutter,
      sourceNotes,
      sourceLocation,
      sourceLat,
      sourceLng,
      additionalMachine,
      destinationOrder,
      destinationDate,
      destinationContact,
      destinationContactKutter,
      destinationNotes,
      destinationLocation,
      destinationLat,
      destinationLng,
      resources: transferResources
    };

    if (!this.validate(data)) return;

    data.sourceOrder = get(sourceOrder);
    data.sourceDate = new Date(sourceDate);
    data.sourceContact = get(sourceContact);
    data.sourceContactKutter = get(sourceContactKutter);
    data.destinationOrder = get(destinationOrder);
    data.destinationDate = new Date(destinationDate);
    data.destinationContact = get(destinationContact);
    data.destinationContactKutter = get(destinationContactKutter);
    data.resources = JSON.stringify(data.resources.map(getResource));

    if (transfer) {
      dispatch(updateTransfer(client, transfer.id, data));
    } else {
      dispatch(createTransfer(client, data));
    }

    this.lock(false);
  }

  delete() {
    const { client, dispatch, transfer } = this.props;
    if (transfer) dispatch(deleteTransfer(client, transfer.id));
    this.lock(false);
  }

  render() {
    const {
      className,
      transfer,
      write,
      vehicles,
      combinations,
      humanResources,
      resources,
      orders,
      costCenters,
      ordersLoading
    } = this.props;

    const {
      vehicle,
      combination,
      humanResource,
      sourceOrder,
      sourceDate,
      sourceContact,
      sourceContactKutter,
      sourceNotes,
      sourceLocation,
      sourceLat,
      sourceLng,
      additionalMachine,
      destinationOrder,
      destinationDate,
      destinationContact,
      destinationContactKutter,
      transferResources,
      sourceContactOptions,
      sourceHumanResourceOptions,
      destinationContactOptions,
      destinationHumanResourceOptions,
      destinationNotes,
      destinationLocation,
      destinationLat,
      destinationLng,
      resourceOptions,
      locked,
      errors,
      userFromVehicle,
      humanResourcesWithVehicles
    } = this.state;

    const orderSelectOptions = [
      ...orders.map(e => ({ ...e, type: "ORDER" })),
      ...costCenters.map(e => ({ ...e, type: "COST_CENTER" }))
    ];

    const showAdditionalMachine = transferResources
      ? !!transferResources.find(r => r.id === "Sonstige")
      : false;

    const sourceContactKutterOptions =
      sourceHumanResourceOptions ||
      humanResources.filter(hr => hr.status === "Aktiv");

    const destinationContactKutterOptions =
      destinationHumanResourceOptions ||
      humanResources.filter(hr => hr.status === "Aktiv");

    return (
      <div className={className} style={{ paddingBottom: 100 }}>
        <TitleBar title="Transportdaten" />

        <div className="row">
          <div className="col form-group">
            <label className="small">Umsetzer*</label>
            <VehicleSelect
              value={
                vehicle ||
                (combination
                  ? {
                      ...combination,
                      id: `combi_${combination.id}`,
                      isCombi: true
                    }
                  : null)
              }
              options={[
                ...vehicles,
                ...combinations.map(combi => ({
                  ...combi,
                  id: `combi_${combi.id}`,
                  isCombi: true
                }))
              ].filter(o => o.status === "Aktiv")}
              disabled={locked}
              error={errors.vehicle}
              onChange={this.handleVehicleChange}
            />
          </div>

          <div className="col-auto form-group">
            <label className="small">&nbsp;</label>
            <button
              type="button"
              className="d-block btn btn-secondary"
              title="Abhängigkeit umkehren"
              onClick={this.switchDependencyDirection}
            >
              <FontAwesomeIcon
                icon={userFromVehicle ? faArrowRight : faArrowLeft}
              />
            </button>
          </div>

          <div className="col form-group">
            <label className="small">Mitarbeiter*</label>
            <HumanResourceSelect
              value={humanResource}
              options={humanResourcesWithVehicles || humanResources}
              disabled={locked}
              error={errors.humanResource}
              onChange={this.handleHumanResourceChange}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col-6 p-1">
            <TitleBar title="Von Baustelle" />
            <div className="row">
              <div className="col-12 form-group">
                <label className="small">
                  Baustelle*{" "}
                  <FontAwesomeIcon
                    style={{
                      position: "relative",
                      color: "#0414d3",
                      marginLeft: "3px"
                    }}
                    icon={faMapMarkerAlt}
                  />
                  {sourceOrder && sourceOrder.id !== "__new__" && (
                    <a className="ml-2" href={"/order/" + sourceOrder.id}>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  )}
                </label>
                <OrderSelect
                  value={sourceOrder}
                  options={orderSelectOptions}
                  loading={ordersLoading}
                  error={errors.sourceOrder}
                  disabled={locked}
                  onChange={this.handleSourceOrderChange}
                />
              </div>

              <div className="col-12 form-group">
                <label className="small">Datum und Uhrzeit*</label>
                <input
                  type="datetime-local"
                  className={`form-control${
                    errors.sourceDate ? " is-invalid" : ""
                  }`}
                  name="sourceDate"
                  value={sourceDate}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </div>

              {sourceOrder &&
              !(
                sourceOrder.cost_center ||
                sourceOrder.label === "Bauhof Leipzig"
              ) ? (
                <div className="col-12 input-group mb-3">
                  <label
                    className="form-check-label small w-100"
                    htmlFor="buildingAddress"
                  >
                    Adresse (wenn vorhanden)
                    <span className="float-right">
                      ({sourceLat}, {sourceLng})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sourceLocation"
                    name="sourceLocation"
                    value={sourceLocation}
                    disabled={locked}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append" style={{ zIndex: 0 }}>
                    <button
                      className="btn btn-primary"
                      disabled={locked}
                      onClick={this.searchSourceAddress}
                    >
                      suchen
                    </button>
                  </div>
                  <div className="input-group-append" style={{ zIndex: 0 }}>
                    <button
                      className="btn btn-secondary"
                      disabled={locked}
                      onClick={this.resetSourceAddress}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="col-12 form-group">
                <label className="small">Notiz Baustelle</label>
                <textarea
                  className="form-control"
                  value={sourceOrder ? sourceOrder.notes : ""}
                  disabled={true}
                />
              </div>

              <div className="col-12 form-group">
                <label className="small">zusätzliche Informationen</label>
                <textarea
                  className="form-control"
                  name="sourceNotes"
                  value={sourceNotes}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="col-6 form-group">
                <label className="small">Ansprechpartner Kutter</label>
                <HumanResourceSelect
                  value={sourceContactKutterOptions.find(
                    scko =>
                      sourceContactKutter &&
                      scko.id === sourceContactKutter.label
                  )}
                  options={sourceContactKutterOptions}
                  allowCreation={true}
                  disabled={locked}
                  onChange={this.handleSourceContactKutterChange}
                />
              </div>

              <div className="col-6 form-group">
                <label className="small">Ansprechpartner Auftraggeber</label>
                <HumanResourceSelect
                  value={sourceContactOptions.find(
                    sco => sourceContact && sco.id === sourceContact.label
                  )}
                  options={sourceContactOptions}
                  allowCreation={true}
                  disabled={locked}
                  onChange={this.handleSourceContactChange}
                />
              </div>
            </div>
          </div>
          <div className="col-6 p-1">
            <TitleBar title="Zur Baustelle" />

            <div className="row">
              <div className="col-12 form-group">
                <label className="small">
                  Baustelle*{" "}
                  <FontAwesomeIcon
                    style={{
                      position: "relative",
                      color: "#D30132",
                      marginLeft: "3px"
                    }}
                    icon={faMapMarkerAlt}
                  />
                  {destinationOrder && destinationOrder.id !== "__new__" && (
                    <a className="ml-2" href={"/order/" + destinationOrder.id}>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  )}
                </label>
                <OrderSelect
                  value={destinationOrder}
                  options={orderSelectOptions}
                  loading={ordersLoading}
                  error={errors.destinationOrder}
                  disabled={locked}
                  onChange={this.handleDestinationOrderChange}
                />
              </div>

              <div className="col-12 form-group">
                <label className="small">Datum und Uhrzeit*</label>
                <input
                  type="datetime-local"
                  className={`form-control${
                    errors.destinationDate ? " is-invalid" : ""
                  }`}
                  name="destinationDate"
                  value={destinationDate}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </div>

              {destinationOrder &&
              !(
                destinationOrder.cost_center ||
                destinationOrder.label === "Bauhof Leipzig"
              ) ? (
                <div className="col-12 input-group mb-3">
                  <label
                    className="form-check-label small w-100"
                    htmlFor="buildingAddress"
                  >
                    Adresse (wenn vorhanden)
                    <span className="float-right">
                      ({destinationLat}, {destinationLng})
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="destinationLocation"
                    name="destinationLocation"
                    value={destinationLocation}
                    disabled={locked}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append" style={{ zIndex: 0 }}>
                    <button
                      className="btn btn-primary"
                      disabled={locked}
                      onClick={this.searchDestinationAddress}
                    >
                      suchen
                    </button>
                  </div>
                  <div className="input-group-append" style={{ zIndex: 0 }}>
                    <button
                      className="btn btn-secondary"
                      disabled={locked}
                      onClick={this.resetDestinationAddress}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
              ) : null}
              <div className="col-12 form-group">
                <label className="small">Notiz Baustelle</label>
                <textarea
                  className="form-control"
                  value={destinationOrder ? destinationOrder.notes : ""}
                  disabled={true}
                />
              </div>

              <div className="col-12 form-group">
                <label className="small">zusätzliche Informationen</label>
                <textarea
                  className="form-control"
                  name="destinationNotes"
                  value={destinationNotes}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="col-6 form-group">
                <label className="small">Ansprechpartner Kutter</label>
                <HumanResourceSelect
                  value={destinationContactKutterOptions.find(
                    scko =>
                      destinationContactKutter &&
                      scko.id === destinationContactKutter.label
                  )}
                  options={destinationContactKutterOptions}
                  allowCreation={true}
                  disabled={locked}
                  onChange={this.handleDestinationContactKutterChange}
                />
              </div>

              <div className="col-6 form-group">
                <label className="small">Ansprechpartner Auftraggeber</label>
                <HumanResourceSelect
                  value={destinationContactOptions.find(
                    dco =>
                      destinationContact && dco.id === destinationContact.label
                  )}
                  options={destinationContactOptions}
                  allowCreation={true}
                  disabled={locked}
                  onChange={this.handleDestinationContactChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className={`${
              showAdditionalMachine ? "col-6" : "col-12"
            } form-group`}
          >
            <label className="small">Maschinen*</label>
            <ResourceSelect
              value={transferResources}
              options={(resourceOptions || resources)
                .concat([
                  {
                    id: "Sonstige",
                    label: "Sonstige",
                    name: "Sonstige",
                    number: "",
                    category: "Sonstige",
                    __isNew__: true
                  }
                ])
                .filter(resource => {
                  if (sourceOrder && !sourceOrder.cost_center) {
                    return true;
                  }
                  return resource.status === "Aktiv";
                })}
              error={errors.transferResources}
              disabled={locked}
              onChange={this.handleResourceChange}
            />
          </div>
          {showAdditionalMachine ? (
            <div className="col-6">
              <label className="small">sonstige Maschine</label>
              <input
                className="form-control"
                name="additionalMachine"
                value={additionalMachine}
                disabled={locked}
                onChange={this.handleInputChange}
              />
            </div>
          ) : null}
        </div>

        <div
          className="col-12 mb-3 hidden-print"
          style={{ width: "100%", height: "500px" }}
        >
          <Map2
            sourceAddress={sourceLocation}
            destinationAddress={destinationLocation}
            sourceCoordinates={
              sourceLat !== "" ? { sourceLat, sourceLng } : null
            }
            destinationCoordinates={
              destinationLat !== "" ? { destinationLat, destinationLng } : null
            }
            locked={locked}
            isOverlayVisible={false}
            onSourceAddressChange={this.handleSourceAddressChange}
            onDestinationAddressChange={this.handleDestinationAddressChange}
          />
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top hidden-print">
            {transfer && locked ? (
              <button
                type="button"
                className="btn btn-outline-secondary ml-3 mt-3 float-left"
                onClick={() => {
                  window.print();
                }}
              >
                drucken
              </button>
            ) : null}
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.save}
              onDelete={this.delete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={Boolean(transfer)}
              locked={locked}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  transfer: PropTypes.object,
  write: PropTypes.bool,
  vehicles: PropTypes.array,
  combinations: PropTypes.array,
  resources: PropTypes.array,
  humanResources: PropTypes.array,
  orders: PropTypes.array,
  costCenters: PropTypes.array,
  ordersLoading: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  vehicles: state.vehicles.get("vehicles"),
  combinations: state.combinations.get("combinations"),
  resources: state.resources.get("resources"),
  humanResources: state.humanResources.get("users"),
  orders: state.transfers.get("orders"),
  costCenters: state.costCenters.get("costCenters"),
  ordersLoading: state.transfers.get("ordersLoading")
}))(Data);

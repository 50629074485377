import gql from "graphql-tag";
import { OrderDetail } from "./fragments";

export default (client, id) => dispatch => {
  dispatch({ type: "SET_ORDER_LOADING" });

  client
    .query({
      query: gql`
        query getOrder($id: ID!) {
          getOrder(id: $id) {
            ...OrderDetail
          }
        }
        ${OrderDetail}
      `,
      variables: { id },
      fetchPolicy: "network-only"
    })
    .then(response => {
      const order = response.data.getOrder;

      if (order !== undefined) {
        dispatch({ type: "SET_CHOSEN_ORDER", data: order });
      }
    });
};

import gql from 'graphql-tag';
import { CostCenterData } from './fragments';

import updateOrderCostCenter from './updateOrderCostCenter';
import getCostCenters from './getCostCenters';

export default (client, costCenter, orderId = null) => dispatch => {
  const query = gql`
    mutation createCostCenter(
      $value: Int
      $establishment: String
      $isContinuance: Boolean
      $buildingProject: String
      $customer: String
      $masterCompany: String
    ) {
      createCostCenter(
        value: $value
        establishment: $establishment
        is_continuance: $isContinuance
        building_project: $buildingProject
        customer: $customer
        master_customer: $masterCompany
      ) {
        ...CostCenterData
      }
    }
    ${CostCenterData}
  `;
  client
    .mutate({
      mutation: query,
      variables: costCenter
    })
    .then(response => {
      dispatch({
        type: 'COST_CENTER_ADDED',
        data: response.data.createCostCenter
      });
      window.history.pushState(
        {},
        null,
        `/cost_center/${response.data.createCostCenter.id}`
      );
      if (orderId)
        dispatch(
          updateOrderCostCenter(
            client,
            orderId,
            response.data.createCostCenter.id
          )
        );
    }).catch(e => {
        dispatch({
          type: 'COST_CENTER_DUPLICATE'
        });
        dispatch(getCostCenters(client));
        console.error(e);
  }
  );
};

import gql from "graphql-tag";
import store from "../store";

export default (client, id) => dispatch => {
  const requestUser = store.getState().main.get("loginName");

  client
    .mutate({
      mutation: gql`
        mutation validateOrderPeriod($requestUser: String!, $id: ID) {
          validateOrderPeriod(requestUser: $requestUser, id: $id) {
            id
          }
        }
      `,
      variables: {
        requestUser,
        id
      }
    })
    .then(response => {});
};

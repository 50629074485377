import { whenModified } from "./modifyHelper";
import gql from "graphql-tag";
import getAbsencesCalendar from "./getAbsencesCalendar";

export default (client, id, dateRange) => dispatch => {
  whenModified(client, "absence", () =>
    client
      .query({
        query: gql`
          query {
            getAbsencesInRange(from: "${dateRange[0]}", to: "${dateRange[1]}") {
              id
              from
              to
              type
              days
              humanResource {
                id
                lastname
                firstname
                position
              }
            }
          }
        `,
        fetchPolicy: "network-only"
      })
      .then(response => {
        dispatch({
          type: "ABSENCES_FOUND",
          data: response.data.getAbsencesInRange
        });
        dispatch(getAbsencesCalendar(client));
      })
  );
};

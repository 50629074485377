import Immutable from 'immutable';

const initialState = Immutable.Map({
  costCenterFrames: [],
  costCenters: [],
  chosenCostCenter: null,
  duplicateError: "",
});

export default (state = initialState, action) => {
  if (action.type === 'COST_CENTER_FRAMES_FOUND') {
    return state.set('costCenterFrames', action.data);
  }

  if (action.type === 'COST_CENTER_FRAME_ADDED') {
    const costCentersFrames = state.get('costCenterFrames');
    const newcostCentersFrame = action.data;
    return state.set(
      'costCenterFrames',
      costCentersFrames.concat(newcostCentersFrame)
    );
  }

  if (action.type === 'SET_NEW_COST_CENTER') {
    return state.set('chosenCostCenter', null);
  }

  if (action.type === 'COST_CENTERS_FOUND') {
    return state.set('costCenters', action.data);
  }

  if (action.type === 'SET_CHOSEN_COST_CENTER') {
    return state.set('chosenCostCenter', action.data);
  }

  if (action.type === 'COST_CENTER_ADDED') {
    const costCenters = state.get('costCenters');
    const newCostCenter = action.data;
    return state
      .set('costCenters', [].concat(...costCenters, newCostCenter))
      .set('chosenCostCenter', newCostCenter);
  }

  if (action.type === 'COST_CENTER_UPDATED') {
    const costCenters = state.get('costCenters');
    return state
      .set(
        'costCenters',
        costCenters.map(costCenter => {
          if (costCenter.id === action.data.id) return action.data;
          return costCenter;
        })
      )
      .set('chosenCostCenter', action.data);
  }

  if (action.type === 'COST_CENTER_DELETED') {
    const costCenters = state.get('costCenters');
    const otherCostCenter = costCenters.filter(
      costCenter => costCenter.id !== action.id
    );
    return state
      .set('costCenters', [].concat(...otherCostCenter))
      .set('chosenCostCenter', null);
  }


  if (action.type === 'COST_CENTER_DUPLICATE') {
    return state.set('duplicateError', "Diese nummer ist bereits vergeben.");
  }

  return state;
};

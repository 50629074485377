import gql from 'graphql-tag';
import { ReservationData } from './fragments';

export default (client, startDate, endDate) => dispatch => {
  dispatch({ type: 'RESERVATIONS_LOADING', data: 'resources' });

  client
    .query({
      query: gql`
        query getResources($startDate: Date, $endDate: Date) {
          getResources {
            id
            name
            number
            category
            subcategory
            establishment
            status
            leavingDate
            humanResources {
              id
              lastname
              firstname
              absences(start: $startDate, end: $endDate) {
                id
                from
                to
                type
                days
              }
            }
            reservations(start: $startDate, end: $endDate) {
              ...ReservationData
            }
          }
        }
        ${ReservationData}
      `,
      variables: {
        startDate,
        endDate
      },
      options: {
        fetchPolicy: 'network-only'
      }
    })
    .then(response => {
      dispatch({
        type: 'RESOURCES_WITH_RESERVATIONS_FOUND',
        data: response.data.getResources
      });
    });
};

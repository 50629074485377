import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from 'react-select';

import TitleBar from '../TitleBar';
import SimpleTable from '../SimpleTable';

import { filterBy } from '../../util/filterBy';

import getCostCenterFrames from '../../actions/getCostCenterFrames';
import createCostCenterFrame from '../../actions/createCostCenterFrame';

class App extends Component {
  constructor(props) {
    super(props);
    document.title = 'Kostenstellenrahmen';

    this.state = {
      from: '',
      to: '',
      establishment: props.establishment,
      errors: null
    };

    props.dispatch(getCostCenterFrames(props.client));

    this.listMapping = [
      { key: 'id', label: 'ID' },
      { key: 'from', label: 'Startnummer' },
      { key: 'to', label: 'Endnummer' },
      { key: 'establishment', label: 'Niederlassung' }
    ];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);

    this.validateFrame = this.validateFrame.bind(this);
    this.save = this.save.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleEstablishmentChange(value) {
    this.setState({ establishment: value });
  }

  validateFrame(frame) {
    const { costCenterFrames } = this.props;

    let error = null;

    if (frame.to <= frame.from)
      error = 'Endnummer muss nach Startnummer liegen.';

    if (frame.from < 0 || frame.to < 0) {
      error = 'Es dürfen keine negative Zahlen eigegeben werden.';
    }

    let intersection = false;
    costCenterFrames.forEach(elem => {
      intersection =
        intersection || (frame.to >= elem.from && frame.from <= elem.to);
    });

    if (intersection) error = 'Überschneidung festgestellt.';

    if (error) {
      this.setState({
        errors: error
      });
      return false;
    }

    this.setState({ errors: null });
    return true;
  }

  save() {
    const { state } = this;
    const { client, dispatch } = this.props;

    const frame = {
      from: parseInt(state.from, 10),
      to: parseInt(state.to, 0),
      establishment: state.establishment ? state.establishment.value : null
    };

    if (!this.validateFrame(frame)) return;

    dispatch(createCostCenterFrame(client, frame));
  }

  render() {
    let { costCenterFrames } = this.props;
    const { permissions, loginName, establishments } = this.props;
    const { from, to, establishment, errors } = this.state;

    const write =
      Boolean(
        permissions.find(p => p.name === 'cost_center_frames' && p.write)
      ) || loginName === 'klout';

    if (this.props.establishment) {
      costCenterFrames = filterBy(
        costCenterFrames,
        ['establishment'],
        this.props.establishment.value
      );
    }

    const title = (
      <Fragment>
        <a className="h4 mr-3" href="/cost_center">
          Kostenstellen
        </a>
        <span className="h4">Kostenstellenrahmen</span>
      </Fragment>
    );

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 padding-20">
              <TitleBar titleComponent={title} />

              <SimpleTable
                className="mt-3"
                mapping={this.listMapping}
                sortKey="id"
                data={costCenterFrames}
              />
            </div>

            <div className="col-12 padding-20">
              <div className="row">
                <div className="col-4" />

                <div className="col form-group">
                  <label className="small">Startnummer</label>
                  <input
                    type="number"
                    className={`form-control${errors ? ' is-invalid' : ''}`}
                    name="from"
                    value={from}
                    onChange={this.handleInputChange}
                    disabled={!write}
                  />
                  {errors ? (
                    <small className="form-text text-danger">{errors}</small>
                  ) : null}
                </div>

                <div className="col form-group">
                  <label className="small">Endnummer</label>
                  <input
                    type="number"
                    className={`form-control${errors ? ' is-invalid' : ''}`}
                    name="to"
                    value={to}
                    onChange={this.handleInputChange}
                    disabled={!write}
                  />
                </div>

                <div className="col-2">
                  <label className="small">Niederlassung</label>
                  <Select
                    isDisabled={!write}
                    isSearchable={false}
                    placeholder=""
                    value={establishment}
                    options={establishments}
                    onChange={this.handleEstablishmentChange}
                  />
                </div>

                {write ? (
                  <div className="col-auto form-group">
                    <label className="small">&nbsp;</label>
                    <button
                      className="btn btn-success form-control"
                      onClick={this.save}
                      disabled={!establishment || from === '' || to === ''}>
                      hinzufügen
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  costCenterFrames: PropTypes.array,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client'),
  establishment: state.main.get('establishment'),
  establishments: state.main.get('establishments'),
  costCenterFrames: state.costCenters.get('costCenterFrames'),
  permissions: state.main.get('permissions'),
  loginName: state.main.get('loginName')
}))(App);

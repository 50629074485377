import gql from 'graphql-tag';
import { OrderDetail } from './fragments';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription updateOrder($user: String!) {
          updateOrder(user: $user) {
            id
            order {
              ...OrderDetail
            }
          }
        }
        ${OrderDetail}
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { updateOrder } = data;
        dispatch({
          type: 'ORDER_UPDATED',
          data: updateOrder.order,
          preventUpdate: true
        });
        dispatch({
          type: 'DISPO_ORDER_UPDATED',
          data: updateOrder.order
        });
      }
    });
};

import gql from 'graphql-tag';

export default (
  client,
  humanResourceId,
  type,
  from,
  to,
  preventLocationChange = false
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createAbsence(
          $human_resource_id: ID
          $from: String
          $to: String
          $type: String
        ) {
          createAbsence(
            human_resource_id: $human_resource_id
            from: $from
            to: $to
            type: $type
          ) {
            id
            from
            to
            type
            days
            humanResource {
              id
              firstname
              lastname
              position
            }
          }
        }
      `,
      variables: {
        human_resource_id: humanResourceId,
        from,
        to,
        type
      }
    })
    .then(response => {
      dispatch({
        type: 'ABSENCE_ADDED',
        data: response.data.createAbsence
      });
      if (!preventLocationChange)
        window.history.pushState(
          {},
          null,
          `/absences/${response.data.createAbsence.id}`
        );
    });
};

import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';
import { CombinationData } from './fragments';

export default (client, id) => dispatch => {
  whenModified(client, 'combination', () =>
    client
      .query({
        query: gql`
          query {
            getCombinations {
              ...CombinationData
            }
          }
          ${CombinationData}
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({
          type: 'COMBINATIONS_FOUND',
          data: response.data.getCombinations
        });
        if (id !== undefined && id !== '') {
          const combination = response.data.getCombinations.find(
            obj => obj.id === id
          );
          if (combination !== undefined) {
            dispatch({ type: 'SET_CHOSEN_COMBINATION', data: combination });
          }
        }
      })
  );
};

import gql from 'graphql-tag';

import createContact from './createContact';
import updateContact from './updateContact';

export default (
  client,
  name,
  adress,
  zipcode,
  city,
  phone,
  fax,
  email,
  isNewContact,
  contact
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createCompany(
          $name: String
          $adress: String
          $zipcode: String
          $city: String
          $phone: String
          $fax: String
          $email: String
        ) {
          createCompany(
            name: $name
            adress: $adress
            zipcode: $zipcode
            city: $city
            phone: $phone
            fax: $fax
            email: $email
          ) {
            id
            name
            adress
            zipcode
            city
            phone
            fax
            email
          }
        }
      `,
      variables: {
        name,
        adress,
        zipcode,
        city,
        phone,
        fax,
        email
      }
    })
    .then(response => {
      dispatch({
        type: 'COMPANY_ADDED',
        data: response.data.createCompany
      });
      if(contact) {
        if (isNewContact) {
          dispatch(
            createContact(
              client,
              contact.salutation,
              contact.lastname,
              contact.firstname,
              contact.phone,
              contact.position,
              contact.mobile,
              contact.email,
              contact.fax,
              response.data.createCompany.id
            )
          );
        } else {
          dispatch(
            updateContact(
              client,
              contact.id,
              contact.salutation,
              contact.lastname,
              contact.firstname,
              contact.phone,
              contact.position,
              contact.mobile,
              contact.email,
              contact.fax,
              contact.notice,
              response.data.createCompany.id
            )
          );
        }
      } else {
        window.history.pushState({}, null, '/companies/' + response.data.createCompany.id);
      }
    });
};

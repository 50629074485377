import React from "react";
import PropTypes from "prop-types";

import CreatableSelect from "react-select/lib/Creatable";

import { matches, flattenObject } from "../../util/filterBy";

import { CustomSingleValue, CustomOption } from "./orderSelectComponents";

export default class OrderSelect extends React.Component {
  static filter = (option, filterString) => {
    const { data } = option;

    // eslint-disable-next-line
    if (data.__isNew__) return true;

    switch (data.type) {
      case "ORDER": {
        return matches(
          data,
          [
            "cost_center.value",
            "cost_center.customer",
            "cost_center.building_project",
            "description",
            "building_address",
            "id"
          ],
          filterString,
          flattenObject
        );
      }
      case "COST_CENTER": {
        return matches(
          data,
          ["value", "customer", "building_project", "id"],
          filterString,
          flattenObject
        );
      }
      default: {
        return true;
      }
    }
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, { action }) {
    const { onChange } = this.props;

    if (action !== "pop-value") onChange(value);
  }

  render() {
    const { value, options, loading, error, disabled } = this.props;

    const styles = {
      control: base => ({
        ...base,
        height: 60,
        borderColor: error ? "#dc3545" : base.borderColor
      })
    };

    if (
      value &&
      value.label !== "Sonstiges" &&
      value.label !== "Bauhof Leipzig" &&
      (!value.type || value.type > 0)
    ) {
      value.type = value.cost_center ? "ORDER" : "COST_CENTER";
    }

    return (
      <CreatableSelect
        isClearable={false}
        isDisabled={disabled}
        isLoading={loading}
        placeholder="Abruf wählen..."
        noOptionsMessage={() => "Name Eingeben"}
        noResultsText="Keine Übereinstimmung"
        loadingMessage={() => "Lade Abrufe..."}
        getOptionValue={option => option.id + option.label}
        styles={styles}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue
        }}
        filterOption={OrderSelect.filter}
        isValidNewOption={inputValue => inputValue.trim() !== ""}
        getNewOptionData={inputValue => ({
          id: "__new__",
          label: inputValue,
          value: inputValue,
          __isNew__: true
        })}
        value={value}
        options={[
          { id: "__new__", label: "Sonstiges", value: "Sonstiges" },
          { id: "__new__", label: "Bauhof Leipzig", value: "Bauhof Leipzig" },
          ...options
        ]}
        onChange={this.handleChange}
      />
    );
  }
}

OrderSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

import Immutable from "immutable";

const initialState = Immutable.Map({
  orders: [],
  chosenOrder: null,
  newOrder: false,
  orderLoading: false,
  ordersLoading: false,
  ordersLoaded: false,
  subcontractors: []
});

export default (state = initialState, action) => {
  if (action.type === "SET_NEW_ORDER") {
    return state
      .set("chosenOrder", null)
      .set("newOrder", true)
      .set("orderLoading", false);
  }

  if (action.type === "SET_ORDER_LOADING") {
    return state.set("orderLoading", true);
  }

  if (action.type === "SET_ORDERS_LOADING") {
    return state.set("ordersLoading", true);
  }

  if (action.type === "ORDERS_FOUND") {
    return state
      .set("orders", action.data)
      .set("ordersLoading", false)
      .set("ordersLoaded", true);
  }

  if (action.type === "SET_CHOSEN_ORDER") {
    return state
      .set("chosenOrder", action.data)
      .set("newOrder", false)
      .set("orderLoading", false);
  }

  if (action.type === "ORDER_ADDED") {
    // TODO: check if order fits into the current filtered range.
    const orders = state.get("orders");
    const newOrder = action.data;

    const nextState = state.set("orders", [].concat(...orders, newOrder));

    if (action.preventUpdate) {
      return nextState;
    }
    return nextState.set("chosenOrder", newOrder);
  }

  if (action.type === "ORDER_UPDATED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { id } = action.data;
    const nextOrders = orders.map(order => {
      if (order.id === id) return action.data;
      return order;
    });

    const nextState = state.set("orders", nextOrders);
    if (action.preventUpdate) {
      if (chosenOrder && chosenOrder.id === action.data.id) {
        return nextState.set("chosenOrder", null);
      }
      return nextState;
    }
    return nextState.set("chosenOrder", action.data);
  }

  if (action.type === "ORDER_DELETED") {
    const orders = state.get("orders");
    const orderId = action.orderId.toString();
    return state
      .set("orders", orders.filter(order => order.id !== orderId))
      .set("chosenOrder", null);
  }

  if (action.type === "ORDER_SOS_ADDED") {
    const orders = state.get("orders");
    let chosenOrder = state.get("chosenOrder");
    const { id, data } = action;

    const nextOrders = orders.map(order => {
      if (order.id === id) {
        chosenOrder = {
          ...order,
          scopeOfServices: order.scopeOfServices.concat(data)
        };
        return chosenOrder;
      }
      return order;
    });

    return state.set("orders", nextOrders).set("chosenOrder", chosenOrder);
  }

  if (action.type === "ADDED_RESOURCE_TO_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");

    if (chosenOrder) {
      const newResources = chosenOrder.resources.concat(action.data);

      const nextChosenOrder = {
        ...chosenOrder,
        resources: newResources
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }
  if (action.type === "REMOVED_RESOURCE_FROM_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const resourceId = action.data.id;

    if (chosenOrder) {
      const newResources = chosenOrder.resources.filter(
        elem => elem.id !== resourceId
      );

      const nextChosenOrder = {
        ...chosenOrder,
        resources: newResources
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }
  if (action.type === "ADDED_VEHICLE_TO_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");

    if (chosenOrder) {
      const newVehicles = chosenOrder.vehicles.concat(action.data);

      const nextChosenOrder = {
        ...chosenOrder,
        vehicles: newVehicles
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }
  if (action.type === "REMOVED_VEHICLE_FROM_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const vehicleId = action.data.id;

    if (chosenOrder) {
      const newVehicles = chosenOrder.vehicles.filter(
        elem => elem.id !== vehicleId
      );

      const nextChosenOrder = {
        ...chosenOrder,
        vehicles: newVehicles
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }
  if (action.type === "ADDED_HUMAN_RESOURCE_TO_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");

    if (chosenOrder) {
      const newHumanResources = chosenOrder.humanResources.concat(action.data);

      const nextChosenOrder = {
        ...chosenOrder,
        humanResources: newHumanResources
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }
  if (action.type === "REMOVED_HUMAN_RESOURCE_FROM_ORDER") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const humanResourceId = action.data.id;

    if (chosenOrder) {
      const newHumanResources = chosenOrder.humanResources.filter(
        elem => elem.id !== humanResourceId
      );

      const nextChosenOrder = {
        ...chosenOrder,
        humanResources: newHumanResources
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "TRANSPORTER_ADDED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { data } = action;

    if (chosenOrder) {
      const nextTransporters = chosenOrder.transporters
        .concat(data)
        .filter(transporter => transporter.id !== "new");

      const nextChosenOrder = {
        ...chosenOrder,
        transporters: nextTransporters
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "TRANSPORTER_UPDATED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { data } = action;

    if (chosenOrder) {
      const nextTransporters = chosenOrder.transporters.map(transporter => {
        if (transporter.id === data.id) return data;
        return transporter;
      });

      const nextChosenOrder = {
        ...chosenOrder,
        transporters: nextTransporters
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "TRANSPORTER_REMOVED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { success, id } = action;
    if (!success) return state;

    if (chosenOrder) {
      const nextTransporters = chosenOrder.transporters.filter(
        transporter => transporter.id !== id
      );

      const nextChosenOrder = {
        ...chosenOrder,
        transporters: nextTransporters
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "DROPZONE_ADDED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { data } = action;

    if (chosenOrder) {
      const nextDropzones = chosenOrder.dropzones
        .concat(data)
        .filter(dropzone => dropzone.id !== "new");

      const nextChosenOrder = {
        ...chosenOrder,
        dropzones: nextDropzones
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "DROPZONE_UPDATED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { data } = action;

    if (chosenOrder) {
      const nextDropzones = chosenOrder.dropzones.map(dropzone => {
        if (dropzone.id === data.id) return data;
        return dropzone;
      });

      const nextChosenOrder = {
        ...chosenOrder,
        dropzones: nextDropzones
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "DROPZONE_REMOVED") {
    const orders = state.get("orders");
    const chosenOrder = state.get("chosenOrder");
    const { success, id } = action;
    if (!success) return state;

    if (chosenOrder) {
      const nextDropzones = chosenOrder.dropzones.filter(
        dropzone => dropzone.id !== id
      );

      const nextChosenOrder = {
        ...chosenOrder,
        dropzones: nextDropzones
      };

      const nextOrders = orders.map(order => {
        if (order.id === chosenOrder.id) return nextChosenOrder;
        return order;
      });

      return state
        .set("orders", nextOrders)
        .set("chosenOrder", nextChosenOrder);
    }
  }

  if (action.type === "SUBCONTRACTORS_FOUND") {
    return state.set("subcontractors", action.data);
  }

  // if (action.type === "UPDATE_MACHINE") {
  //   const updatedOrder = state.get("chosenOrder");
  //   if (action.data && updatedOrder && updatedOrder.resources) {
  //     const machine = updatedOrder.resources.find(r => {
  //       return r.id === action.data.id;
  //     });
  //     if (machine) {
  //       const newResourceArr = [];
  //       updatedOrder.resources.forEach(r => {
  //         if (r.id !== machine.id) {
  //           newResourceArr.push(r);
  //         }
  //       });
  //       machine.from = action.data.from;
  //       machine.to = action.data.to;
  //       newResourceArr.push(machine);
  //       updatedOrder.resources = newResourceArr;
  //     }
  //   }
  //   return state.set("chosenOrder", updatedOrder);
  // }
  // if (action.type === "UPDATE_VEHICLE") {
  //   const updatedOrder = state.get("chosenOrder");
  //   if (action.data && updatedOrder && updatedOrder.vehicles) {
  //     const vehicle = updatedOrder.vehicles.find(v => {
  //       return v.id === action.data.id;
  //     });
  //     if (vehicle) {
  //       const newVehicleArr = [];
  //       updatedOrder.resources.forEach(v => {
  //         if (v.id !== vehicle.id) {
  //           newVehicleArr.push(v);
  //         }
  //       });
  //       vehicle.from = action.data.from;
  //       vehicle.to = action.data.to;
  //       newVehicleArr.push(vehicle);
  //       updatedOrder.vehicles = newVehicleArr;
  //     }
  //   }
  //   return state.set("chosenOrder", updatedOrder);
  // }
  // if (action.type === "UPDATE_HUMAN_RESOURCE") {
  //   const updatedOrder = state.get("chosenOrder");
  //   if (action.data && updatedOrder && updatedOrder.humanResources) {
  //     const humanResource = updatedOrder.humanResources.find(hr => {
  //       return hr.id === action.data.id;
  //     });
  //     if (humanResource) {
  //       const newHumanResourceArr = [];
  //       updatedOrder.humanResources.forEach(hr => {
  //         if (hr.id !== humanResource.id) {
  //           newHumanResourceArr.push(hr);
  //         }
  //       });
  //       humanResource.from = action.data.from;
  //       humanResource.to = action.data.to;
  //       newHumanResourceArr.push(humanResource);
  //       updatedOrder.humanResources = newHumanResourceArr;
  //     }
  //   }
  //   return state.set("chosenOrder", { ...updatedOrder });
  // }
  // if (action.type === "DISPO_HRR_REMOVED") {
  //   const updatedOrder = state.get("chosenOrder");
  //   if (action.data && updatedOrder && updatedOrder.humanResources) {
  //     updatedOrder.humanResources.forEach(hr => {
  //       const newResIdsArr = [];
  //       if (hr.resource_ids) {
  //         hr.resource_ids.forEach(rsids => {
  //           if (rsids.id !== action.data) {
  //             newResIdsArr.push(rsids);
  //           }
  //         });
  //         hr.resource_ids = newResIdsArr;
  //       }
  //     });
  //   }
  //   return state.set("chosenOrder", { ...updatedOrder });
  // }

  return state;
};

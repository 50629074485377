import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TitleBar from "../TitleBar";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import {
  dateToMonthRange,
  monthToString,
  dayToDayShort
} from "../../util/dateHelper";
import getUsers from "../../actions/getUsers";
import getDisposHumanResource from "../../actions/getDisposHumanResource";
import getOrdersByHRID from "../../actions/getOrdersByHRID";

class App extends Component {
  constructor(props) {
    super(props);

    const dateRange = dateToMonthRange(new Date());
    this.state = {
      init: true,
      showCalendar: false,
      date: dateRange,
      dateString: `${monthToString(
        dateRange[0].getMonth()
      )} ${dateRange[1].getFullYear()}`,
      listMapping: [{ key: "humanResource", label: "Mitarbeiter" }],
      userList: null,
      filteredUserList: null
    };

    const { client, dispatch } = props;

    document.title = "Kalender";
    dispatch(getUsers(client, undefined));
    dispatch(getDisposHumanResource(client));
    this.addMonth = this.addMonth.bind(this);
    this.subMonth = this.subMonth.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);

    for (let i = 1; i <= this.state.date[1].getDate(); i++) {
      const tmp = new Date(
        this.state.date[1].getFullYear(),
        this.state.date[1].getMonth(),
        i
      );
      this.state.listMapping.push({
        key: i.toString(),
        label: `${dayToDayShort(tmp.getDay())} ${i}`
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateString !== this.state.dateString || this.state.init) {
      const newListMapping = [
        {
          key: "humanResource",
          label: "Mitarbeiter"
        }
      ];
      for (let i = 1; i <= this.state.date[1].getDate(); i++) {
        const tmp = new Date(
          this.state.date[1].getFullYear(),
          this.state.date[1].getMonth(),
          i
        );
        newListMapping.push({
          key: i.toString(),
          label: `${dayToDayShort(tmp.getDay())} ${i}`,
          date: tmp
        });
      }
      this.setState(
        { listMapping: newListMapping, init: false },
        () => this.filterUsers
      );
    }
    if (
      this.props.users &&
      prevProps.users &&
      this.props.users.length !== prevProps.users.length
    ) {
      this.setUsers();
    }
    if (
      this.props.disposHumanResource &&
      prevProps.disposHumanResource &&
      this.props.disposHumanResource.length !==
        prevProps.disposHumanResource.length
    ) {
      this.setUsers();
    }
  }

  filterUsers() {
    const { date, userList } = this.state;
    if (userList && date) {
      const monthStart = moment(date[0]);
      const monthEnd = moment(date[1]);
      const filteredUserList = userList.map(user => {
        const dayIndexes = [];
        if (user.absences) {
          user.absences.forEach(absence => {
            const from = moment(absence.from);
            const to = moment(absence.to);
            if (from.isBetween(monthStart, monthEnd, "day", "[]")) {
              if (to.isBetween(monthStart, monthEnd, "day", "[]")) {
                //  [..{}..]
                for (let i = from.date(); i <= to.date(); i++) {
                  dayIndexes.push({
                    color:
                      absence.type === "Krank" ||
                      absence.type === "Schule" ||
                      absence.type === "Feiertag"
                        ? "#f8d7da"
                        : absence.type === ""
                        ? "#FFFFFF"
                        : "#fff3cd",
                    type: absence.type,
                    key: i
                  });
                }
              } else {
                //  [..{..]}
                for (let i = from.date(); i <= monthEnd.date(); i++) {
                  dayIndexes.push({
                    color:
                      absence.type === "Krank" ||
                      absence.type === "Schule" ||
                      absence.type === "Feiertag"
                        ? "#f8d7da"
                        : absence.type === ""
                        ? "#FFFFFF"
                        : "#fff3cd",
                    type: absence.type,
                    key: i
                  });
                }
              }
            } else {
              if (to.isBetween(monthStart, monthEnd, "day", "[]")) {
                //  {[..}..]
                for (let i = 1; i <= to.date(); i++) {
                  dayIndexes.push({
                    color:
                      absence.type === "Krank" ||
                      absence.type === "Schule" ||
                      absence.type === "Feiertag"
                        ? "#f8d7da"
                        : absence.type === ""
                        ? "#FFFFFF"
                        : "#fff3cd",
                    type: absence.type,
                    key: i
                  });
                }
              } else {
                //  {} [....] {}
              }
            }
          });
        }
        const monthOrders = [];
        if (user.orders) {
          user.orders.forEach(order => {
            const from = moment(order.from);
            const to = moment(order.to);
            const hours =
              to.hour() - from.hour() > 14 ? 14 : to.hour() - from.hour();
            const leftOffset = from.hour() - 4 > 14 ? 14 : from.hour() - 4;
            if (from.isBetween(monthStart, monthEnd, "day", "[]")) {
              if (to.isBetween(monthStart, monthEnd, "day", "[]")) {
                //  [..{}..]
                for (let i = from.date(); i <= to.date(); i++) {
                  const day = monthOrders.find(order => order.day === i);
                  if (day) {
                    day.orders.push({
                      order: order.orderId,
                      from: from.format("HH:mm"),
                      to: to.format("HH:mm"),
                      hours,
                      leftOffset
                    });
                  }
                  monthOrders.push({
                    day: i,
                    orders: [
                      {
                        order: order.orderId,
                        from: from.format("HH:mm"),
                        to: to.format("HH:mm"),
                        hours,
                        leftOffset
                      }
                    ]
                  });
                }
              } else {
                //  [..{..]}
                for (let i = from.date(); i <= monthEnd.date(); i++) {
                  const day = monthOrders.find(order => order.day === i);
                  if (day) {
                    day.orders.push({
                      order: order.orderId,
                      from: from.format("HH:mm"),
                      to: to.format("HH:mm"),
                      hours,
                      leftOffset
                    });
                  }
                  monthOrders.push({
                    day: i,
                    orders: [
                      {
                        order: order.orderId,
                        from: from.format("HH:mm"),
                        to: to.format("HH:mm"),
                        hours,
                        leftOffset
                      }
                    ]
                  });
                }
              }
            } else {
              if (to.isBetween(monthStart, monthEnd, "day", "[]")) {
                //  {[..}..]
                for (let i = 1; i <= to.date(); i++) {
                  const day = monthOrders.find(order => order.day === i);
                  if (day) {
                    day.orders.push({
                      order: order.orderId,
                      from: from.format("HH:mm"),
                      to: to.format("HH:mm"),
                      hours,
                      leftOffset
                    });
                  }
                  monthOrders.push({
                    day: i,
                    orders: [
                      {
                        order: order.orderId,
                        from: from.format("HH:mm"),
                        to: to.format("HH:mm"),
                        hours,
                        leftOffset
                      }
                    ]
                  });
                }
              } else {
                //  {} [....] {}
              }
            }
          });
        }
        return { ...user, absences: dayIndexes, orders: monthOrders };
      });
      this.setState({ filteredUserList });
    }
  }

  setUsers() {
    const { users, disposHumanResource } = this.props;
    const userList = users.map(user => {
      return {
        id: user.id,
        name: user.firstname + " " + user.lastname,
        absences: user.absences,
        establishment: user.establishment
      };
    });
    disposHumanResource.forEach(dispoEntry => {
      if (dispoEntry) {
        const user = userList.find(
          user => user.id === dispoEntry.humanResource.id
        );
        if (user) {
          if (user.orders) {
            user.orders.push({
              orderId: dispoEntry.order_id,
              from: dispoEntry.from,
              to: dispoEntry.to
            });
          } else {
            user.orders = [
              {
                orderId: dispoEntry.order_id,
                from: dispoEntry.from,
                to: dispoEntry.to
              }
            ];
          }
        }
      }
    });
    this.setState({ userList }, () => this.filterUsers());
  }

  toggleCalendar() {
    const { showCalendar } = this.state;
    this.setState({ showCalendar: !showCalendar });
  }

  addMonth() {
    const newDate = this.state.date[0];
    newDate.setMonth(this.state.date[0].getMonth() + 1);
    this.setState(
      {
        date: dateToMonthRange(newDate),
        dateString: `${monthToString(
          newDate.getMonth()
        )} ${newDate.getFullYear()}`
      },
      this.filterUsers
    );
  }

  subMonth() {
    const newDate = this.state.date[0];
    newDate.setMonth(this.state.date[0].getMonth() - 1);
    this.setState(
      {
        date: dateToMonthRange(newDate),
        dateString: `${monthToString(
          newDate.getMonth()
        )} ${newDate.getFullYear()}`
      },
      this.filterUsers
    );
  }

  render() {
    const { dateString, filteredUserList, listMapping } = this.state;

    // const write =
    //   Boolean(permissions.find(p => p.name === "absence" && p.write)) ||
    //   loginName === "klout";

    const title = (
      <Fragment>
        <a className="h4 mr-3" href="/absences">
          Tabelle
        </a>
        <span className="h4 mr-3">Kalender</span>
      </Fragment>
    );

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 padding-20 hidden-print">
              <TitleBar titleComponent={title}>
                <div className="col d-flex justify-content-end">
                  <div>
                    <button
                      type="button"
                      onClick={this.subMonth}
                      className="btn btn-outline-primary btn-sm px-3"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                      className="btn btn-outline-primary btn-sm px-3 mx-3"
                      style={{ width: 140 }}
                      onClick={this.toggleCalendar}
                    >
                      {dateString}
                    </button>
                    <button
                      type="button"
                      onClick={this.addMonth}
                      className="btn btn-outline-primary btn-sm px-3"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>
                </div>
              </TitleBar>
              <table
                className="table table-sm no-scrollbar"
                style={{
                  height: "calc(100vh - 170px)",
                  display: "table"
                }}
              >
                <thead className="sticky-top">
                  <tr
                    style={{
                      display: "table",
                      width: "100%",
                      tableLayout: "fixed"
                    }}
                  >
                    {listMapping &&
                      listMapping.map((entry, index) => {
                        return (
                          <td
                            key={entry.key + index}
                            className="border-left border-right border-bottom"
                            style={index === 0 ? { width: "150px" } : {}}
                          >
                            <p className="small">{entry.label}</p>
                          </td>
                        );
                      })}
                  </tr>
                </thead>
                <tbody
                  style={{
                    height: "calc(100vh - 210px)",
                    display: "block",
                    width: "100%",
                    overflowY: "scroll"
                  }}
                >
                  {filteredUserList &&
                    filteredUserList.map((entry, index) => {
                      if (
                        !this.props.establishment ||
                        entry.establishment === this.props.establishment.label
                      ) {
                        return (
                          <tr
                            key={index}
                            style={{
                              display: "table",
                              width: "100%",
                              tableLayout: "fixed"
                            }}
                          >
                            <td
                              key={entry.name}
                              style={{
                                width: "150px"
                              }}
                              className="small border-left border-right"
                            >
                              {entry.name}
                            </td>
                            {listMapping &&
                              listMapping.map(date => {
                                if (date.key === "humanResource") return;
                                let color = "#ffffff";
                                let type = "Unbekannt";
                                const absence = entry.absences.find(
                                  o => o.key === parseInt(date.key)
                                );

                                if (absence) {
                                  color = absence.color;
                                  type = absence.type;
                                }

                                const orders = entry.orders.find(
                                  order => order.day === parseInt(date.key)
                                );
                                return (
                                  <td
                                    key={entry.name + date.key}
                                    className="small p-0 border-right"
                                    style={{ position: "relative" }}
                                  >
                                    {orders ? (
                                      orders.orders.length <= 4 ? (
                                        orders.orders.map(order => {
                                          return (
                                            <div
                                              style={
                                                order.leftOffset +
                                                  order.hours <=
                                                14
                                                  ? {
                                                      position: "absolute",
                                                      left:
                                                        7.1 * order.leftOffset +
                                                        "%",
                                                      top: "15%",
                                                      height: "70%",
                                                      width:
                                                        order.hours * 7.1 + "%",
                                                      backgroundImage:
                                                        "linear-gradient(90deg, #d4edda " +
                                                        (100 -
                                                          (16 -
                                                            order.hours +
                                                            order.leftOffset)) +
                                                        "%, " +
                                                        " #000000 100%)"
                                                    }
                                                  : {
                                                      position: "absolute",
                                                      left:
                                                        7.1 * order.leftOffset +
                                                        "%",
                                                      top: "15%",
                                                      height: "70%",
                                                      width:
                                                        7.1 *
                                                          (14 -
                                                            order.leftOffset) +
                                                        "%",
                                                      backgroundImage:
                                                        "linear-gradient(90deg, #d4edda " +
                                                        (100 -
                                                          (16 -
                                                            (order.hours +
                                                              order.leftOffset >
                                                            14
                                                              ? 14
                                                              : order.hours +
                                                                order.leftOffset))) +
                                                        "% , #dd0000 100% )"
                                                    }
                                              }
                                              onClick={() => {
                                                window.open(
                                                  "/order/" + order.order
                                                );
                                              }}
                                              title={
                                                order.from + " bis " + order.to
                                              }
                                            >
                                              &nbsp;
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div
                                          className="my-1"
                                          style={{
                                            display: "inline-block",
                                            height: "100%",
                                            width: "100%",
                                            backgroundColor: "#d1ecf1"
                                          }}
                                        >
                                          &nbsp;
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className="my-1"
                                        style={{
                                          display: "inline-block",
                                          backgroundColor: color,
                                          height: "100%",
                                          width: "100%"
                                        }}
                                        title={
                                          type !== "Unbekannt" ? type : null
                                        }
                                      >
                                        &nbsp;
                                      </div>
                                    )}
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  absences: PropTypes.array,
  absenceTypes: PropTypes.array,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  establishment: state.main.get("establishment"),
  users: state.humanResources.get("users"),
  disposHumanResource: state.humanResources.get("disposHumanResource")
}))(App);

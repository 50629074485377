import gql from 'graphql-tag';
import _ from 'lodash';
import { VehicleData } from './fragments';

import createHumanResourceVehicle from './createHumanResourceVehicle';
import deleteHumanResourceVehicle from './deleteHumanResourceVehicle';

export default (
  client,
  vehicle,
  brand,
  type,
  licensePlate,
  image,
  category,
  comment,
  establishment,
  humanResources,
  implementation,
  number,
  status,
  leavingDate
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateVehicle(
          $vehicleId: ID
          $brand: String
          $type: String
          $licensePlate: String
          $image: String
          $category: String
          $comment: String
          $establishment: String
          $implementation: String
          $number: String
          $status: String
          $leavingDate: Date
        ) {
          updateVehicle(
            id: $vehicleId
            brand: $brand
            type: $type
            licensePlate: $licensePlate
            image: $image
            category: $category
            comment: $comment
            establishment: $establishment
            implementation: $implementation
            number: $number
            status: $status
            leavingDate: $leavingDate
          ) {
            ...VehicleData
          }
        }
        ${VehicleData}
      `,
      variables: {
        vehicleId: vehicle.id,
        brand,
        type,
        licensePlate,
        image,
        category,
        comment,
        establishment,
        implementation,
        number,
        status,
        leavingDate
      }
    })
    .then(response => {
      const addedHumanResources = humanResources.filter(
        h => vehicle.humanResources.map(c => c.id).indexOf(h.id) === -1
      );
      const removedHumanResources = _.difference(
        vehicle.humanResources,
        humanResources
      );

      dispatch(
        deleteHumanResourceVehicle(client, removedHumanResources, vehicle.id)
      );

      dispatch(
        createHumanResourceVehicle(client, addedHumanResources, vehicle.id)
      );

      dispatch({
        type: 'VEHICLE_UPDATED',
        data: response.data.updateVehicle
      });
    });
};

import gql from "graphql-tag";
import { ReservationData } from "./fragments";
import store from "../store";
import getOrder from "./getOrder";

export default (
  client,
  id,
  resourceId,
  resourceType,
  from,
  to,
  status,
  oldResourceId,
  note
) => dispatch => {
  const user = store.getState().main.get("loginName");

  client
    .mutate({
      mutation: gql`
        mutation updateReservation(
          $user: String!
          $id: ID
          $resource_id: ID
          $old_resource_id: ID
          $type: String
          $from: String
          $to: String
          $status: String
          $note: String
        ) {
          updateReservation(
            user: $user
            id: $id
            resource_id: $resource_id
            old_resource_id: $old_resource_id
            type: $type
            from: $from
            to: $to
            status: $status
            note: $note
          ) {
            ...ReservationData
          }
        }
        ${ReservationData}
      `,
      variables: {
        user,
        id,
        resource_id: resourceId,
        old_resource_id: oldResourceId,
        type: resourceType,
        from,
        to,
        status,
        note
      }
    })
    .then(response => {
      setTimeout(
        () =>
          dispatch({
            type: "RESERVATION_UPDATED",
            data: response.data.updateReservation,
            resourceId,
            oldResourceId,
            resourceType,
            id
          }),
        500
      );
    });
};

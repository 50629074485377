import gql from 'graphql-tag';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription deleteReservation($user: String!) {
          deleteReservation(user: $user) {
            id
            resourceId
            type
          }
        }
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { deleteReservation } = data;
        dispatch({
          type: 'RESERVATION_DELETED',
          data: {
            id: deleteReservation.id,
            resourceId: deleteReservation.resourceId,
            type: deleteReservation.type
          }
        });
      }
    });
};

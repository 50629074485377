import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

import cog7 from './cog_7_dark.svg';
import cog10 from './cog_10_dark.svg';

class LoadingIndicator extends Component {
  render() {
    const { className, show } = this.props;

    if (show) {
      return (
        <div className={className}>
          <div className="lois-cogs">
            <img
              className="lois-cog-7"
              alt=""
              src={cog7}
              width="12"
              height="12"
            />
            <img
              className="lois-cog-10"
              alt=""
              src={cog10}
              width="14"
              height="14"
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  loadingMessage: PropTypes.string
};

export default LoadingIndicator;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { faCheck, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHover } from "react-use";

import * as fileStack from "filestack-js";
import DropZone from "../DropZone";
import {
  FILESTACK_API_KEY, FILESTACK_API_KEY_NEW,
  FILESTACK_POLICY, FILESTACK_POLICY_NEW,
  FILESTACK_SIGNATURE, FILESTACK_SIGNATURE_NEW
} from "../../Workshop/filestackCredentials";
import { holidays2 } from "../../../util/dateHelper";

const weekdays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

const color = state => {
  const baseStyle = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    top: "50%"
  };
  if (state === "holiday") {
    return {
      ...baseStyle,
      backgroundColor: "#B6B6B6",
      color: "black"
    };
  }

  if (state === "Krank" || state === "Schule" || state === "Feiertag") {
    return {
      ...baseStyle,
      backgroundColor: "#FF0000",
      color: "white"
    };
  }

  return {
    ...baseStyle,
    backgroundColor: "#FF8800",
    color: "black"
  };
};

const EmployeeHoursRow = ({
  date,
  entries,
  user,
  index,
  setActive,
  updateEntry,
  absence
}) => {
  const client = useMemo(
    () =>
      fileStack.init(FILESTACK_API_KEY, {
        security: {
          policy: FILESTACK_POLICY,
          signature: FILESTACK_SIGNATURE
        }
      }),
    []
  );

  const clientNew = useMemo(
    () =>
      fileStack.init(FILESTACK_API_KEY_NEW, {
        security: {
          policy: FILESTACK_POLICY_NEW,
          signature: FILESTACK_SIGNATURE_NEW
        }
      }),
    []
  );

  const dateString = date.toISOString().slice(0, 10);
  const userId = useMemo(() => parseInt(user.id, 10), [user]);
  const possibleEntries = useMemo(
    () =>
      entries.filter(
        e =>
          e.date === dateString &&
          e.human_resource_id === userId &&
          (e.value ||
            e.comment ||
            e.bonusDifficulty ||
            e.bonusPerformance ||
            e.file)
      ),
    [dateString, entries, userId]
  );
  if (possibleEntries.length > 1) {
    console.warn(possibleEntries);
  }

  if (possibleEntries.length === 0) {
    possibleEntries.push({
      date: dateString,
      human_resource_id: userId
    });
  }
  const entry = possibleEntries[0];

  const value = entry.value || "";
  const comment = entry.comment || "";
  const bonusDifficulty = entry.bonusDifficulty || "";
  const bonusPerformance = entry.bonusPerformance || "";
  const file = entry.file || undefined;

  const element = hovered => (
    <div
      className="alert-success rounded small py-2 text-center cursor-pointer"
      onClick={() => {
        if (hovered && file) {
          clientNew
            .remove(file)
            .then(() => {
              entry.file = null;
              updateEntry(entry);
            })
            .catch(err => {
              if (err.message === "Request failed with status code 404") {
                client
                    .remove(file)
                    .then(() => {
                      entry.file = null;
                      updateEntry(entry);
                    })
                    .catch(err => {
                      if (err.message === "Request failed with status code 404") {
                        entry.file = null;
                        updateEntry(entry);
                      } else {
                        console.error(err);
                      }
                    });
                entry.file = null;
                updateEntry(entry);
              } else {
                console.error(err);
              }
            });
        }
      }}
    >
      <FontAwesomeIcon icon={hovered ? faTrash : faCheck} />
    </div>
  );
  const [Hoverable] = useHover(element);
  const year = date.year();
  const weekdayNumber = date.weekday();
  const day = date.date();

  const weekday = weekdays[weekdayNumber];

  let isHoliday = false;
  let isWeekend = false;
  if (weekdayNumber >= 5) {
    isWeekend = true;
  }

  const yearHoliday = holidays2[year];
  const holidayName = yearHoliday[dateString];

  if (holidayName) {
    isHoliday = true;
  }

  return (
    <div
      className={`row border-top border-bottom ${
        isWeekend || isHoliday ? "bg-light" : ""
      }`}
    >
      <div className="col-1 small p-1 mt-1" style={absence ? { top: -2 } : {}}>
        {day < 10 ? `0${day}` : day} {weekday}
      </div>
      <div className="col-1 small p-1 mt-1">
        {absence && (
          <div className="badge" style={color(absence)} title={absence}>
            {absence}
          </div>
        )}
        {holidayName && (
          <div className="badge" style={color("holiday")} title={holidayName}>
            {holidayName}
          </div>
        )}
      </div>
      <div className="col-1 py-1 px-1">

        <input
          tabIndex={index + 1}
          type="text"
          className="form-control w-100 form-control-sm"
          onFocus={() => setActive(entry)}
          onChange={e => {
            entry.value = e.target.value;
            updateEntry(entry);
          }}
          value={value || ""}
          disabled={absence}
        />
      </div>
      <div className="col-1 py-1">
        {file
          ? Hoverable
          : !absence && (
              <DropZone
                accept={"application/pdf"}
                isFileStack={true}
                onDrop={handle => {
                  entry.file = handle;
                  updateEntry(entry);
                }}
              >
                {percentage => (
                  <div
                    className="alert-danger rounded small py-2 text-center cursor-pointer"
                    onClick={() => {}}
                  >
                    {percentage ? (
                      `${percentage}%`
                    ) : (
                      <FontAwesomeIcon icon={faTimes} />
                    )}
                  </div>
                )}
              </DropZone>
            )}
      </div>

      <div className="col-1 p-1">
        <input
          type="number"
          step="0.01"
          className="form-control w-100 form-control-sm"
          onChange={e => {
            entry.bonusDifficulty = parseFloat(e.target.value);
            updateEntry(entry);
          }}
          value={bonusDifficulty}
        />
      </div>

      <div className="col-1 p-1">
        <input
          type="number"
          className="form-control w-100 form-control-sm"
          onChange={e => {
            entry.bonusPerformance = parseInt(e.target.value, 10);
            updateEntry(entry);
          }}
          value={bonusPerformance}
        />
      </div>

      <div className="col-6 p-1">
        <input
          type="text"
          className="form-control w-100 form-control-sm"
          onChange={e => {
            entry.comment = e.target.value;
            updateEntry(entry);
          }}
          value={comment}
        />
      </div>
    </div>
  );
};

EmployeeHoursRow.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object,
  index: PropTypes.number,
  entries: PropTypes.array,
  setActive: PropTypes.func,
  updateEntry: PropTypes.func,
  absence: PropTypes.array
};

export default EmployeeHoursRow;

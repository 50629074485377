import gql from 'graphql-tag';

export default (client, value) => dispatch =>
  client
    .mutate({
      mutation: gql`
        mutation createSubcontractor($subcontractor: String) {
          createSubcontractor(value: $subcontractor) {
            id
            value
          }
        }
      `,
      variables: {
        subcontractor: value
      }
    })
    .then(resp => resp.data.createSubcontractor)
    .then(data =>
      dispatch({ type: 'SUBCONTRACTORS_FOUND', data: data.map(d => d.value) })
    );

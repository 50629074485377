import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./style.scss";

import Gant from "./Gant";

import getResourcesWithReservations from "../../actions/getResourcesWithReservations";
import getVehiclesWithReservations from "../../actions/getVehiclesWithReservations";
import getCombinationsWithReservations from "../../actions/getCombinationsWithReservations";
import getTransfersForPeriod from "../../actions/getTransfersForPeriod";
import getOrdersSubcontractor from "../../actions/getOrdersSubcontractor";

import getCostCenters from "../../actions/getCostCenters";
import getSubcontractors from "../../actions/getSubcontractors";
import getContacts from "../../actions/getContacts";
import getCompanies from "../../actions/getCompanies";

class App extends Component {
  constructor(props) {
    super(props);
    let { start, end } = props;
    const {
      client,
      dispatch,
      overrideStart,
      overrideEnd,
      resourcesLoaded,
      vehiclesLoaded,
      combinationsLoaded,
      transfersLoaded,
      ordersLoaded
    } = props;

    let override = false;
    if (overrideStart && overrideEnd) {
      start = overrideStart;
      end = overrideEnd;
      override = true;
    }

    if (!resourcesLoaded || override) {
      dispatch(getResourcesWithReservations(client, start, end));
    }

    if (!vehiclesLoaded || override) {
      dispatch(getVehiclesWithReservations(client, start, end));
    }

    if (!combinationsLoaded || override) {
      dispatch(getCombinationsWithReservations(client, start, end));
    }

    if (!transfersLoaded || override) {
      dispatch(getTransfersForPeriod(client, start, end));
    }

    if (!ordersLoaded || override) {
      dispatch(getOrdersSubcontractor(client, start, end));
    }

    dispatch(getSubcontractors(client));
    dispatch(getCostCenters(client));
    dispatch(getContacts(client));
    dispatch(getCompanies(client));

    document.title = "Disposition";
  }

  render() {
    const {
      history,
      start,
      end,
      overrideStart,
      overrideEnd,
      disableWrite,
      hideTimeControl,
      resourcesLoaded,
      vehiclesLoaded,
      combinationsLoaded,
      loading
    } = this.props;

    const isLoading =
      !resourcesLoaded || !vehiclesLoaded || !combinationsLoaded || loading;

    return (
      <Fragment>
        <div className="container-fluid p-0">
          <Gant
            history={history}
            startDate={overrideStart || start}
            endDate={overrideEnd || end}
            loading={isLoading}
            write={!disableWrite}
            hideTimeControl={hideTimeControl}
          />
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  start: PropTypes.object,
  end: PropTypes.object,
  overrideStart: PropTypes.object,
  overrideEnd: PropTypes.object,
  resourcesLoaded: PropTypes.bool,
  vehiclesLoaded: PropTypes.bool,
  combinationsLoaded: PropTypes.bool,
  transfersLoaded: PropTypes.bool,
  ordersLoaded: PropTypes.bool,
  loading: PropTypes.bool,
  disableWrite: PropTypes.bool,
  hideTimeControl: PropTypes.bool,
  contacts: PropTypes.array,
  companies: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  start: state.disposition.get("start"),
  end: state.disposition.get("end"),
  resourcesLoaded: state.disposition.get("resourcesLoaded"),
  vehiclesLoaded: state.disposition.get("vehiclesLoaded"),
  combinationsLoaded: state.disposition.get("combinationsLoaded"),
  transfersLoaded: state.disposition.get("transfersLoaded"),
  ordersLoaded: state.disposition.get("ordersLoaded"),
  loading: state.disposition.get("loading")
}))(App);

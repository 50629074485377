import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';

import { faPlusCircle, faArchive } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Task from './task';
import EditTask from './editTask';

export default class Column extends Component {
  render() {
    const {
      column,
      tasks,
      dragStartId,
      write,
      onNewTask,
      onAddTask,
      onUpdateTask,
      onEdit,
      onCancelEdit,
      onArchive,
      onDelete
    } = this.props;

    let conflicts = {};
    if (column.id === 'column2') {
      const workspaces = tasks.reduce(
        (acc, task) =>
          acc.concat(task.workspaces.map(workspace => workspace.id)),
        []
      );

      const uniqueWorkspaces = workspaces
        .map(workspace => ({
          count: 1,
          value: workspace
        }))
        .reduce((acc, val) => {
          acc[val.value] = (acc[val.value] || 0) + val.count;
          return acc;
        }, {});

      const duplicateWorkspaces = Object.keys(uniqueWorkspaces)
        .filter(key => uniqueWorkspaces[key] > 1)
        .filter(id => id !== '14' && id !== '15');

      conflicts = { duplicateWorkspaces };
    }

    const isDropDisabled =
      dragStartId === 'column0' &&
      !(column.id === 'column0' || column.id === 'column1');

    let backgroundClass =
      conflicts.duplicateWorkspaces && conflicts.duplicateWorkspaces.length > 0
        ? ' cb-bg-danger'
        : ' bg-light';
    if (isDropDisabled) backgroundClass = 'cb-bg-dark';

    return (
      <div className="col p-2">
        <div
          className={`cb-drop-container rounded mt-2 px-2 pt-2 ${backgroundClass}`}>
          <div className="h5 text-black-50 mb-3">
            {column.title}
            {column.id === 'column0' ? (
              <button
                type="button"
                className="position-relative btn-no-style fa-btn fa-btn-secondary float-right"
                style={{ top: -4, right: -4 }}
                onClick={onNewTask}
                disabled={!write}>
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            ) : null}
            {column.id === 'column100' ? (
              <NavLink to="/workshop_archive">
                <button
                  type="button"
                  className="position-relative btn-no-style fa-btn fa-btn-secondary float-right"
                  style={{ top: -4, right: -4 }}>
                  <FontAwesomeIcon icon={faArchive} />
                </button>
              </NavLink>
            ) : null}
          </div>
          <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
            {provided => (
              <div
                className="h-100"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {tasks.map((task, index) =>
                  task.id === 'new' || task.edit ? (
                    <EditTask
                      key={task.id}
                      task={task}
                      index={index}
                      onAddTask={onAddTask}
                      onUpdateTask={onUpdateTask}
                      onCancel={onCancelEdit}
                    />
                  ) : (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      columnId={column.id}
                      conflicts={conflicts}
                      write={write}
                      onEdit={onEdit}
                      onArchive={onArchive}
                      onDelete={onDelete}
                    />
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}

Column.propTypes = {
  column: PropTypes.object,
  tasks: PropTypes.array,
  dragStartId: PropTypes.string,
  write: PropTypes.bool,
  onNewTask: PropTypes.func,
  onAddTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
  onEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func
};

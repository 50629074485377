import gql from "graphql-tag";
import getLoginName from "../getLoginName";
import getOrder from "./getOrder";

const updateDispoHumanResourceTime = gql`
  mutation updateDispoHumanResourceTime(
    $requestUser: String!
    $id: ID
    $from: String
    $to: String
    $note: String
  ) {
    updateDispoHumanResourceTime(
      requestUser: $requestUser
      id: $id
      from: $from
      to: $to
      note: $note
    ) {
      id
      from
      to
      note
      humanResource {
        id
        lastname
        firstname
      }
    }
  }
`;

export default (client, id, from, to, type, orderId, note) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem("klout"));
  const requestUser = getLoginName(localStorageItems);
  dispatch({
    type: `SET_DISPO_${type}_TIME`,
    data: {
      id: orderId,
      resourceId: id,
      from,
      to,
      note
    }
  });

  client
    .mutate({
      mutation: updateDispoHumanResourceTime,
      variables: {
        requestUser,
        id: parseInt(id, 10),
        from,
        to,
        note
      }
    })
    .then(response => {
      dispatch(getOrder(client, orderId));
    });
};

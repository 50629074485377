import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Select from 'react-select';

import TitleBar from '../TitleBar';
import Button from '../UnlockButton';
import Modal from '../Modal';
import LoadingIndicator from '../LoadingIndicator/cogs';

import createCompany from '../../actions/createCompany';
import updateCompany from '../../actions/updateCompany';
import deleteCompany from '../../actions/deleteCompany';
import transferCompany from '../../actions/transferCompany';

class Data extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      name: '',
      address: '',
      zipcode: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
      locked: !props.write,
      validationErrors: {},
      showModal: false,
      modalLoading: false,
      modalData: null,
      modalCompany: null
    };
    this.state = this.initState;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModalCompanyChange = this.handleModalCompanyChange.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { company } = this.props;
    if (prevProps.company !== company) {
      if (company) {
        this.setState({
          name: company.name || '',
          address: company.adress || '',
          zipcode: company.zipcode || '',
          city: company.city || '',
          phone: company.phone || '',
          fax: company.fax || '',
          email: company.email || '',
          locked: true,
          validationErrors: {}
        });
      } else {
        this.setState(this.initState);
      }
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleModalCompanyChange(value) {
    this.setState({ modalCompany: value });
  }

  unlock() {
    this.setState({
      locked: false
    });
  }

  lock() {
    this.setState({
      locked: true
    });
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({
      showModal: false,
      modalLoading: false,
      modalData: null,
      modalCompany: null
    });
  }

  validateForm() {
    const { validationErrors, name, phone, fax, email } = this.state;

    validationErrors.name = name === '';
    validationErrors.phone = !phone.match(/^[+]?[0-9 /\-()]*$/i);
    validationErrors.fax = !fax.match(/^[+]?[0-9 /\-()]*$/i);

    if (email === '') {
      validationErrors.email = false;
    } else {
      validationErrors.email = !email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
    }

    let valid = true;
    // eslint-disable-next-line
    for (const key in validationErrors) valid = valid && !validationErrors[key];

    this.setState({ validationErrors });
    return valid;
  }

  onSave() {
    const { dispatch, client, company } = this.props;
    const { name, address, zipcode, city, phone, fax, email } = this.state;

    if (!this.validateForm()) {
      return;
    }

    if (company) {
      dispatch(
        updateCompany(
          client,
          company.id,
          name,
          address,
          zipcode,
          city,
          phone,
          fax,
          email
        )
      );
    } else {
      dispatch(
        createCompany(client, name, address, zipcode, city, phone, fax, email)
      );
    }

    this.lock();
  }

  onDelete() {
    const { client, company } = this.props;

    if (company) {
      this.showModal();
      this.setState({ modalLoading: true });

      client
        .query({
          query: gql`
            query getContactsByCompanyName($name: String) {
              getContactsByCompanyName(name: $name) {
                id
              }
              getCostCentersByCompanyName(name: $name) {
                id
              }
            }
          `,
          variables: { name: company.name }
        })
        .then(response => {
          const contacts = response.data.getContactsByCompanyName || [];
          const costCenters = response.data.getCostCentersByCompanyName || [];

          this.setState({
            modalLoading: false,
            modalData: { contacts, costCenters }
          });
        });
    }
  }

  handleDelete() {
    const { client, dispatch, company } = this.props;

    if (company) {
      const { modalData, modalCompany } = this.state;

      if (modalData && modalCompany) {
        const { contacts, costCenters } = modalData;
        if (contacts.length > 0 || costCenters.length > 0) {
          dispatch(transferCompany(client, company.id, modalCompany.id));
        }
      } else {
        dispatch(deleteCompany(client, company.id));
      }
    }

    this.hideModal();
    this.lock();
  }

  render() {
    const { className, company, companies, write } = this.props;
    const {
      name,
      address,
      zipcode,
      city,
      phone,
      fax,
      email,
      validationErrors: errors,
      locked,
      showModal,
      modalLoading,
      modalData,
      modalCompany
    } = this.state;

    let modalSubmitEnabled = true;
    let modalContent = (
      <div>
        Es wurden keine Kontakte oder Kostenstellen gefunden. Firma löschen?
      </div>
    );

    if (modalData) {
      const { contacts, costCenters } = modalData;
      if (contacts.length > 0 || costCenters.length > 0) {
        modalSubmitEnabled = false;

        modalContent = (
          <div>
            <div className="alert alert-danger">
              Zu dieser Firma existieren noch Kontakte oder Kostenstellen.
            </div>
            <div className="form-group mt-3">
              <label className="small">
                Kontakte und Kostenstellen übertragen:
              </label>
              <Select
                isClearable={false}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={modalCompany}
                options={companies}
                onChange={this.handleModalCompanyChange}
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div className={className}>
        <Modal
          show={showModal}
          titleText="Firma löschen"
          closeBtnText="Abbrechen"
          submitBtnText="Anwenden"
          onClose={this.hideModal}
          onSubmit={this.handleDelete}
          submitEnabled={
            (!modalLoading && Boolean(modalCompany)) || modalSubmitEnabled
          }>
          {modalLoading ? (
            <div className="d-flex justify-content-center">
              <LoadingIndicator />
            </div>
          ) : (
            modalContent
          )}
        </Modal>

        <TitleBar title="Stammdaten" />

        <div className="row">
          <div className="form-group col-sm-12">
            <label className="form-check-label small">Name*</label>
            <input
              type="text"
              className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              value={name}
              disabled={locked}
              name="name"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-5">
            <label className="form-check-label small">Adresse</label>
            <input
              type="text"
              className={`form-control ${errors.address ? 'is-invalid' : ''}`}
              value={address}
              disabled={locked}
              name="address"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-2">
            <label className="form-check-label small">PLZ</label>
            <input
              type="text"
              className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
              value={zipcode}
              disabled={locked}
              name="zipcode"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-5">
            <label className="form-check-label small">Stadt</label>
            <input
              type="text"
              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
              value={city}
              disabled={locked}
              name="city"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">Telefonnummer</label>
            <input
              type="tel"
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
              value={phone}
              disabled={locked}
              name="phone"
              onChange={this.handleInputChange}
            />
            {errors.phone ? (
              <small className="form-text text-danger">
                Darf nur Zahlen, +, /, (, ) und - enthalten.
              </small>
            ) : null}
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">Fax</label>
            <input
              type="text"
              className={`form-control ${errors.fax ? 'is-invalid' : ''}`}
              value={fax}
              disabled={locked}
              name="fax"
              onChange={this.handleInputChange}
            />
            {errors.fax ? (
              <small className="form-text text-danger">
                Darf nur Zahlen, +, /, (, ) und - enthalten.
              </small>
            ) : null}
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">E-Mail</label>
            {locked && email !== '' ? (
              <a
                href={`mailto:${email}`}
                className="btn btn-outline-primary w-100 text-left">
                {email}
              </a>
            ) : (
              <input
                type="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                value={email}
                disabled={locked}
                name="email"
                onChange={this.handleInputChange}
              />
            )}
            {errors.email ? (
              <small className="form-text text-danger">
                Keine korrekte E-Mail.
              </small>
            ) : null}
          </div>
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave}
              onDelete={this.onDelete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={Boolean(company)}
              locked={locked}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  company: PropTypes.object,
  companies: PropTypes.array,
  write: PropTypes.bool
};

export default Data;

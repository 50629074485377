import gql from 'graphql-tag';

export default (
  client,
  salutation,
  lastname,
  firstname,
  phone,
  position,
  mobile,
  email,
  fax,
  company
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createContactWithCompanyName(
          $salutation: String
          $lastname: String
          $firstname: String
          $phone: String
          $position: String
          $mobile: String
          $email: String
          $fax: String
          $company: String
        ) {
          createContactWithCompanyName(
            salutation: $salutation
            lastname: $lastname
            firstname: $firstname
            phone: $phone
            position: $position
            mobile: $mobile
            email: $email
            fax: $fax
            company: $company
          ) {
            id
            salutation
            lastname
            firstname
            phone
            email
            fax
            mobile
            position
            notice
            company {
              id
              name
              adress
              zipcode
              city
              phone
              fax
              email
            }
          }
        }
      `,
      variables: {
        salutation,
        lastname,
        firstname,
        phone,
        email,
        fax,
        mobile,
        position,
        company
      }
    })
    .then(response => {});
};

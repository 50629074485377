import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import List from './list';

import getWorkshopTasks from '../../actions/getWorkshopTasks';

class App extends Component {
  constructor(props) {
    super(props);
    document.title = 'Werkstattarchiv';

    const { client, dispatch, currentDate } = props;

    dispatch(getWorkshopTasks(client, currentDate, true));
  }

  render() {
    const { permissions, loginName, tasks } = this.props;

    const write =
      Boolean(permissions.find(p => p.name === 'workshop' && p.write)) ||
      loginName === 'klout';

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-12 padding-20"
              task={null}
              tasks={tasks}
              write={write}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  tasks: PropTypes.array,
  currentDate: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client'),
  permissions: state.main.get('permissions'),
  loginName: state.main.get('loginName'),
  tasks: state.workshop.get('archivedTasks'),
  currentDate: state.workshop.get('currentDate')
}))(App);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class InactiveBox extends Component {
  static preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { data, start, showHours, width } = this.props;
    const startDate = moment(start);

    const { leavingDate } = data;
    if (!leavingDate) {
      return null;
    }

    const diffHours = moment(leavingDate).diff(startDate, 'hours');

    let offset = 0;
    if (showHours) {
      offset = 20 * diffHours;
    } else {
      offset = 5 * diffHours;
    }

    return (
      <div
        className="gantt-inactive-line"
        style={{
          left: offset,
          width: width - offset
        }}
        title="Inaktiv"
        onMouseDown={InactiveBox.preventEvent}
        onMouseMove={InactiveBox.preventEvent}
        onMouseUp={InactiveBox.preventEvent}
      />
    );
  }
}

InactiveBox.propTypes = {
  data: PropTypes.object,
  start: PropTypes.object,
  showHours: PropTypes.bool,
  width: PropTypes.number
};

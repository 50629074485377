import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const open = name => id => {
  window.open(`/${name}/${id}`, '_blank');
};

const openHr = id => {
  window.open(`/human_resources/${id}`, '_blank');
};

function ResourceListItem(props) {
  const { data, isConflictElement, onClick } = props;

  const height = data.heightFactor ? data.heightFactor * 41 : 41;

  const isSubcontractor = data.description === 'Sub-Unternehmen';
  const existingWithToType = data.reservations.find(
    reservation => reservation.order && reservation.order.type === 2
  );

  let className = 'gantt-machine-list-item';
  if (isSubcontractor) {
    if (!existingWithToType) {
      return null;
    }

    className += ' alert-warning';
  }
  if (isConflictElement) {
    className += ' alert-primary';
  }

  const hrs = data.humanResources.map(hr => ({
    name: `${hr.firstname} ${hr.lastname}`,
    id: hr.id
  }));

  return (
    <div className={className} style={{ height }}>
      <div
        className="gantt-machine-list-item-color"
        style={{ background: data.color }}
      />
      <div className="gantt-machine-list-item-name">
        <span
          className="cursor-pointer"
          onClick={() => {
            if (data.id) {
              onClick(data.id);
            }
          }}>
          {data.name}
        </span>
        <div className="gantt-machine-list-item-info pr-2">
          {data.number} {data.category} {data.subcategory}
        </div>
      </div>

      <div className="gantt-machine-list-item-description pr-2">
        {hrs.length
          ? hrs.map((hr, i) => (
              <Fragment key={hr.id}>
                <span className="cursor-pointer" onClick={() => openHr(hr.id)}>
                  {hr.name}
                </span>
                {i < hrs.length - 1 ? <span> / </span> : null}
              </Fragment>
            ))
          : data.description}
      </div>
    </div>
  );
}

ResourceListItem.propTypes = {
  data: PropTypes.object,
  isConflictElement: PropTypes.number,
  onClick: PropTypes.func
};

function VehicleListItem(props) {
  const { data, isConflictElement, onClick } = props;

  let className = 'gantt-machine-list-item';
  if (isConflictElement) {
    className += ' alert-primary';
  }

  const hrs = data.humanResources.map(hr => ({
    name: `${hr.firstname} ${hr.lastname}`,
    id: hr.id
  }));

  return (
    <div className={className}>
      <div className="gantt-machine-list-item-name">
        <span className="cursor-pointer" onClick={() => onClick(data.id)}>
          {data.name}
        </span>
        <div className="gantt-machine-list-item-info pr-2">
          {data.number} {data.licensePlate} {data.category}
        </div>
      </div>

      <div className="gantt-machine-list-item-description pr-2">
        {hrs.map((hr, i) => (
          <Fragment key={hr.id}>
            <span className="cursor-pointer" onClick={() => openHr(hr.id)}>
              {hr.name}
            </span>
            {i < hrs.length - 1 ? <span> / </span> : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

VehicleListItem.propTypes = {
  data: PropTypes.object,
  isConflictElement: PropTypes.number,
  onClick: PropTypes.func
};

function CombinationListItem(props) {
  const { data, isConflictElement, onClick } = props;

  let className = 'gantt-machine-list-item';
  if (isConflictElement) {
    className += ' alert-primary';
  }

  return (
    <div className={className}>
      <div className="gantt-machine-list-item-name">
        <span className="cursor-pointer" onClick={() => onClick(data.id)}>
          {data.name}
        </span>
        <div className="gantt-machine-list-item-info pr-2" />
      </div>

      <div className="gantt-machine-list-item-description pr-2">
        {data.description}
      </div>
    </div>
  );
}

CombinationListItem.propTypes = {
  data: PropTypes.object,
  isConflictElement: PropTypes.number,
  onClick: PropTypes.func
};

export default class List extends React.PureComponent {
  render() {
    const { resources, onSelect, conflictingResources } = this.props;

    return (
      <div id="gantt-machine-list">
        {resources.map(elem => {
          const conflictingElem = conflictingResources.filter(
            // eslint-disable-next-line no-underscore-dangle
            cr => cr.__typename.toUpperCase() === elem.type && cr.id === elem.id
          );
          const isConflictElement = conflictingElem.length;

          if (elem.type === 'RESOURCE') {
            return (
              <ResourceListItem
                key={`R${elem.id + elem.name}`}
                data={elem}
                isConflictElement={isConflictElement}
                onClick={open('resources')}
              />
            );
          }
          if (elem.type === 'VEHICLE') {
            return (
              <VehicleListItem
                key={`V${elem.id}`}
                data={elem}
                isConflictElement={isConflictElement}
                onClick={open('vehicles')}
              />
            );
          }
          return (
            <CombinationListItem
              key={`C${elem.id}`}
              data={elem}
              onSelect={onSelect}
              isConflictElement={isConflictElement}
              onClick={open('combinations')}
            />
          );
        })}
      </div>
    );
  }
}

List.propTypes = {
  resources: PropTypes.array,
  conflictingResources: PropTypes.array,
  onSelect: PropTypes.func
};

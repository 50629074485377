import gql from 'graphql-tag';

export default (
  client,
  contactId,
  salutation,
  lastname,
  firstname,
  phone,
  position,
  mobile,
  email,
  fax,
  notice,
  companyId
) => dispatch => {
  const query = `
    mutation updateContact(
      $contactId: ID
      $salutation: String
      $lastname: String
      $firstname: String
      $phone: String
      $position: String
      $mobile: String
      $email: String
      $fax: String
      $notice: String
      $companyId: ID
    ) {
      updateContact(
        id: $contactId
        salutation: $salutation
        lastname: $lastname
        firstname: $firstname
        phone: $phone
        position: $position
        mobile: $mobile
        email: $email
        fax: $fax
        notice: $notice
        company_id: $companyId
      ) {
        id
        salutation
        lastname
        firstname
        phone
        email
        fax
        mobile
        position
        notice
        company {
          id
          name
          adress
          zipcode
          city
          phone
          fax
          email
        }
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        contactId,
        salutation,
        lastname,
        firstname,
        phone,
        email,
        fax,
        mobile,
        position,
        notice,
        companyId
      }
    })
    .then(response => {
      dispatch({
        type: 'CONTACT_UPDATED',
        data: response.data.updateContact,
        contactId
      });
    });
};

import gql from 'graphql-tag';
import { CostCenterData } from './fragments';

export default (client, costCenter) => dispatch => {
  const query = gql`
    mutation updateCostCenter(
      $id: ID
      $value: Int
      $establishment: String
      $isContinuance: Boolean
      $buildingProject: String
      $customer: String
      $masterCompany: String
    ) {
      updateCostCenter(
        id: $id
        value: $value
        establishment: $establishment
        is_continuance: $isContinuance
        building_project: $buildingProject
        customer: $customer
        master_customer: $masterCompany
      ) {
        ...CostCenterData
      }
    }
    ${CostCenterData}
  `;
  client
    .mutate({
      mutation: query,
      variables: costCenter
    })
    .then(response => {
      dispatch({
        type: 'COST_CENTER_UPDATED',
        data: response.data.updateCostCenter
      });
    });
};

import gql from "graphql-tag";
import { OrderDetail } from "./fragments";

export default (client, startDate, endDate) => dispatch => {
  dispatch({ type: "RESERVATIONS_LOADING", data: "orders" });

  client
    .query({
      query: gql`
        query getOrdersSubcontractor($startDate: Date!, $endDate: Date!) {
          getOrdersSubcontractor(from: $startDate, to: $endDate) {
            ...OrderDetail
          }
        }
        ${OrderDetail}
      `,
      variables: {
        startDate,
        endDate
      },
      options: {
        fetchPolicy: "network-only"
      }
    })
    .then(response => {
      dispatch({
        type: "ORDERS_FOR_PERIOD_FOUND",
        data: response.data.getOrdersSubcontractor
      });
    });
};

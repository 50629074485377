import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferIsEmployeeHoursValid(
    $id: ID!
    $isEmployeeHoursValid: String!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferIsEmployeeHoursValid(
      id: $id
      isEmployeeHoursValid: $isEmployeeHoursValid
    ) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, value) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      isEmployeeHoursValid: value
    }
  });

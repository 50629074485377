import gql from "graphql-tag";
import { OrderMaxiOverview } from "./fragments";

export default (client, orderId, allowance) => {
  const Query = gql`
    mutation deleteOrderAllowance(
      $id: ID!
      $allowance: String!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      deleteOrderAllowance(id: $id, allowance: $allowance) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: Query,
      variables: {
        id: orderId,
        allowance
      }
    })
    .then(response => {
      console.log(response);
    });
};

import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';

export default (client, nameFilter, id) => dispatch => {
  whenModified(client, 'company', () =>
    client
      .query({
        query: gql`
          query getCompanies($name: String) {
            getCompanies(name: $name) {
              id
              name
              adress
              zipcode
              city
              phone
              fax
              email
            }
          }
        `,
        variables: {
          name: nameFilter || null
        },
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({ type: 'COMPANIES_FOUND', data: response.data.getCompanies });
        if (id !== undefined && id !== '') {
          const company = response.data.getCompanies.find(obj => obj.id === id);
          if (company !== undefined) {
            dispatch({ type: 'SET_CHOSEN_COMPANY', data: company });
          }
        }
      })
  );
};

import gql from 'graphql-tag';

export default (client, id, data) => dispatch => {
  const query = `
    mutation updateTransporter(
      $id: ID
      $data: String
    ) {
      updateTransporter(
        id: $id
        data: $data
      ) {
        id
        company
        contactName: contact_name
        contactPhone: contact_phone
        data {
          amount
          vehicle
          time
          amountTransfer
          amountTransferUnit
        }
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        id,
        data: JSON.stringify(data)
      }
    })
    .then(response => {
      dispatch({
        type: 'TRANSPORTER_UPDATED',
        data: response.data.updateTransporter
      });
    });
};

import gql from 'graphql-tag';

export default (client, commitments, resourceId) => dispatch => {
  const values = [];
  Promise.all(
    commitments.map(commitment => {
      values.push(commitment.value);
      return client.mutate({
        mutation: gql`
          mutation deleteCommitment($resourceId: ID, $value: String) {
            deleteCommitment(resource_id: $resourceId, value: $value) {
              success
            }
          }
        `,
        variables: {
          resourceId,
          value: commitment.value
        }
      });
    })
  ).then(() => {
    dispatch({
      type: 'COMMITMENT_DELETED',
      values,
      resourceId
    });
  });
};

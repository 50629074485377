import gql from 'graphql-tag';
import { OrderMaxiOverview } from './fragments';

export default (client, orderId, value) => {
  const SET_NOTE = gql`
    mutation setNote(
      $id: ID!
      $note: String!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      setNote(id: $id, note: $note) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: SET_NOTE,
      variables: {
        id: orderId,
        note: value
      }
    })
    .then(response => {
      console.log(response);
    });
};

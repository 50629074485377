import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faArrowsAlt,
  // faTimes,
  faCheck,
  faStickyNote
} from "@fortawesome/pro-solid-svg-icons";

import Modal from "../../Modal";

import OrderData from "./orderData";
import order from "../../Order/order";

class ReservationBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderData: null,
      showModal: false
    };

    this.handleOrderDataChange = this.handleOrderDataChange.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.submitModal = this.submitModal.bind(this);

    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);

    this.resizeStart = this.resizeStart.bind(this);

    this.delete = this.delete.bind(this);

    this.openContextMenu = this.openContextMenu.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
  }

  static preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  static resourceToString(resource) {
    const { type } = resource;
    switch (type) {
      case "RESOURCE":
        return `${resource.name} (${resource.number})`;
      case "VEHICLE":
        return `${resource.name} (${resource.licensePlate})`;
      case "COMBINATION":
        return `${resource.name}`;
      default:
        return "";
    }
  }

  static modelsToString(models) {
    if (!models || models.length === 0) return "";

    return models
      .map(model => ReservationBox.resourceToString(model.resource))
      .join(", ");
  }

  handleOrderDataChange(data) {
    this.setState({ orderData: data });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ orderData: null, showModal: false });
  }

  submitModal() {
    const { model, onCreateOrder } = this.props;
    const { orderData } = this.state;
    if (
      orderData &&
      (orderData.costCenter || orderData.noCostCenter) &&
      orderData.establishment
    ) {
      onCreateOrder(model, orderData);

      this.closeModal();
    } else {
      const newOrderData = orderData
        ? (orderData["error"] = true)
        : { error: true };
      this.setState({ orderData: newOrderData });
    }
  }

  unlock() {
    const { model, view, index, onResizeUnlock } = this.props;
    this.setState({ editMode: true });
    onResizeUnlock(model, view, index);
  }

  lock() {
    const { model, onResizeLock } = this.props;
    this.setState({ editMode: false });
    onResizeLock(model.id);
  }

  resizeStart(e) {
    const { onResizeStart } = this.props;
    const { side } = e.currentTarget.dataset;
    onResizeStart(e, side);
  }

  delete() {
    const { model, onDelete } = this.props;
    if (model) onDelete(model.id);
  }

  openContextMenu(e) {
    ReservationBox.preventEvent(e);

    const { onContextMenu, view } = this.props;
    if (view.type === "RESERVATION_BOX") {
      onContextMenu(e, this, "RESERVATION");
    } else if (view.type === "WORKSHOP_BOX") {
      onContextMenu(e, this, "WORKSHOP");
    }
  }

  handleSelect() {
    const { model, isSelected, onSelect } = this.props;
    onSelect(model, !isSelected);
  }

  render() {
    const {
      model,
      view,
      containerWidth,
      editMode,
      selectMode,
      isSelected,
      isValid,
      costCenters,
      selectedElements,
      onResizeEnd,
      contactPerson,
      contactPersonCompany
    } = this.props;
    const { showModal } = this.state;

    let className = isValid
      ? "gantt-reservation-box-reserved"
      : "gantt-reservation-box-invalid";

    if (view.type === "WORKSHOP_BOX") {
      className = "gantt-reservation-box-workshop";

      if (model.workshopTask) {
        const { workspaces } = model.workshopTask;

        // Workspaces is a JSON string. An on site repair can only have one workspace with id 15.
        // So there is no need to parse, if we can just compare the whole string.
        if (workspaces && workspaces === "[15]") {
          className += " muted";
        }
      }
    }

    if (view.type === "TRANSFER_BOX") {
      className = "gantt-reservation-box-transfer cursor-pointer";
    }

    if (isSelected) className = "gantt-reservation-box-selection blinking";

    let machines = ReservationBox.resourceToString(model.resource);
    let { scopeOfServices } = model;
    if (selectedElements && selectedElements.length > 0) {
      machines = ReservationBox.modelsToString(selectedElements);
      scopeOfServices = selectedElements.reduce(
        (acc, elem) => acc.concat(elem.scopeOfServices),
        []
      );
    }

    // const toolsEnabled = view.width > 16;
    const showText = view.width > 60;

    let centerControlStyle = null;
    // let toolsStyle = null;

    if (view.offset < 0) centerControlStyle = { left: "calc(100% - 19px)" };
    else if (view.offset + view.width > containerWidth) {
      centerControlStyle = { left: 3 };
      // toolsStyle = { left: 0, right: 'auto', borderWidth: '0 1px 0 0' };
    }

    let tooltipStyle = { height: 32 };
    if (view.info.length > 0) {
      tooltipStyle = null;
    }

    const isNote = !!model.note;
    const contactString =
      (contactPerson
        ? contactPerson.lastname + " " + contactPerson.firstname
        : "") +
      " " +
      (contactPersonCompany
        ? "(" + contactPersonCompany.name + ", " + contactPerson.mobile + ")"
        : "");

    return (
      <Fragment>
        <Modal
          show={showModal}
          titleText="Abruf anlegen"
          closeBtnText="abbrechen"
          submitBtnText="speichern"
          onClose={this.closeModal}
          onSubmit={this.submitModal}
        >
          <OrderData
            scopeOfServices={scopeOfServices}
            machines={machines}
            costCenters={costCenters}
            onUpdate={this.handleOrderDataChange}
            establishment={
              this.props.establishment ? this.props.establishment : null
            }
          />
        </Modal>

        <div
          className={className}
          style={{
            left: view.offset,
            width: view.width,
            zIndex: editMode ? 3 : null
          }}
          onMouseDown={ReservationBox.preventEvent}
          onMouseUp={ReservationBox.preventEvent}
          onContextMenu={this.openContextMenu}
        >
          <div
            className="gantt-reservation-box-content"
            onClick={() => {
              if (selectMode) {
                this.handleSelect();
              }
              if (view.type === "TRANSFER_BOX") {
                window.location = `/transfers/${model.resource.id}`;
              }
            }}
          >
            {editMode ? (
              <div className="box-controls" onMouseUp={onResizeEnd}>
                <div
                  className="left-control"
                  data-side="left"
                  onMouseDown={this.resizeStart}
                />
                <button
                  className="btn-no-style center-control"
                  style={centerControlStyle}
                  onClick={this.lock}
                  disabled={!isValid}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <div
                  className="right-control"
                  data-side="right"
                  onMouseDown={this.resizeStart}
                />
              </div>
            ) : (
              <Fragment>
                {isNote ? (
                  <FontAwesomeIcon
                    className="float-left mt-1"
                    icon={faStickyNote}
                  />
                ) : null}
                {showText ? (
                  <span className="box-status">
                    {contactString.length > 2
                      ? view.status + "/ " + contactString
                      : view.status}
                  </span>
                ) : null}
              </Fragment>
            )}
          </div>
          {editMode ? (
            <div
              className="box-drag-control"
              data-side="middle"
              onMouseDown={this.resizeStart}
              onMouseUp={onResizeEnd}
            />
          ) : (
            <div className="gantt-reservation-box-tooltip" style={tooltipStyle}>
              {isNote ? model.note : view.status + "/ " + contactString}
              <div style={{ height: 6 }} />
              {view.info.map((elem, i) => (
                <Fragment key={i}>
                  <span>{elem}</span>
                  <br />
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

ReservationBox.propTypes = {
  model: PropTypes.object,
  view: PropTypes.object,
  containerWidth: PropTypes.number,
  index: PropTypes.number,
  costCenters: PropTypes.array,
  selectedElements: PropTypes.array,
  editMode: PropTypes.bool,
  selectMode: PropTypes.bool,
  isResizable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isValid: PropTypes.bool,
  onResizeLock: PropTypes.func,
  onResizeUnlock: PropTypes.func,
  onCreateOrder: PropTypes.func,
  onResize: PropTypes.func,
  onDelete: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResizeEnd: PropTypes.func,
  onContextMenu: PropTypes.func,
  onSelect: PropTypes.func,
  establishment: PropTypes.object,
  contactPerson: PropTypes.object,
  contactPersonCompany: PropTypes.object
};

export default ReservationBox;

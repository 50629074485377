import request from 'superagent';

export default backend => dispatch => {
  const host = `${backend}/getSalt`;
  request.get(host).end((err, response) => {
    if (response) {
      dispatch({ type: 'SALT_FOUND', data: response.text });
    }
  });
};

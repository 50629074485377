import gql from 'graphql-tag';

export default (
  client,
  id,
  name,
  adress,
  zipcode,
  city,
  phone,
  fax,
  email
) => dispatch => {
  const query = `
    mutation updateCompany(
      $id: ID
      $name: String
      $adress: String
      $zipcode: String
      $city: String
      $phone: String
      $fax: String
      $email: String
    ) {
      updateCompany(
        id: $id
        name: $name
        adress: $adress
        zipcode: $zipcode
        city: $city
        phone: $phone
        fax: $fax
        email: $email
      ) {
        id
        name
        adress
        zipcode
        city
        phone
        fax
        email
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        id,
        name,
        adress,
        zipcode,
        city,
        phone,
        fax,
        email
      }
    })
    .then(response => {
      dispatch({
        type: 'COMPANY_UPDATED',
        data: response.data.updateCompany
      });
    });
};

import gql from 'graphql-tag';
import getLoginName from '../getLoginName';

export default (client, reservationId) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  client
    .mutate({
      mutation: gql`
        mutation deleteReservationScopeOfServices(
          $requestUser: String!
          $reservation_id: ID
        ) {
          deleteReservationScopeOfServices(
            requestUser: $requestUser
            reservation_id: $reservation_id
          ) {
            success
          }
        }
      `,
      variables: {
        requestUser,
        reservation_id: reservationId
      }
    })
    .then(response => {
      if (response.data.deleteReservationScopeOfServices.success === false) {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteReservationScopeOfServices.success
        });
      }
    });
};

import gql from "graphql-tag";
import getOrder from "./getOrder";

export default (client, dispoId, resourceId, orderId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createDispoHumanResourceResource(
          $resource_id: ID
          $dispo_human_resource_id: ID
          $orderId: ID
        ) {
          createDispoHumanResourceResource(
            resource_id: $resource_id
            dispo_human_resource_id: $dispo_human_resource_id
            orderId: $orderId
          ) {
            id
            orderId
            resource_id
          }
        }
      `,
      variables: {
        resource_id: resourceId,
        dispo_human_resource_id: dispoId,
        orderId
      }
    })
    .then(() => {
      dispatch(getOrder(client, orderId));
    });
};

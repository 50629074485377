import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft
} from '@fortawesome/pro-solid-svg-icons';

class TimeControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate.format('YYYY-MM-DD'),
      endDate: props.endDate.format('YYYY-MM-DD')
    };

    this.removeDays = this.removeDays.bind(this);
    this.removeWeek = this.removeWeek.bind(this);
    this.removeMonth = this.removeMonth.bind(this);
    this.addDays = this.addDays.bind(this);
    this.addWeek = this.addWeek.bind(this);
    this.addMonth = this.addMonth.bind(this);

    this.alterDate = this.alterDate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.setDate = debounce(this.setDate, 750);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startDate, endDate } = this.props;
    if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
      this.setState({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      });
    }
  }

  removeDays(days) {
    this.alterDate(-days, 'days');
  }

  removeWeek() {
    this.alterDate(-7, 'days');
  }

  removeMonth() {
    this.alterDate(-1, 'months');
  }

  addDays(days) {
    this.alterDate(days, 'days');
  }

  addWeek() {
    this.alterDate(7, 'days');
  }

  addMonth() {
    this.alterDate(1, 'months');
  }

  alterDate(count, type) {
    const { startDate, endDate, onDateChange } = this.props;

    const nextStartDate = moment(startDate).add(count, type);
    const nextEndDate = moment(endDate).add(count, type);

    onDateChange(nextStartDate, nextEndDate);
  }

  handleDateChange(e) {
    let { startDate, endDate } = this.props;
    const { name, value } = e.currentTarget;

    if (value !== '') {
      if (name === 'startDate') {
        startDate = moment(value);
      } else {
        endDate = moment(value);
      }

      // Check if the dates are equal.
      if (startDate.isSame(endDate)) {
        endDate = moment(startDate).add(6, 'days');
      } else {
        // Check if the endDate is before the startDate.
        if (endDate.isBefore(startDate)) {
          if (name === 'startDate') {
            endDate = moment(startDate).add(6, 'days');
          } else {
            startDate = moment(endDate).subtract(6, 'days');
          }
        }

        const maxDifference = 3;
        // Check if the difference is more than maxDifference months.
        if (Math.abs(endDate.diff(startDate, 'months', true)) > maxDifference) {
          if (name === 'startDate') {
            const endDays = endDate.days();
            endDate = moment(startDate)
              .days(endDays)
              .add(3, 'months');
          } else {
            const startDays = startDate.days();
            startDate = moment(endDate)
              .days(startDays)
              .subtract(3, 'months');
          }
        }
      }

      this.setDate(startDate, endDate);
    }

    this.setState({ [name]: value });
  }

  setDate(startDate, endDate) {
    const { onDateChange } = this.props;
    onDateChange(startDate, endDate);
  }

  render() {
    const { showHours, hideRangeControls, onToggleView, disabled } = this.props;
    const { startDate, endDate } = this.state;

    let removeLessFunc = this.removeWeek;
    let removeMoreFunc = this.removeMonth;
    let addLessFunc = this.addWeek;
    let addMoreFunc = this.addMonth;

    if (showHours) {
      removeLessFunc = () => this.removeDays(3);
      removeMoreFunc = this.removeWeek;
      addLessFunc = () => this.addDays(3);
      addMoreFunc = this.addWeek;
    }

    const typeControl = (
      <div className="btn-group btn-group-sm h-100 mx-3">
        <button
          type="button"
          className={`btn ${showHours ? 'btn-outline-primary' : 'btn-primary'}`}
          style={{ width: 80 }}
          onClick={onToggleView}
          disabled={!showHours}>
          Tage
        </button>
        <button
          type="button"
          className={`btn ${showHours ? 'btn-primary' : 'btn-outline-primary'}`}
          style={{ width: 80 }}
          onClick={onToggleView}
          disabled={showHours}>
          Stunden
        </button>
      </div>
    );

    if (hideRangeControls) {
      return (
        <div className="row d-flex justify-content-between px-3 py-1">
          <div>{typeControl}</div>
        </div>
      );
    }

    return (
      <div className="row d-flex justify-content-between px-3 py-1">
        <div>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm h-100"
            style={{ width: 40 }}
            onClick={removeMoreFunc}
            disabled={disabled}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>

          <button
            type="button"
            className="btn btn-outline-primary btn-sm h-100 ml-2"
            style={{ width: 40 }}
            onClick={removeLessFunc}
            disabled={disabled}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        </div>

        <div>
          <input
            type="date"
            className="d-inline-block form-control form-control-sm w-auto"
            name="startDate"
            value={startDate}
            onChange={this.handleDateChange}
          />

          {typeControl}

          <input
            type="date"
            className="d-inline-block form-control form-control-sm w-auto"
            name="endDate"
            value={endDate}
            onChange={this.handleDateChange}
          />
        </div>

        <div>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm h-100 mr-2"
            style={{ width: 40 }}
            onClick={addLessFunc}
            disabled={disabled}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>

          <button
            type="button"
            className="btn btn-outline-primary btn-sm h-100"
            style={{ width: 40 }}
            onClick={addMoreFunc}
            disabled={disabled}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </button>
        </div>
      </div>
    );
  }
}

TimeControl.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showHours: PropTypes.bool,
  hideRangeControls: PropTypes.bool,
  onToggleView: PropTypes.func,
  onDateChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default TimeControl;

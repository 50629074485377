import gql from 'graphql-tag';

export default (client, vehicleId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteVehicle($vehicleId: ID) {
          deleteVehicle(id: $vehicleId) {
            success
          }
        }
      `,
      variables: { vehicleId }
    })
    .then(response => {
      if (response.data.deleteVehicle.success === true) {
        dispatch({
          type: 'VEHICLE_DELETED',
          vehicleId
        });
        window.history.pushState({}, null, '/vehicles');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteVehicle.success
        });
      }
    });
};

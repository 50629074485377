import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CreatableSelect from 'react-select/lib/Creatable';
import gql from 'graphql-tag';

class PersistSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workshops: []
    };

    this.loadWorkshops = this.loadWorkshops.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static isValidNewOption(inputValue, selectValue, selectOptions) {
    const label = inputValue.trim().toUpperCase();
    if (label.trim() === '') return false;
    if (selectOptions.find(option => option.label.toUpperCase() === label))
      return false;

    return true;
  }

  componentDidMount() {
    this.loadWorkshops();
  }

  loadWorkshops() {
    const { client, type } = this.props;
    let query = null;
    switch (type) {
      case 'externalWorkshop': {
        query = gql`
          {
            getExternalWorkshops {
              id
              name
            }
          }
        `;
        break;
      }
      case 'externalMachine': {
        query = gql`
          {
            getExternalMachines {
              id
              name
            }
          }
        `;
        break;
      }
      default: {
        break;
      }
    }

    if (query) {
      client
        .query({
          query,
          fetchPolicy: 'network-only'
        })
        .then(result => {
          const workshops =
            type === 'externalWorkshop'
              ? result.data.getExternalWorkshops
              : result.data.getExternalMachines;

          this.setState({
            workshops: workshops.map(ws => ({
              id: ws.id,
              label: ws.name,
              value: ws.name
            }))
          });
        });
    }
  }

  handleChange(value, { action }) {
    const { client, onChange, type } = this.props;
    const { workshops } = this.state;

    let mutation = null;
    switch (type) {
      case 'externalWorkshop': {
        mutation = gql`
          mutation createExternalWorkshop($name: String!) {
            createExternalWorkshop(name: $name) {
              id
              name
            }
          }
        `;
        break;
      }
      case 'externalMachine': {
        mutation = gql`
          mutation createExternalMachine($name: String!) {
            createExternalMachine(name: $name) {
              id
              name
            }
          }
        `;
        break;
      }
      default: {
        break;
      }
    }

    if (mutation && action === 'create-option') {
      client
        .mutate({
          mutation,
          variables: {
            name: value.label.trim()
          }
        })
        .then(result => {
          const workshop =
            type === 'externalWorkshop'
              ? result.data.createExternalWorkshop
              : result.data.createExternalMachine;

          this.setState({
            workshops: workshops.concat({
              id: workshop.id,
              label: workshop.name,
              value: workshop.name
            })
          });
        });
    }

    onChange(value);
  }

  render() {
    const { workshop } = this.props;
    const { workshops } = this.state;

    const value = workshops.find(ws => ws.label === workshop) || null;

    return (
      <CreatableSelect
        isClearable
        placeholder=""
        noOptionsMessage={() => 'Hinzufügen mit Eingabe'}
        formatCreateLabel={inputValue => `+ ${inputValue}`}
        value={value}
        options={workshops}
        isValidNewOption={PersistSelect.isValidNewOption}
        onChange={this.handleChange}
      />
    );
  }
}

PersistSelect.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  workshop: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client')
}))(PersistSelect);

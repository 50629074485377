import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterBy } from '../../util/filterBy';

import TitleBar from '../TitleBar';
import SimpleTable from '../SimpleTable';

import setChosenResource from '../../actions/setChosenResource';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      sortKey: null,
      reverseSort: true
    };

    this.setNewResource = this.setNewResource.bind(this);
    this.setChosenResource = this.setChosenResource.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  headRenderer = className => {
    return (
      <Fragment>
        <div className={className}>Kategorie</div>
        <div className={className}>Inventarnummer</div>
        <div className={className}>Name</div>
        <div className={className}>Gruppierung</div>
        <div className={className}>Niederlassung</div>
      </Fragment>
    );
  };

  colRenderer = (user, className) => {
    const { category, number, name, subcategory, establishment, status } = user;
    const newClassName =
      status === 'Inaktiv' ? `${className} text-grey` : className;

    return (
      <Fragment>
        <div className={newClassName}>{category}</div>
        <div className={newClassName}>{number}</div>
        <div className={newClassName}>{name}</div>
        <div className={newClassName}>{subcategory}</div>
        <div className={newClassName}>{establishment}</div>
      </Fragment>
    );
  };

  static filter(data, filterString) {
    return filterBy(
      data,
      ['name', 'number', 'category', 'subcategory', 'establishment'],
      filterString
    );
  }

  setNewResource() {
    this.props.dispatch({
      type: 'SET_NEW_RESOURCE'
    });
    window.history.pushState({}, null, '/resources');
  }

  setChosenResource(resource) {
    this.props.dispatch(setChosenResource(resource));
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  render() {
    let { resources } = this.props;
    const { className, chosenResource, write } = this.props;
    const { filter, sortKey, reverseSort } = this.state;

    resources = List.filter(resources, filter);

    const selected = chosenResource ? ['id', chosenResource.id] : null;

    return (
      <div className={className}>
        <TitleBar
          title="Maschinen"
          onBtnClick={this.setNewResource}
          showControls={write}
        />

        <input
          className="form-control mt-3"
          placeholder="Filter..."
          value={filter}
          onChange={this.setFilter}
        />

        <SimpleTable
          className="mt-3"
          colRenderer={this.colRenderer}
          headRenderer={this.headRenderer}
          selected={selected}
          sortKey={sortKey}
          reverseSort={reverseSort}
          data={resources}
          onColSelected={this.setSortKey}
          onRowSelected={this.setChosenResource}
        />
      </div>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  establishment: PropTypes.object,
  resources: PropTypes.array,
  chosenResource: PropTypes.object,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch
}))(List);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Row from './row';

class ScopeOfService extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);

    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleOtherChange = this.handleOtherChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleChange(id, sos) {
    const { scopeOfServices, onChange } = this.props;
    const nextSos = scopeOfServices.map(elem => {
      if (elem.id === id) {
        return sos;
      }
      return elem;
    });

    nextSos.forEach(elem => {
      // eslint-disable-next-line
      elem.width = elem.width.toString();
      // eslint-disable-next-line
      elem.depth = elem.depth.toString();
      // eslint-disable-next-line
      elem.amount = elem.amount.toString();
    });

    onChange(nextSos);
  }

  addRow() {
    const { scopeOfServices, onChange } = this.props;

    let maxIdx = -1;
    scopeOfServices.forEach(sos => {
      if (sos.id.startsWith('_')) {
        const idx = parseInt(sos.id.substr(1), 10);
        if (idx > maxIdx) maxIdx = idx;
      }
    });

    onChange(
      scopeOfServices.concat({
        id: `_${maxIdx + 1}`,
        width: '0',
        depth: '0',
        depthTo: null,
        amount: '0',
        amountUnit: 'qm',
        scopeOfServiceTyps: []
      })
    );
  }

  removeRow(id) {
    const { scopeOfServices, onChange } = this.props;
    onChange(scopeOfServices.filter(sos => sos.id !== id));
  }

  handleTypeChange(id, value) {
    const { scopeOfServices } = this.props;
    const sos = scopeOfServices.find(elem => elem.id === id);
    if (sos) {
      const types = sos.scopeOfServiceTyps;
      let nextTypes = types;

      let foundIdx = -1;
      types.forEach((type, i) => {
        if (type.value === value) {
          foundIdx = i;
        } else if (value === 'OTHER:' && type.value.startsWith('OTHER:')) {
          foundIdx = i;
        }
      });

      if (foundIdx < 0) {
        nextTypes = types.concat({ id: 'new', value });
      } else {
        nextTypes = types.filter((type, i) => i !== foundIdx);
      }

      this.handleChange(id, {
        ...sos,
        scopeOfServiceTyps: nextTypes
      });
    }
  }

  handleOtherChange(id, value) {
    const { scopeOfServices } = this.props;
    const sos = scopeOfServices.find(elem => elem.id === id);
    if (sos) {
      const types = sos.scopeOfServiceTyps;

      this.handleChange(id, {
        ...sos,
        scopeOfServiceTyps: types.map(type => {
          if (type.value.startsWith('OTHER:'))
            return { id: 'new', value: `OTHER:${value}` };
          return type;
        })
      });
    }
  }

  handleQuantityChange(id, name, value) {
    const { scopeOfServices } = this.props;
    const sos = scopeOfServices.find(elem => elem.id === id);
    if (sos) {
      this.handleChange(id, {
        ...sos,
        [name]: value === null || value === '' ? value : Math.max(value, 0)
      });
    }
  }

  handleValueChange(id, name, value) {
    const { scopeOfServices } = this.props;
    const sos = scopeOfServices.find(elem => elem.id === id);
    if (sos) {
      this.handleChange(id, {
        ...sos,
        [name]: value
      });
    }
  }

  render() {
    const { scopeOfServices, locked } = this.props;

    return (
      <Fragment>
        <h4>Leistungsumfang</h4>
        {scopeOfServices.map(sos => (
          <Fragment key={sos.id}>
            <Row
              data={sos}
              disabled={locked}
              onTypeChange={this.handleTypeChange}
              onOtherChange={this.handleOtherChange}
              onQuantityChange={this.handleQuantityChange}
              onValueChange={this.handleValueChange}
              onDelete={this.removeRow}
            />
            <hr />
          </Fragment>
        ))}
        <div className="row">
          <div className="col-12">
            <button
              onClick={this.addRow}
              type="button"
              disabled={locked}
              className="btn btn-primary float-right">
              Zeile hinzufügen
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

ScopeOfService.propTypes = {
  scopeOfServices: PropTypes.array,
  locked: PropTypes.bool,
  onChange: PropTypes.func
};

export default ScopeOfService;

import React, { Component } from 'react';
import Proptypes from 'prop-types';

class Button extends Component {
  constructor() {
    super();
    this.state = {
      isDeleteMode: false
    };
  }

  activateDeleteMode() {
    this.setState({ isDeleteMode: true });
  }

  cancelDeleteMode() {
    this.setState({ isDeleteMode: false });
  }

  lock() {
    this.props.lock();
    this.setState({ isDeleteMode: false });
  }

  handleDelete() {
    this.props.onDelete();
    this.setState({ isDeleteMode: false });
  }

  render() {
    const isLocked = this.props.locked || this.props.disabled;

    if (!isLocked) {
      if (this.state.isDeleteMode) {
        return (
          <div className={this.props.className}>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-outline-secondary float-left"
                onClick={this.cancelDeleteMode.bind(this)}>
                abbrechen
              </button>
              <button
                type="button"
                className="btn btn-danger ml-3 float-left"
                onClick={this.handleDelete.bind(this)}>
                löschen
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className={this.props.className}>
          {this.props.chosen ? (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={this.activateDeleteMode.bind(this)}>
                löschen
              </button>
            </div>
          ) : (
            <div className="col-6" />
          )}
          <div className="col-6">
            <button
              type="button"
              className="btn btn-success ml-3 float-right"
              onClick={this.props.onSave}
              disabled={!this.props.saveEnabled}>
              speichern
            </button>
            {this.props.lock ? (
              <button
                type="button"
                className="btn btn-outline-secondary float-right"
                onClick={this.lock.bind(this)}>
                abbrechen
              </button>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className={this.props.className}>
        <div className="col-sm-9">&nbsp;</div>
        <div className="form-group col-sm-3">
          <button
            type="button"
            className="btn btn-secondary w-100"
            onClick={this.props.unlock}>
            bearbeiten
          </button>
        </div>
      </div>
    );
  }
}

Button.defaultProps = {
  saveEnabled: true,
  className: 'row pt-3 pl-3 hidden-print mr-2'
};

Button.propTypes = {
  onSave: Proptypes.func,
  onDelete: Proptypes.func,
  unlock: Proptypes.func,
  lock: Proptypes.func,
  chosen: Proptypes.bool,
  locked: Proptypes.bool,
  disabled: Proptypes.bool,
  saveEnabled: Proptypes.bool,
  className: Proptypes.string
};

export default Button;

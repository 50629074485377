import gql from 'graphql-tag';

export default (client, id, otherId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation transferCompany($id: ID, $otherId: ID) {
          transferCompany(id: $id, other_id: $otherId) {
            success
          }
        }
      `,
      variables: { id, otherId }
    })
    .then(response => {
      if (response.data.transferCompany.success === true) {
        dispatch({
          type: 'COMPANY_DELETED',
          id
        });
        window.history.pushState({}, null, '/companies');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.transferCompany.success
        });
      }
    });
};

import gql from "graphql-tag";
import * as moment from "moment";
import { OrderMaxiOverview } from "./fragments";

export default (client, from, to) => dispatch => {
  dispatch({ type: "SET_ORDERS_LOADING" });

  const ORDERS_MAXI_OVERVIEW = gql`
    query getOpenOrdersInRange(
      $from: Date!
      $to: Date!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      getOpenOrdersInRange(from: $from, to: $to) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  const start = moment.utc(from);
  let month = start.month() + 2;
  let year = start.year();
  if (month > 12) {
    year += 1;
    month = 1;
  }

  client
    .query({
      query: ORDERS_MAXI_OVERVIEW,
      variables: {
        from: from.toISOString(),
        to: to.toISOString(),
        employeeFilter: { month, year }
      },
      fetchPolicy: "network-only"
    })
    .then(response => {
      dispatch({
        type: "ORDERS_FOUND",
        data: response.data.getOpenOrdersInRange
      });
    });
};

import gql from "graphql-tag";
import { OrderDetail } from "./fragments";
import store from "../store";

export default (
  client,
  orderId,
  costCenter,
  establishment,
  projectNumber,
  subcontractor,
  buildingAddress,
  lat,
  lng,
  directions,
  constructionManagerName,
  constructionManagerPhone,
  certifiedForemanName,
  certifiedForemanPhone,
  kutterContactId,
  kutterBookerId,
  startDateTime,
  startMeetingTime,
  operationalPeriodStart,
  operationalPeriodFinish,
  description,
  sosChanges,
  notes,
  ebNumber,
  type
) => dispatch => {
  const requestUser = store.getState().main.get("loginName");

  const CREATE_SCOPE_OF_SERVICE = gql`
    mutation createScopeOfService(
      $requestUser: String!
      $order_id: ID
      $width: String
      $depth: String
      $depthTo: String
      $amount: String
      $amountUnit: String
    ) {
      createScopeOfService(
        requestUser: $requestUser
        order_id: $order_id
        width: $width
        depth: $depth
        depthTo: $depthTo
        amount: $amount
        amountUnit: $amountUnit
      ) {
        id
        width
        depth
        depthTo
        amount
        amountUnit
        scopeOfServiceTyps {
          id
          value
        }
      }
    }
  `;

  const UPDATE_SCOPE_OF_SERVICE = gql`
    mutation updateScopeOfService(
      $requestUser: String!
      $id: ID
      $order_id: ID
      $width: String
      $depth: String
      $depthTo: String
      $amount: String
      $amountUnit: String
    ) {
      updateScopeOfService(
        requestUser: $requestUser
        id: $id
        order_id: $order_id
        width: $width
        depth: $depth
        depthTo: $depthTo
        amount: $amount
        amountUnit: $amountUnit
      ) {
        id
        width
        depth
        depthTo
        amount
        amountUnit
        scopeOfServiceTyps {
          id
          value
        }
      }
    }
  `;

  const DELETE_SCOPE_OF_SERVICE = gql`
    mutation deleteScopeOfService($requestUser: String!, $id: ID) {
      deleteScopeOfService(requestUser: $requestUser, id: $id) {
        success
      }
    }
  `;

  const CREATE_SCOPE_OF_SERVICE_TYPE = gql`
    mutation createScopeOfServiceType(
      $requestUser: String!
      $scope_of_service_id: ID
      $value: String
    ) {
      createScopeOfServiceType(
        requestUser: $requestUser
        scope_of_service_id: $scope_of_service_id
        value: $value
      ) {
        id
        value
      }
    }
  `;

  const DELETE_SCOPE_OF_SERVICE_TYPE = gql`
    mutation deleteScopeOfServiceTyps($requestUser: String!, $id: ID) {
      deleteScopeOfServiceTyps(requestUser: $requestUser, id: $id) {
        success
      }
    }
  `;

  // dispatch(
  //   updateDispoNew(client, resources, humanResources, vehicles, orderId)
  // );

  const UPDATE_ORDER = gql`
    mutation updateOrder(
      $requestUser: String!
      $orderId: ID
      $cost_center_id: ID
      $establishment: String
      $project_number: String
      $subcontractor: String
      $start_date_time: String
      $start_meeting_time: String
      $operational_period_start: String
      $operational_period_finish: String
      $description: String
      $building_address: String
      $lat: String
      $lng: String
      $directions: String
      $construction_manager_name: String
      $construction_manager_phone: String
      $certified_foreman_name: String
      $certified_foreman_phone: String
      $kutter_contact_id: ID
      $kutter_booker_id: ID
      $notes: String
      $intern: Boolean
      $ebNumber: String
      $type: Int
    ) {
      updateOrder(
        requestUser: $requestUser
        id: $orderId
        cost_center_id: $cost_center_id
        establishment: $establishment
        project_number: $project_number
        subcontractor: $subcontractor
        start_date_time: $start_date_time
        start_meeting_time: $start_meeting_time
        operational_period_start: $operational_period_start
        operational_period_finish: $operational_period_finish
        description: $description
        building_address: $building_address
        lat: $lat
        lng: $lng
        directions: $directions
        construction_manager_name: $construction_manager_name
        construction_manager_phone: $construction_manager_phone
        certified_foreman_name: $certified_foreman_name
        certified_foreman_phone: $certified_foreman_phone
        kutter_contact_id: $kutter_contact_id
        kutter_booker_id: $kutter_booker_id
        notes: $notes
        intern: $intern
        ebNumber: $ebNumber
        type: $type
      ) {
        ...OrderDetail
      }
    }
    ${OrderDetail}
  `;

  if (sosChanges) {
    Promise.all(
      sosChanges.deleteType.map(deleteType =>
        client.mutate({
          mutation: DELETE_SCOPE_OF_SERVICE_TYPE,
          variables: {
            requestUser,
            id: parseInt(deleteType.id, 10)
          }
        })
      )
    ).then(() =>
      Promise.all(
        sosChanges.createType.map(createType =>
          client.mutate({
            mutation: CREATE_SCOPE_OF_SERVICE_TYPE,
            variables: {
              requestUser,
              scope_of_service_id: createType[0],
              value: createType[1].value
            }
          })
        )
      ).then(() =>
        Promise.all(
          sosChanges.delete.map(sos =>
            client.mutate({
              mutation: DELETE_SCOPE_OF_SERVICE,
              variables: {
                requestUser,
                id: sos.id
              }
            })
          )
        ).then(() =>
          Promise.all(
            sosChanges.update.map(sos =>
              client.mutate({
                mutation: UPDATE_SCOPE_OF_SERVICE,
                variables: {
                  requestUser,
                  id: sos.id,
                  order_id: parseInt(orderId, 10),
                  width: sos.width,
                  depth: sos.depth,
                  depthTo: sos.depthTo ? sos.depthTo.toString() : sos.depthTo,
                  amount: sos.amount,
                  amountUnit: sos.amountUnit
                }
              })
            )
          ).then(() =>
            Promise.all(
              sosChanges.create.map(sos =>
                client
                  .mutate({
                    mutation: CREATE_SCOPE_OF_SERVICE,
                    variables: {
                      requestUser,
                      order_id: parseInt(orderId, 10),
                      width: sos.width,
                      depth: sos.depth,
                      depthTo: sos.depthTo
                        ? sos.depthTo.toString()
                        : sos.depthTo,
                      amount: sos.amount,
                      amountUnit: sos.amountUnit
                    }
                  })
                  .then(res => {
                    const sosId = res.data.createScopeOfService.id;
                    Promise.all(
                      sos.scopeOfServiceTyps.map(type =>
                        client.mutate({
                          mutation: CREATE_SCOPE_OF_SERVICE_TYPE,
                          variables: {
                            requestUser,
                            scope_of_service_id: sosId,
                            value: type.value
                          }
                        })
                      )
                    );
                  })
              )
            ).then(() =>
              client
                .mutate({
                  mutation: UPDATE_ORDER,
                  variables: {
                    requestUser,
                    orderId,
                    subcontractor,
                    establishment,
                    description,
                    lat,
                    lng,
                    directions,
                    notes,
                    cost_center_id: costCenter,
                    project_number: projectNumber,
                    start_date_time: startDateTime,
                    start_meeting_time: startMeetingTime,
                    operational_period_start: operationalPeriodStart,
                    operational_period_finish: operationalPeriodFinish,
                    building_address: buildingAddress,
                    construction_manager_name: constructionManagerName,
                    construction_manager_phone: constructionManagerPhone,
                    certified_foreman_name: certifiedForemanName,
                    certified_foreman_phone: certifiedForemanPhone,
                    kutter_contact_id: kutterContactId,
                    kutter_booker_id: kutterBookerId,
                    ebNumber,
                    type
                  }
                })
                .then(response => {
                  console.log(response);
                  dispatch({
                    type: "ORDER_UPDATED",
                    data: response.data.updateOrder
                  });
                  dispatch({
                    type: "DISPO_ORDER_UPDATED",
                    data: response.data.updateOrder
                  });
                })
            )
          )
        )
      )
    );
  }
};

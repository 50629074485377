import gql from "graphql-tag";
import { ReservationData } from "./fragments";

export default (client, startDate, endDate) => dispatch => {
  dispatch({ type: "RESERVATIONS_LOADING", data: "combinations" });

  client
    .query({
      query: gql`
        query getCombinations($startDate: Date, $endDate: Date) {
          getCombinations {
            id
            name
            establishment
            description
            status
            leavingDate
            resources {
              id
              humanResources {
                id
              }
            }
            vehicles {
              id
              humanResources {
                id
              }
            }
            humanResources {
              id
            }
            reservations(start: $startDate, end: $endDate) {
              ...ReservationData
            }
          }
        }
        ${ReservationData}
      `,
      variables: {
        startDate,
        endDate
      },
      options: {
        fetchPolicy: "network-only"
      }
    })
    .then(response => {
      dispatch({
        type: "COMBINATIONS_WITH_RESERVATIONS_FOUND",
        data: response.data.getCombinations
      });
    });
};

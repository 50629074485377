import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sha512 } from 'js-sha512';

import login from '../../actions/login';

export default class PasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      oldPassword: '',
      newPassword1: '',
      newPassword2: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  changePassword() {
    const { dispatch, backend, salt } = this.props;
    const { username, oldPassword, newPassword1, newPassword2 } = this.state;

    if (newPassword1 !== newPassword2) {
      dispatch({
        type: 'SET_ERROR',
        data: 'Die Passwörter stimmen nicht überein.'
      });
      return;
    }

    if (newPassword1 === '') {
      dispatch({
        type: 'SET_ERROR',
        data: 'Das Passwort darf nicht leer sein.'
      });
      return;
    }

    if (newPassword1.length < 5) {
      dispatch({
        type: 'SET_ERROR',
        data: 'Das Passwort darf nicht kürzer al 5 Zeichen sein.'
      });
      return;
    }

    dispatch(
      login(
        username,
        sha512(salt + oldPassword),
        backend,
        sha512(salt + newPassword1)
      )
    );
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') this.changePassword();
  }

  render() {
    const { message, error, onShowLoginForm } = this.props;
    const { username, oldPassword, newPassword1, newPassword2 } = this.state;

    return (
      <Fragment>
        <div className="form-group">
          <label htmlFor="username">Nutzername</label>
          <input
            className="form-control"
            type="text"
            name="username"
            value={username}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Altes Passwort</label>
          <input
            className="form-control"
            type="password"
            name="oldPassword"
            value={oldPassword}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Neues Passwort</label>
          <input
            className="form-control"
            type="password"
            name="newPassword1"
            value={newPassword1}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Neues Passwort wiederholen</label>
          <input
            className="form-control"
            type="password"
            name="newPassword2"
            value={newPassword2}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        {message ? <div className="alert alert-primary small">{message}</div> : null}
        {error ? <div className="alert alert-danger small">{error}</div> : null}

        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-outline-primary w-100"
              onClick={onShowLoginForm}>
              Abbrechen
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary w-100"
              onClick={this.changePassword}>
              Ändern
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

PasswordForm.propTypes = {
  dispatch: PropTypes.func,
  backend: PropTypes.string,
  salt: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  onShowLoginForm: PropTypes.func
};

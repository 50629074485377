import gql from "graphql-tag";
import { TransferDataWithEmployeeHours } from "./fragments";

const QUERY = gql`
  mutation deleteTransferInvoice(
    $id: ID!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    deleteTransferInvoice(id: $id) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id
    }
  });

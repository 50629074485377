import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTruckMoving,
  faCar
} from "@fortawesome/pro-regular-svg-icons";

import DisplayRow from "./displayRow";
import SectionHeading from "./sectionHeading";

export default class OrderPrint extends Component {
  componentDidMount() {
    window.print();
  }

  render() {
    const { order } = this.props;
    //console.log(order);

    const link = `/order/${order.id}`;

    const costCenter = order.cost_center;

    let costCenterValue = "";
    const projectNumber = order.project_number || "";
    const subcontractor = order.subcontractor || "";
    let customer = "";
    let masterCompany = "";
    let buildingProject = "";
    const buildingAddress = order.building_address;
    let buildingCoordinates = `(${order.lat}, ${order.lng})`;
    if (buildingCoordinates === "(, )") {
      buildingCoordinates = "";
    }

    const { directions } = order;
    const { notes } = order;

    const constructionManagerName = order.construction_manager_name;
    const constructionManagerPhone = order.construction_manager_phone;
    const certifiedForemanName = order.certified_foreman_name;
    const certifiedForemanPhone = order.certified_foreman_phone;
    let contactName = "";
    let contactPhone = "";

    if (order.kutterContact) {
      contactName = `${order.kutterContact.firstname} ${order.kutterContact.lastname}`;
      contactPhone = order.kutterContact.phone;
    }

    if (costCenter) {
      costCenterValue = costCenter.value;
      customer = costCenter.customer;
      masterCompany = costCenter.masterCompany;
      buildingProject = costCenter.building_project;
    } else {
      costCenterValue = order.description;
    }

    const date = moment.utc(order.start_date_time).format("DD.MM.YYYY");
    const beginTime = moment.utc(order.start_date_time).format("HH:mm");
    const meetingTime = moment.utc(order.start_meeting_time).format("HH:mm");

    const start = moment
      .utc(order.operational_period_start)
      .format("DD.MM.YYYY");
    const finish = moment
      .utc(order.operational_period_finish)
      .format("DD.MM.YYYY");

    const { scopeOfServices, resources, humanResources, vehicles } = order;

    //console.log(order);
    return (
      <Fragment>
        <div className="col-12 py-2 shadow-sm hidden-print">
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => (window.location = link)}
          >
            Zurück
          </button>
          <button
            className="btn btn-secondary btn-sm float-right"
            onClick={() => window.print()}
          >
            Drucken
          </button>
        </div>
        <div
          className="col-12 height-print-auto print-overflow-visible"
          style={{ height: "calc(100vh - 65px)", overflow: "auto" }}
        >
          <div className="row">
            <div className="col-12">
              <h1 className="border-bottom-data-h2">
                Abruf
                <span className="float-right font-weight-bold">KUTTER</span>
              </h1>
            </div>

            <DisplayRow
              className="col-6 mb-2"
              label="Kostenstelle"
              value={costCenterValue}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Projektnummer"
              value={projectNumber}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Sub/Partner-Unternehmen"
              value={subcontractor}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Auftraggeber"
              value={customer}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Bauher/Hauptauftraggeber"
              value={masterCompany}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Bauvorhaben"
              value={buildingProject}
            />

            <div className="col-12 mb-2">
              <div className="row large">
                <div className="col-auto">
                  <span className="value small">Bauleiter AG:</span>
                  <br />
                  <span className="value small">Polier AG:</span>
                  <br />
                  <span className="value small">Bauleiter Kutter:</span>
                </div>
                <div className="col">
                  <span className="float-right pr-2">
                    {constructionManagerName} / {constructionManagerPhone}
                  </span>
                  <br />
                  <span className="float-right pr-2">
                    {certifiedForemanName} / {certifiedForemanPhone}
                  </span>
                  <br />
                  <span className="float-right pr-2">
                    {contactName} / {contactPhone}
                  </span>
                </div>
              </div>
              <div className="separator" />
            </div>

            <DisplayRow className="col-3 mb-2" label="Datum" value={date} />
            <DisplayRow
              className="col-2 mb-2"
              label="Beginn"
              value={beginTime}
            />
            <DisplayRow
              className="col-2 mb-2"
              label="Treff"
              value={meetingTime}
            />
            <div className="col-1" />
            <DisplayRow className="col-2 mb-2" label="Start" value={start} />
            <DisplayRow className="col-2 mb-2" label="Ende" value={finish} />

            <DisplayRow label="Notizen" value={notes} />

            <SectionHeading text="Anfahrtsbeschreibung" />

            <DisplayRow
              className="col-6 mb-2"
              label="Adresse"
              value={buildingAddress}
            />
            <DisplayRow
              className="col-6 mb-2"
              label="Koordinaten"
              value={buildingCoordinates}
            />
            <DisplayRow label="Anfahrtsbeschreibung" value={directions} />

            <SectionHeading text="Leistungsumfang" />

            <div className="col-12 mb-2">
              <div className="row text-right">
                <div className="col-6" />
                <div className="col-2">
                  <strong>Breite </strong>
                  <span className="label">ca. in m</span>
                </div>
                <div className="col-2">
                  <strong>Tiefe </strong>
                  <span className="label">ca. in cm</span>
                </div>
                <div className="col-2">
                  <strong>Menge </strong>
                  <span className="label">ca.</span>
                </div>
              </div>
              {scopeOfServices.map((sos, index) => {
                let depthStr = sos.depth;
                if (
                  sos.depthTo !== undefined &&
                  sos.depthTo !== null &&
                  sos.depthTo !== ""
                )
                  depthStr += ` - ${sos.depthTo}`;

                return (
                  <div
                    key={sos.id}
                    className={`row large pt-2 mb-2${
                      index > 0 ? " border-top-dashed" : ""
                    }`}
                  >
                    <div className="col-6">
                      <span className="value">
                        {sos.scopeOfServiceTyps
                          .map(sost =>
                            sost.value.startsWith("OTHER:")
                              ? sost.value.substr(6)
                              : sost.value
                          )
                          .sort()
                          .join(", ")}
                      </span>
                    </div>
                    <div className="col-2 text-right">{sos.width}</div>
                    <div className="col-2 text-right">{depthStr}</div>
                    <div className="col-2 text-right">
                      {sos.amount} {sos.amountUnit}
                    </div>
                  </div>
                );
              })}
            </div>

            <SectionHeading text="Ressourcen und Mitarbeiter" />

            <div className="col-4">
              <label className="label w-100">
                Maschinen
                <FontAwesomeIcon
                  className="float-right mt-1"
                  icon={faTruckMoving}
                />
              </label>
              <br />
              <ul className="list-group-flush p-0">
                {resources.map(r => {
                  const start = moment(r.from);
                  const end = moment(r.to);

                  return <li key={r.id} className="list-group-item">
                    <div className="row no-gutters">
                      <div className="col-sm-6">
                        {r.resource.name}{" "}
                        <span>{r.resource.number}</span>
                      </div>
                      <div className="col-sm-6">
                        {start.format('Do MM')} - {end.format('Do MM')}
                      </div>
                      <div className="col-sm-6" />
                      <div className="col-sm-6 float-right">
                        {start.format('HH:mm')} - {end.format('HH:mm')}
                      </div>
                      <div className="col-12 small" style={{ opacity: 0.7 }}>
                        {r.note}
                      </div>
                    </div>
                  </li>
                })}
              </ul>
            </div>
            <div className="col-4">
              <label className="label w-100">
                Fahrzeuge
                <FontAwesomeIcon className="float-right mt-1" icon={faCar} />
              </label>
              <br />
              <ul className="list-group-flush p-0">
                {vehicles.map(v => {
                  const start = moment(v.from);
                  const end = moment(v.to);

                  return (<li key={v.id} className="list-group-item">
                    <div className="row no-gutters">
                      <div className="col-sm-6 text-wrap">
                        {v.resource.brand} {v.resource.type}
                        <span>
                          {" " + v.resource.licensePlate}
                        </span>
                      </div>
                      <div className="col-sm-6">
                        {start.format('Do MM')} - {end.format('Do MM')}
                      </div>
                      <div className="col-sm-6" />
                      <div className="col-sm-6 float-right">
                        {start.format('HH:mm')} - {end.format('HH:mm')}
                      </div>
                      <div className="col-12 small" style={{ opacity: 0.7 }}>
                        {v.note}
                      </div>
                    </div>
                  </li>);
                })}
              </ul>
            </div>
            <div className="col-4">
              <label className="label w-100">
                Mitarbeiter
                <FontAwesomeIcon className="float-right mt-1" icon={faUser} />
              </label>
              <br />
              <ul className="list-group-flush p-0">
                {humanResources.map(hr => {
                  const start = moment(hr.from);
                  const end = moment(hr.to);

                  return (<li key={hr.id} className="list-group-item">
                    <div className="row no-gutters">
                      <div className="col-sm-6">
                        {hr.humanResource.firstname} {hr.humanResource.lastname}
                      </div>
                      <div className="col-sm-6">
                        {start.format('Do MM')} - {end.format('Do MM')}
                      </div>
                      <div className="col-sm-6" />
                      <div className="col-sm-6 float-right">
                        {start.format('HH:mm')} - {end.format('HH:mm')}
                      </div>

                      <div className="col-12 small" style={{opacity: 0.7}}>
                        {hr.note}
                      </div>
                    </div>
                  </li>)
                })
                }
              </ul>
            </div>
            <br style={{ pageBreakBefore: "always" }} />
            <SectionHeading text="Abtransport und Verkippung" />
            {order.transporters.map(transporter => {
              //console.log(transporter);
              return (
                <>
                  <DisplayRow
                    className="col-12 mb-2"
                    label="Firma"
                    value={transporter.company}
                  />
                  <DisplayRow
                    className="col-2 mb-2"
                    label="Kontakt"
                    value={transporter.contactName}
                  />
                  <DisplayRow
                    className="col-2 mb-2"
                    label="Nummer"
                    value={transporter.contactPhone}
                  />
                  <DisplayRow
                    className="col-8 mb-2"
                    label="Notiz"
                    value={transporter.notice}
                  />
                  {transporter.data.map(instance => {
                    return (
                      <>
                        <div className="col-2" />
                        <DisplayRow
                          className="col-2 mb-2"
                          label="Anzahl"
                          value={instance.amount}
                        />
                        <DisplayRow
                          className="col-4 mb-2"
                          label="Kontakt"
                          value={instance.vehicle}
                        />
                        <DisplayRow
                          className="col-2 mb-2"
                          label="Gebühren"
                          value={
                            instance.amountTransfer +
                            " " +
                            instance.amountTransferUnit
                          }
                        />
                        <DisplayRow
                          className="col-2 mb-2"
                          label="Stellzeit"
                          value={instance.time}
                        />
                      </>
                    );
                  })}
                </>
              );
            })}
            {order.dropzones.map(dropzone => {
              //console.log(dropzone);
              return (
                <>
                  <DisplayRow
                    className="col-12 mb-2"
                    label="Firma"
                    value={dropzone.company}
                  />
                  <DisplayRow
                    className="col-6 mb-2"
                    label="Adresse"
                    value={dropzone.address}
                  />
                  <DisplayRow
                    className="col-2 mb-2"
                    label="Kontakt"
                    value={dropzone.contactName}
                  />
                  <DisplayRow
                    className="col-2 mb-2"
                    label="Nummer"
                    value={dropzone.contactPhone}
                  />
                  <DisplayRow
                    className="col-2 mb-2"
                    label="Zeit"
                    value={dropzone.date}
                  />

                  {dropzone.data.map(instance => {
                    return (
                      <>
                        <div className="col-4" />
                        <DisplayRow
                          className="col-4 mb-2"
                          label="Material"
                          value={instance.material}
                        />
                        <DisplayRow
                          className="col-2 mb-2"
                          label="Kontaminiert"
                          value={instance.contaminated ? "ja" : "Nein"}
                        />
                        <DisplayRow
                          className="col-2 mb-2"
                          label="Gebühren"
                          value={
                            (instance.amountDropZone
                              ? instance.amountDropZone
                              : "-") +
                            " " +
                            (instance.amountDropZoneUnit
                              ? instance.amountDropZoneUnit
                              : "")
                          }
                        />
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

OrderPrint.propTypes = {
  order: PropTypes.object
};

import gql from 'graphql-tag';
import { TransferData } from './fragments';

export default (client, startDate, endDate) => dispatch => {
  dispatch({ type: 'RESERVATIONS_LOADING', data: 'transfers' });

  client
    .query({
      query: gql`
        query getTransfersForPeriod($startDate: Date, $endDate: Date) {
          getTransfers(startDate: $startDate, endDate: $endDate) {
            ...TransferData
          }
        }
        ${TransferData}
      `,
      variables: {
        startDate,
        endDate
      },
      options: {
        fetchPolicy: 'network-only'
      }
    })
    .then(response =>
      dispatch({
        type: 'TRANSFERS_FOR_PERIOD_FOUND',
        data: response.data.getTransfers
      })
    );
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sha512 } from 'js-sha512';

import login from '../../actions/login';

export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.login = this.login.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  login() {
    const { dispatch, backend, salt } = this.props;
    const { username, password } = this.state;

    dispatch(login(username, sha512(salt + password), backend));
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') this.login();
  }

  render() {
    const { error, onShowPasswordForm } = this.props;
    const { username, password } = this.state;

    return (
      <Fragment>
        <div className="form-group">
          <label htmlFor="username">Nutzername</label>
          <input
            className="form-control"
            type="text"
            name="username"
            value={username}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Passwort</label>
          <input
            className="form-control"
            type="password"
            name="password"
            value={password}
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />
          <small className="w-100 text-right">
            <button
              className="btn-no-style btn-link w-auto my-1 float-right"
              onClick={onShowPasswordForm}>
              Passwort ändern
            </button>
          </small>
        </div>

        {error ? <div className="alert alert-danger small">{error}</div> : null}

        <button className="btn btn-primary w-100 mt-2" onClick={this.login}>
          Login
        </button>
      </Fragment>
    );
  }
}

LoginForm.propTypes = {
  dispatch: PropTypes.func,
  backend: PropTypes.string,
  salt: PropTypes.string,
  error: PropTypes.string,
  onShowPasswordForm: PropTypes.func
};

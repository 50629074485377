import React from "react";
import Immutable from "immutable";
import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { HttpLink, createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import queryString from "query-string";

import {
  faAddressCard,
  faBalanceScaleRight,
  faBook,
  faChartBar,
  faClipboardList,
  faExchange,
  faFileInvoiceDollar,
  faTruck,
  faTruckContainer,
  faUsers,
  faUserSlash,
  faWrench,
  faCashRegister
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import iTruckConvoy from "../icons/truck_convoy.svg";
import iMachineSmall from "../icons/machine_small2.svg";

import Absences from "../components/Absences";
import Accounting from "../components/Accounting";
import Calendar from "../components/Calendar";
import Combinations from "../components/Combinations";
import Company from "../components/Companies";
import Contact from "../components/Contacts";
import CostCenter from "../components/CostCenter";
import CostCenterFrames from "../components/CostCenterFrames";
import HumanResources from "../components/HumanResources";
import MachineDisposition from "../components/MachineDisposition";
import Main from "../components/Mainpage";
import Order from "../components/Order";
import Permission from "../components/Permission";
import Resources from "../components/Resources";
import Transfer from "../components/Transfer";
import Vehicles from "../components/Vehicles";
import Workshop from "../components/Workshop";
import WorkshopArchive from "../components/WorkshopArchive";

import getLoginName from "../getLoginName";

let localStorageItems = JSON.parse(window.localStorage.getItem("klout")) || {};

const { port, hostname } = window.location;
const backend = `https://${hostname}${port ? `:${port}` : ""}/api`;
const wsBackend = `wss://${hostname}${port ? `:${port}` : ""}/api/subscription`;

const createClient = loginToken => {
  let token = null;
  if (localStorageItems && localStorageItems.value) {
    token = localStorageItems.value;
  } else if (loginToken) {
    token = loginToken;
  }
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token || ""
    }
  }));
  const httpLink = new HttpLink({
    uri: `${backend}/graphql`,
    rejectUnauthorized: false,
    request: operation => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: localStorageItems.value || null
        }
      }));
    }
  });
  const wsLink = new WebSocketLink({
    uri: wsBackend,
    options: {
      reconnect: true
    }
  });
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    authLink.concat(httpLink)
  );
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: "UNION",
            name: "ResourceUnion",
            possibleTypes: [
              {
                name: "Resource"
              },
              {
                name: "Vehicle"
              }
            ]
          }
        ]
      }
    }
  });
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.error(`[Network error]: ${networkError}`);
      }),
      link
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  });
};

const generateMondayClient = () => {
  const httpLink = createHttpLink({ uri: `https://api.monday.com/v2` });
  const authLink = setContext((_, { headers }) => {
    const token =
      "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjM4MjUzMTk2LCJ1aWQiOjEyNjgyMTI4LCJpYWQiOiIyMDIwLTAyLTIxIDA3OjExOjQzIFVUQyIsInBlciI6Im1lOndyaXRlIn0.3DYKl5qv-hLMpRfMy6Ul-NTX6rTMVdtHvuO1VHxUfBQ";
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
};

const getRoutes = (routes, permissions, loginName) => {
  const filterFunc = route => {
    if (!route.path || loginName === "klout" || route.path === "/") return true;

    if (route.path === "/companies") {
      const permission = permissions.find(perm => perm.name === "contacts");
      return permission && permission.read;
    }
    if (route.path === "/workshop_archive") {
      const permission = permissions.find(perm => perm.name === "workshop");
      return permission && permission.read;
    }
    if (route.name === "Loki") {
      const routeSplit = route.path.split("/");
      const query = queryString.parse(routeSplit[routeSplit.length - 1]);
      if (query.token === "" && localStorageItems.value) {
        // eslint-disable-next-line
        route.path += localStorageItems.value;
      }
      const permission = permissions.find(perm => route.name === perm.name);
      return permission && permission.read;
    }

    const permission = permissions.find(perm => route.path === `/${perm.name}`);
    return permission && permission.read;
  };

  const allowedRoutes = {
    left: routes.left.filter(filterFunc),
    right: []
  };

  const right = routes.right.filter(filterFunc);
  const count = right.length;

  let prev = null;

  right.forEach((route, index) => {
    if (index === count - 1) {
      if (route.path) allowedRoutes.right.push(route);
    } else if (!(prev && !prev.path && !route.path)) {
      allowedRoutes.right.push(route);
    }
    prev = route;
  });

  return allowedRoutes;
};

let loginName = getLoginName(localStorageItems);
const loginUserName =
  localStorageItems && localStorageItems.username
    ? localStorageItems.username
    : "";
let permissions =
  localStorageItems && localStorageItems.permissions
    ? localStorageItems.permissions
    : [];
let loginUserId =
  localStorageItems && localStorageItems.id ? localStorageItems.id : null;

const routes = {
  left: [
    {
      path: "/contacts",
      component: Contact,
      name: "Kontakte",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faAddressCard} />
    },
    { path: null, key: "s0" },
    {
      path: "/companies",
      component: Company,
      name: "Firmen",
      icon: null
    },
    {
      path: "/transfers",
      component: Transfer,
      name: "Transporte",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faTruckContainer} />
    },
    {
      path: "/order",
      component: Order,
      name: "Abrufe",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faClipboardList} />
    },
    {
      path: "/disposition",
      component: MachineDisposition,
      name: "Disposition",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faExchange} />
    }
  ],
  right: [
    {
      path: "/cost_center",
      component: CostCenter,
      name: "Kostenstelle",
      icon: (
        <FontAwesomeIcon className="navlink-icon" icon={faFileInvoiceDollar} />
      )
    },
    {
      path: "/cost_center_frame",
      component: CostCenterFrames,
      name: "Kostenstellenrahmen",
      icon: null
    },
    { path: null, key: "s1" },
    {
      path: "/workshop",
      component: Workshop,
      name: "Werkstatt",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faWrench} />
    },
    {
      path: "/workshop_archive",
      component: WorkshopArchive,
      name: "Werkstattarchiv",
      icon: null
    },
    {
      path: "/resources",
      component: Resources,
      name: "Maschinen",
      // icon: <FontAwesomeIcon className="navlink-icon" icon={faTruckMonster} />
      icon: (
        <img
          className="navlink-icon"
          src={iMachineSmall}
          alt="Icon für Maschinen"
          height="26"
        />
      )
    },
    {
      path: "/vehicles",
      component: Vehicles,
      name: "Fahrzeuge",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faTruck} />
    },
    {
      path: "/combinations",
      component: Combinations,
      name: "Gespanne",
      icon: (
        <img
          className="navlink-icon"
          src={iTruckConvoy}
          alt="Icon für Gespanne"
          height="26"
        />
      )
    },
    { path: null, key: "s2" },
    {
      path: "/accounting",
      component: Accounting,
      name: "Abrechnung",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faCashRegister} />
    },
    {
      path: "/human_resources",
      component: HumanResources,
      name: "Mitarbeiter",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faUsers} />
    },
    {
      path: "/absences",
      component: Absences,
      name: "Abwesenheit",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faUserSlash} />
    },
    {
      path: "/calendar",
      component: Calendar,
      name: "Abwesenheit",
      icon: null
    },
    { path: null, key: "s3" },
    {
      path: "/permissions",
      component: Permission,
      name: "Berechtigungen",
      icon: (
        <FontAwesomeIcon className="navlink-icon" icon={faBalanceScaleRight} />
      )
    },
    {
      path: null,
      action: ["SHOW_DOCUMENTATION", true],
      component: null,
      name: "Dokumentation",
      icon: <FontAwesomeIcon className="navlink-icon" icon={faBook} />
    },
    // {
    //   path: `http://${window.location.hostname}:5555/?token=${
    //     localStorageItems && localStorageItems.value
    //       ? localStorageItems.value
    //       : ""
    //   }`,
    //   component: () => null,
    //   name: "Loki",
    //   icon: <FontAwesomeIcon className="navlink-icon" icon={faChartBar} />
    // },
    {
      path: "/",
      component: Main,
      name: "Dashboard",
      icon: null
    }
  ]
};

const establishments = [
  { label: "Leipzig", value: "Leipzig" },
  { label: "Duben", value: "Duben" }
];

const establishment = JSON.parse(window.localStorage.getItem("establishment"));

const initialState = Immutable.Map({
  client: createClient(),
  mondayClient: generateMondayClient(),
  backend,
  loginName,
  loginUserName,
  permissions,
  loginUserId,
  isLogin: Boolean(localStorageItems),
  token:
    localStorageItems && localStorageItems.value ? localStorageItems.value : "",
  salt: "",
  error: null,
  message: null,
  routes: getRoutes(routes, permissions, loginName),
  establishment,
  establishments,
  showDocumentation: false
});

export default (state = initialState, action) => {
  const { type } = action;

  if (type === "SET_ERROR") {
    return state.set("error", action.data);
  }

  if (type === "SET_MESSAGE") {
    return state.set("message", action.data);
  }

  if (type === "SALT_FOUND") {
    return state.set("salt", action.data);
  }

  if (type === "LOGIN_SUCCESSFUL") {
    window.localStorage.setItem("klout", JSON.stringify(action.data));
    localStorageItems = JSON.parse(window.localStorage.getItem("klout"));
    loginName = getLoginName(localStorageItems);
    permissions =
      localStorageItems && localStorageItems.permissions
        ? localStorageItems.permissions
        : [];
    loginUserId =
      localStorageItems && localStorageItems.id ? localStorageItems.id : null;

    const token = action.data.value;
    const client = createClient(token);

    return state
      .set("client", client)
      .set("loginUserId", loginUserId)
      .set("isLogin", Boolean(localStorageItems))
      .set("token", token)
      .set("loginName", loginName)
      .set("loginUserName", localStorageItems.username)
      .set("permissions", permissions)
      .set("routes", getRoutes(routes, permissions, loginName))
      .set("error", null);
  }

  if (type === "CHECK_TOKEN") {
    if (!action.verified) {
      window.localStorage.setItem("klout", null);
    }
    return state.set("isLogin", action.verified);
  }

  if (type === "REMOVE_LOCAL_STORAGE_ITEM") {
    window.localStorage.removeItem("klout");
  }

  if (type === "UPDATE_PERMISSIONS") {
    permissions = action.data;
    if (localStorageItems) {
      localStorageItems.permissions = permissions;
      window.localStorage.setItem("klout", JSON.stringify(localStorageItems));
    }
    return state
      .set("permissions", permissions)
      .set("routes", getRoutes(routes, permissions, loginName));
  }

  if (type === "SET_ESTABLISHMENT") {
    window.localStorage.setItem("establishment", JSON.stringify(action.data));
    return state.set("establishment", action.data);
  }

  if (type === "SHOW_DOCUMENTATION") {
    return state.set("showDocumentation", action.data);
  }

  return state;
};

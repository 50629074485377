import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faCheck,
  faEuroSign,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons/faTimesCircle";

import Calendar from "react-calendar";

import moment from "moment";
import { filterBy, flattenObject } from "../../util/filterBy";
import {
  toDateString,
  monthToString,
  getWeekNumber,
  addDays,
  dateToMonthRange
} from "../../util/dateHelper";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";
import LoadingIndicator from "../LoadingIndicator";

import getOrder from "../../actions/getOrder";
import duplicateOrder from "../../actions/duplicateOrder";
import { generateProjectNumberInterval } from "../Accounting/list";

const getCompareDates = (date1, date2) => {
  const date1From = new Date(date1.from).getTime();
  const date1To = new Date(date1.to).getTime();
  const date2From = new Date(date2.from).getTime();
  const date2To = new Date(date2.to).getTime();

  const date1Crosses =
    (date2From <= date1From && date1From <= date2To) ||
    (date2From <= date1To && date1To <= date2To);
  const date2Crosses =
    (date1From <= date2From && date2From <= date1To) ||
    (date1From <= date2To && date2To <= date1To);

  return date1Crosses || date2Crosses;
};

const getOverlaps = orders =>
  orders.map(order => {
    const from = order.operational_period_start;
    const to = order.operational_period_finish;

    const crossingOrders = orders.reduce((acc, o) => {
      if (
        o !== order &&
        getCompareDates(
          {
            from: o.operational_period_start,
            to: o.operational_period_finish
          },
          { from, to }
        )
      ) {
        const crossingResources = order.resources
          .flatMap(cResource =>
            o.resources
              .filter(
                cr =>
                  getCompareDates(cr, cResource) &&
                  cr.resource &&
                  cResource.resource &&
                  cr.resource.id === cResource.resource.id
              )
              .map(cr => cr.resource.id)
          )
          .filter(e => e.length);

        const crossingVehicles = order.vehicles
          .flatMap(cVehicle =>
            o.vehicles
              .filter(
                cv =>
                  getCompareDates(cv, cVehicle) &&
                  cv.resource &&
                  cVehicle.resource &&
                  cv.resource.id === cVehicle.resource.id
              )
              .map(cv => cv.resource.id)
          )
          .filter(e => e.length);

        const crossingHumanResources = order.humanResources
          .flatMap(cHumanResources =>
            o.humanResources
              .filter(
                chr =>
                  getCompareDates(chr, cHumanResources) &&
                  chr.humanResource &&
                  cHumanResources.humanResource &&
                  chr.humanResource.id === cHumanResources.humanResource.id
              )
              .map(chr => chr.humanResource.id)
          )
          .filter(e => e.length);

        if (
          crossingResources.length +
          crossingVehicles.length +
          crossingHumanResources.length
        ) {
          acc.push({
            id: o.id,
            resources: crossingResources,
            vehicles: crossingVehicles,
            humanResources: crossingHumanResources
          });
        }
      }

      return acc;
    }, []);
    return { ...order, crossingOrders };
  });

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      filter: "",
      continuanceFilter: false,
      sortKey: null,
      reverseSort: true,
      showModal: false,
      message: ""
    };

    this.tableHeadRenderer = className => {
      className += " col-fixed-9th";
      return (
        <Fragment>
          <div className={className}>ID</div>
          <div className={className}>KS</div>
          <div className={className}>Projektnummer</div>
          <div className={className}>Auftraggeber</div>
          <div className={className}>Bauvorhaben</div>
          <div className={className}>Adresse</div>
          <div className={className}>Bauleiter</div>
          <div className={className}>Polier</div>
          <div className={className}>Bauleiter Kutter</div>
        </Fragment>
      );
    };

    this.tableHeadRendererExpanded = className => {
      const noticeClassName = `${className} col-fixed-7th`;
      className += " col-fixed-13th";

      return (
        <Fragment>
          <div className={className}>KS</div>
          <div className={className}>Projektnummer</div>
          <div className={className}>Auftraggeber</div>
          <div className={className}>Bauvorhaben</div>
          <div className={className}>Bauleiter/Polier</div>
          <div className={className}>Datum</div>
          <div className={className}>Leistung</div>
          <div className={className}>Maschinen</div>
          <div className={className}>Fahrzeuge</div>
          <div className={className}>Mitarbeiter</div>
          <div className={noticeClassName}>Notizen</div>
        </Fragment>
      );
    };

    this.tableColRenderer = (elem, className) => {
      let customer = "";
      let buildingProject = "";
      let buildingAddress = elem.building_address;
      const constructionManager = elem.construction_manager_name;
      const certifiedForeman = elem.certified_foreman_name;
      const kutterContactName = elem.kutterContact
        ? `${elem.kutterContact.firstname} ${elem.kutterContact.lastname}`
        : "";

      let hasGPS = false;
      const { project_number, ebNumber, type, subcontractor } = elem;

      const costCenter = elem.cost_center;
      if (costCenter) {
        customer = costCenter.customer;
        buildingProject = costCenter.building_project;
      }

      if (elem.lat !== "") {
        buildingAddress = `In der Nähe von: ${buildingAddress}`;
        hasGPS = true;
      }

      className += " col-fixed-9th clip-text";

      let statusClassName = "order-status-blob-stroke";
      let statusTitle = "";
      let icon = null;

      switch (elem.status) {
        case 2:
          statusClassName += " bg-box-green";
          statusTitle = "Kaufmännisch geprüft";
          icon = faEuroSign;
          break;
        case 1:
          statusClassName += " bg-box-green";
          statusTitle = "Veröffentlicht";
          icon = faCheck;
          break;
        default:
          // status is 0
          if (elem.cost_center) statusClassName += " bg-box-grey";
          else {
            statusClassName += " bg-box-lightgrey";
            statusTitle = "keine Kostenstelle";
          }
          break;
      }

      return (
        <Fragment>
          <div className={`${className} text-right`}>
            <div className={statusClassName} title={statusTitle}>
              <div className="order-status-blob-fill">
                {icon ? (
                  <FontAwesomeIcon
                    className="order-status-blob-icon"
                    icon={icon}
                  />
                ) : null}
              </div>
            </div>
            {elem.id}
          </div>
          <div className={className}>
            {costCenter ? (
              <a href={`/cost_center/${costCenter.id}`}>
                {costCenter.value}
                {costCenter.oldValue ? ` (${costCenter.oldValue})` : ""}
              </a>
            ) : (
              <a href={`/cost_center?order=${elem.id}`}>{elem.description}</a>
            )}
          </div>
          <div className={className}>
            {type === 0 ? (
              <span>
                <a
                  className="custom-a"
                  href={`fileopener:K:\\Kutter Leipzig\\Angebote - Projekte\\${generateProjectNumberInterval(
                    project_number
                  )}\\${project_number}\\1. Angebotsphase`}
                  onClick={e => e.stopPropagation()}
                >
                  {project_number}
                </a>
                <br />
                <p className="badge badge-secondary">{ebNumber}</p>
              </span>
            ) : type === 1 ? (
              <span>von {subcontractor}</span>
            ) : (
              <span>an {subcontractor}</span>
            )}
          </div>
          <div className={className}>{customer}</div>
          <div className={className}>{buildingProject}</div>
          <div className={className}>
            {buildingAddress}
            {hasGPS ? (
              <FontAwesomeIcon
                className="order-gps-icon"
                icon={faLocationArrow}
              />
            ) : null}
          </div>
          <div className={className}>{constructionManager}</div>
          <div className={className}>{certifiedForeman}</div>
          <div className={className}>{kutterContactName}</div>
        </Fragment>
      );
    };

    this.tableColRendererExpanded = (elem, className) => {
      const { users } = this.props;

      const from = elem.operational_period_start;
      const to = elem.operational_period_finish;

      let customer = "";
      let buildingProject = "";
      let date = "";
      const performance = [];
      let buildingAddress = elem.building_address;
      const constructionManager = elem.construction_manager_name;
      const certifiedForeman = elem.certified_foreman_name;
      const kutterContactName = elem.kutterContact
        ? `${elem.kutterContact.firstname} ${elem.kutterContact.lastname}`
        : "";
      const {
        project_number,
        ebNumber,
        type,
        subcontractor,
        crossingOrders
      } = elem;

      let hasGPS = false;
      const costCenter = elem.cost_center;
      if (costCenter) {
        customer = costCenter.customer;
        buildingProject = costCenter.building_project;
      }
      if (elem.operational_period_start) {
        date += moment(elem.operational_period_start).format("DD.MM.YYYY");
      }
      if (elem.operational_period_finish) {
        date += ` - ${moment(elem.operational_period_finish).format(
          "DD.MM.YYYY"
        )}`;
      }

      if (elem.scopeOfServices) {
        elem.scopeOfServices.forEach(service => {
          let performanceString = "";
          service.scopeOfServiceTyps.forEach(type => {
            performanceString += `${type.value}, `;
          });
          if (service.scopeOfServiceTyps.length > 0) {
            performanceString = performanceString.slice(0, -2);
            performanceString += ` (${service.width}m, ${service.depth}cm, ${service.amount}qm)`;
          }
          performance.push(performanceString);
        });
      }

      if (elem.lat !== "") {
        buildingAddress = `In der Nähe von: ${buildingAddress}`;
        hasGPS = true;
      }

      const noticeClassName = `${className} col-fixed-7th clip-text`;
      className += " col-fixed-13th clip-text";

      let statusClassName = "order-status-blob-stroke";
      let statusTitle = "";
      let icon = null;

      switch (elem.status) {
        case 2:
          statusClassName += " bg-box-green";
          statusTitle = "Kaufmännisch geprüft";
          icon = faEuroSign;
          break;
        case 1:
          statusClassName += " bg-box-green";
          statusTitle = "Veröffentlicht";
          icon = faCheck;
          break;
        default:
          // status is 0
          if (elem.cost_center) statusClassName += " bg-box-grey";
          else {
            statusClassName += " bg-box-lightgrey";
            statusTitle = "keine Kostenstelle";
          }
          break;
      }

      return (
        <Fragment>
          <div className={`${className} text-right`}>
            <div className={statusClassName} title={statusTitle}>
              <div className="order-status-blob-fill">
                {icon ? (
                  <FontAwesomeIcon
                    className="order-status-blob-icon"
                    icon={icon}
                  />
                ) : null}
              </div>
            </div>
            {costCenter ? (
              <a href={`/cost_center/${costCenter.id}`}>
                {costCenter.value}
                {costCenter.oldValue ? ` (${costCenter.oldValue})` : ""}
              </a>
            ) : (
              <a href={`/cost_center?order=${elem.id}`}>{elem.description}</a>
            )}
          </div>
          <div className={className}>
            {type === 0 ? (
              <span>
                <a
                  className="custom-a"
                  href={`fileopener:K:\\Kutter Leipzig\\Angebote - Projekte\\${generateProjectNumberInterval(
                    project_number
                  )}\\${project_number}\\1. Angebotsphase`}
                  onClick={e => e.stopPropagation()}
                >
                  {project_number}
                </a>
                <br />
                <p className="badge badge-secondary">{ebNumber}</p>
              </span>
            ) : type === 1 ? (
              <span>von {subcontractor}</span>
            ) : (
              <span>an {subcontractor}</span>
            )}
          </div>
          <div className={className}>{customer}</div>
          <div className={className}>
            {buildingProject}
            <br />
            {buildingAddress}
            <br />
            {hasGPS ? (
              <FontAwesomeIcon
                className="order-gps-icon"
                icon={faLocationArrow}
              />
            ) : null}
          </div>
          <div className={className}>
            {constructionManager && (
              <p className="m-0">
                {`${constructionManager} (BL)`}
                <br />
              </p>
            )}
            {certifiedForeman && (
              <p className="m-0">
                {`${certifiedForeman} (PL)`}
                <br />
              </p>
            )}
            {kutterContactName && (
              <p className="m-0">{`${kutterContactName} (BLK)`}</p>
            )}
          </div>
          <div className={className}>{date}</div>
          <div className={className}>
            {performance.map(text => {
              return (
                <div key={text}>
                  <p className="m-0">{text}</p>
                  <br />
                </div>
              );
            })}
          </div>
          <div className={className}>
            {elem.resources
              ? elem.resources.map(r => {
                  if (!r.resource) {
                    return null;
                  }
                  const crossingResources = crossingOrders.filter(co =>
                    co.resources.includes(r.resource.id)
                  );
                  let resourceClassName = "";
                  let onClick = () => {};
                  let title;
                  if (crossingResources.length) {
                    const crossingResource = crossingResources[0];
                    resourceClassName = "cursor-pointer badge badge-danger";
                    onClick = e => {
                      e.stopPropagation();
                      this.setChosenOrder(crossingResource);
                    };
                    title = crossingResources.map(c => c.id).join(", ");
                  }
                  return (
                    <Fragment key={r.id}>
                      <span
                        className={resourceClassName}
                        onClick={onClick}
                        title={title}
                      >
                        {r.resource
                          ? `${r.resource.name}/${r.resource.number}`
                          : ""}
                      </span>
                      <br />
                    </Fragment>
                  );
                })
              : null}
          </div>
          <div className={className}>
            {elem.vehicles
              ? elem.vehicles.map(v => {
                  if (!v.resource) {
                    return null;
                  }
                  const crossingVehicles = crossingOrders.filter(co =>
                    co.vehicles.includes(v.resource.id)
                  );
                  let resourceClassName = "";
                  let onClick = () => {};
                  let title;
                  if (crossingVehicles.length) {
                    const crossingVehicle = crossingVehicles[0];
                    resourceClassName = "cursor-pointer badge badge-danger";
                    onClick = e => {
                      e.stopPropagation();
                      this.setChosenOrder(crossingVehicle);
                    };
                    title = crossingVehicles.map(c => c.id).join(", ");
                  }

                  return (
                    <Fragment key={v.id}>
                      <span
                        className={resourceClassName}
                        onClick={onClick}
                        title={title}
                      >
                        {v.resource.brand} {v.resource.type} /{" "}
                        {v.resource.number} ({v.resource.licensePlate})
                      </span>
                      <br />
                    </Fragment>
                  );
                })
              : null}
          </div>
          <div className={className}>
            {elem.humanResources
              ? elem.humanResources.map(hr => {
                  if (!hr.humanResource) {
                    return null;
                  }
                  let resourceClassName = "";
                  let onClick = () => {};
                  let title;

                  const user = users.find(u => u.id === hr.humanResource.id);
                  if (user && user.absences.length) {
                    const absences = user.absences.filter(a =>
                      getCompareDates({ ...a }, { from, to })
                    );
                    if (absences.length) {
                      resourceClassName = "cursor-pointer badge badge-warning";
                      onClick = e => {
                        e.stopPropagation();
                        window.location = `/absences/${absences[0].id}`;
                      };
                      title = absences
                        .map(
                          a =>
                            `${moment(a.from).format("DD.MM.YY")}-${moment(
                              a.to
                            ).format("DD.MM.YY")}`
                        )
                        .join(", ");
                    }
                  }

                  const crossingHumanResources = crossingOrders.filter(co =>
                    co.humanResources.includes(hr.humanResource.id)
                  );
                  if (crossingHumanResources.length) {
                    const crossingHumanResource = crossingHumanResources[0];
                    resourceClassName = "cursor-pointer badge badge-danger";
                    onClick = e => {
                      e.stopPropagation();
                      this.setChosenOrder(crossingHumanResource);
                    };
                    title = crossingHumanResources.map(c => c.id).join(", ");
                  }
                  return (
                    <Fragment key={hr.id}>
                      <span
                        className={resourceClassName}
                        onClick={onClick}
                        title={title}
                      >
                        {hr.humanResource.firstname} {hr.humanResource.lastname}
                      </span>
                      <br />
                    </Fragment>
                  );
                })
              : null}
          </div>
          <div className={noticeClassName}>{elem.notes}</div>
        </Fragment>
      );
    };

    this.setNewOrder = this.setNewOrder.bind(this);
    this.setChosenOrder = this.setChosenOrder.bind(this);
    this.duplicateOrder = this.duplicateOrder.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.setContinuanceFilter = this.setContinuanceFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);

    this.changeView = this.changeView.bind(this);

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.handleDaySelect = this.handleDaySelect.bind(this);
    this.handleWeekSelect = this.handleWeekSelect.bind(this);
    this.handleMonthSelect = this.handleMonthSelect.bind(this);
    this.addDate = this.addDate.bind(this);
    this.subDate = this.subDate.bind(this);
    this.alterDate = this.alterDate.bind(this);
  }

  setNewOrder() {
    const { dispatch, onViewChange } = this.props;

    onViewChange("LIST_DETAIL");
    dispatch({
      type: "SET_NEW_ORDER"
    });
    window.history.pushState({}, null, "/order");
  }

  setChosenOrder(order) {
    const { client, dispatch, expanded, onViewChange } = this.props;

    if (expanded) {
      onViewChange("LIST_DETAIL");
    }
    dispatch(getOrder(client, order.id));
    window.history.pushState({}, null, `/order/${order.id}`);
  }

  duplicateOrder() {
    const { client, dispatch, chosenOrderId } = this.props;
    if (chosenOrderId && chosenOrderId !== "-1") {
      dispatch(duplicateOrder(client, chosenOrderId));
    }
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setContinuanceFilter(e) {
    this.setState({ continuanceFilter: e.target.checked });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  static filter(data, filterString, continuanceFilter) {
    const filteredData = filterBy(
      data,
      [
        "building_address",
        "construction_manager_name",
        "certified_foreman_name",
        "establishment",
        "cost_center.establishment",
        "cost_center.value",
        "cost_center.customer",
        "cost_center.building_project"
      ],
      filterString,
      flattenObject
    );

    return (continuanceFilter
      ? filteredData.filter(elem =>
          elem.cost_center ? elem.cost_center.is_continuance : false
        )
      : filteredData
    ).filter(e => e);
  }

  static filterOrder(data, filterString, continuanceFilter) {
    const filteredData = filterBy(
      data,
      [
        "building_address",
        "construction_manager_name",
        "certified_foreman_name",
        "cost_center.value",
        "cost_center.customer",
        "cost_center.building_project"
      ],
      filterString,
      flattenObject
    );

    return (continuanceFilter
      ? filteredData.filter(elem =>
          elem.cost_center ? elem.cost_center.is_continuance : false
        )
      : filteredData
    ).filter(e => e);
  }

  changeView() {
    const { expanded, onViewChange } = this.props;
    onViewChange(expanded ? "LIST_DETAIL" : "LIST");
  }

  toggleCalendar() {
    const { showCalendar } = this.state;
    this.setState({ showCalendar: !showCalendar });
  }

  handleDaySelect(date) {
    const { onDateChange } = this.props;
    onDateChange(date, "DAY", toDateString(date));
  }

  handleWeekSelect(weekNumber, date) {
    const { onDateChange } = this.props;
    onDateChange([date, addDays(date, 6)], "WEEK", `KW ${weekNumber}`);
  }

  handleMonthSelect(date) {
    const { onDateChange } = this.props;

    onDateChange(
      dateToMonthRange(date),
      "MONTH",
      `${monthToString(date.getMonth())} ${date.getFullYear()}`
    );
  }

  addDate() {
    this.alterDate(false);
  }

  subDate() {
    this.alterDate(true);
  }

  alterDate(back) {
    const {
      filterDate,
      filterDateType,
      filterDateDisplayValue,
      onDateChange
    } = this.props;

    const factor = back ? -1 : 1;

    let nextDateValue = filterDate;
    let nextDisplayValue = filterDateDisplayValue;

    switch (filterDateType) {
      case "DAY":
        nextDateValue = addDays(filterDate, factor);
        nextDisplayValue = toDateString(nextDateValue);
        break;
      case "WEEK":
        nextDateValue = [
          addDays(filterDate[0], 7 * factor),
          addDays(filterDate[1], 7 * factor)
        ];
        nextDisplayValue = `KW ${getWeekNumber(nextDateValue[0])}`;
        break;
      case "MONTH": {
        const date = filterDate[0];
        const startDate = new Date(
          date.getFullYear(),
          date.getMonth() + factor,
          1
        );
        const endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        nextDateValue = [startDate, endDate];
        nextDisplayValue = `${monthToString(
          startDate.getMonth()
        )} ${startDate.getFullYear()}`;
        break;
      }
      default:
        break;
    }

    onDateChange(nextDateValue, filterDateType, nextDisplayValue);
  }

  render() {
    let { orders } = this.props;
    const {
      className,
      chosenOrderId,
      loading,
      expanded,
      filterDate,
      filterDateType,
      filterDateDisplayValue,
      write,
      establishment
    } = this.props;
    const { showCalendar, filter, continuanceFilter } = this.state;

    if (establishment) {
      orders = orders.filter(o => o.establishment === establishment.value);
    }

    orders = List.filterOrder(orders, filter, continuanceFilter);

    orders.sort((a, b) => {
      if (!a) {
        return -1;
      }
      if (!b) {
        return 1;
      }
      return parseInt(b.id, 10) - parseInt(a.id, 10);
    });

    if (expanded) {
      orders = getOverlaps(orders);
    }

    const headRenderer = expanded
      ? this.tableHeadRendererExpanded
      : this.tableHeadRenderer;
    const colRenderer = expanded
      ? this.tableColRendererExpanded
      : this.tableColRenderer;

    return (
      <div className={className}>
        <TitleBar title="Abrufe">
          <div className="col d-flex justify-content-center">
            <div>
              <button
                type="button"
                onClick={this.subDate}
                className="btn btn-outline-primary btn-sm px-3"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button
                className="btn btn-outline-primary btn-sm px-3 mx-3"
                style={{ width: 140 }}
                onClick={this.toggleCalendar}
              >
                {filterDateDisplayValue}
              </button>
              <button
                type="button"
                onClick={this.addDate}
                className="btn btn-outline-primary btn-sm px-3"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
          <div className="col-auto">
            {write ? (
              <Fragment>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={this.duplicateOrder}
                  disabled={chosenOrderId === "-1"}
                >
                  duplizieren
                </button>
                <button
                  type="button"
                  onClick={this.setNewOrder}
                  className="btn btn-outline-primary btn-sm ml-3"
                >
                  hinzufügen
                </button>
              </Fragment>
            ) : null}
            <button
              type="button"
              onClick={this.changeView}
              className="btn btn-outline-primary btn-sm px-3 ml-3"
            >
              <FontAwesomeIcon
                icon={expanded ? faChevronLeft : faChevronRight}
              />
            </button>
          </div>

          {showCalendar ? (
            <div>
              <Calendar
                className="order-calendar shadow"
                locale="de-DE"
                view={filterDateType === "MONTH" ? "year" : "month"}
                minDetail="year"
                prev2Label={null}
                next2Label={null}
                showNavigation={true}
                showWeekNumbers={true}
                value={filterDate}
                onChange={this.handleDaySelect}
                onClickWeekNumber={this.handleWeekSelect}
                onClickMonth={this.handleMonthSelect}
              />
              <div
                className="order-calendar-close"
                onClick={() => this.setState({ showCalendar: false })}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    top: "30%",
                    left: "28%",
                    width: "40%",
                    height: "40%"
                  }}
                />
              </div>
            </div>
          ) : null}
        </TitleBar>

        <div className="row mt-3">
          <div className="col">
            <input
              className="form-control"
              placeholder="Filter..."
              value={filter}
              onChange={this.setFilter}
            />
          </div>
          <div className="col-auto pt-2">
            <div className="form-check">
              <input
                id="continuance-filter-input"
                className="form-check-input"
                type="checkbox"
                checked={continuanceFilter}
                onChange={this.setContinuanceFilter}
              />
              <label
                className="form-check-label"
                htmlFor="continuance-filter-input"
              >
                Dauerabrufe
              </label>
            </div>
          </div>
        </div>

        <SimpleTable
          className="mt-3"
          headRenderer={headRenderer}
          colRenderer={colRenderer}
          selected={["id", chosenOrderId]}
          data={orders}
          doubleClick={expanded}
          onColSelected={this.setSortKey}
          onRowSelected={this.setChosenOrder}
        />

        <LoadingIndicator
          className="mt-5"
          show={loading}
          loadingMessage="Lade Abrufe..."
        />
      </div>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  chosenOrderId: PropTypes.string,
  orders: PropTypes.array,
  write: PropTypes.bool,
  loading: PropTypes.bool,
  establishment: PropTypes.object,
  expanded: PropTypes.bool,
  filterDate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filterDateType: PropTypes.string,
  filterDateDisplayValue: PropTypes.string,
  onViewChange: PropTypes.func,
  onDateChange: PropTypes.func,
  users: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  users: state.humanResources.get("users")
}))(List);

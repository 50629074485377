import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';
import { TransferData } from './fragments';

export default (client, id) => dispatch => {
  whenModified(client, 'transfer', () =>
    client
      .query({
        query: gql`
          query {
            getTransfers {
              ...TransferData
            }
          }
          ${TransferData}
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        const data = response.data.getTransfers;

        dispatch({
          type: 'TRANSFERS_FOUND',
          data
        });

        if (id && id !== '') {
          const transfer = data.find(obj => obj.id === id);

          if (transfer)
            dispatch({ type: 'SET_CHOSEN_TRANSFER', data: transfer });
        }
      })
  );
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Transporter from "./transporter";
import Dropzone from "./dropzone";

class Disposal extends Component {
  constructor(props) {
    super(props);

    this.addTransporter = this.addTransporter.bind(this);
    this.addDropzone = this.addDropzone.bind(this);
  }

  addTransporter() {
    const { transporters } = this.props;
    if (!transporters.find(transporter => transporter.id === "new")) {
      transporters.push({
        id: "new",
        company: "",
        contactName: "",
        contactPhone: "",
        date: "12:00",
        minutes: 0,
        data: [],
        isNew: true
      });
      this.setState(prevState => ({ ...prevState }));
    }
  }

  addDropzone() {
    const { dropzones } = this.props;
    if (!dropzones.find(dropzone => dropzone.id === "new")) {
      dropzones.push({
        id: "new",
        company: "",
        address: "",
        contactName: "",
        contactPhone: "",
        date: "12:00",
        data: [],
        isNew: true
      });
      this.setState(prevState => ({ ...prevState }));
    }
  }

  render() {
    const {
      client,
      dispatch,
      order,
      transporters,
      dropzones,
      disabled
    } = this.props;

    const orderId = order ? order.id : "";
    const addTransporterDisabled = transporters.find(
      transporter => transporter.id === "new"
    );
    const addDropzoneDisabled = dropzones.find(
      dropzone => dropzone.id === "new"
    );

    return (
      <div className="col-12">
        <div className="row">
          <div className="col-12 mt-3">
            <span className="h4">Abtransport</span>
          </div>
          {transporters.map(transporter => (
            <Transporter
              key={transporter.id}
              client={client}
              dispatch={dispatch}
              orderId={orderId}
              transporter={transporter}
              disabled={disabled}
            />
          ))}
          <div className="col-12 py-3 border-bottom">
            <button
              className="btn btn-secondary float-right"
              onClick={this.addTransporter}
              disabled={disabled || addTransporterDisabled}>
              Spediteur hinzufügen
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <span className="h4">Kippstellen</span>
          </div>
          {dropzones.map(dropzone => (
            <Dropzone
              key={dropzone.id}
              client={client}
              dispatch={dispatch}
              orderId={orderId}
              dropzone={dropzone}
              disabled={disabled}
            />
          ))}
          <div className="col-12 py-3 border-bottom">
            <button
              className="btn btn-secondary float-right"
              onClick={this.addDropzone}
              disabled={disabled || addDropzoneDisabled}>
              Kippstelle hinzufügen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Disposal.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  order: PropTypes.object,
  transporters: PropTypes.array,
  dropzones: PropTypes.array,
  disabled: PropTypes.bool,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client")
}))(Disposal);

import gql from "graphql-tag";
import getLoginName from "../getLoginName";
import getOrder from "./getOrder";

export default (client, id, orderId, from, to, note) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem("klout"));
  const requestUser = getLoginName(localStorageItems);

  const updateDispoMachine = gql`
    mutation updateDispoMachine(
      $requestUser: String!
      $id: ID
      $order_id: ID
      $from: String
      $to: String
      $note: String
    ) {
      updateDispoMachine(
        requestUser: $requestUser
        id: $id
        order_id: $order_id
        from: $from
        to: $to
        note: $note
      ) {
        id
        from
        to
        note
        resource {
          id
        }
      }
    }
  `;
  client
    .mutate({
      mutation: updateDispoMachine,
      variables: {
        requestUser,
        id: parseInt(id, 10),
        order_id: orderId,
        from,
        to,
        note
      }
    })
    .then(response => {
      dispatch(getOrder(client, orderId));
    });
};

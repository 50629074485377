import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';
import { WorkshopTaskData } from './fragments';

import { startOfDay, endOfDay } from '../util/dateHelper';

export default (client, date, archived = false) => dispatch => {
  const start = date ? startOfDay(date).toISOString() : null;
  const end = date ? endOfDay(date).toISOString() : null;

  whenModified(
    client,
    'workshop_task',
    () =>
      client
        .query({
          query: gql`
            query getWorkshopTasks(
              $archived: Boolean
              $start: Date
              $end: Date
            ) {
              getWorkshopTasks(archived: $archived) {
                ...WorkshopTaskData
              }
            }
            ${WorkshopTaskData}
          `,
          variables: {
            archived,
            start,
            end
          },
          fetchPolicy: 'network-only'
        })
        .then(response => {
          dispatch({
            type: 'TASKS_FOUND',
            data: { tasks: response.data.getWorkshopTasks, archived }
          });
        }),
    archived
  );
};

import gql from "graphql-tag";

import updateWorkshopTask from "./updateWorkshopTask";

export default (client, task, srcColumn, dstColumn, date) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateWorkshopTaskColumn($id: ID, $column: Int) {
          updateWorkshopTaskColumn(id: $id, column: $column) {
            id
            column
          }
        }
      `,
      variables: {
        id: task.id,
        column: dstColumn
      }
    })
    .then(() => {
      if (srcColumn === 1 && dstColumn === 2) {
        const newTask = {
          ...task,
          startDate:
            task.startDate && task.startDate !== "" ? task.startDate : date
        };

        dispatch(updateWorkshopTask(client, newTask, task, date));
      } else if (dstColumn === 100) {
        const newTask = { ...task, dueDate: date };

        dispatch(updateWorkshopTask(client, newTask, newTask, date, true));
      }
    });
};

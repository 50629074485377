import gql from 'graphql-tag';
import { OrderMaxiOverview } from './fragments';

export default (client, orderId, humanResourceId, value) => {
  const SET_ORDER_IS_EMPLOYEE_HOURS_VALID = gql`
    mutation setOrderIsEmployeeHoursValid(
      $id: ID!
      $humanResourceId: ID!
      $isEmployeeHoursValid: Boolean!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      setOrderIsEmployeeHoursValid(
        id: $id
        humanResourceId: $humanResourceId
        isEmployeeHoursValid: $isEmployeeHoursValid
      ) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: SET_ORDER_IS_EMPLOYEE_HOURS_VALID,
      variables: {
        id: orderId,
        humanResourceId,
        isEmployeeHoursValid: value
      }
    })
    .then(response => {
      console.log(response);
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReservationBox from "./reservationBox";

export default class ConflictBox extends Component {
  static getElementsToShow = resources => {
    return resources
      .map(resource => {
        let machine = "";
        // eslint-disable-next-line no-underscore-dangle
        if (resource) {
          switch (resource.__typename) {
            case "Resource": {
              machine = `${resource.name} (${resource.number})`;
              break;
            }
            case "Vehicle": {
              machine = `${resource.type} (${resource.licensePlate})`;
              break;
            }
            case "Combination": {
              machine = `${resource.name}`;
              break;
            }
            default: {
              break;
            }
          }
          return machine;
        }
      })
      .join(", ");
  };

  openContextMenu(e) {
    ReservationBox.preventEvent(e);

    const { onContextMenu } = this.props;
    onContextMenu(e, this, "CONFLICT_BOX");
  }

  render() {
    const { data, line, index, onClick } = this.props;
    const { model, view, order } = data;

    const resources = [...order.resources, ...order.vehicles].map(
      r => r.resource
    );
    const elementsToShow = ConflictBox.getElementsToShow(resources);

    const offsetY = line * 41 + index * 8 + 6;
    const tooltipStyle = { height: 70 };

    return (
      <div
        className="gantt-conflict-line"
        style={{
          left: view.offset,
          top: offsetY,
          width: view.width
        }}
        title={model.name}
        onMouseDown={ReservationBox.preventEvent}
        onMouseUp={ReservationBox.preventEvent}
        onContextMenu={e => this.openContextMenu(e)}
        onClick={() => onClick(resources)}
      >
        <div
          className="gantt-reservation-box-content"
          style={{
            width: view.width,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "inline-block"
          }}
        >
          {elementsToShow || `${model.name}, ${view.status}`}
        </div>
        <div className="gantt-reservation-box-tooltip" style={tooltipStyle}>
          {model.name}, {elementsToShow}
          <br />
          {order.cost_center ? (
            <Fragment>
              {order.cost_center.building_project}
              <br />
            </Fragment>
          ) : null}
          {order.description}
        </div>
      </div>
    );
  }
}

ConflictBox.propTypes = {
  data: PropTypes.object,
  line: PropTypes.number,
  index: PropTypes.number,
  onClick: PropTypes.func
};

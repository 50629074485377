import gql from "graphql-tag";
import * as moment from "moment";
import { TransferDataWithEmployeeHours } from "./fragments";

const QUERY = gql`
  query getTransfersInRange(
    $from: Date!
    $to: Date!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    getTransfersInRange(from: $from, to: $to) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, from, to) => dispatch => {
  dispatch({ type: "SET_TRANSFERS_LOADING" });

  const start = moment.utc(from);
  let month = start.month() + 2;
  let year = start.year();
  if (month > 12) {
    year += 1;
    month = 1;
  }

  client
    .query({
      query: QUERY,
      variables: {
        from: from.toISOString(),
        to: to.toISOString(),
        employeeFilter: { month, year }
      },
      fetchPolicy: "network-only"
    })
    .then(response => {
      dispatch({
        type: "TRANSFERS_FOUND",
        data: response.data.getTransfersInRange
      });
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Data from "./data";
import List from "./list";

import getResources from "../../actions/getResources";
import getMachinists from "../../actions/getMachinists";

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Maschinen";

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getResources(props.client, id));
    props.dispatch(getMachinists(props.client));
  }

  render() {
    const {
      permissions,
      loginName,
      establishment,
      resources,
      chosenResource
    } = this.props;

    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "resources" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              establishment={establishment}
              resources={resources}
              chosenResource={chosenResource}
              write={hasWritePermission}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              establishment={establishment}
              resource={chosenResource}
              resources={resources}
              write={hasWritePermission}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object,
  resources: PropTypes.array,
  chosenResource: PropTypes.object,
  users: PropTypes.array,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  resources: state.resources.get("resources"),
  chosenResource: state.resources.get("chosenResource"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);

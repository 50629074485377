import gql from 'graphql-tag';

export default (client, humanResources, vehicleId) => dispatch => {
  const ids = [];
  Promise.all(
    humanResources.map(humanResource => {
      ids.push(humanResource.id);
      return client.mutate({
        mutation: gql`
          mutation deleteHumanResourceVehicle(
            $vehicleId: ID
            $humanResourceId: ID
          ) {
            deleteHumanResourceVehicle(
              vehicle_id: $vehicleId
              human_resource_id: $humanResourceId
            ) {
              success
            }
          }
        `,
        variables: {
          vehicleId,
          humanResourceId: humanResource.id
        }
      });
    })
  ).then(() => {
    dispatch({
      type: 'HUMANRESOURCEVEHICLE_DELETED',
      ids,
      vehicleId
    });
  });
};

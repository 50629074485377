import gql from 'graphql-tag';

export default client => dispatch => {
  client
    .query({
      query: gql`
        query {
          getMachinists {
            id
            lastname
            firstname
            phone
            street
            city
            zipcode
            position
            status
            absences {
              id
              from
              to
              type
              days
            }
          }
        }
      `
    })
    .then(response => {
      dispatch({ type: 'USERS_FOUND', data: response.data.getMachinists });
    });
};

/*
  Takes a list of objects and groups them by the value of the given key.
  Returns an object which keys correspond to the encountered values.
  Each key holds an array of objects which match the group key.
 */
export function groupBy(objects, key) {
  const grouped = {};

  objects.forEach(obj => {
    const value = obj[key].toString();
    if (value) {
      if (grouped[value]) grouped[value].push(obj);
      else grouped[value] = [obj];
    }
  });

  return grouped;
}

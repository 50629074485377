import gql from "graphql-tag";
import store from "../store";
import getOrder from "./getOrder";

export default (client, id, resourceId, type, orderId) => dispatch => {
  const user = store.getState().main.get("loginName");

  client
    .mutate({
      mutation: gql`
        mutation deleteReservation($user: String!, $id: ID) {
          deleteReservation(user: $user, id: $id) {
            success
          }
        }
      `,
      variables: { user, id }
    })
    .then(response => {
      if (response.data.deleteReservation.success === true) {
        dispatch({
          type: "RESERVATION_DELETED",
          data: { id, resourceId, type }
        });
        dispatch(getOrder(client, orderId));
      } else {
        dispatch({
          type: "SET_ERROR",
          data: response.data.deleteReservation.success
        });
      }
    });
};

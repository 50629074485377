import Immutable from 'immutable';

const initialState = Immutable.Map({
  absences: [],
  chosenAbsence: null
});

export default (state = initialState, action) => {
  if (action.type === 'SET_NEW_ABSENCE') {
    return state.set('chosenAbsence', null);
  }

  if (action.type === 'ABSENCES_FOUND') {
    return state.set('absences', action.data);
  }

  if (action.type === 'SET_CHOSEN_ABSENCE') {
    return state.set('chosenAbsence', action.data);
  }

  if (action.type === 'ABSENCE_ADDED') {
    const absences = state.get('absences');
    const newAbsence = action.data;
    return state
      .set('absences', [].concat(...absences, newAbsence))
      .set('chosenAbsence', newAbsence);
  }

  if (action.type === 'ABSENCE_UPDATED') {
    const absences = state.get('absences');
    return state
      .set(
        'absences',
        absences.map(absence => {
          if (absence.id === action.data.id) return action.data;
          return absence;
        })
      )
      .set('chosenAbsence', action.data);
  }

  if (action.type === 'ABSENCE_DELETED') {
    const absences = state.get('absences');
    const nextAbsences = absences.filter(
      absence => absence.id !== action.id
    );
    return state
      .set('absences', [].concat(...nextAbsences))
      .set('chosenAbsence', null);
  }

  return state;
};

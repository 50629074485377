import gql from 'graphql-tag';

export default (
  client,
  userId,
  username,
  password
) => dispatch => {
  const query = `
    mutation updateHumanResourceUsernameAndPassword(
      $userId: ID
      $username: String
      $password: String
    ) {
      updateHumanResourceUsernameAndPassword(
        id: $userId
        username: $username
        password: $password
      ) {
        id
        lastname
        firstname
        username
        phone
        street
        city
        zipcode
        position
        absences {
          id
          from
          to
          type
          days
        }
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        userId,
        username,
        password
      }
    })
    .then(response => {
      dispatch({
        type: 'HUMAN_RESOURCE_UPDATED',
        data: response.data.updateHumanResourceUsernameAndPassword,
        userId
      });
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Floorplan extends Component {
  getDuben() {
    const [
      lap1,
      lap2,
      lap3,
      lap4,
      lap5,
      lap6,
      lap7,
      lap8,
      dap1,
      dap2,
      dap3,
      dap4,
      dap5
    ] = this.props.workspaces;

    return (
      <div className="cb-fp-container">
        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 116,
              background: dap3.color,
              opacity: dap3.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {dap3.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Schweißplatz
              <br />
              [D-AP-3]
            </span>
            {dap3.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 145,
              background: dap4.color,
              opacity: dap4.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {dap4.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Kleinmaschinen, Kompaktlader
              <br />
              [D-AP-4]
            </span>
            {dap4.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 58,
              background: dap5.color,
              opacity: dap5.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {dap5.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Kleingeräte
              <br />
              [D-AP-5]
            </span>
            {dap5.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>
        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{ width: 5, background: "#aaa", marginLeft: -10 }}
          />
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 322,
              background: dap1.color,
              opacity: dap1.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {dap1.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Werkstatt
              <br />
              [D-AP-1]
            </span>
            {dap1.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>
        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 264,
              background: dap2.color,
              opacity: dap2.tasks.length > 0 ? "1" : "0.5",
              marginLeft: 58
            }}>
            <span>
              {dap2.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Werkstatt
              <br />
              [D-AP-2]
            </span>
            {dap2.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>
      </div>
    );
  }

  getLeipzig() {
    const [
      lap1,
      lap2,
      lap3,
      lap4,
      lap5,
      lap6,
      lap7,
      lap8,
      dap1,
      dap2,
      dap3,
      dap4,
      dap5,
      schlosserei,
      hebebuehne,
      lager,
      grubenhalle,
      waschhalle,
      other
    ] = this.props.workspaces;
    return (
      <div className="cb-fp-container">
        <div className="cb-fp-row">
          <div
            className="cb-fp-outside-workshop"
            style={{
              color: other.color,
              borderColor: other.color,
              opacity: other.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>Sonstiges</span>
            {other.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-room"
            style={{ width: 100, opacity: 0.5 }}>
            <span>Büro Meister</span>
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 210,
              background: schlosserei.color,
              opacity: schlosserei.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {schlosserei.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Schlosserei
            </span>
            {schlosserei.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 110,
              background: lap1.color,
              opacity: lap1.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap1.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-1
            </span>
            {lap1.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 110,
              background: lap2.color,
              opacity: lap2.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap2.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-2
            </span>
            {lap2.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-room"
            style={{
              width: 100,
              color: hebebuehne.color,
              borderColor: hebebuehne.color,
              opacity: hebebuehne.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {hebebuehne.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              PKW Hebebühne
            </span>
            {hebebuehne.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 140,
              background: lap3.color,
              opacity: lap3.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap3.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-3
            </span>
            {lap3.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 140,
              background: lap4.color,
              opacity: lap4.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap4.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-4
            </span>
            {lap4.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 160,
              background: lap5.color,
              opacity: lap5.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap5.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-5
            </span>
            {lap5.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 160,
              background: lap6.color,
              opacity: lap6.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap6.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-6
            </span>
            {lap6.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 160,
              background: lap7.color,
              opacity: lap7.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap7.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-7
            </span>
            {lap7.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 160,
              background: lap8.color,
              opacity: lap8.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lap8.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              L-AP-8
            </span>
            {lap8.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-room"
            style={{ width: 120, opacity: 0.5 }}>
            <span>Büro</span>
          </div>
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 190,
              background: lager.color,
              opacity: lager.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {lager.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Lager
            </span>
            {lager.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-ap"
            style={{
              width: 320,
              background: grubenhalle.color,
              opacity: grubenhalle.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {grubenhalle.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Grubenhalle
            </span>
            {grubenhalle.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>

        <div className="cb-fp-row">
          <div
            className="cb-fp-block cb-fp-block-room"
            style={{
              width: 320,
              color: waschhalle.color,
              borderColor: waschhalle.color,
              opacity: waschhalle.tasks.length > 0 ? "1" : "0.5"
            }}>
            <span>
              {waschhalle.tasks.length > 1 ? (
                <FontAwesomeIcon
                  className="text-danger mr-1"
                  icon={faExclamationTriangle}
                />
              ) : null}
              Waschhalle
            </span>
            {waschhalle.tasks.map(t => (
              <span key={t.id}>{t.text}</span>
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { establishment } = this.props;

    let component;
    switch (establishment) {
      case "Leipzig":
        component = this.getLeipzig();
        break;
      case "Duben":
        component = this.getDuben();
        break;
      default:
        component = (
          <Fragment>
            {this.getDuben()}
            <hr />
            {this.getLeipzig()}
          </Fragment>
        );
        break;
    }

    return (
      <div className="col-auto p-2">
        <div className="cb-drop-container bg-light rounded mt-2">
          {component}
        </div>
      </div>
    );
  }
}

Floorplan.propTypes = {
  workspaces: PropTypes.array,
  establishment: PropTypes.string
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class AbsenceBox extends Component {
  render() {
    const { model, view, line, index } = this.props;
    const { hrName, type } = model;

    const offsetY = line * 41 + index * 8 + 6;

    const title = `${hrName} (${type})`;

    let className = 'gantt-absence-line gantt-absence-line-orange';

    if (type === 'Krank' || type === 'Schule' || type === 'Feiertag') {
      className = 'gantt-absence-line gantt-absence-line-red';
    }

    return (
      <div
        className={className}
        style={{
          left: view.offset,
          top: offsetY,
          width: view.width
        }}
        title={title}
      />
    );
  }
}

AbsenceBox.propTypes = {
  model: PropTypes.object,
  view: PropTypes.object,
  line: PropTypes.number,
  index: PropTypes.number
};

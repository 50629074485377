import gql from 'graphql-tag';

export default (client, humanResources, resourceId) => dispatch => {
  Promise.all(
    humanResources.map(humanResource =>
      client.mutate({
        mutation: gql`
          mutation createHumanResourceResource(
            $resourceId: ID
            $humanResourceId: ID
          ) {
            createHumanResourceResource(
              resource_id: $resourceId
              human_resource_id: $humanResourceId
            ) {
              id
              lastname
              firstname
            }
          }
        `,
        variables: {
          resourceId,
          humanResourceId: humanResource.id
        }
      })
    )
  ).then(responses => {
    responses.map(response => {
      dispatch({
        type: 'HUMANRESOURCERESOURCE_CREATED',
        data: response.data.createHumanResourceResource,
        resourceId
      });
      return response;
    });
  });
};

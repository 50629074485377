import gql from 'graphql-tag';

export default (client, humanResources, vehicleId) => dispatch => {
  Promise.all(
    humanResources.map(humanResource =>
      client.mutate({
        mutation: gql`
          mutation createHumanResourceVehicle(
            $vehicleId: ID
            $humanResourceId: ID
          ) {
            createHumanResourceVehicle(
              vehicle_id: $vehicleId
              human_resource_id: $humanResourceId
            ) {
              id
              lastname
              firstname
            }
          }
        `,
        variables: {
          vehicleId,
          humanResourceId: humanResource.id
        }
      })
    )
  ).then(responses => {
    responses.map(response => {
      dispatch({
        type: 'HUMANRESOURCEVEHICLE_CREATED',
        data: response.data.createHumanResourceVehicle,
        vehicleId
      });
      return response;
    });
  });
};

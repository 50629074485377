import request from "superagent";

const CHANGE_PASSWORD = `
  mutation updateUserPassword(
    $username: String
    $oldPassword: String
    $newPassword: String
  ) {
    updateUserPassword(
      username: $username
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
    }
  }
`;

export default (
  username,
  password,
  backend,
  newPassword = null
) => dispatch => {
  const host = `${backend}/getToken`;
  request
    .post(host, {
      username,
      password
    })
    .end((err, response) => {
      if (err) {
        dispatch({
          type: "SET_ERROR",
          data: "Login nicht erfolgreich."
        });
      } else if (response) {
        const data = JSON.parse(response.text);

        if (!err && data.error === null) {
          const loginData = {
            id: data.id,
            value: data.value,
            firstname: data.firstname,
            lastname: data.lastname,
            username: data.username,
            permissions: data.permissions
          };

          if (newPassword) {
            dispatch({
              type: "SET_MESSAGE",
              data: "Ändere Passwort..."
            });
            fetch(`${backend}/graphql`, {
              method: "POST",
              body: JSON.stringify({
                operationName: null,
                query: CHANGE_PASSWORD,
                variables: {
                  username,
                  oldPassword: password,
                  newPassword
                }
              }),
              headers: {
                authorization: data.value,
                "Content-Type": "application/json"
              }
            })
              .then(res => res.json())
              .then(changePasswordData => {
                if (changePasswordData) {
                  const {
                    success
                  } = changePasswordData.data.updateUserPassword;

                  if (success === true) {
                    dispatch({
                      type: "SET_MESSAGE",
                      data:
                        "Ihr Passwort wurde erfolgreich geändert. Sie werden in 5 Sekunden automatisch angemeldet."
                    });
                    setTimeout(
                      () =>
                        dispatch({
                          type: "LOGIN_SUCCESSFUL",
                          data: loginData
                        }),
                      5000
                    );
                  } else {
                    dispatch({
                      type: "SET_ERROR",
                      data: success
                    });
                  }
                }
              });
          } else {
            dispatch({
              type: "LOGIN_SUCCESSFUL",
              data: loginData
            });
          }
        } else if (err) {
          dispatch({ type: "SET_ERROR", data: err });
        } else {
          dispatch({ type: "SET_ERROR", data: data.error });
        }
      } else {
        dispatch({
          type: "SET_ERROR",
          data: "Login nicht erfolgreich."
        });
      }
    });
};

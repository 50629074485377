import React, { Component, Fragment } from "react";
import { GetTimePercentage, getTimeRange } from "./dateHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/pro-regular-svg-icons";

export default class TimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: props.resources,
      vehicles: props.vehicles,
      humanResources: props.humanResources,
      machinesToAdjust: props.machinesToAdjust,
      vehiclesToAdjust: props.vehiclesToAdjust,
      humanResourcesToAdjust: props.humanResourcesToAdjust,
      opStartDate: props.opStartDate,
      opFinishDate: props.opFinishDate,
      onSave: props.onSave,
      orderStartDate: props.orderStartDate ? props.orderStartDate : null,
      orderEndDate: props.orderEndDate ? props.orderEndDate : null,
      order: props.order ? props.order : null,
      orderCheck: props.orderCheck !== null ? props.orderCheck : null,
      handleModalOrderChange: props.handleModalOrderChange
        ? props.handleModalOrderChange
        : null,
      selectedResourceId: props.selectedResourceId
        ? props.selectedResourceId
        : null
    };
  }

  render() {
    const {
      resources,
      vehicles,
      humanResources,
      machinesToAdjust,
      vehiclesToAdjust,
      humanResourcesToAdjust,
      opStartDate,
      opFinishDate,
      orderStartDate,
      orderEndDate,
      order,
      orderCheck,
      handleModalOrderChange,
      selectedResourceId
    } = this.state;
    const { handleModalChange, width } = this.props;
    const dateRangeObj = getTimeRange(opStartDate, opFinishDate);

    return (
      <div className="confirmationModal" style={{ width: width }}>
        <div
          className="modalBox"
          style={
            width === "100%" ? { marginLeft: "35%", marginRight: "35%" } : {}
          }
        >
          <div className="card">
            <div className="card-body">
              <p className="m-0" style={{ fontSize: "14px" }}>
                Der Einsatzzeitraum hat sich geändert! <br />
                <br />
                Neuer Einsatzzeitraum:{" "}
                {dateRangeObj.startDate +
                  (dateRangeObj.endDate !== "..."
                    ? " - " + dateRangeObj.endDate
                    : "")}{" "}
                <br />
                <br />
                Welche der folgenden Instanzen sollen zeitlich an den neuen
                Zeitraum angepasst werden?
              </p>
              <div className="p-2">
                <div className="row p-2">
                  <div className="col-11" />
                  <div className="col-1">
                    {resources.length === machinesToAdjust.length &&
                      vehicles.length === vehiclesToAdjust.length &&
                      humanResources.length === humanResourcesToAdjust.length &&
                      (orderCheck !== null && orderCheck) && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "-2px"
                          }}
                          onClick={() => {
                            const orderCheckTmp =
                              orderCheck !== null ? false : null;
                            this.setState({
                              machinesToAdjust: [],
                              vehiclesToAdjust: [],
                              humanResourcesToAdjust: [],
                              orderCheck: orderCheckTmp
                            });
                          }}
                        />
                      )}
                    {(resources.length !== machinesToAdjust.length ||
                      vehicles.length !== vehiclesToAdjust.length ||
                      humanResources.length !== humanResourcesToAdjust.length ||
                      (orderCheck !== null && !orderCheck)) && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "-2px"
                        }}
                        onClick={() => {
                          const newMachineArr = [];
                          const newVehicleArr = [];
                          const newHumanResourceArr = [];
                          const orderCheckTmp =
                            orderCheck !== null ? true : null;
                          resources.forEach(r => {
                            newMachineArr.push(r.id);
                          });
                          vehicles.forEach(v => {
                            newVehicleArr.push(v.id);
                          });
                          humanResources.forEach(hr => {
                            newHumanResourceArr.push(hr.id);
                          });
                          this.setState({
                            machinesToAdjust: newMachineArr,
                            vehiclesToAdjust: newVehicleArr,
                            humanResourcesToAdjust: newHumanResourceArr,
                            orderCheck: orderCheckTmp
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
                {orderStartDate && orderEndDate && order && (
                  <div className="row p-2">
                    <div className="col-4 py-2" key="orderExtend">
                      Abruf
                    </div>
                    <div className="col-7" key="orderExtendBox">
                      {/*{getTimePercentage(*/}
                      {/*  opStartDate,*/}
                      {/*  opFinishDate,*/}
                      {/*  orderStartDate,*/}
                      {/*  orderEndDate*/}
                      {/*)}*/}
                      <GetTimePercentage
                        disabled={true}
                        startDate={opStartDate}
                        endDate={opFinishDate}
                        startDateInstance={orderStartDate}
                        endDateInstance={orderEndDate}
                      />
                    </div>
                    <div className="col-1 py-2 pr-1" key="orderExtendCheck">
                      {orderCheck ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "-2px"
                          }}
                          onClick={() => {
                            this.setState({ orderCheck: false });
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "-2px"
                          }}
                          onClick={() => {
                            this.setState({ orderCheck: true });
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="row p-2 instanceList">
                  {resources.map(r => {
                    if (r.resource && selectedResourceId !== r.id) {
                      return (
                        <Fragment>
                          <div
                            className="col-4 py-2"
                            id={r.resource.name}
                            style={{
                              overflow: "hidden",
                              display: "block",
                              maxWidth: "100%",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              background: r.color
                            }}
                            title={
                              r.resource
                                ? `${r.resource.name} / ${r.resource.number}`
                                : ""
                            }
                          >
                            {r.resource.name}
                          </div>
                          <div className="col-7" id={r.id}>
                            <GetTimePercentage
                              disabled={true}
                              startDate={opStartDate}
                              endDate={opFinishDate}
                              startDateInstance={r.from}
                              endDateInstance={r.to}
                            />
                          </div>
                          <div className="col-1 py-2 pr-1" id={`${r.id}i`}>
                            <span>
                              {machinesToAdjust.includes(r.id) && (
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    const newArr = [];
                                    machinesToAdjust.forEach(machine => {
                                      if (machine !== r.id) {
                                        newArr.push(machine);
                                      }
                                    });
                                    this.setState({
                                      machinesToAdjust: newArr
                                    });
                                  }}
                                />
                              )}
                              {!machinesToAdjust.includes(r.id) && (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      machinesToAdjust: machinesToAdjust.concat(
                                        r.id
                                      )
                                    });
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </Fragment>
                      );
                    }
                  })}
                  {vehicles.map(v => {
                    if (v.resource && selectedResourceId !== v.id) {
                      return (
                        <Fragment>
                          <div className="col-4 py-2" id={`${v.id}name`}>
                            <span
                              className="rounded"
                              style={{
                                overflow: "hidden",
                                display: "block",
                                maxWidth: "100%",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                background: v.color
                              }}
                              title={
                                v.resource
                                  ? `${v.resource.brand} ${v.resource.type} / ${v.resource.licensePlate}`
                                  : ""
                              }
                            >
                              {v.resource
                                ? `${v.resource.brand} ${v.resource.type} / ${v.resource.licensePlate}`
                                : ""}{" "}
                            </span>
                          </div>
                          <div className="col-7" id={v.id}>
                            <GetTimePercentage
                              disabled={true}
                              startDate={opStartDate}
                              endDate={opFinishDate}
                              startDateInstance={v.from}
                              endDateInstance={v.to}
                            />
                          </div>
                          <div className="col-1 py-2 pr-1" id={`${v.id}i`}>
                            <span>
                              {vehiclesToAdjust.includes(v.id) && (
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    const newArr = [];
                                    vehiclesToAdjust.forEach(id => {
                                      if (id !== v.id) {
                                        newArr.push(id);
                                      }
                                    });
                                    this.setState({
                                      vehiclesToAdjust: newArr
                                    });
                                  }}
                                />
                              )}
                              {!vehiclesToAdjust.includes(v.id) && (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      vehiclesToAdjust: vehiclesToAdjust.concat(
                                        v.id
                                      )
                                    });
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </Fragment>
                      );
                    }
                  })}
                  {humanResources.map(hr => {
                    if (hr.humanResource && hr.id !== selectedResourceId)
                      return (
                        <Fragment>
                          <div className="col-4 py-2" id={`${hr.id}name`}>
                            <span
                              className="rounded"
                              style={{
                                overflow: "hidden",
                                display: "block",
                                maxWidth: "100%",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                background: hr.color
                              }}
                              title={`${hr.humanResource.lastname} ${hr.humanResource.firstname}`}
                            >
                              {`${hr.humanResource.lastname} ${hr.humanResource.firstname}`}
                            </span>
                          </div>
                          <div className="col-7" id={hr.id}>
                            <GetTimePercentage
                              disabled={true}
                              startDate={opStartDate}
                              endDate={opFinishDate}
                              startDateInstance={hr.from}
                              endDateInstance={hr.to}
                            />
                          </div>
                          <div className="col-1 py-2 pr-1" id={`${hr.id}i`}>
                            <span>
                              {humanResourcesToAdjust.includes(hr.id) && (
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    const newArr = [];
                                    humanResourcesToAdjust.forEach(id => {
                                      if (id !== hr.id) {
                                        newArr.push(id);
                                      }
                                    });
                                    this.setState({
                                      humanResourcesToAdjust: newArr
                                    });
                                  }}
                                />
                              )}
                              {!humanResourcesToAdjust.includes(hr.id) && (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "-2px"
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      humanResourcesToAdjust: humanResourcesToAdjust.concat(
                                        hr.id
                                      )
                                    });
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </Fragment>
                      );
                  })}
                </div>
                <div
                  className="btn btn-outline-success m-2 float-right"
                  onClick={() => {
                    if (order) {
                      handleModalOrderChange(order, orderCheck);
                    }
                    handleModalChange(
                      machinesToAdjust,
                      vehiclesToAdjust,
                      humanResourcesToAdjust,
                      true
                    );
                  }}
                >
                  bestätigen & speichern
                </div>
                <div
                  className="btn btn-outline-secondary m-2 float-right"
                  onClick={() => {
                    if (order) {
                      handleModalOrderChange(order, orderCheck);
                    }
                    handleModalChange(
                      machinesToAdjust,
                      vehiclesToAdjust,
                      humanResourcesToAdjust,
                      false
                    );
                  }}
                >
                  abbrechen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import gql from 'graphql-tag';
import { ResourceData } from './fragments';

import createHumanResourceResource from './createHumanResourceResource';
import createCommitment from './createCommitment';

export default (
  client,
  name,
  number,
  image,
  category,
  subcategory,
  comment,
  commitments,
  establishment,
  humanResources,
  implementation,
  status,
  leavingDate
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createResource(
          $name: String
          $number: String
          $image: String
          $category: String
          $subcategory: String
          $comment: String
          $establishment: String
          $implementation: String
          $status: String
          $leavingDate: Date
        ) {
          createResource(
            name: $name
            number: $number
            image: $image
            category: $category
            subcategory: $subcategory
            comment: $comment
            establishment: $establishment
            implementation: $implementation
            status: $status
            leavingDate: $leavingDate
          ) {
            ...ResourceData
          }
        }
        ${ResourceData}
      `,
      variables: {
        name,
        number,
        image,
        category,
        subcategory,
        comment,
        establishment,
        implementation,
        status,
        leavingDate
      }
    })
    .then(response => {
      const resourceId = response.data.createResource.id;
      dispatch(createHumanResourceResource(client, humanResources, resourceId));
      dispatch(createCommitment(client, commitments, resourceId));
      dispatch({
        type: 'RESOURCE_ADDED',
        data: response.data.createResource
      });
      window.history.pushState(
        {},
        null,
        `/resources/${response.data.createResource.id}`
      );
    });
};

import gql from 'graphql-tag';

export default client => dispatch => {
  client
    .query({
      query: gql`
        query {
          getVehicleBrands {
            value: brand
            label: brand
          }
        }
      `
    })
    .then(response => {
      dispatch({
        type: 'VEHICLE_BRANDS_FOUND',
        data: response.data.getVehicleBrands
      });
    });
};

import Immutable from "immutable";

const initialState = Immutable.Map({
  users: [],
  chosenUser: null,
  positions: [
    "Abrechnung",
    "Bauleiter",
    "Buchhaltung",
    "Büroleitung/Lohnbuchhaltung",
    "Disposition",
    "Kalkulation",
    "Maschinist",
    "Niederlassungsleiter",
    "Schlosser",
    "Umsetzer",
    "Zweigstellenleiter"
  ],
  absenceTypes: [
    { label: "Krank", value: "Krank", color: "#FF0000" },
    { label: "Schule", value: "Schule", color: "#FF0000" },
    { label: "Feiertag", value: "Feiertag", color: "#FF0000" },
    { label: "Urlaub", value: "Urlaub", color: "#FF8800" },
    { label: "Resturlaub", value: "Resturlaub", color: "#FF8800" },
    {
      label: "Betriebliche Freistellung",
      value: "Betriebliche Freistellung",
      color: "#FF8800"
    },
    { label: "Sonderurlaub", value: "Sonderurlaub", color: "#FF8800" },
    { label: "Abbau Überstunden", value: "Abbau Überstunden", color: "#FF8800" }
  ],
  employeeHours: [],
  disposHumanResource: []
});

export const mapFunc = d => {
  const returnData = d;
  // eslint-disable-next-line no-underscore-dangle
  delete returnData.__typename;
  return {
    ...returnData,
    human_resource_id: parseInt(returnData.human_resource_id, 10)
  };
};

export default (state = initialState, action) => {
  if (action.type === "USERS_FOUND") {
    return state.set("users", action.data);
  }
  if (action.type === "DISPOS_HR_FOUND") {
    return state.set("disposHumanResource", action.data);
  }
  if (action.type === "SET_CHOSEN_USER") {
    return state.set("chosenUser", action.data);
  }
  if (action.type === "ABSENCE_ADDED") {
    const users = state.get("users");
    const userId = action.data.humanResource.id;

    let nextChosenUser = null;

    const nextUsers = users.map(user => {
      if (user.id === userId) {
        nextChosenUser = {
          ...user,
          absences: user.absences.concat(action.data)
        };
        return nextChosenUser;
      }
      return user;
    });

    return state.set("users", nextUsers).set("chosenUser", nextChosenUser);
  }

  if (action.type === "ABSENCE_UPDATED") {
    const users = state.get("users");
    const userId = action.data.humanResource.id;

    let nextChosenUser = null;

    const nextUsers = users.map(user => {
      if (user.id === userId) {
        nextChosenUser = {
          ...user,
          absences: user.absences.map(absence =>
            absence.id === action.data.id ? action.data : absence
          )
        };
        return nextChosenUser;
      }
      return user;
    });

    return state.set("users", nextUsers).set("chosenUser", nextChosenUser);
  }

  if (action.type === "ABSENCE_DELETED") {
    const users = state.get("users");
    const userId = action.userId.toString();

    let nextChosenUser = null;

    const nextUsers = users.map(user => {
      if (user.id === userId) {
        nextChosenUser = {
          ...user,
          absences: user.absences.filter(absence => absence.id !== action.id)
        };
        return nextChosenUser;
      }
      return user;
    });

    return state.set("users", nextUsers).set("chosenUser", nextChosenUser);
  }

  if (action.type === "HUMAN_RESOURCE_ADDED") {
    const users = state.get("users");
    const newUser = action.data;
    return state
      .set("users", [].concat(...users, newUser))
      .set("chosenUser", newUser);
  }

  if (action.type === "HUMAN_RESOURCE_UPDATED") {
    const users = state.get("users");
    const userId = action.userId.toString();

    const userIds = users.map(u => u.id);
    const index = userIds.indexOf(userId);

    const user = action.data;

    const newUsers = [...users];
    newUsers[index] = user;

    return state.set("users", newUsers).set("chosenUser", user);
  }

  if (action.type === "HUMAN_RESOURCE_DELETED") {
    const users = state.get("users");
    const userId = action.userId.toString();
    const otherUsers = users.filter(user => user.id !== userId);
    return state.set("users", [].concat(...otherUsers)).set("chosenUser", null);
  }

  if (action.type === "SET_NEW_USER") {
    return state.set("chosenUser", null);
  }

  if (action.type === "HUMAN_RESOURCE_TOKEN_SET") {
    const users = [...state.get("users")];
    const userId = action.userId.toString();
    const user = Object.assign({}, users.filter(u => u.id === userId)[0]);
    user.token = action.data.token;
    user.registeredMobileDevice = action.data.registeredMobileDevice;
    user.deviceToken = action.data.deviceToken;
    const userIndex = users.map(u => u.id).indexOf(userId);
    users[userIndex] = user;
    return state.set("users", users).set("chosenUser", user);
  }

  if (action.type === "EMPLOYEE_HOURS_FOUND") {
    const employeeHours = [...state.get("employeeHours")];
    const data = action.data.map(mapFunc);
    const allEmployeeHours = [...data, ...employeeHours];
    const newEmployeeHours = [...new Set(allEmployeeHours.map(e => e.id))].map(
      id => allEmployeeHours.find(d => d.id === id)
    );

    return state.set("employeeHours", newEmployeeHours);
  }

  if (action.type === "EMPLOYEE_HOURS_SAVED") {
    const employeeHours = [...state.get("employeeHours")];
    action.data.updated.forEach(u => {
      const index = employeeHours.indexOf(u);
      employeeHours[index] = u;
    });

    const newEmployeeHours = [...employeeHours, ...action.data.created].filter(
      e => e.id
    );
    return state.set("employeeHours", newEmployeeHours);
  }

  if (action.type === "UPDATE_ENTRY") {
    if (action.data && action.data.file) {
      action.data.file += "/x";
    }
    const employeeHours = [...state.get("employeeHours")];
    const existingEntries = employeeHours.filter(
      e =>
        e.date === action.data.date &&
        e.human_resource_id === action.data.human_resource_id
    );
    existingEntries.forEach(existingEntry => {
      const index = employeeHours.indexOf(existingEntry);
      employeeHours[index] = { ...action.data, id: existingEntry.id };
    });

    if (existingEntries.length === 0) {
      employeeHours.push(action.data);
    }

    return state.set("employeeHours", employeeHours);
  }

  return state;
};

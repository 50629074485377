import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Importer extends Component {
  constructor() {
    super();
    this.state = {
      rawData1: null,
      rawData2: null,
      error: null,
      notifiedFilesReady: false
    };

    this.supported = false;
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      this.supported = true;
    }

    this.handleFileChange = this.handleFileChange.bind(this);
    this.importData = this.importData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.doImport && this.props.doImport) {
      this.importData();
    }
    if (
      this.state.rawData1 &&
      this.state.rawData2 &&
      !this.state.notifiedFilesReady
    ) {
      this.props.onStateChange('FILES_READY');
      this.setState({ notifiedFilesReady: true });
    }
  }

  handleFileChange(e) {
    const { onStateChange } = this.props;

    const { name, files } = e.target;
    if (files.length === 1) {
      const file = files[0];
      const ending = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();
      if (ending.match('csv')) {
        const reader = new FileReader();

        reader.onload = () => {
          const data = reader.result;
          const lines = data.split(/\r\n|\n/);

          if (name === 'file1') {
            this.setState({ rawData1: lines });
          } else {
            this.setState({ rawData2: lines });
          }
        };

        // reader.readAsText(file, 'ISO-8859-1');
        reader.readAsText(file, 'UTF-8');

        this.setState({ error: null });
      } else {
        this.setState({ error: 'Keine .csv Datei' });
        onStateChange('NO_FILES');
      }
    }
  }

  importData() {
    const { onStateChange, onDataImported } = this.props;
    const { rawData1, rawData2 } = this.state;
    const dataContacts = [];
    const dataCustomers = [];

    for (let i = 1; i < rawData1.length; i += 1) {
      const values = rawData1[i].split(';');
      if(values[0] !== '') {
        const contact = Importer.readContact3(values);
        if (contact.firstname !== '' || contact.lastname !== '' || contact.email !== '')
          dataContacts.push(contact);
      }

      // if (values.length === 18)
      //   dataContacts.push(Importer.readContact2(values));
    }

    for (let i = 1; i < rawData2.length; i += 1) {
      const values = rawData2[i].split(';');
      dataCustomers.push(Importer.readCustomer2(values));
    }

    // const contactsSeparated = Importer.separateContacts(dataContacts);
    // const data = Importer.combineData2(contactsSeparated);

    // console.log("Import stats:");
    // console.log("Company count: " + dataCustomers.length);
    // console.log("Contact count count: " + dataContacts.length);
    const data = Importer.combineData3(dataContacts, dataCustomers);
    // const ccc = data.companiesWithContacts.reduce((acc, val) => (acc + val.contacts.length), 0);
    // console.log("Combined customer count: " + data.companiesWithContacts.length);
    // console.log("Lonely contacts: " + data.lonelyContacts.length);
    // console.log("Contact count in customers: " + ccc);

    // const data = Importer.combineData(dataContacts, dataCustomers);
    // const data = Importer.readData(dataContacts);

    // console.log(data);
    onDataImported(data);
    onStateChange('DATA_READY');
  }

  static readContact(values) {
    let phone = values[8];
    if (phone) {
      if (phone.length < 3) phone = '';
      phone = phone.replace(/\s/g, '');
      if (phone.length > 15) phone.slice(0, 15);
    }

    return {
      reference: values[2],
      salutation: values[3],
      firstname: values[5],
      lastname: values[4],
      occupation: values[6],
      phone,
      email: values[9]
    };
  }

  static readContact2(values) {
    const name = values[7].trim();
    let firstname = '';
    let lastname = '';
    let names = [];

    if (name.includes(',')) {
      names = name.split(',');
      const first = names[0];
      names = names.slice(1);
      names.push(first);
    } else {
      names = name.split(' ');
    }

    if (names.length === 1) {
      lastname = names[names.length - 1];
    } else if (names.length > 1) {
      lastname = names.pop();
      firstname = names.join(' ').trim();
    }

    let salutation = '';
    if (firstname === 'Herr') {
      salutation = 'Herr';
      firstname = '';
    } else if (firstname === 'Frau') {
      salutation = 'Frau';
      firstname = '';
    }

    if(firstname.startsWith('Herr')) {
      salutation = 'Herr';
      firstname = firstname.substr(5);
    } else if(firstname.startsWith('Frau')) {
      salutation = 'Frau';
      firstname = firstname.substr(5);
    }

    let phone1 = values[5];
    if(phone1.endsWith('…')) {
      phone1 = phone1.substr(0, phone1.length - 1).trim();
    }

    const data = {
      companyName1: values[0],
      companyName2: values[1],
      companyAddress: values[2],
      companyZipcode: values[3],
      companyCity: values[4],
      companyPhone1: phone1,
      companyPhone2: values[6],

      salutation,
      firstname,
      lastname,
      email: values[8],
      phone: values[9],
      occupation: values[10]
    };

    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'string') data[key] = data[key].trim();
    });

    return data;
  }

  static readContact3(values) {
    const name = values[3].trim();
    let firstname = '';
    let lastname = '';
    let names = [];

    if (name.includes(',')) {
      names = name.split(',');
      const first = names[0];
      names = names.slice(1);
      names.push(first);
    } else {
      names = name.split(' ');
    }

    if (names.length === 1) {
      lastname = names[names.length - 1];
    } else if (names.length > 1) {
      lastname = names.pop();
      firstname = names.join(' ').trim();
    }

    let salutation = '';
    if (firstname === 'Herr') {
      salutation = 'Herr';
      firstname = '';
    } else if (firstname === 'Frau') {
      salutation = 'Frau';
      firstname = '';
    }

    if(firstname.startsWith('Herr')) {
      salutation = 'Herr';
      firstname = firstname.substr(5);
    } else if(firstname.startsWith('Frau')) {
      salutation = 'Frau';
      firstname = firstname.substr(5);
    }

    if(firstname.startsWith('Hr.')) {
      salutation = 'Herr';
      firstname = firstname.substr(3).trim();
    } else if(lastname.startsWith('Fr.')) {
      salutation = 'Frau';
      firstname = firstname.substr(3).trim();
    }

    if(lastname.startsWith('Hr.')) {
      salutation = 'Herr';
      lastname = lastname.substr(3).trim();
    } else if(lastname.startsWith('Fr.')) {
      salutation = 'Frau';
      lastname = lastname.substr(3).trim();
    }

    let occupation = values[6].trim();
    if(occupation === '')
      occupation = values[7];

    const data = {
      reference: values[0],
      salutation,
      firstname,
      lastname,
      email: values[4],
      phone: values[1] + '-' + values[2],
      mobile: values[5],
      occupation
    };

    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'string') data[key] = data[key].trim();
    });

    return data;
  }

  static readCustomer(values) {
    let phone = values[11];
    if (phone) {
      if (phone.length < 3) phone = '';
      phone = phone.replace(/\s/g, '');
      if (phone.length > 15) phone.slice(0, 15);
    }

    let fax = values[14];
    if (fax) {
      if (fax.length < 3) fax = '';
      fax = fax.replace(/\s/g, '');
      if (fax.length > 15) fax.slice(0, 15);
    }

    return {
      reference: values[0],
      name: `${values[3]} ${values[4]} ${values[5]}`,
      address: values[6],
      zipcode: values[7],
      city: values[8],
      phone,
      fax,
      email: '',
      contacts: []
    };
  }

  static readCustomer2(values) {
    const data = {
      name: values[0],
      address: values[1],
      zipcode: values[2],
      city: values[3],
      phone: values[4] + ' ' + values[5],
      fax: '',
      email: values[6],
      contacts: []
    };

    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'string') data[key] = data[key].trim();
    });

    return data;
  }

  static separateContacts(contacts) {
    const data = [];

    contacts.forEach(elem => {
      if (elem.companyName1 === '') {
        if (
          elem.companyName2 !== '' ||
          elem.firstname !== '' ||
          elem.lastname !== ''
        ) {
          if (elem.companyName2 !== '' && elem.lastname !== '') {
            data.push({
              companyName1: elem.companyName1,
              companyName2: elem.companyName2,
              companyAddress: elem.companyAddress,
              companyZipcode: elem.companyZipcode,
              companyCity: elem.companyCity,
              companyPhone1: elem.companyPhone1,
              companyPhone2: elem.companyPhone2,

              salutation: '',
              firstname: '',
              lastname: '',
              email: '',
              phone: '',
              occupation: ''
            });
            data.push({
              companyName1: '',
              companyName2: '',
              companyAddress: '',
              companyZipcode: '',
              companyCity: '',
              companyPhone1: elem.companyPhone1,
              companyPhone2: elem.companyPhone2,

              salutation: elem.salutation,
              firstname: elem.firstname,
              lastname: elem.lastname,
              email: elem.email,
              phone: elem.phone,
              occupation: elem.occupation
            });
          } else {
            data.push(elem);
          }
        }
      }
    });

    return data;
  }

  static combineData(contacts, customers) {
    const data = customers;
    const lonelyContacts = [];
    contacts.forEach(contact => {
      const ref = contact.reference;
      const customer = customers.find(elem => elem.reference === ref);
      if (customer) {
        customer.contacts.push(contact);
      } else {
        // lonelyContacts.push(contact);
      }
    });

    return { companiesWithContacts: data, lonelyContacts };
  }

  static combineData2(contacts) {
    const data = [];
    let currentCompany = null;

    contacts.forEach(contact => {
      if(contact.companyName2 !== '') {
        currentCompany = {
          name: contact.companyName2,
          address: contact.companyAddress,
          zipcode: contact.companyZipcode,
          city: contact.companyCity,
          phone: contact.companyPhone1 + ' - ' + contact.companyPhone2,
          fax: '',
          email: '',
          contacts: []
        };
        data.push(currentCompany);
      } else if (currentCompany) {
        currentCompany.contacts.push({
          ...contact,
          mobile: contact.phone,
          phone: contact.companyPhone1 + ' - ' + contact.companyPhone2
        });
      }
    });

    return { companiesWithContacts: data, lonelyContacts: [] };
  }

  static combineData3(contacts, customers) {
    const data = customers;
    const lonelyContacts = [];
    contacts.forEach(contact => {
      const ref = contact.reference;
      const customer = customers.find(elem => elem.name === ref);
      if (customer) {
        customer.contacts.push(contact);
      } else {
        lonelyContacts.push(contact);
      }
    });

    return { companiesWithContacts: data, lonelyContacts };
  }

  render() {
    const { error } = this.state;

    if (this.supported) {
      return (
        <Fragment>
          <div className="form-group">
            <label>Kontakte</label>
            <input
              className="form-control"
              type="file"
              id="file2"
              name="file1"
              onChange={this.handleFileChange}
            />
          </div>
          <div className="form-group">
            <label>Firmen</label>
            <input
              className="form-control"
              type="file"
              id="file2"
              name="file2"
              onChange={this.handleFileChange}
            />
          </div>
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              <strong>Fehler</strong> {error}
            </div>
          )}
        </Fragment>
      );
    }
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Import nicht möglich:</strong>
        &nbsp;Der Browser unterstützt keine Dateiuploads.
      </div>
    );
  }
}

Importer.propTypes = {
  dispatch: PropTypes.func,
  doImport: PropTypes.bool,
  onStateChange: PropTypes.func,
  onDataImported: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch
}))(Importer);

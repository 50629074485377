import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import "./print.css";

// TODO: SET ORDERS LOADING WITH THE DATE CHOSEN / CACHED FROM APP STORAGE.

import {
  monthToString,
  dateToMonthRange,
  endOfDay,
  monthStringToMonth,
  toDateString
} from "../../util/dateHelper";

import List from "./list";
import Order from "./order";
import OrderPrint from "./OrderPrint";
import LoadingIndicator from "../LoadingIndicator";
import MachineDisposition from "../MachineDisposition";

import getOrder from "../../actions/getOrder";
import getOrdersOverviewInRange from "../../actions/getOrdersOverviewInRange";
import getUsers from "../../actions/getUsers";
import getResources from "../../actions/getResources";
import getCostCenters from "../../actions/getCostCenters";
import getSubcontractors from "../../actions/getSubcontractors";
import getCombinations from "../../actions/getCombinations";

const checkDate = (dateType, dateString, cb) => {
  switch (dateType) {
    case "DAY": {
      const d = new Date(dateString);
      // An invalid date object returns NaN for getTime() and NaN is the only object not strictly equal to itself.
      // eslint-disable-next-line no-self-compare
      if (d.getTime() === d.getTime()) {
        cb();
      }
      break;
    }

    case "WEEK": {
      const dateStringSplit = dateString.split(" ");
      if (dateStringSplit.length > 1) {
        if (
          dateStringSplit[0] === "KW" &&
          !isNaN(parseInt(dateStringSplit[1], 10))
        ) {
          cb();
        }
      }
      break;
    }

    case "MONTH": {
      const dateStringSplit = dateString.split(" ");
      if (dateStringSplit.length > 1) {
        const month = monthStringToMonth(dateStringSplit[0]);
        if (month > 0 && !isNaN(parseInt(dateStringSplit[1], 10))) {
          cb();
        }
      }
      break;
    }

    default:
      break;
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Abrufe";

    const { client, dispatch, location, chosenOrder } = props;

    // get cached last selected date set by the user
    const dateFilterValues = JSON.parse(
      window.localStorage.getItem("orderDateFilterValues")
    );

    const dateRange = dateToMonthRange(new Date());

    this.state = {
      view: "LIST_DETAIL",
      collapseList: window.innerWidth < 2500,
      date: dateRange,
      dateType: "MONTH",
      dateString: `${monthToString(
        dateRange[0].getMonth()
      )} ${dateRange[1].getFullYear()}`
    };

    if (dateFilterValues) {
      const { date, dateString, dateType } = dateFilterValues;

      // the async calls to setState produces a reload bug that makes the date  that was retrieved from local storage
      // not to be taken, but the one that is actual.
      // use sync call "this.state = something" instead.
      if (date) {
        if (typeof date === "object") {
          this.state.date = [new Date(date[0]), new Date(date[1])];
        } else {
          const dateObj = new Date(date);
          this.state.date = dateObj;
          this.state.dateType = "DAY";
          this.state.dateString = toDateString(dateObj);
        }
      }

      const setDateData = () => {
        this.state.dateType = dateType;
        this.state.dateString = dateString;
      };
      checkDate(dateType, dateString, setDateData);
    }

    const path = location.pathname.split("/");
    const id = path[2];

    this.print = path.length > 3 && path[3] === "print";

    if (id !== undefined && id !== "") {
      dispatch(getOrder(client, id));
    } else if (!chosenOrder) {
      this.state.view = "LIST";
    }

    // if (!ordersLoaded) {
    //   dispatch(
    //     getOrdersOverviewInRange(
    //       client,
    //       dateRange[0],
    //       dateRange[1],
    //       this.state.view === 'LIST'
    //     )
    //   );
    // }

    this.loadOrders();

    dispatch(getUsers(client));
    dispatch(getCombinations(client));
    dispatch(getResources(client));
    dispatch(getCostCenters(client));
    dispatch(getSubcontractors(client));

    this.loadOrders = this.loadOrders.bind(this);

    this.handleResize = this.handleResize.bind(this);

    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    const { date, dateType, dateString } = this.state;

    window.removeEventListener("resize", this.handleResize);

    window.localStorage.setItem(
      "orderDateFilterValues",
      JSON.stringify({ date, dateType, dateString })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.chosenOrder && this.props.chosenOrder) {
      const date = new Date(this.props.chosenOrder.start_meeting_time);
      const dateRange = dateToMonthRange(date);
      this.setState(
        {
          date: dateRange,
          dateString: `${monthToString(
            dateRange[0].getMonth()
          )} ${dateRange[1].getFullYear()}`
        },
        () => this.loadOrders(true)
      );
    }
  }

  loadOrders(force = false) {
    const { client, dispatch, ordersLoaded } = this.props;
    const { view, date } = this.state;

    let from = null;
    let to = null;
    if (date.length) {
      // eslint-disable-next-line prefer-destructuring
      from = date[0];
      // eslint-disable-next-line prefer-destructuring
      to = date[1];
    } else {
      from = date;
      to = date;
    }

    if (!from || !to) return;

    to = endOfDay(to);

    if (view === "LIST") {
      dispatch(getOrdersOverviewInRange(client, from, to, true));
    } else if (!ordersLoaded || force) {
      dispatch(getOrdersOverviewInRange(client, from, to));
    }
  }

  handleResize() {
    this.setState({ collapseList: window.innerWidth < 2500 });
  }

  handleViewChange(e) {
    const { view } = this.state;
    let value = "";
    if (typeof e === "string") value = e;
    else value = e.currentTarget.dataset.view;
    if (value !== "" && view !== value) {
      this.setState({ view: value }, () => this.loadOrders());
    }
  }

  handleDateChange(date, dateType, dateString) {
    this.setState({ date, dateType, dateString }, () => this.loadOrders(true));

    //user sets a new date, cache it into local storage.
    window.localStorage.setItem(
      "orderDateFilterValues",
      JSON.stringify({ date, dateType, dateString })
    );
  }

  render() {
    const {
      history,
      permissions,
      loginName,
      chosenOrder,
      orders,
      newOrder,
      orderLoading,
      ordersLoading,
      costCenters
    } = this.props;
    const { view, collapseList, date, dateType, dateString } = this.state;
    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "order" && p.write)) ||
      loginName === "klout";

    const checkPermission =
      Boolean(permissions.find(p => p.name === "order_check" && p.write)) ||
      loginName === "klout";

    const publishPermission =
      Boolean(permissions.find(p => p.name === "order_publish" && p.write)) ||
      loginName === "klout";

    const disposalPermission = permissions.find(p => p.name === "disposal");
    const hasDisposalReadPermission =
      (disposalPermission && disposalPermission.read) || loginName === "klout";
    const hasDisposalWritePermission =
      (disposalPermission && disposalPermission.write) || loginName === "klout";

    const chosenOrderId = chosenOrder ? chosenOrder.id : "-1";

    const showList = view === "DETAIL_DISPO" ? !collapseList : true;

    let listCol = "col-6";
    if (view === "LIST") listCol = "col-12";
    else if (view === "DETAIL_DISPO") listCol = "col-4";

    let orderCol = "col-6";
    let footerClassName = "order-fixed-bottom";
    if (view === "DETAIL_DISPO") {
      if (collapseList) {
        footerClassName = "order-fixed-bottom force-left";
      } else {
        orderCol = "col-3";
        footerClassName = "order-fixed-bottom force-middle";
      }
    }

    const start = chosenOrder
      ? moment(chosenOrder.operational_period_start).startOf("day")
      : null;
    const end = chosenOrder
      ? moment(chosenOrder.operational_period_finish).endOf("day")
      : null;

    if (start) {
      start.subtract(1, "days");
    }

    if (end) {
      end.add(1, "days");
    }

    return (
      <Fragment>
        <div className="container-fluid position-relative">
          <div className="row">
            {this.print ? (
              chosenOrder ? (
                <OrderPrint order={chosenOrder} />
              ) : null
            ) : (
              <Fragment>
                {showList ? (
                  <List
                    className={`${listCol} padding-20`}
                    chosenOrderId={chosenOrderId}
                    orders={orders}
                    loading={ordersLoading}
                    write={hasWritePermission}
                    expanded={view === "LIST"}
                    view={view}
                    filterDate={date}
                    filterDateType={dateType}
                    filterDateDisplayValue={dateString}
                    onViewChange={this.handleViewChange}
                    onDateChange={this.handleDateChange}
                  />
                ) : null}
                {view !== "LIST" ? (
                  <div className={`${orderCol} data-panel border-left p-0`}>
                    {chosenOrder || newOrder ? (
                      <Order
                        footerClassName={footerClassName}
                        expanded={view === "DETAIL_DISPO"}
                        order={chosenOrder}
                        orders={orders}
                        costCenters={costCenters}
                        write={hasWritePermission}
                        checkPermission={checkPermission}
                        publishPermission={publishPermission}
                        disposalRead={hasDisposalReadPermission}
                        disposalWrite={hasDisposalWritePermission}
                        onExpand={this.handleViewChange}
                      />
                    ) : (
                      <LoadingIndicator
                        className="mt-5"
                        show={orderLoading}
                        loadingMessage="Lade Abruf..."
                      />
                    )}
                  </div>
                ) : null}
                {view === "DETAIL_DISPO" ? (
                  <div
                    className={`${
                      collapseList ? "col-6" : "col-5"
                    } p-0 border-left`}
                  >
                    <MachineDisposition
                      history={history}
                      disableWrite={true}
                      hideTimeControl={true}
                      overrideStart={start}
                      overrideEnd={end}
                    />
                  </div>
                ) : null}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  orders: PropTypes.array,
  chosenOrder: PropTypes.object,
  newOrder: PropTypes.bool,
  orderLoading: PropTypes.bool,
  ordersLoading: PropTypes.bool,
  ordersLoaded: PropTypes.bool,
  costCenters: PropTypes.array,
  client: PropTypes.object,
  location: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  orders: state.orders.get("orders"),
  chosenOrder: state.orders.get("chosenOrder"),
  newOrder: state.orders.get("newOrder"),
  orderLoading: state.orders.get("orderLoading"),
  ordersLoading: state.orders.get("ordersLoading"),
  ordersLoaded: state.orders.get("ordersLoaded"),
  costCenters: state.costCenters.get("costCenters"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);

import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Cogs from '../../LoadingIndicator/cogs';

export default function LoadingOverlay(props) {
  return (
    <CSSTransition
      in={props.show}
      appear={true}
      timeout={300}
      classNames="fade-opacity">
      <div className={`gantt-loading-overlay${props.show ? '' : ' hidden'}`}>
        <Cogs />
      </div>
    </CSSTransition>
  );
}

LoadingOverlay.propTypes = {
  show: PropTypes.bool
};

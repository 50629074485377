import React, { Component, Fragment } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { sha512 } from 'js-sha512';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import QRCode from 'qrcode.react';

import updateUsernameAndPassword from '../../actions/updateUsernameAndPassword';
import generateToken from '../../actions/generateToken';
import removeToken from '../../actions/removeToken';

class UsernamePassword extends Component {
  constructor(props) {
    super(props);
    this.initState = {
      username: props.user.username ? props.user.username : '',
      password: '',
      repassword: '',
      passwordError: false,
      showAccess: false,
      locked: !props.write
    };
    this.state = this.initState;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        username: nextProps.user.username,
        password: nextProps.user.password,
        repassword: nextProps.user.repassword
      });
    } else {
      this.setState(this.initState);
    }
  }

  changeShowAccess() {
    this.setState({
      showAccess: !this.state.showAccess
    });
  }

  changeUserName(e) {
    this.setState({
      username: e.target.value
    });
  }

  changePassword(e) {
    const { value } = e.target;
    const { repassword } = this.state;
    this.setState({
      password: value,
      passwordError: !UsernamePassword.checkPassword(value, repassword) && repassword !== ''
    });
  }

  changeRePassword(e) {
    const { value } = e.target;
    const { password } = this.state;
    this.setState({
      repassword: value,
      passwordError: !UsernamePassword.checkPassword(password, value) && value !== ''
    });
  }

  static checkPassword(password, repassword) {
    return password === repassword;
  }

  generateToken() {
    const { dispatch, client, user } = this.props;
    dispatch(generateToken(client, user.id));
  }

  removeToken() {
    const { dispatch, client, user } = this.props;
    dispatch(removeToken(client, user.id));
  }

  savePassword() {
    const { client, user, salt } = this.props;
    const { username, password, repassword } = this.state;

    if (UsernamePassword.checkPassword(password, repassword)) {
      const passwordHash = sha512(salt + password);
      this.props.dispatch(
        updateUsernameAndPassword(client, user.id, username, passwordHash)
      );
      this.changeShowAccess();
    }
  }

  render() {
    const {
      username,
      password,
      repassword,
      passwordError,
      locked,
      showAccess
    } = this.state;
    let disabled = true;
    if (
      repassword !== '' &&
      username !== '' &&
      password !== '' &&
      password === repassword
    ) {
      disabled = false;
    }
    const { user } = this.props;
    const { registeredMobileDevice } = user;
    const token = user.token || '';
    const deviceToken = user.deviceToken || '';

    return (
      <div className="row border-top border-bottom pt-3 pb-3">
        <div className="col-md-12">
          <div className="col-md-2 p-0">
            <button
              className="btn btn-outline-primary btn-sm w-100"
              onClick={this.changeShowAccess.bind(this)}>
              Zugriff erlauben
            </button>
          </div>
        </div>
        {showAccess ? (
          <div className="col-md-12 mt-3">
            <div className="row">
              <h5 className="col-md-12 pb-2">Desktop Anmeldung</h5>

              <div className="form-group col-md-2">
                <label>Nutzername</label>
                <input
                  className="form-control"
                  type="text"
                  value={username}
                  disabled={locked}
                  onChange={this.changeUserName.bind(this)}
                />
              </div>
              <div className="form-group col-md-4">
                <label>Passwort</label>
                <input
                  className="form-control"
                  type="password"
                  disabled={locked}
                  onChange={this.changePassword.bind(this)}
                />
              </div>
              <div className="form-group col-md-4">
                <label>Passwort wiederholen</label>
                <input
                  className={`form-control ${
                    passwordError ? 'is-invalid' : ''
                  }`}
                  type="password"
                  disabled={locked}
                  onChange={this.changeRePassword.bind(this)}
                />
                {passwordError ? (
                  <small className="form-text text-danger">
                    Wiederholung ist falsch
                  </small>
                ) : null}
              </div>
              <div className="form-group col-md-2">
                <label>&nbsp;</label>
                <button
                  className="form-control btn btn-primary float-right"
                  onClick={this.savePassword.bind(this)}
                  disabled={disabled}>
                  Speichern
                </button>
              </div>

              <div className="col-12 border-bottom mb-3" />

              <h5 className="col-md-12 pb-2">Mobile Zugriff</h5>
              <div className="form-group col-md-4">
                <div
                  className="btn btn-primary w-100"
                  onClick={this.generateToken.bind(this)}>
                  Token generieren
                </div>
              </div>
              <div className="form-group input-group col-md-6">
                <input
                  className="form-control"
                  type="text"
                  aria-describedby="basic-addon"
                  disabled={true}
                  value={token}
                />
                <div
                  className="input-group-append"
                  onClick={this.removeToken.bind(this)}>
                  <span className="input-group-text" id="basic-addon">
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </div>
              </div>
              <div className="form-group col-md-2 pt-2">
                <div className="custom-control custom-checkbox">
                  <label
                    className={
                      registeredMobileDevice
                        ? 'custom-control-label checked'
                        : 'custom-control-label'
                    }
                    htmlFor="customCheck">
                    Aktiviert
                  </label>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck"
                    disabled={true}
                  />
                </div>
              </div>
              {token !== '' && (
                <Fragment>
                  <div className="col-4" />
                  <div className="col-8">
                    <QRCode value={token} size={256} />
                  </div>
                </Fragment>
              )}

              {user.deviceToken ? (
                <Fragment>
                  <div className="col-md-4" />
                  <div className="form-group col-md-6">
                    <label>Geräteidentifikator</label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={true}
                      value={deviceToken}
                    />
                  </div>
                </Fragment>
              ) : null}
            </div>
            {this.props.write ? <div className={'row'} /> : null}
          </div>
        ) : null}
      </div>
    );
  }
}

UsernamePassword.propTypes = {
  dispatch: Proptypes.func,
  client: Proptypes.object,
  user: Proptypes.object,
  salt: Proptypes.string,
  write: Proptypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client'),
  salt: state.main.get('salt')
}))(UsernamePassword);

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

import moment from "moment";
import TitleBar from "../TitleBar";
import Button from "../UnlockButton";

import createVehicle from "../../actions/createVehicle";
import updateVehicle from "../../actions/updateVehicle";
import deleteVehicle from "../../actions/deleteVehicle";

class Data extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      brand: null,
      number: "",
      type: null,
      licensePlate: "",
      category: "PKW",
      comment: "",
      establishment: props.establishment,
      humanResources: [],
      implementation: "",
      locked: !props.write,
      errors: {},
      humanResource: null,
      status: "Aktiv",
      leavingDate: null
    };
    this.state = this.initState;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
    this.handleHumanResourceChange = this.handleHumanResourceChange.bind(this);

    this.addHumanResource = this.addHumanResource.bind(this);
    this.removeHumanResource = this.removeHumanResource.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vehicle) {
      let { establishment } = nextProps.vehicle;
      if (establishment)
        establishment = { label: establishment, value: establishment };

      this.setState({
        brand: nextProps.vehicle.brand
          ? { label: nextProps.vehicle.brand, value: nextProps.vehicle.brand }
          : null,
        type: nextProps.vehicle.type
          ? { label: nextProps.vehicle.type, value: nextProps.vehicle.type }
          : null,
        licensePlate: nextProps.vehicle.licensePlate,
        category: nextProps.vehicle.category,
        comment: nextProps.vehicle.comment,
        establishment,
        humanResources: nextProps.vehicle.humanResources,
        implementation: nextProps.vehicle.implementation,
        locked: true,
        number: nextProps.vehicle.number || "",
        errors: {},
        status: nextProps.vehicle.status,
        leavingDate: nextProps.vehicle.leavingDate
          ? moment(nextProps.vehicle.leavingDate).format("YYYY-MM-DD")
          : null
      });
      // this.brandSelectForm.setInput(nextProps.vehicle.brand);
      // this.typeSelectForm.setInput(nextProps.vehicle.type);
    } else if (this.props.vehicle) {
      // this.brandSelectForm.clearInput();
      // this.typeSelectForm.clearInput();
      this.setState(this.initState);
    } else {
      this.setState(this.initState);
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleBrandChange(value) {
    this.setState({ brand: value });
  }

  handleTypeChange(value) {
    this.setState({ type: value });
  }

  handleEstablishmentChange(value) {
    this.setState({
      establishment: value
    });
  }

  handleHumanResourceChange(value) {
    this.setState({
      humanResource: value
    });
  }

  addHumanResource() {
    const { humanResource, humanResources } = this.state;

    if (humanResource) {
      this.setState({
        humanResources: humanResources.concat(humanResource),
        humanResource: null
      });
    }
  }

  removeHumanResource(e) {
    const { humanResources } = this.state;

    const { id } = e.currentTarget.dataset;

    this.setState({
      humanResources: humanResources.filter(h => h.id !== id)
    });
  }

  unlock() {
    this.setState({
      locked: false
    });
  }

  lock() {
    this.setState({
      locked: true
    });
  }

  onSave() {
    const { client, vehicle, vehicles } = this.props;
    const {
      licensePlate,
      image,
      category,
      comment,
      establishment,
      humanResources,
      implementation,
      number,
      status,
      leavingDate
    } = this.state;
    let { brand, type } = this.state;

    if (!vehicle || vehicle.number !== number) {
      if (vehicles.find(r => r.number === number)) {
        this.setState({ errors: { number: true } });
        return;
      }
    }

    brand = brand ? brand.label.trim() : "";
    type = type ? type.label.trim() : "";

    if (vehicle) {
      this.props.dispatch(
        updateVehicle(
          client,
          vehicle,
          brand,
          type,
          licensePlate,
          image,
          category,
          comment,
          establishment ? establishment.value : null,
          humanResources,
          implementation,
          number,
          status,
          leavingDate
        )
      );
    } else {
      this.props.dispatch(
        createVehicle(
          client,
          brand,
          type,
          licensePlate,
          image,
          category,
          comment,
          establishment ? establishment.value : null,
          humanResources,
          implementation,
          number,
          status,
          leavingDate
        )
      );
    }
    this.lock();
  }

  // TODO: Prevent delete when Vehicle is used somewhere.
  onDelete() {
    const { client, dispatch, vehicle } = this.props;
    dispatch(deleteVehicle(client, vehicle.id));
    this.lock();
  }

  render() {
    const {
      className,
      establishments,
      users,
      vehicle,
      write,
      brands,
      types
    } = this.props;
    const {
      brand,
      type,
      licensePlate,
      category,
      comment,
      establishment,
      humanResources,
      number,
      locked,
      errors,
      humanResource,
      status,
      leavingDate
    } = this.state;

    const possibleUsers = users.filter(
      u =>
        u.status === "Aktiv" &&
        humanResources.map(h => h.id).indexOf(u.id) === -1
    );

    return (
      <div className={className}>
        <TitleBar title="Stammdaten" />

        <div className="row">
          <div className="form-group col-sm-3">
            <label className="form-label small">Hersteller (z.B. VW)</label>
            <CreatableSelect
              isClearable={true}
              isDisabled={locked}
              placeholder=""
              noOptionsMessage={() => "Eingeben..."}
              formatCreateLabel={inputValue => inputValue}
              value={brand}
              options={brands.filter(elem => Boolean(elem.label))}
              onChange={this.handleBrandChange}
            />
          </div>

          <div className="form-group col-sm-3">
            <label className="form-label small">Typ (z.B. Passat)</label>
            <CreatableSelect
              isClearable={true}
              isDisabled={locked}
              placeholder=""
              noOptionsMessage={() => "Eingeben..."}
              formatCreateLabel={inputValue => inputValue}
              value={type}
              options={types.filter(elem => Boolean(elem.label))}
              onChange={this.handleTypeChange}
            />
          </div>

          <div className="form-group col-sm-3">
            <label className="form-label small">Nummernschild</label>
            <input
              type="text"
              className="form-control"
              name="licensePlate"
              value={licensePlate}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group col-sm-3">
            <label className="form-label small">Inventarnummer</label>
            <input
              type="text"
              className={`form-control ${errors.number ? "is-invalid" : ""}`}
              name="number"
              value={number}
              disabled={locked}
              onChange={this.handleInputChange}
            />
            {errors.number ? (
              <small className="form-text text-danger">
                Inventarnummer bereits vergeben.
              </small>
            ) : null}
          </div>

          <div className="form-group col-sm-3">
            <label className="form-label small">Niederlassung</label>
            <Select
              isDisabled={locked}
              isSearchable={false}
              placeholder=""
              value={establishment}
              options={establishments}
              onChange={this.handleEstablishmentChange}
            />
          </div>

          <div className="form-group col-md-3">
            <label className="form-label small">Kategorie</label>
            <select
              className="form-control form-control"
              name="category"
              value={category}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              <option>PKW</option>
              <option>LKW</option>
              <option>Anhänger</option>
              <option>Zugmaschine</option>
              <option>Tieflader</option>
              <option>Wasserwagen</option>
              <option>Werkstattauto</option>
            </select>
          </div>

          <div className="form-group col-sm-6">
            <label className="form-label small">Bemerkung</label>
            <input
              type="text"
              className="form-control"
              name="comment"
              value={comment}
              disabled={locked}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label small">Status</label>
            <select
              className="form-control"
              name="status"
              value={status}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              {["Aktiv", "Inaktiv"].map(stateOption => (
                <option key={stateOption}>{stateOption}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-4">
            {status === "Inaktiv" ? (
              <Fragment>
                <label className="form-label small">Austrittsdatum</label>
                <input
                  type="date"
                  className="form-control"
                  name="leavingDate"
                  value={leavingDate}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </Fragment>
            ) : null}
          </div>

          <div className="form-group col-sm-8">
            <label className="form-label small">Fahrer</label>

            <div className="row mb-3">
              {humanResources.map(hr => (
                <Fragment key={hr.id}>
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={`${hr.firstname} ${hr.lastname}`}
                    />
                  </div>

                  <div className="col-6 mb-3">
                    {!locked ? (
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-id={hr.id}
                        onClick={this.removeHumanResource}
                      >
                        <FontAwesomeIcon className="fa-trash" icon={faTimes} />
                      </button>
                    ) : null}
                  </div>
                </Fragment>
              ))}
            </div>
            {!locked && possibleUsers.length > 0 ? (
              <div className="row">
                <div className="col-sm-6">
                  <Select
                    isDisabled={locked}
                    placeholder="Mitarbeiter wählen..."
                    noOptionsMessage={() => "Keine Übereinstimmung"}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.firstname} ${o.lastname}`}
                    value={humanResource}
                    options={possibleUsers}
                    onChange={this.handleHumanResourceChange}
                  />
                </div>

                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    disabled={locked || !humanResource}
                    onClick={this.addHumanResource}
                  >
                    Mitarbeiter hinzufügen
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave}
              onDelete={this.onDelete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={Boolean(vehicle)}
              locked={locked}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  vehicle: PropTypes.object,
  vehicles: PropTypes.array,
  users: PropTypes.array,
  brands: PropTypes.array,
  types: PropTypes.array,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishments: state.main.get("establishments"),
  users: state.humanResources.get("users"),
  brands: state.vehicles.get("brands"),
  types: state.vehicles.get("types")
}))(Data);

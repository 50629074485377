import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getWorkingDays } from '../../../util/dateHelper';
import { groupBy } from '../../../util/groupBy';

function OverviewItem(props) {
  return (
    <div className="text-center p-2">
      <div className="h3">{props.days ? props.days : '-'}</div>
      <div className="">{props.type}</div>
    </div>
  );
}

const monthlyVactionPositions = ['Maschinist', 'Umsetzer', 'Schlosser'];

const currentYear = new Date().getFullYear();
const years = [];
for (let i = 2018; i <= currentYear + 1; i += 1) {
  years.push(i);
}
years.sort((a, b) => b - a);

export default class Overview extends Component {
  render() {
    const { year, setYear, vacationDemand, position, overview} = this.props;

    const today = new Date();
    const isThisYear = year === today.getFullYear();

    let vacationUntilMonth = null;
    let restVacation = 0;
    if (vacationDemand && monthlyVactionPositions.indexOf(position) > -1) {
      vacationUntilMonth = (vacationDemand.vacation / 12) * today.getMonth();
      restVacation = vacationUntilMonth;
    }

    return (
      <div className="d-flex justify-content-left">
        <div className="text-center bg-white p-2 border-left border-right border-bottom">
          <select
            className="form-control"
            style={{ width: 100 }}
            value={year}
            onChange={e => setYear(parseInt(e.target.value, 10))}>
            {years.map(y => (
              <option key={y}>{y}</option>
            ))}
          </select>
          <div>Jahr</div>
        </div>

        {isThisYear ? (
          <div className="text-center bg-white p-2 border-right border-bottom">
            <div className="h3">
              {today.toLocaleString('de-DE', { month: 'long' })}
            </div>
            <div className="">Monat</div>
          </div>
        ) : null}

        {overview ? (
          <div
            className={`text-center p-2 border-right border-bottom ${
              restVacation < 0 ? 'alert-danger' : 'bg-white'
            }`}>
            <div className="h3">
              {overview.Urlaub || 0}
            </div>
            <div className="">Urlaub</div>
          </div>
        ) : null}

        <div className="text-center p-2 border-right border-bottom bg-white">
          <div className="h3">
            {overview.Sonderurlaub ? overview.Sonderurlaub : '-'}
          </div>
          <div>Sonderurlaub</div>
        </div>

        {overview ? (
          <div
            className="text-center p-2 border-right border-bottom bg-white">
            <div className="h3">
              {overview.Resturlaub || 0}
            </div>
            <div className="">Resturlaub</div>
          </div>
        ) : null}

        <OverviewItem type="Krank" days={overview.Krank} />
        <OverviewItem type="Schule" days={overview.Schule} />
        <OverviewItem
          type="Betriebliche Freistellung"
          days={overview['Betriebliche Freistellung']}
        />
        <OverviewItem
          type="Abbau Überstunden"
          days={overview['Abbau Überstunden']}
        />
      </div>
    );
  }
}

Overview.propTypes = {
  absences: PropTypes.array,
  year: PropTypes.number,
  setYear: PropTypes.func,
  vacationDemand: PropTypes.object,
  position: PropTypes.string
};

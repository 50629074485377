import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";
import Modal from "../Modal";
import Importer from "./importer";

import setChosenContact from "../../actions/setChosenContact";
import importContacts from "../../actions/importContacts";

class List extends Component {
  constructor() {
    super();
    this.initState = {
      filter: "",
      isSortedBy: null,
      sortedContacts: null,
      sortCount: 0,
      showModal: false,
      importerState: "NO_FILES",
      importData: null,
      doImport: false
    };
    this.state = this.initState;

    this.listMapping = [
      { key: "salutation", label: "Anrede" },
      { key: "__this__", label: "Name" },
      { key: "company", label: "Firma" },
      { key: "position", label: "Position" },
      { key: "mobile", label: "Telefon (Mobil)" },
      { key: "email", label: "Email" }
    ];

    this.dataRenderers = {
      __this__: elem => `${elem.firstname} ${elem.lastname}`,
      company: elem => (elem ? elem.name : ""),
      email: elem => (
        <div className="bg-white rounded px-1">
          <a href={`mailto:${elem}`}>{elem}</a>
        </div>
      )
    };

    this.colRenderer = (elem, className) => (
      <Fragment>
        <div className={className}>{elem.salutation}</div>
        <div className={className}>{`${elem.firstname} ${elem.lastname}`}</div>
        <div className={className}>{elem.company ? elem.company.name : ""}</div>
        <div className={className}>{elem.position}</div>
        <div className={className}>{elem.mobile}</div>
        <div className={className}>
          <div className="bg-white rounded px-1">
            <a href={`mailto:${elem.email}`}>{elem.email}</a>
          </div>
        </div>
      </Fragment>
    );

    this.setNewContact = this.setNewContact.bind(this);
    this.setChosenContact = this.setChosenContact.bind(this);
    this.sortBy = this.sortBy.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitModal = this.submitModal.bind(this);

    this.handleImporterStateChange = this.handleImporterStateChange.bind(this);
    this.setImportData = this.setImportData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contacts && this.state.sortedContacts) {
      if (nextProps.contacts.length !== this.state.sortedContacts.length) {
        this.setState(this.initState);
      }
    }

    if (nextProps.importStatus === "Done: ") {
      this.setState({ importerState: "IMPORT_DONE" });
    }
  }

  setNewContact() {
    this.props.dispatch({
      type: "SET_NEW_CONTACT"
    });
    window.history.pushState({}, null, "/contacts");
  }

  setChosenContact(contact) {
    this.props.dispatch(setChosenContact(contact));
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  sortBy(key) {
    let { sortCount } = this.state;
    if (key !== this.state.isSortedBy) {
      sortCount = 0;
    }

    this.setState({
      isSortedBy: key,
      sortCount: sortCount + 1
    });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({
      showModal: false,
      importerState: "NO_FILE",
      importData: null
    });
  }

  submitModal() {
    const { dispatch, client } = this.props;
    const { importerState } = this.state;

    if (importerState === "FILES_READY") {
      this.setState({ doImport: true });
    } else if (importerState === "DATA_READY") {
      const importData = JSON.parse(this.state.importData);

      if (importData) {
        setTimeout(() => {
          dispatch(
            importContacts(
              client,
              importData.companiesWithContacts,
              importData.lonelyContacts
            )
          );
        }, 100);

        this.setState({ importerState: "DO_IMPORT" });
      }
    } else if (importerState === "IMPORT_DONE") {
      this.closeModal();
    }
  }

  handleImporterStateChange(state) {
    this.setState({ importerState: state });
  }

  setImportData(data) {
    const importData = data;
    const { companies } = this.props;
    const c = importData.companiesWithContacts;

    const entries = [];

    c.forEach(elem => {
      const cc = companies.find(
        company => company.name.trim() === elem.name.trim()
      );
      if (cc) entries.push(cc);
    });

    // console.log(entries);

    const companiesToDelete = entries.map(e => parseInt(e.id, 10));
    // (JSON.stringify(companiesToDelete));

    this.setState({ importData: JSON.stringify(data) });
  }

  static filter(data, filterString) {
    if (filterString === "") return data;
    const filter = filterString.toUpperCase();

    return data.filter(elem => {
      if (elem) {
        const { company } = elem;
        const firstname = elem.firstname ? elem.firstname : "";
        const lastname = elem.lastname ? elem.lastname : "";
        const position = elem.position ? elem.position : "";

        let testString = `${firstname.toUpperCase()} ${lastname.toUpperCase()}_${position.toUpperCase()}`;
        if (company) testString += `_${company.name.toUpperCase()}`;

        return testString.indexOf(filter) > -1;
      }
    });
  }

  render() {
    let { contacts } = this.props;
    const {
      className,
      contact,
      write,
      loginName,
      importStatus,
      importProgress
    } = this.props;
    const {
      filter,
      showModal,
      doImport,
      importerState,
      importData,
      isSortedBy,
      sortCount
    } = this.state;
    if (this.state.sortedContacts) {
      contacts = this.state.sortedContacts;
    }

    contacts = List.filter(contacts, filter);

    const importListMapping = [
      { key: "name", label: "Firmenname" },
      { key: "adress", label: "Straße" },
      { key: "zipcode", label: "PLZ" },
      { key: "city", label: "Ort" },
      { key: "phone", label: "Telefon" },
      { key: "fax", label: "Fax" },
      { key: "email", label: "Email" },
      { key: "contacts", label: "Kontakte" }
    ];

    const dataRenderers = {
      contacts: elem => {
        if (elem.length > 5) {
          const trimmed = elem.splice(0, 5);
          return (
            <ul>
              {trimmed.map((obj, index) => (
                <li key={index}>
                  {obj.salutation} {obj.lastname}
                </li>
              ))}
              <li>...{elem.length} gesamt</li>
            </ul>
          );
        }
        return (
          <ul>
            {elem.map((obj, index) => (
              <li key={index}>
                {obj.salutation} {obj.lastname}
              </li>
            ))}
          </ul>
        );
      }
    };

    const selected = contact ? ["id", contact.id] : null;

    const modalContent = {
      IMPORTER: (
        <Importer
          doImport={doImport}
          onStateChange={this.handleImporterStateChange}
          onDataImported={this.setImportData}
        />
      ),
      DATA_PREVIEW: importData ? (
        <SimpleTable
          mapping={importListMapping}
          viewRange={[0, 200]}
          dataRenderers={dataRenderers}
          data={JSON.parse(importData).companiesWithContacts}
        />
      ) : null,
      PROGRESS: (
        <div className="form-group">
          <label>{importStatus}</label>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ transition: "none", width: `${importProgress * 100}%` }}
            />
          </div>
        </div>
      ),
      NONE: <div>Seite neu laden</div>
    };

    const submitEnabled =
      importerState === "FILES_READY" ||
      importerState === "DATA_READY" ||
      importerState === "IMPORT_DONE";
    let modalContentKey = "NONE";

    switch (importerState) {
      case "NO_FILES":
      case "FILES_READY":
        modalContentKey = "IMPORTER";
        break;
      case "DATA_READY":
        modalContentKey = "DATA_PREVIEW";
        break;
      case "DO_IMPORT":
      case "IMPORT_DONE":
        modalContentKey = "PROGRESS";
        break;
      default:
        break;
    }

    const title = (
      <Fragment>
        <span className="h4 mr-3">Kontakte</span>
        <a className="h4" href="/companies">
          Firmen
        </a>
      </Fragment>
    );

    return (
      <Fragment>
        <Modal
          show={showModal}
          submitEnabled={submitEnabled}
          titleText="Kontakte importieren"
          closeBtnText="abbrechen"
          submitBtnText="importieren"
          modalDialogStyle={{ maxWidth: "100%", width: "95%" }}
          onClose={this.closeModal}
          onSubmit={this.submitModal}
        >
          {modalContent[modalContentKey]}
        </Modal>
        <div className={className}>
          <TitleBar titleComponent={title}>
            <div className="col">
              {write ? (
                <div className="float-right">
                  {loginName === "klout" ? (
                    <button
                      type="button"
                      onClick={this.openModal}
                      className="btn btn-outline-primary btn-sm"
                    >
                      importieren
                    </button>
                  ) : null}
                  <button
                    type="button"
                    onClick={this.setNewContact}
                    className="btn btn-outline-primary btn-sm ml-3"
                  >
                    hinzufügen
                  </button>
                </div>
              ) : null}
            </div>
          </TitleBar>

          <input
            className="form-control mt-3"
            placeholder="Filter..."
            onChange={this.setFilter.bind(this)}
          />

          <SimpleTable
            className="mt-3"
            mapping={this.listMapping}
            selected={selected}
            sortKey={isSortedBy}
            reverseSort={sortCount % 2 < 1}
            data={contacts}
            dataRenderers={this.dataRenderers}
            colRenderer={this.colRenderer}
            onRowSelected={this.setChosenContact}
            onColSelected={this.sortBy}
          />
        </div>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  contacts: PropTypes.array,
  contact: PropTypes.object,
  loginName: PropTypes.string,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  loginName: state.main.get("loginName"),
  importStatus: state.contacts.get("importStatus"),
  importProgress: state.contacts.get("importProgress")
}))(List);

import gql from 'graphql-tag';
import { ReservationData } from './fragments';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription updateReservation($user: String!) {
          updateReservation(user: $user) {
            resourceId
            oldResourceId
            reservation {
              ...ReservationData
            }
          }
        }
        ${ReservationData}
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { updateReservation } = data;
        dispatch({
          type: 'RESERVATION_UPDATED',
          data: updateReservation.reservation,
          resourceId: updateReservation.resourceId,
          oldResourceId: updateReservation.oldResourceId,
          id: updateReservation.reservation.id,
          resourceType: updateReservation.reservation.type
        });
      }
    });
};

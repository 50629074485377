const getLoginName = localStorageItems => {
  if (localStorageItems) {
    if (localStorageItems.firstname && localStorageItems.lastname) {
      return `${localStorageItems.firstname} ${localStorageItems.lastname}`;
    } else if (localStorageItems.username) {
      return localStorageItems.username;
    }
  }
  return '';
};

export default getLoginName;

import gql from 'graphql-tag';

export default client => dispatch => {
  client
    .query({
      query: gql`
        query {
          getVehicleTypes {
            value: type
            label: type
          }
        }
      `
    })
    .then(response => {
      dispatch({
        type: 'VEHICLE_TYPES_FOUND',
        data: response.data.getVehicleTypes
      });
    });
};

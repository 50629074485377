import gql from 'graphql-tag';
import store from '../store';

import deleteReservation from './deleteReservation';

export default (client, task) => dispatch => {
  const requestUser = store.getState().main.get('loginName');

  client
    .mutate({
      mutation: gql`
        mutation deleteWorkshopTask($requestUser: String!, $id: ID) {
          deleteWorkshopTask(requestUser: $requestUser, id: $id) {
            success
          }
        }
      `,
      variables: { requestUser, id: task.id }
    })
    .then(response => {
      if (response.data.deleteWorkshopTask.success === true) {

        if (task.reservation && task.resource) {
          dispatch(
            deleteReservation(
              client,
              task.reservation.id,
              task.resource.id,
              task.resourceType
            )
          );
        }

        dispatch({
          type: 'TASK_DELETED',
          data: task.id
        });
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteWorkshopTask.success
        });
      }
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";
import Data from "./data";

import getUsers from "../../actions/getUsers";
import getAbsences from "../../actions/getAbsences";
import setChosenAbsence from "../../actions/setChosenAbsence";

class Absences extends Component {
  constructor(props) {
    super(props);

    document.title = "Abwesenheit";

    this.state = {
      filter: ""
    };

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getAbsences(props.client, id));
    props.dispatch(getUsers(props.client));

    this.listMapping = [
      { key: "type", label: "Typ" },
      { key: "from", label: "Von" },
      { key: "to", label: "Bis" },
      { key: "days", label: "Anzahl Tage" },
      { key: "humanResource", label: "Mitarbeiter" }
    ];

    this.listDataRenderers = {
      type: elem => {
        const color =
          elem === "Krank" || elem === "Schule" || elem === "Feiertag"
            ? "#FF0000"
            : "#FF8800";
        return (
          <Fragment>
            <div className="table-color-bar" style={{ background: color }} />
            {elem}
          </Fragment>
        );
      },
      from: elem => moment.utc(elem).format("DD.MM.YYYY"),
      to: elem => moment.utc(elem).format("DD.MM.YYYY"),
      humanResource: elem => (elem ? `${elem.firstname} ${elem.lastname}` : "")
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.setChosenAbsence = this.setChosenAbsence.bind(this);
    this.setNewAbsence = this.setNewAbsence.bind(this);
  }

  handleFilterChange(e) {
    this.setState({ filter: e.target.value });
  }

  setChosenAbsence(absence) {
    this.props.dispatch(setChosenAbsence(absence));
  }

  setNewAbsence() {
    this.props.dispatch({
      type: "SET_NEW_ABSENCE"
    });
    window.history.pushState({}, null, "/absences");
  }

  static filter(data, filterString) {
    if (filterString === "") {
      return data;
    }
    const filter = filterString.toUpperCase();

    return data.filter(elem => {
      const { humanResource } = elem;
      let match = false;
      if (humanResource) {
        const testString = `${humanResource.firstname.toUpperCase()} ${humanResource.lastname.toUpperCase()}`;
        match = match || testString.indexOf(filter) > -1;
      }

      const from = moment.utc(elem.from).format("DD.MM.YYYY");
      const to = moment.utc(elem.to).format("DD.MM.YYYY");

      match =
        match ||
        elem.type.toUpperCase().indexOf(filter) > -1 ||
        from.indexOf(filter) > -1 ||
        to.indexOf(filter) > -1;

      return match;
    });
  }

  render() {
    let { absences } = this.props;
    const {
      chosenAbsence,
      absenceTypes,
      users,
      permissions,
      loginName
    } = this.props;
    const { filter } = this.state;

    const write =
      Boolean(permissions.find(p => p.name === "absences" && p.write)) ||
      loginName === "klout";

    const selected = chosenAbsence ? ["id", chosenAbsence.id] : null;

    absences = Absences.filter(absences, filter);

    const title = (
      <Fragment>
        <span className="h4 mr-3">Tabelle</span>
        <a className="h4" href="/calendar">
          Kalender
        </a>
      </Fragment>
    );

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 padding-20 hidden-print">
              <TitleBar
                title="Abwesenheiten"
                titleComponent={title}
                onBtnClick={this.setNewAbsence}
                showControls={write}
              />

              <input
                className="form-control mt-3"
                placeholder="Filter..."
                value={filter}
                onChange={this.handleFilterChange}
              />
              <SimpleTable
                className="mt-3"
                mapping={this.listMapping}
                selected={selected}
                sortKey="from"
                reverseSort
                data={absences}
                dataRenderers={this.listDataRenderers}
                onRowSelected={this.setChosenAbsence}
              />
            </div>
            <Data
              className="col-md-6 data-panel padding-20 col-print-12 border-left border-print-none height-print-auto"
              absence={chosenAbsence}
              absences={absences}
              absenceTypes={absenceTypes}
              users={users}
              write={write}
              onSelect={this.handleFilterChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

Absences.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  absences: PropTypes.array,
  chosenAbsence: PropTypes.object,
  absenceTypes: PropTypes.array,
  users: PropTypes.array,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  absences: state.absences.get("absences"),
  chosenAbsence: state.absences.get("chosenAbsence"),
  absenceTypes: state.humanResources.get("absenceTypes"),
  users: state.humanResources.get("users"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(Absences);

import gql from "graphql-tag";

export default (client, transporter) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createTransporter(
          $order_id: ID
          $company: String
          $contact_name: String
          $contact_phone: String
          $notice: String
        ) {
          createTransporter(
            order_id: $order_id
            company: $company
            contact_name: $contact_name
            contact_phone: $contact_phone
            notice: $notice
          ) {
            id
            company
            contactName: contact_name
            contactPhone: contact_phone
            notice
            data {
              amount
              vehicle
              time
              amountTransfer
              amountTransferUnit
            }
          }
        }
      `,
      variables: {
        order_id: transporter.orderId,
        company: transporter.company,
        contact_name: transporter.contactName,
        contact_phone: transporter.contactPhone,
        notice: transporter.notice
      }
    })
    .then(response => {
      console.log(response);
      dispatch({
        type: "TRANSPORTER_ADDED",
        data: response.data.createTransporter
      });
    });
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

export default class VehicleSelect extends Component {
  static CustomSingleValue = props => {
    const { isCombi } = props.data;

    if (isCombi) {
      const { description, establishment } = props.data;

      return (
        <components.SingleValue {...props}>
          <span>{description}</span>
          <span className="text-black-50"> {establishment}</span>
        </components.SingleValue>
      );
    }

    const { brand, type, licensePlate, category } = props.data;

    return (
      <components.SingleValue {...props}>
        <span>
          {brand} {type}
        </span>
        <span className="text-black-50">
          {' '}
          {licensePlate} / {category}
        </span>
      </components.SingleValue>
    );
  };

  static CustomOption = props => {
    const {
      brand,
      type,
      licensePlate,
      category,
      humanResources,
      isCombi
    } = props.data;

    if (isCombi) {
      const { description, establishment } = props.data;
      return (
        <components.Option {...props}>
          <span>{description}</span>
          <span className="text-black-50 float-right">{establishment}</span>
          <div className="clearfix" />
          <div className="small text-muted">
            {humanResources
              .map(hr => `${hr.firstname} ${hr.lastname}`)
              .join(', ')}
          </div>
        </components.Option>
      );
    }

    return (
      <components.Option {...props}>
        <span>
          {brand} {type}
        </span>
        <span className="text-black-50 float-right">
          {licensePlate} / {category}
        </span>
        <div className="clearfix" />
        <div className="small text-muted">
          {humanResources
            .map(hr => `${hr.firstname} ${hr.lastname}`)
            .join(', ')}
        </div>
      </components.Option>
    );
  };

  static filter = (option, filterString) => {
    const { data } = option;

    const testString = `${data.brand} ${data.type}_${data.licensePlate}_${data.category}`.toLowerCase();

    return testString.indexOf(filterString.toLowerCase()) > -1;
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, { action }) {
    const { onChange } = this.props;

    if (action !== 'pop-value') onChange(value);
  }

  render() {
    const { value, options, error, disabled } = this.props;

    const styles = {
      control: base => ({
        ...base,
        borderColor: error ? '#dc3545' : base.borderColor
      })
    };

    return (
      <Select
        isClearable={false}
        isDisabled={disabled}
        placeholder="Fahrzeug wählen..."
        noOptionsMessage={() => '...'}
        getOptionValue={option => option.id}
        styles={styles}
        components={{
          Option: VehicleSelect.CustomOption,
          SingleValue: VehicleSelect.CustomSingleValue
        }}
        filterOption={VehicleSelect.filter}
        value={value}
        options={options}
        onChange={this.handleChange}
      />
    );
  }
}

VehicleSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

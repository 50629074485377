import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

class Display extends Component {
  render() {
    const { absence, disabled, onEdit, onDelete } = this.props;
    return (
      <div className="row employee-absence-entry">
        <div className="col-md-3 border-bottom border-right border-left p-2">
          {absence.type}
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          {moment.utc(absence.from).format('DD.MM.YYYY')}
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          {moment.utc(absence.to).format('DD.MM.YYYY')}
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          {absence.days}
          {!disabled ? (
            <div className="float-right">
              <button
                data-id={absence.id}
                style={{ width: 32 }}
                className="btn btn-outline-secondary btn-sm mr-2"
                onClick={onEdit}>
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <button
                data-id={absence.id}
                style={{ width: 32 }}
                className="btn btn-outline-danger btn-sm"
                onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Display.propTypes = {
  absence: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool
};

export default Display;

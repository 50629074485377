import gql from 'graphql-tag';
import { OrderMaxiOverview } from './fragments';

export default (client, orderId, value) => {
  const MUTATION = gql`
    mutation setOrderAccountingState(
      $id: ID!
      $accountingState: Boolean!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      setOrderAccountingState(id: $id, accountingState: $accountingState) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: MUTATION,
      variables: {
        id: orderId,
        accountingState: value
      }
    })
    .then(response => {
      console.log(response);
    });
};

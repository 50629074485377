import gql from "graphql-tag";

export default (client, dispoId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteDispoHumanResourceResource($dispoId: ID) {
          deleteDispoHumanResourceResource(id: $dispoId) {
            success
          }
        }
      `,
      variables: {
        dispoId
      }
    })
    .then(response => {
      if (response.data.deleteDispoHumanResourceResource.success) {
        dispatch({
          type: "DISPO_HRR_REMOVED",
          data: dispoId
        });
      }
    });
};

import Immutable from 'immutable';

const initialState = Immutable.Map({
  resources: [],
  chosenResource: null
});

export default (state = initialState, action) => {
  if (action.type === 'RESOURCES_FOUND') {
    return state.set('resources', action.data);
  }

  if (action.type === 'SET_CHOSEN_RESOURCE') {
    return state.set('chosenResource', action.data);
  }

  if (action.type === 'RESOURCE_UPDATED') {
    const resources = state.get('resources');
    const resourceId = action.resourceId.toString();

    const nextResources = resources.map(resource => {
      if (resource.id === resourceId) {
        return action.data;
      }
      return resource;
    });

    return state
      .set('resources', nextResources)
      .set('chosenResource', action.data);
  }

  if (action.type === 'RESOURCE_ADDED') {
    const resources = state.get('resources');
    const newResource = action.data;
    return state
      .set('resources', [].concat(...resources, newResource))
      .set('chosenResource', newResource);
  }

  if (action.type === 'RESOURCE_DELETED') {
    const resources = state.get('resources');
    const resourceId = action.data.toString();
    const otherResource = resources.filter(
      resource => resource.id !== resourceId
    );
    return state
      .set('resources', [].concat(...otherResource))
      .set('chosenResource', null);
  }

  if (action.type === 'HUMANRESOURCERESOURCE_CREATED') {
    const resources = state.get('resources');
    const humanResource = action.data;
    const resourceId = action.resourceId.toString();

    let nextChosenResource = null;

    const nextResources = resources.map(elem => {
      if (elem.id === resourceId) {
        nextChosenResource = {
          ...elem,
          humanResources: elem.humanResources.concat(humanResource)
        };
        return nextChosenResource;
      }
      return elem;
    });

    return state
      .set('resources', nextResources)
      .set('chosenResource', nextChosenResource);
  }

  if (action.type === 'COMMITMENT_CREATED') {
    const resources = state.get('resources');
    const commitment = action.data;
    const resourceId = action.resourceId.toString();

    let nextChosenResource = null;

    const nextResources = resources.map(elem => {
      if (elem.id === resourceId) {
        nextChosenResource = {
          ...elem,
          commitments: elem.commitments.concat(commitment)
        };
        return nextChosenResource;
      }
      return elem;
    });

    return state
      .set('resources', nextResources)
      .set('chosenResource', nextChosenResource);
  }

  if (action.type === 'HUMANRESOURCERESOURCE_DELETED') {
    const resources = state.get('resources');
    const resourceId = action.resourceId.toString();
    const { ids } = action;

    let nextChosenResource = null;

    const nextResources = resources.map(elem => {
      if (elem.id === resourceId) {
        const nextHumanResources = elem.humanResources.filter(
          hr => !ids.find(id => id === hr.id)
        );

        nextChosenResource = {
          ...elem,
          humanResources: nextHumanResources
        };
        return nextChosenResource;
      }
      return elem;
    });

    return state
      .set('resources', nextResources)
      .set('chosenResource', nextChosenResource);
  }

  if (action.type === 'COMMITMENT_DELETED') {
    const resources = state.get('resources');
    const resourceId = action.resourceId.toString();
    const { values } = action;

    let nextChosenResource = null;

    const nextResources = resources.map(elem => {
      if (elem.id === resourceId) {
        const nextCommitments = elem.commitments.filter(
          c => !values.find(id => id === c.id)
        );

        nextChosenResource = {
          ...elem,
          commitments: nextCommitments
        };
        return nextChosenResource;
      }
      return elem;
    });

    return state
      .set('resources', nextResources)
      .set('chosenResource', nextChosenResource);
  }

  if (action.type === 'SET_NEW_RESOURCE') {
    return state.set('chosenResource', null);
  }

  return state;
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import { faCheck, faEuroSign } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TitleBar from "../TitleBar";
import Modal from "../Modal";
import Data from "./data";

import updateOrderStatus from "../../actions/updateOrderStatus";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "data",
      showModal: false,
      message: ""
    };

    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  handleViewChange(e) {
    const { view } = e.target.dataset;
    this.setState({ view });
  }

  handleStatusChange(e) {
    e.stopPropagation();
    const { order } = this.props;
    if (order) {
      const { dispatch, client } = this.props;
      const requestStatus = parseInt(e.currentTarget.dataset.status, 10);
      const { id, status } = order;
      let nextStatus = requestStatus;
      if (status === 0 && requestStatus === 1) return;
      if (status === 1 && requestStatus === 1) nextStatus = 2;
      if (status === 2 && requestStatus === 2) nextStatus = 0;

      if (status === 0) {
        const costCenter = order.cost_center;
        const {
          building_address: buildingAddress,
          lat,
          certified_foreman_name: certifiedForemanName,
          construction_manager_name: constructionManagerName,
          scopeOfServices,
          humanResources,
          resources,
          vehicles,
          subcontractor
        } = order;
        if (costCenter) {
          if (buildingAddress !== "" || lat !== "") {
            if (certifiedForemanName !== "" || constructionManagerName !== "") {
              if (scopeOfServices && scopeOfServices.length > 0) {
                if (
                  (humanResources && humanResources.length > 0) ||
                  subcontractor
                ) {
                  if (
                    (resources && resources.length > 0) ||
                    (vehicles && vehicles.length > 0) ||
                    subcontractor
                  ) {
                    dispatch(updateOrderStatus(client, id, nextStatus, false));
                  } else {
                    this.showModal(
                      "Keine Maschine/Fahrzeuge oder Subunternehmen zugeordnet"
                    );
                  }
                } else {
                  this.showModal(
                    "Keine Mitarbeiter oder Subunternehmen zugeordnet"
                  );
                }
              } else {
                this.showModal("Kein Leistungsumfang angegeben");
              }
            } else {
              this.showModal("Bauleiter oder Polier fehlt");
            }
          } else {
            this.showModal("Keine Adresse angegeben");
          }
        } else {
          this.showModal("Keine Kostenstelle gewählt");
        }
      } else {
        dispatch(updateOrderStatus(client, id, nextStatus, false));
      }
    }
  }

  showModal(message) {
    this.setState({ showModal: true, message });
  }

  hideModal() {
    this.setState({ showModal: false, message: "" });
  }

  render() {
    const {
      footerClassName,
      expanded,
      order,
      orders,
      costCenters,
      write,
      checkPermission,
      publishPermission,
      disposalRead,
      disposalWrite,
      onExpand
    } = this.props;
    const { view, showModal, message } = this.state;

    let status = null;
    if (order) {
      status = order.status;
    }

    return (
      <Fragment>
        <Modal
          show={showModal}
          submitEnabled={false}
          showControls={false}
          onClose={this.hideModal}
          titleText="Achtung"
        >
          {message}
        </Modal>

        <div className="padding-20">
          <TitleBar title="Stammdaten">
            <div className="col d-flex justify-content-center">
              <div>
                <div className="btn-group btn-group-sm">
                  <button
                    className={`btn btn-outline-success ${
                      status === 1 || status === 2
                        ? "btn-outline-success-force-active"
                        : ""
                    }`}
                    data-status="2"
                    onClick={this.handleStatusChange}
                    disabled={
                      status === 1 || (!checkPermission && status !== 2)
                    }
                  >
                    <FontAwesomeIcon icon={faEuroSign} />
                    &nbsp; kaufm. geprüft
                  </button>
                  <button
                    className={`btn btn-outline-success ${
                      status === 1 ? "btn-outline-success-force-active" : ""
                    }`}
                    data-status="1"
                    onClick={this.handleStatusChange}
                    disabled={status === 0 || !publishPermission}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    &nbsp; veröffentlicht
                  </button>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="btn-group btn-group-sm">
                <button
                  className={`btn btn-outline-secondary ${
                    view === "data" ? "active" : ""
                  }`}
                  data-view="data"
                  onClick={this.handleViewChange}
                >
                  Daten
                </button>
                {disposalRead ? (
                  <button
                    className={`btn btn-outline-secondary ${
                      view === "disposal" ? "active" : ""
                    }`}
                    data-view="disposal"
                    onClick={this.handleViewChange}
                    disabled={!order}
                  >
                    Abtransport und Verkippung
                  </button>
                ) : null}
              </div>
            </div>
          </TitleBar>
        </div>

        <Data
          footerClassName={footerClassName}
          expanded={expanded}
          view={view}
          order={order}
          orders={orders}
          costCenters={costCenters}
          write={write}
          disposalWrite={disposalWrite}
          onExpand={onExpand}
        />
      </Fragment>
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  footerClassName: PropTypes.string,
  expanded: PropTypes.bool,
  order: PropTypes.object,
  orders: PropTypes.array,
  costCenters: PropTypes.array,
  write: PropTypes.bool,
  checkPermission: PropTypes.bool,
  publishPermission: PropTypes.bool,
  disposalRead: PropTypes.bool,
  disposalWrite: PropTypes.bool,
  onExpand: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client")
}))(Order);

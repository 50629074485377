import gql from 'graphql-tag';

export default (client, humanResources, resourceId) => dispatch => {
  const ids = [];
  Promise.all(
    humanResources.map(humanResource => {
      ids.push(humanResource.id);
      return client.mutate({
        mutation: gql`
          mutation deleteHumanResourceResource(
            $resourceId: ID
            $humanResourceId: ID
          ) {
            deleteHumanResourceResource(
              resource_id: $resourceId
              human_resource_id: $humanResourceId
            ) {
              success
            }
          }
        `,
        variables: {
          resourceId,
          humanResourceId: humanResource.id
        }
      });
    })
  ).then(() => {
    dispatch({
      type: 'HUMANRESOURCERESOURCE_DELETED',
      ids,
      resourceId
    });
  });
};

import gql from 'graphql-tag';

export default (client, humanResourceId, permission) => dispatch => {
  dispatch({
    type: 'TOGGLE_READ_PERMISSION',
    id: humanResourceId,
    name: permission.name,
    actualRead: permission.read
  });
  let query;
  if (permission.id) {
    query = gql`
      mutation updatePermission(
        $id: ID
        $human_resource_id: ID
        $name: String
        $read: Boolean
        $write: Boolean
      ) {
        updatePermission(
          id: $id
          human_resource_id: $human_resource_id
          name: $name
          read: $read
          write: $write
        ) {
          id
          name
          read
          write
        }
      }
    `;
  } else {
    query = gql`
      mutation createPermission(
        $human_resource_id: ID
        $name: String
        $read: Boolean
        $write: Boolean
      ) {
        createPermission(
          human_resource_id: $human_resource_id
          name: $name
          read: $read
          write: $write
        ) {
          id
        }
      }
    `;
  }
  const variables = {
    id: permission.id,
    human_resource_id: humanResourceId,
    name: permission.name,
    read: !permission.read,
    write: !permission.read === false ? false : permission.write
  };
  client
    .mutate({
      mutation: query,
      variables
    })
    .then(response => {
      if (!permission.id) {
        dispatch({
          type: 'UPDATE_PERMISSION',
          id: response.data.createPermission.id,
          name: permission.name,
          humanResourceId
        });
      }
    });
};

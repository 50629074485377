import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import TitleBar from '../TitleBar';
import SimpleTable from '../SimpleTable';

import setChosenCompany from '../../actions/setChosenCompany';

class List extends Component {
  constructor() {
    super();
    this.initState = {
      filter: '',
      isSortedBy: null,
      sortCount: 0
    };
    this.state = this.initState;

    this.listMapping = [
      { key: 'name', label: 'Name' },
      { key: 'adress', label: 'Adresse' },
      { key: 'city', label: 'Stadt' },
      { key: 'phone', label: 'Telefon' },
      { key: 'email', label: 'Email' }
    ];

    this.dataRenderers = {
      email: elem => (
        <div className="bg-white rounded px-1">
          <a href={`mailto:${elem}`}>{elem}</a>
        </div>
      )
    };

    this.setNewContact = this.setNewContact.bind(this);
    this.setChosenCompany = this.setChosenCompany.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  setNewContact() {
    this.props.dispatch({
      type: 'SET_NEW_CONTACT'
    });
    window.history.pushState({}, null, '/contacts');
  }

  setChosenCompany(company) {
    this.props.dispatch(setChosenCompany(company));
    window.history.pushState({}, null, `/companies/${company.id}`);
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  sortBy(key) {
    let { sortCount } = this.state;
    if (key !== this.state.isSortedBy) {
      sortCount = 0;
    }

    this.setState({
      isSortedBy: key,
      sortCount: sortCount + 1
    });
  }

  static filter(data, filterString) {
    if (filterString === '') return data;
    const filter = filterString.toUpperCase();

    const keys = ['name', 'adress', 'city', 'phone', 'email'];

    return data.filter(elem => {
      const testString = keys
        .reduce((str, value) => {
          if (elem[value]) return `${str + elem[value]}_`;
          return str;
        }, '')
        .toUpperCase();

      return testString.indexOf(filter) > -1;
    });
  }

  render() {
    let { companies } = this.props;
    const { company, className, write } = this.props;
    const { filter, isSortedBy, sortCount } = this.state;

    companies = List.filter(companies, filter);

    const selected = company ? ['id', company.id] : null;

    const title = (
      <Fragment>
        <a className="h4 mr-3" href="/contacts">
          Kontakte
        </a>
        <span className="h4">Firmen</span>
      </Fragment>
    );

    return (
      <Fragment>
        <div className={className}>
          <TitleBar titleComponent={title}>
            <div className="col">
              {write ? (
                <button
                  type="button"
                  onClick={this.setNewContact}
                  className="btn btn-outline-primary btn-sm ml-3 float-right">
                  hinzufügen
                </button>
              ) : null}
            </div>
          </TitleBar>

          <input
            className="form-control mt-3"
            placeholder="Filter..."
            onChange={this.setFilter}
          />

          <SimpleTable
            className="mt-3"
            mapping={this.listMapping}
            selected={selected}
            sortKey={isSortedBy}
            reverseSort={sortCount % 2 < 1}
            data={companies}
            dataRenderers={this.dataRenderers}
            onRowSelected={this.setChosenCompany}
            onColSelected={this.sortBy}
          />
        </div>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  companies: PropTypes.array,
  company: PropTypes.object,
  write: PropTypes.bool
};

export default List;

import request from 'superagent';

export default userId => dispatch => {
  const host = `https://${window.location.hostname}/api/getPermissions`;
  request
    .post(host, {
      userId
    })
    .end((err, response) => {
      if (response) {
        const data = JSON.parse(response.text);
        dispatch({ type: 'UPDATE_PERMISSIONS', data: data.permissions });
      }
    });
};

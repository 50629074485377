import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ScopeOfService from "../../ScopeOfService";
import CreatableSelect from "react-select/lib/Creatable";
import { components } from "react-select";

const CustomOptionComponent = props => (
  <components.Option {...props}>
    <div className="row no-gutters">
      <div className="col-6">
        <span
          className="small"
          title={props.data.label}
          style={{
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "95%",
            whiteSpace: "nowrap"
          }}
        >
          {props.data.label}
        </span>
      </div>
      <div className="col-6 small">
        {props.data.company !== "" && (
          <span
            className="small badge badge-secondary float-right"
            title={props.data.company}
            style={{
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "95%",
              whiteSpace: "nowrap"
            }}
          >
            {props.data.company}
          </span>
        )}
      </div>
    </div>
  </components.Option>
);

class ReservationData extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      name: "",
      scopeOfServices: [],
      contactOptions: this.props.contacts,
      selectedContact: null
    };
    this.state = props.value || this.initState;

    this.input = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleScopeOfServiceChange = this.handleScopeOfServiceChange.bind(
      this
    );

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const { contacts, value } = this.props;
    if (this.input.current) this.input.current.focus();
    if (contacts) {
      const contactOptions = contacts.map(contact => {
        return {
          label: contact.lastname + " " + contact.firstname,
          value: contact.id,
          company: contact.company
            ? contact.company.name
              ? contact.company.name
              : ""
            : ""
        };
      });
      if (value && value.contactPersonId) {
        const contact = contacts.find(
          contact => contact.id === value.contactPersonId
        );
        if (contact) {
          this.setState({
            selectedContact: {
              label: contact.lastname + " " + contact.firstname,
              value: contact.id
            }
          });
        }
      }
      this.setState({ contactOptions });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState !== this.state) this.props.onChange(this.state);
  }

  handleInputChange(e) {
    const { currentTarget } = e;
    const value =
      currentTarget.type === "checkbox"
        ? currentTarget.checked
        : currentTarget.value;
    const { name } = currentTarget;

    this.setState({ [name]: value });
  }

  handleScopeOfServiceChange(scopeOfServices) {
    this.setState({ scopeOfServices });
  }

  handleKeyPress(e) {
    const { onConfirm } = this.props;
    const { name } = this.state;
    if (e.key === "Enter" && name !== "") {
      onConfirm();
    }
  }

  render() {
    const {
      name,
      scopeOfServices,
      contactOptions,
      selectedContact
    } = this.state;

    return (
      <Fragment>
        <div className="row form-group">
          <div className="col-6">
            <label for="name">Bezeichnung</label>
            <input
              className="form-control"
              id="name"
              name="name"
              value={name}
              ref={this.input}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="contact">Ansprechpartner</label>
            <CreatableSelect
              isClearable
              id="contacts"
              placeholder=""
              noOptionsMessage={() => "Name Eingeben"}
              noResultsMessage={() => "Keine Übereinstimmung"}
              value={selectedContact}
              options={contactOptions}
              formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
              onChange={e => this.setState({ selectedContact: e })}
              components={{ Option: CustomOptionComponent }}
            />
          </div>
        </div>

        <ScopeOfService
          scopeOfServices={scopeOfServices}
          onChange={this.handleScopeOfServiceChange}
        />
      </Fragment>
    );
  }
}

ReservationData.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  contacts: PropTypes.array
};

export default ReservationData;

import gql from "graphql-tag";
import moment from "moment";

import createReservation from "./createReservation";
import updateReservation from "./updateReservation";
import order from "../components/Order/order";
import getOrder from "./getOrder";

export default (
  dispatch,
  client,
  orderId,
  resourceId,
  resourceType,
  startDate,
  endDate,
  note
) => {
  return client
    .query({
      query: gql`
        query getReservation(
          $resource_id: ID
          $type: String
          $from: String
          $to: String
        ) {
          getReservation(
            resource_id: $resource_id
            type: $type
            from: $from
            to: $to
          ) {
            id
          }
        }
      `,
      variables: {
        resource_id: resourceId,
        type: resourceType,
        from: startDate,
        to: endDate
      }
    })
    .then(response => {
      if (!response.data.getReservation) {
        return createReservation(
          dispatch,
          client,
          resourceId,
          null,
          resourceType,
          startDate,
          endDate,
          orderId,
          null,
          note
        );
      }
      dispatch(
        updateReservation(
          client,
          response.data.getReservation.id,
          resourceId,
          resourceType,
          startDate,
          endDate,
          orderId,
          "0"
        )
      );
      return undefined;
    });
};

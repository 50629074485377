import gql from "graphql-tag";
import createReservation from "./createReservation";

export default (
  dispatch,
  client,
  lastname,
  firstname,
  resource,
  reservationData,
  start,
  end
) => {
  client
    .mutate({
      mutation: gql`
        mutation createContact($lastname: String, $firstname: String) {
          createContact(lastname: $lastname, firstname: $firstname) {
            id
            salutation
            lastname
            firstname
            phone
            email
            fax
            mobile
            position
            notice
            company {
              id
              name
              adress
              zipcode
              city
              phone
              fax
              email
            }
          }
        }
      `,
      variables: {
        lastname,
        firstname
      }
    })
    .then(response => {
      createReservation(
        dispatch,
        client,
        resource.id,
        null,
        resource.type,
        start,
        end,
        `RES_${reservationData.name}`,
        reservationData.scopeOfServices,
        null,
        response.data.createContact.id
      );
    });
};

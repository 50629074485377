import gql from 'graphql-tag';
import _ from 'lodash';
import { ResourceData } from './fragments';

import createHumanResourceResource from './createHumanResourceResource';
import createCommitment from './createCommitment';
import deleteHumanResourceResource from './deleteHumanResourceResource';
import deleteCommitment from './deleteCommitment';

export default (
  client,
  resource,
  name,
  number,
  image,
  category,
  subcategory,
  comment,
  commitments,
  establishment,
  humanResources,
  implementation,
  status,
  leavingDate
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateResource(
          $resourceId: ID
          $name: String
          $number: String
          $image: String
          $category: String
          $subcategory: String
          $comment: String
          $establishment: String
          $implementation: String
          $status: String
          $leavingDate: Date
        ) {
          updateResource(
            id: $resourceId
            name: $name
            number: $number
            image: $image
            category: $category
            subcategory: $subcategory
            comment: $comment
            establishment: $establishment
            implementation: $implementation
            status: $status
            leavingDate: $leavingDate
          ) {
            ...ResourceData
          }
        }
        ${ResourceData}
      `,
      variables: {
        resourceId: resource.id,
        name,
        number,
        image,
        category,
        subcategory,
        comment,
        establishment,
        implementation,
        status,
        leavingDate
      }
    })
    .then(response => {
      const addedHumanResources = humanResources.filter(
        h => resource.humanResources.map(c => c.id).indexOf(h.id) === -1
      );
      const removedHumanResources = _.difference(
        resource.humanResources,
        humanResources
      );
      const addedCommitments = commitments.filter(c => c.id === 'new');
      const removedCommitments = commitments.filter(c => c.id === 'removed');

      if (removedHumanResources.length > 0) {
        dispatch(
          deleteHumanResourceResource(
            client,
            removedHumanResources,
            resource.id
          )
        );
      }

      if (removedCommitments.length > 0) {
        dispatch(deleteCommitment(client, removedCommitments, resource.id));
      }

      if (addedHumanResources.length > 0) {
        dispatch(
          createHumanResourceResource(client, addedHumanResources, resource.id)
        );
      }

      if (addedCommitments.length > 0) {
        dispatch(createCommitment(client, addedCommitments, resource.id));
      }

      dispatch({
        type: 'RESOURCE_UPDATED',
        data: response.data.updateResource,
        resourceId: resource.id
      });
    });
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class EstablishmentSelect extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  toggleOpen() {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleSelect(e) {
    const { value, options, onSelect } = this.props;

    const index = parseInt(e.currentTarget.dataset.index, 10);
    if (index >= 0 && index < options.length) {
      const establishment = options[index];
      if (!value || establishment.value !== value.value)
        onSelect(options[index]);
    } else if (index === -1 && value) {
      onSelect(null);
    }

    this.toggleOpen();
  }

  render() {
    const { value, options } = this.props;
    const { open } = this.state;

    const selected = value ? value.value : null;

    return (
      <div className="header-establishment-select-container">
        <div
          className="header-establishment-select-value"
          onClick={this.toggleOpen}>
          {value ? value.label.toUpperCase() : 'ALLE'}
          <div className="header-establishment-select-arrow" />
        </div>
        {open ? (
          <div className="header-establishment-select-dropdown shadow">
            <div
              className={`header-establishment-select-dropdown-item${
                value ? '' : ' active'
              }`}
              data-index={-1}
              onClick={this.handleSelect}>
              Alle
            </div>
            {options.map((option, index) => (
              <div
                key={option.value}
                className={`header-establishment-select-dropdown-item${
                  selected === option.value ? ' active' : ''
                }`}
                data-index={index}
                onClick={this.handleSelect}>
                {option.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

EstablishmentSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  onSelect: PropTypes.func
};

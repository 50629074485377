import gql from "graphql-tag";
import getLoginName from "../getLoginName";
import { OrderDetail } from "./fragments";

export default (
  client,
  orderId,
  operationalPeriodStart,
  operationalPeriodFinish
) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem("klout"));
  const requestUser = getLoginName(localStorageItems);
  client
    .mutate({
      mutation: gql`
        mutation updateOrderPeriod(
          $requestUser: String!
          $orderId: ID
          $start_date_time: String
          $operational_period_start: String
          $operational_period_finish: String
        ) {
          updateOrderPeriod(
            requestUser: $requestUser
            id: $orderId
            start_date_time: $start_date_time
            operational_period_start: $operational_period_start
            operational_period_finish: $operational_period_finish
          ) {
            ...OrderDetail
          }
        }
        ${OrderDetail}
      `,
      variables: {
        requestUser,
        orderId,
        start_date_time: operationalPeriodStart,
        operational_period_start: operationalPeriodStart,
        operational_period_finish: operationalPeriodFinish
      }
    })
    .then(response => {
      dispatch({
        type: "ORDERS_FOUND"
      });
    });
};

import gql from 'graphql-tag';

export default client => dispatch =>
  client
    .query({
      query: gql`
        query getSubcontractors {
          getSubcontractors
        }
      `,
      fetchPolicy: 'network-only'
    })
    .then(resp => resp.data.getSubcontractors)
    .then(subcontractors =>
      dispatch({ type: 'SUBCONTRACTORS_FOUND', data: subcontractors })
    );

import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferInvoice(
    $id: ID!
    $invoice: String!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferInvoice(id: $id, invoice: $invoice) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, invoice) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      invoice
    }
  });

import gql from "graphql-tag";

export const CombinationData = gql`
  fragment CombinationData on Combination {
    id
    name
    establishment
    description
    status
    leavingDate
    resources {
      id
      name
      number
      category
      humanResources {
        id
        lastname
        firstname
      }
    }
    humanResources {
      id
      lastname
      firstname
    }
    vehicles {
      id
      brand
      type
      licensePlate
    }
  }
`;

export const CostCenterData = gql`
  fragment CostCenterData on CostCenter {
    id
    value
    oldValue
    project_number
    establishment
    is_continuance
    building_project
    customer
    masterCompany: master_customer
  }
`;

export const CostCenterFrameData = gql`
  fragment CostCenterFrameData on CostCenterFrame {
    id
    from
    to
    establishment
  }
`;

export const OrderOverview = gql`
  fragment OrderOverview on Order {
    id
    establishment
    building_address
    project_number
    lat
    lng
    construction_manager_name
    certified_foreman_name
    description
    status
    ebNumber
    intern
    type
    subcontractor

    cost_center {
      id
      value
      oldValue
      project_number
      establishment
      is_continuance
      customer
      building_project
    }
    kutterContact {
      id
      firstname
      lastname
    }
    scopeOfServices {
      id
      width
      depth
      amount
      scopeOfServiceTyps {
        id
        value
      }
    }
  }
`;

export const HumanResourceData = gql`
  fragment HumanResourceData on HumanResource {
    id
    lastname
    firstname
    staff_id
    phone
    street
    city
    zipcode
    position
    establishment
    username
    token
    registeredMobileDevice
    deviceToken
    status
    reason
    leavingDate
    vacationDemands {
      id
      vacation
      vacationPlus
      vacationPlusDecay
      year
    }
    absences {
      id
      from
      to
      type
      days
    }
    salaries {
      id
      year
      month
      salary
    }
  }
`;

export const OrderMaxiOverview = gql`
  fragment OrderMaxiOverview on Order {
    id
    establishment
    building_address
    project_number
    lat
    lng
    directions
    construction_manager_name
    certified_foreman_name
    operational_period_start
    operational_period_finish
    description
    status
    notes
    ebNumber
    intern
    allowances
    invoice
    allowanceValid
    invoiceNumber
    note
    type
    accountingState
    subcontractor
    cost_center {
      id
      value
      oldValue
      project_number
      establishment
      is_continuance
      building_project
      customer
    }
    kutterContact {
      id
      firstname
      lastname
    }
    resources {
      id
      from
      to
      resource {
        __typename
        ... on Resource {
          id
          name
          number
        }
      }
    }
    vehicles {
      id
      from
      to
      resource {
        __typename
        ... on Vehicle {
          id
          number
          brand
          type
          licensePlate
        }
      }
    }
    humanResources {
      id
      from
      to
      isEmployeeHoursValid
      humanResource {
        id
        lastname
        firstname
        employeeHours(filter: $employeeFilter) {
          id
          date
          value
          file
          comment
        }
      }
    }
    scopeOfServices {
      id
      width
      depth
      amount
      scopeOfServiceTyps {
        id
        value
      }
    }
  }
`;

export const OrderDetail = gql`
  fragment OrderDetail on Order {
    id
    establishment
    project_number
    subcontractor
    building_address
    lat
    lng
    directions
    construction_manager_name
    construction_manager_phone
    certified_foreman_name
    certified_foreman_phone
    start_date_time
    start_meeting_time
    operational_period_start
    operational_period_finish
    description
    status
    notes
    intern
    ebNumber
    type
    accountingState

    cost_center {
      id
      value
      project_number
      establishment
      is_continuance
      building_project
      customer
      projectNumbers
      masterCompany: master_customer
    }
    kutterContact {
      id
      firstname
      lastname
      phone
    }
    kutterBooker {
      id
      firstname
      lastname
      phone
    }
    scopeOfServices {
      id
      width
      amount
      depth
      depthTo
      amountUnit
      scopeOfServiceTyps {
        id
        value
      }
    }
    resources {
      id
      from
      to
      note
      resource {
        __typename
        ... on Resource {
          id
          name
          number
          category
        }
      }
    }
    vehicles {
      id
      from
      to
      note
      resource {
        __typename
        ... on Vehicle {
          id
          number
          brand
          type
          licensePlate
          humanResources {
            id
          }
        }
      }
    }
    humanResources {
      id
      from
      to
      note
      resource_ids {
        id
        resource_id
        orderId
      }
      humanResource {
        id
        lastname
        firstname
        absences {
          from
          to
        }
      }
      from
      to
    }
    transporters {
      id
      company
      contactName: contact_name
      contactPhone: contact_phone
      notice
      data {
        time
        amount
        vehicle
        amountTransfer
        amountTransferUnit
      }
    }
    dropzones {
      id
      company
      address
      contactName: contact_name
      contactPhone: contact_phone
      date
      data {
        material
        contaminated
        amountDropZone
        amountDropZoneUnit
      }
    }
  }
`;

export const ReservationData = gql`
  fragment ReservationData on Reservation {
    id
    groupId: group_id
    type
    from
    to
    status
    note
    contactPersonId
    scopeOfServices {
      id
      width
      depth
      amount
      depthTo
      amountUnit
      scopeOfServiceTyps {
        value
      }
    }
    order {
      id
      status
      description
      subcontractor
      ebNumber
      project_number
      start_date_time
      operational_period_finish
      actual_start_date_time
      actual_end_date_time
      intern
      cost_center {
        id
        value
        project_number
        establishment
        customer
        building_project
      }
      kutterContact {
        id
        firstname
        lastname
      }
      scopeOfServices {
        id
        width
        depth
        depthTo
        amount
        amountUnit
        scopeOfServiceTyps {
          value
        }
      }
      resources {
        id
        from
        to
        note
        resource {
          __typename
          ... on Resource {
            id
            name
            number
          }
        }
      }
      vehicles {
        id
        from
        to
        note
        resource {
          __typename
          ... on Vehicle {
            id
            type
            licensePlate
          }
        }
      }
      humanResources {
        id
        from
        to
        note
        humanResource {
          id
          firstname
          lastname
        }
      }
    }
    workshopTask {
      id
      description
      workspaces
    }
  }
`;

export const ResourceData = gql`
  fragment ResourceData on Resource {
    id
    name
    number
    image
    establishment
    category
    subcategory
    comment
    implementation
    status
    leavingDate
    commitments {
      id
      value
    }
    humanResources {
      id
      lastname
      firstname
    }
  }
`;

export const TransferData = gql`
  fragment TransferData on Transfer {
    id
    sourceDate: source_date
    destinationDate: destination_date
    sourceOrderString
    sourceContactString
    sourceContactKutterString
    sourceNotes
    sourceLocation
    sourceLat
    sourceLng
    additionalMachine
    destinationOrderString
    destinationContactString
    destinationContactKutterString
    destinationNotes
    destinationLocation
    destinationLat
    destinationLng
    vehicle {
      id
      number
      brand
      type
      licensePlate
      category
    }
    combination {
      id
      description
      establishment
    }
    humanResource {
      id
      firstname
      lastname
      position
      phone
    }
    sourceOrder
    sourceContact {
      id
      lastname
      firstname
      phone
      mobile
    }
    sourceContactKutter {
      id
      firstname
      lastname
      position
      phone
    }
    destinationOrder
    destinationContact {
      id
      lastname
      firstname
      phone
      mobile
    }
    destinationContactKutter {
      id
      firstname
      lastname
      position
      phone
    }
    resources {
      id
      name
      number
      category
      status
    }
  }
`;

export const TransferDataWithEmployeeHours = gql`
  fragment TransferDataWithEmployeeHours on Transfer {
    id

    allowances
    invoice
    allowanceValid
    invoiceNumber
    note
    type
    accountingState
    isEmployeeHoursValid

    sourceDate: source_date
    destinationDate: destination_date
    sourceOrderString
    sourceContactString
    sourceContactKutterString
    sourceNotes
    sourceLocation
    additionalMachine
    destinationOrderString
    destinationContactString
    destinationContactKutterString
    destinationNotes
    destinationLocation
    vehicle {
      id
      number
      brand
      type
      licensePlate
      category
    }
    combination {
      id
      name
      description
      establishment
    }
    humanResource {
      id
      firstname
      lastname
      position
      phone
      employeeHours(filter: $employeeFilter) {
        id
        date
        value
        file
        comment
      }
    }
    sourceOrder
    sourceContact {
      id
      lastname
      firstname
      phone
      mobile
    }
    sourceContactKutter {
      id
      firstname
      lastname
      position
      phone
    }
    destinationOrder
    destinationContact {
      id
      lastname
      firstname
      phone
      mobile
    }
    destinationContactKutter {
      id
      firstname
      lastname
      position
      phone
    }
    resources {
      id
      name
      number
      category
      status
    }
  }
`;

export const VehicleData = gql`
  fragment VehicleData on Vehicle {
    id
    number
    brand
    type
    licensePlate
    image
    establishment
    category
    comment
    implementation
    status
    leavingDate
    humanResources {
      id
      lastname
      firstname
    }
  }
`;

export const WorkshopTaskData = gql`
  fragment WorkshopTaskData on WorkshopTask {
    id
    column
    resourceType
    creator
    establishment
    resource {
      __typename
      ... on Resource {
        id
        name
        number
        category
      }
      ... on Vehicle {
        id
        brand
        type
        number
        category
        licensePlate
      }
    }
    description
    workspaces
    externalWorkshop
    externalMachine
    workers {
      id
      firstname
      lastname
      absences(start: $start, end: $end) {
        id
        from
        to
      }
    }
    startDate
    dueDate
    files {
      filename
      handle
      url
    }
    createdAt
    reservation {
      id
      from
    }
    otherReservations(start: $start, end: $end) {
      id
      from
      to
      status
    }
  }
`;

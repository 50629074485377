import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterBy } from '../../util/filterBy';

import TitleBar from '../TitleBar';
import SimpleTable from '../SimpleTable';

import setChosenVehicle from '../../actions/setChosenVehicle';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      sortKey: null,
      reverseSort: true
    };

    this.tableMapping = [
      { key: 'category', label: 'Kategorie' },
      { key: 'number', label: 'Inventarnummer' },
      { key: 'brand', label: 'Hersteller' },
      { key: 'type', label: 'Typ' },
      { key: 'licensePlate', label: 'Nummernschild' },
      { key: 'establishment', label: 'Niederlassung' }
    ];

    this.setNewVehicle = this.setNewVehicle.bind(this);
    this.setChosenVehicle = this.setChosenVehicle.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  static filter(data, filterString) {
    return filterBy(
      data,
      ['brand', 'type', 'number', 'category', 'licensePlate', 'establishment'],
      filterString
    );
  }

  headRenderer = className => {
    return (
      <Fragment>
        <div className={className}>Kategorie</div>
        <div className={className}>Inventarnummer</div>
        <div className={className}>Hersteller</div>
        <div className={className}>Typ</div>
        <div className={className}>Nummernschild</div>
        <div className={className}>Niederlassung</div>
      </Fragment>
    );
  };

  colRenderer = (user, className) => {
    const {
      category,
      number,
      brand,
      type,
      licensePlate,
      establishment,
      status
    } = user;
    const newClassName =
      status === 'Inaktiv' ? `${className} text-grey` : className;

    return (
      <Fragment>
        <div className={newClassName}>{category}</div>
        <div className={newClassName}>{number}</div>
        <div className={newClassName}>{brand}</div>
        <div className={newClassName}>{type}</div>
        <div className={newClassName}>{licensePlate}</div>
        <div className={newClassName}>{establishment}</div>
      </Fragment>
    );
  };

  setNewVehicle() {
    this.props.dispatch({
      type: 'SET_NEW_VEHICLE'
    });
    window.history.pushState({}, null, '/vehicles');
  }

  setChosenVehicle(vehicle) {
    this.props.dispatch(setChosenVehicle(vehicle));
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  render() {
    let { vehicles } = this.props;
    const { className, chosenVehicle, write } = this.props;
    const { filter, sortKey, reverseSort } = this.state;

    vehicles = List.filter(vehicles, filter);

    const selected = chosenVehicle ? ['id', chosenVehicle.id] : null;

    return (
      <div className={className}>
        <TitleBar
          title="Fahrzeuge"
          onBtnClick={this.setNewVehicle}
          showControls={write}
        />

        <input
          className="form-control mt-3"
          placeholder="Filter..."
          value={filter}
          onChange={this.setFilter}
        />

        <SimpleTable
          className="mt-3"
          headRenderer={this.headRenderer}
          colRenderer={this.colRenderer}
          selected={selected}
          sortKey={sortKey}
          reverseSort={reverseSort}
          data={vehicles}
          onColSelected={this.setSortKey}
          onRowSelected={this.setChosenVehicle}
        />
      </div>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  establishment: PropTypes.object,
  vehicles: PropTypes.array,
  chosenVehicle: PropTypes.object,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch
}))(List);

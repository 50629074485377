import gql from 'graphql-tag';
import { ReservationData } from './fragments';

export default (client, startDate, endDate) => dispatch => {
  dispatch({ type: 'RESERVATIONS_LOADING', data: 'vehicles' });

  client
    .query({
      query: gql`
        query getVehicles($startDate: Date, $endDate: Date) {
          getVehicles {
            id
            brand
            type
            licensePlate
            establishment
            category
            number
            status
            leavingDate
            humanResources {
              id
              lastname
              firstname
            }
            reservations(start: $startDate, end: $endDate) {
              ...ReservationData
            }
          }
        }
        ${ReservationData}
      `,
      variables: {
        startDate,
        endDate
      },
      options: {
        fetchPolicy: 'network-only'
      }
    })
    .then(response => {
      dispatch({
        type: 'VEHICLES_WITH_RESERVATIONS_FOUND',
        data: response.data.getVehicles
      });
    });
};

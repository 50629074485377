import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faTimes } from '@fortawesome/pro-solid-svg-icons';
import OrderData from './orderData';
import Modal from '../../Modal';

class SelectionBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderData: null,
      noteData: null,
      showModal: false,
      showNoteModal: false
    };

    this.handleOrderDataChange = this.handleOrderDataChange.bind(this);
    this.handleNoteDataChange = this.handleNoteDataChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);

    this.openModal = this.openModal.bind(this);
    this.openNoteModal = this.openNoteModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.closeNoteModal = this.closeNoteModal.bind(this);

    this.submitModal = this.submitModal.bind(this);
    this.submitNoteModal = this.submitNoteModal.bind(this);
  }

  static preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  openModal() {
    this.setState({ showModal: true });
  }

  openNoteModal() {
    this.setState({ showNoteModal: true });
  }

  closeModal() {
    this.setState({ orderData: null, showModal: false });
  }

  closeNoteModal() {
    this.setState({ noteData: null, showNoteModal: false });
  }

  submitModal() {
    const { model, createOrder } = this.props;
    const { orderData } = this.state;

    createOrder(model, orderData);

    this.closeModal();
  }

  submitNoteModal() {
    const { model, createNote } = this.props;
    const { noteData } = this.state;

    createNote(model, noteData);

    this.closeModal();
  }

  handleOrderDataChange(data) {
    this.setState({ orderData: data });
  }

  handleNoteDataChange(e) {
    this.setState({ noteData: e.target.value });
  }

  handleCreate() {
    const { model } = this.props;
    const isSelect = model.resource && !model.resource.heightFactor;

    if (isSelect) {
      this.props.createReservation();
    } else {
      this.openModal();
    }
  }

  render() {
    const { model, view, onCancel, costCenters, resources } = this.props;
    const { showModal, showNoteModal } = this.state;

    const index = view.offsetY / 41;
    const resource = resources[index];
    const isReservation = resource && !resource.heightFactor;

    return (
      <Fragment>
        <Modal
          show={showModal}
          titleText="Abruf anlegen"
          closeBtnText="abbrechen"
          submitBtnText="speichern"
          onClose={this.closeModal}
          onSubmit={this.submitModal}>
          <OrderData
            scopeOfServices={[]}
            machines={''}
            costCenters={costCenters}
            onUpdate={this.handleOrderDataChange}
          />
        </Modal>

        <Modal
          show={showNoteModal}
          titleText="Notiz anlegen"
          closeBtnText="abbrechen"
          submitBtnText="speichern"
          onClose={this.closeNoteModal}
          onSubmit={this.submitNoteModal}>
          <textarea
            className="form-control"
            onChange={this.handleNoteDataChange}
          />
        </Modal>

        <div
          className="gantt-reservation-box-selection"
          style={{
            left: view.offsetX,
            top: view.offsetY,
            width: view.width,
            zIndex: 'auto'
          }}>
          <div className="gantt-reservation-box-content no-select">
            <span
              className="box-status cursor-pointer"
              onClick={this.handleCreate}>
              {isReservation ? 'Maschine reservieren' : 'Abruf erstellen'}
            </span>
          </div>

          {model.isSet && (
            <div
              className="gantt-reservation-box-tools"
              onMouseDown={SelectionBox.preventEvent}
              onMouseUp={SelectionBox.preventEvent}>
              <button
                className="btn-no-style gantt-reservation-box-tools-icon"
                onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                className="btn-no-style gantt-reservation-box-tools-icon"
                onClick={this.openNoteModal}>
                <FontAwesomeIcon icon={faStickyNote} />
              </button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

SelectionBox.propTypes = {
  model: PropTypes.object,
  view: PropTypes.object,
  costCenters: PropTypes.array,
  resources: PropTypes.array,
  onCancel: PropTypes.func,
  createReservation: PropTypes.func,
  createOrder: PropTypes.func
};

export default SelectionBox;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import DropzoneInput from "./dropzoneInput";

import createDropzone from "../../../actions/createDropzone";
import updateDropzoneData from "../../../actions/updateDropzoneData";
import deleteDropzone from "../../../actions/deleteDropzone";
import updateDropzone from "../../../actions/updateDropzone";
import ButtonCombination from "../../../util/ButtonCombination";

export default class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: "Asphaltdecke",
      contaminated: false,
      amountDropZone: 0,
      amountDropZoneUnit: "€/T",
      editIndex: -1,
      editMaterial: null,
      editContaminated: null,
      editAmount: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeIndex = this.handleInputChangeIndex.bind(this);
    this.addData = this.addData.bind(this);
    this.addEditData = this.addEditData.bind(this);
    this.removeData = this.removeData.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAmountUnitChange = this.handleAmountUnitChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.enableEdit = this.enableEdit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
  }

  handleInputChange(e) {
    const { target } = e;
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [name]: value });
  }

  handleInputChangeIndex(e, index) {
    const { target } = e;
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [name]: value, index: index });
  }

  addData() {
    const { client, dispatch, dropzone } = this.props;
    const {
      material,
      contaminated,
      amountDropZone,
      amountDropZoneUnit
    } = this.state;

    const nextData = dropzone.data.concat({
      material,
      contaminated,
      amountDropZone,
      amountDropZoneUnit
    });
    dispatch(updateDropzoneData(client, dropzone.id, nextData));
    this.setState({
      contaminated: false,
      amountDropZone: 0,
      amountDropZoneUnit: "€/T",
      material: "Asphaltdecke"
    });
  }

  addEditData() {
    const { client, dispatch, dropzone } = this.props;
    const {
      editIndex,
      editMaterial,
      editContaminated,
      editAmount,
      amountDropZoneUnit
    } = this.state;

    if (dropzone.data.length >= editIndex) {
      let nextData = dropzone.data;
      const { material, contaminated, amountDropZone } = nextData[editIndex];
      const newMaterial = editMaterial ? editMaterial : material;
      const newContaminated = editContaminated
        ? editContaminated
        : contaminated;
      const newAmountDropZone = editAmount ? editAmount : amountDropZone;
      nextData[editIndex] = {
        material: newMaterial,
        contaminated: newContaminated,
        amountDroZone: newAmountDropZone,
        amountDropZoneUnit
      };
      dispatch(updateDropzoneData(client, dropzone.id, nextData));
      this.setState({
        editIndex: -1,
        editMaterial: null,
        editContaminated: null,
        editAmount: null
      });
    }
  }

  removeData(index) {
    const { client, dispatch, dropzone } = this.props;
    const nextData = dropzone.data.filter((elem, i) => i !== index);
    dispatch(updateDropzoneData(client, dropzone.id, nextData));
  }

  handleCreate(data) {
    const { client, dispatch, orderId } = this.props;

    data.orderId = parseInt(orderId, 10);
    dispatch(createDropzone(client, data));
  }

  handleUpdate(id, data) {
    const { client, dispatch, orderId } = this.props;

    data.orderId = parseInt(orderId, 10);
    dispatch(updateDropzone(client, id, data));
  }

  handleRemove(id) {
    const { client, dispatch } = this.props;
    dispatch(deleteDropzone(client, id));
  }

  handleAmountUnitChange(e) {
    this.setState({ amountDropZoneUnit: e.target.dataset.unit });
  }

  handleQuantityChange(e) {
    this.setState({
      amountDropZone: e.target.value
    });
  }

  enableEdit(index) {
    this.setState({
      editIndex: index
    });
  }

  handleEdit() {
    this.addEditData();
    this.setState({
      editIndex: -1
    });
  }

  handleEditCancel() {
    this.setState({
      editIndex: -1,
      editMaterial: null,
      editContaminated: null,
      editAmount: null
    });
  }

  render() {
    const { client, dispatch, dropzone, disabled } = this.props;
    const {
      material,
      contaminated,
      amountDropZone,
      amountDropZoneUnit,
      editIndex,
      editMaterial,
      editContaminated,
      editAmount
    } = this.state;
    const { data, isNew } = dropzone;

    return (
      <div className="col-12 mt-3">
        <div className="row small border-bottom">
          <DropzoneInput
            client={client}
            dispatch={dispatch}
            data={dropzone}
            disabled={disabled}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
            onRemove={this.handleRemove}
          />
        </div>

        {!isNew ? (
          <div className="row small bg-white border-bottom pt-3">
            {data.map((elem, index) => (
              <Fragment key={index}>
                <div className="form-group col-5">
                  <label className="w-75">Materialart</label>
                  <label className="w-25">
                    kontaminiert
                    <input
                      className="float-right mt-1"
                      type="checkbox"
                      name="editContaminated"
                      checked={
                        editContaminated && editIndex === index
                          ? editContaminated
                          : elem.contaminated
                      }
                      onChange={this.handleInputChange}
                      disabled={index !== editIndex}
                    />
                  </label>
                  {editIndex === index ? (
                    <select
                      className="form-control"
                      name="editMaterial"
                      value={editMaterial ? editMaterial : elem.material}
                      onChange={this.handleInputChange}
                    >
                      <option value="Asphaltdecke">Asphaltdecke</option>
                      <option value="Apshaltbinder">Apshaltbinder</option>
                      <option value="A-Tragschicht">A-Tragschicht</option>
                      <option value="Beton">Beton</option>
                      <option value="HGT">HGT</option>
                      <option value="Gussasphalt">Gussasphalt</option>
                      <option value="Schotter">Schotter</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name="editMaterial"
                      value={elem.material}
                      onChange={this.handleInputChange}
                      disabled
                    />
                  )}
                </div>
                <div className="form-group col-4">
                  <div>
                    <label className="w-50">
                      Gebühren ( in&nbsp;
                      <span
                        className={`badge badge-${
                          elem.amountDropZoneUnit === "€/T"
                            ? "primary"
                            : "secondary"
                        }`}
                        data-unit="€/T"
                      >
                        €/T
                      </span>
                      )
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="number"
                          className="form-control"
                          name="editAmount"
                          value={
                            editAmount && index === editIndex
                              ? editAmount
                              : elem.amountDropZone
                          }
                          min="0"
                          onChange={e => this.handleInputChangeIndex(e, index)}
                          disabled={index !== editIndex}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-3 d-flex align-items-end justify-content-end">
                  <label className="d-block">&nbsp;</label>
                  <ButtonCombination
                    handleEdit={() => this.handleEdit()}
                    enableEdit={() => this.enableEdit(index)}
                    handleDelete={() => this.removeData(index)}
                    handleEditCancel={() => this.handleEditCancel()}
                    handleDeleteCancel={() => this.handleEditCancel()}
                  />
                </div>
              </Fragment>
            ))}

            {disabled ? null : (
              <Fragment>
                <div className="form-group col-5">
                  <label className="w-75">Materialart</label>
                  <label className="w-25">
                    kontaminiert
                    <input
                      className="float-right mt-1"
                      type="checkbox"
                      name="contaminated"
                      checked={contaminated}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                    />
                  </label>
                  <select
                    className="form-control"
                    name="material"
                    value={material}
                    onChange={this.handleInputChange}
                    disabled={disabled}
                  >
                    <option value="Asphaltdecke">Asphaltdecke</option>
                    <option value="Apshaltbinder">Apshaltbinder</option>
                    <option value="A-Tragschicht">A-Tragschicht</option>
                    <option value="Beton">Beton</option>
                    <option value="HGT">HGT</option>
                    <option value="Gussasphalt">Gussasphalt</option>
                    <option value="Schotter">Schotter</option>
                  </select>
                </div>

                <div className="form-group col-4">
                  <div>
                    <label>
                      Gebühren ( in&nbsp;
                      <span
                        className={`badge badge-${
                          amountDropZoneUnit === "€/T" ? "primary" : "secondary"
                        }`}
                        data-unit="€/T"
                        onClick={e => this.handleAmountUnitChange(e)}
                      >
                        €/T
                      </span>
                      {/*&nbsp;/&nbsp;*/}
                      {/*<span*/}
                      {/*  className={`badge badge-${*/}
                      {/*    amountDropZoneUnit === "€/h" ? "primary" : "secondary"*/}
                      {/*  }`}*/}
                      {/*  data-unit="€/h"*/}
                      {/*  onClick={e => this.handleAmountUnitChange(e)}*/}
                      {/*>*/}
                      {/*  €/h*/}
                      {/*</span>*/})
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="number"
                          className="form-control"
                          name="amount"
                          value={amountDropZone}
                          min="0"
                          onChange={e => this.handleQuantityChange(e)}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-3 d-flex align-items-end justify-content-end">
                  <button
                    className="btn btn-secondary btn-square form-control"
                    onClick={this.addData}
                    disabled={disabled}
                  >
                    Ok
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

Dropzone.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  orderId: PropTypes.string,
  dropzone: PropTypes.object,
  disabled: PropTypes.bool,
  onAddData: PropTypes.func
};

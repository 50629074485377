import React, { Component, Fragment } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';

import { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

import setChosenCompany from '../../actions/setChosenCompany';

class Companies extends Component {
  static defaultProps = {
    onRef: () => {}
  };

  constructor(props) {
    super();
    this.initState = {
      id: '',
      name: '',
      adress: '',
      zipcode: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
      locked: true,
      companies: props.companies,
      companyValue: null,
      validationErrors: {}
    };
    this.state = {
      ...this.initState,
      ...Companies.getCompanyData(props.company)
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { company } = this.props;
    const { companyValue } = this.state;
    if (prevProps.company !== company) {
      if (company) {
        this.setState({
          ...Companies.getCompanyData(company),
          locked: true,
          validationErrors: {}
        });
      } else {
        this.setState(this.initState);
      }
    } else if (prevState.companyValue && !companyValue) {
      this.setState(this.initState);
    }
  }

  static getCompanyData(company) {
    if (company) {
      return {
        id: company.id,
        name: company.name || '',
        adress: company.adress || '',
        zipcode: company.zipcode || '',
        city: company.city || '',
        phone: company.phone || '',
        fax: company.fax || '',
        email: company.email || '',
        companyValue: company
      };
    }
    return {};
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  getValues() {
    const { companyValue } = this.state;
    return { ...this.state, name: companyValue ? companyValue.name : null };
  }

  validateForm(ignoreExisting = false) {
    const { validationErrors, companyValue } = this.state;

    if (ignoreExisting) {
      if (companyValue && !companyValue.__isNew__) return true;
    }

    if (companyValue && companyValue.__isNew__) validationErrors.name = false;
    else validationErrors.name = this.state.name === '';

    validationErrors.adress = this.state.adress === '';
    validationErrors.zipcode = !this.state.zipcode.match(/^[0-9]{5}$/i);
    validationErrors.city = this.state.city === '';
    validationErrors.phone = !this.state.phone.match(/^\d+$/i);

    if (this.state.fax === '') {
      validationErrors.fax = false;
    } else {
      validationErrors.fax = !this.state.fax.match(/^\d+$/i);
    }

    if (this.state.email === '') {
      validationErrors.email = false;
    } else {
      validationErrors.email = !this.state.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
    }

    let valid = true;
    for (const field in validationErrors)
      valid = valid && !validationErrors[field];

    this.setState({ validationErrors });

    return valid;
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCompanyChange(option, { action }) {
    if (action === 'create-option') {
      this.setState({ companyValue: option, locked: false });
    } else if (option) {
      this.props.dispatch(setChosenCompany(option));
      this.setState({ companyValue: option, locked: true });
    } else {
      this.props.dispatch({
        type: 'SET_NEW_COMPANY'
      });
      this.setState({ companyValue: null, locked: true });
    }
  }

  CustomOption = props => (
    <components.Option {...props}>
      <div style={{ overflow: 'hidden' }}>
        {props.data.name}
        <span className=" float-right text-black-50"> {props.data.city}</span>
      </div>
    </components.Option>
  );

  static companyFilter(option, filterString) {
    const { data } = option;
    let testString = '';
    if (data.name) testString += `${data.name}_`;
    if (data.city) testString += data.city;
    return testString.toUpperCase().indexOf(filterString.toUpperCase()) > -1;
  }

  static getCompanyOptionData(inputValue, optionLabel) {
    return { id: inputValue, name: optionLabel, __isNew__: true };
  }

  render() {
    const {
      id,
      adress,
      zipcode,
      city,
      phone,
      fax,
      email,
      companies,
      companyValue,
      locked
    } = this.state;

    const errors = this.state.validationErrors;

    return (
      <Fragment>
        <div className="row">
          <div className="form-group col-sm-12">
            <label className="form-check-label small">
              Firma* {id ? <a href={`/companies/${id}`}>(bearbeiten)</a> : ''}
            </label>

            <CreatableSelect
              isClearable
              isDisabled={this.props.locked}
              placeholder=""
              noOptionsMessage={() => 'Keine Übereinstimmung'}
              formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
              value={companyValue}
              options={companies}
              components={{ Option: this.CustomOption }}
              getOptionValue={opt => opt.id}
              getOptionLabel={opt => opt.name}
              getNewOptionData={Companies.getCompanyOptionData}
              isValidNewOption={() => true}
              filterOption={Companies.companyFilter}
              onChange={this.handleCompanyChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-sm-5">
            <label className="form-check-label small">Adresse*</label>
            <input
              type="text"
              className={`form-control ${errors.adress ? 'is-invalid' : ''}`}
              value={adress}
              disabled={locked}
              name="adress"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-2">
            <label className="form-check-label small">PLZ*</label>
            <input
              type="text"
              className={`form-control ${errors.zipcode ? 'is-invalid' : ''}`}
              value={zipcode}
              disabled={locked}
              name="zipcode"
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group col-sm-5">
            <label className="form-check-label small">Stadt*</label>
            <input
              type="text"
              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
              value={city}
              disabled={locked}
              name="city"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">Telefon</label>
            <input
              type="text"
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
              value={phone}
              disabled={locked}
              name="phone"
              onChange={this.handleInputChange}
            />
            {errors.phone ? (
              <small className="form-text text-danger">
                Darf nur Zahlen enthalten.
              </small>
            ) : null}
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">Fax</label>
            <input
              type="text"
              className={`form-control ${errors.fax ? 'is-invalid' : ''}`}
              value={fax}
              disabled={locked}
              name="fax"
              onChange={this.handleInputChange}
            />
            {errors.fax ? (
              <small className="form-text text-danger">
                Darf nur Zahlen enthalten.
              </small>
            ) : null}
          </div>
          <div className="form-group col-sm-4">
            <label className="form-check-label small">E-mail</label>
            <input
              type="text"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              value={email}
              disabled={locked}
              name="email"
              onChange={this.handleInputChange}
            />
            {errors.email ? (
              <small className="form-text text-danger">
                Keine korrekte E-Mail.
              </small>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

Companies.propTypes = {
  dispatch: Proptypes.func,
  companies: Proptypes.array,
  company: Proptypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch
}))(Companies);

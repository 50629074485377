import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { filterBy } from "../../util/filterBy";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";

import setChosenCombination from "../../actions/setChosenCombination";

class List extends Component {
  constructor(props) {
    super(props);
    this.initState = {
      filter: props.establishment ? props.establishment.value : "",
      sortKey: null,
      reverseSort: true
    };
    this.state = this.initState;

    this.setChosenCombination = this.setChosenCombination.bind(this);
    this.setNewCombination = this.setNewCombination.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  headRenderer = className => {
    return (
      <Fragment>
        <div className={className}>Name</div>
        <div className={className}>Beschreibung</div>
        <div className={className}>Maschinen</div>
        <div className={className}>Mitarbeiter</div>
        <div className={className}>Fahrzeuge</div>
        <div className={className}>Niederlassung</div>
      </Fragment>
    );
  };

  colRenderer = (combi, className) => {
    const {
      name,
      description,
      resources,
      humanResources,
      vehicles,
      establishment,
      status
    } = combi;
    const newClassName =
      status === "Inaktiv" ? `${className} text-grey` : className;

    return (
      <Fragment>
        <div className={newClassName}>{name}</div>
        <div className={newClassName}>{description}</div>
        <div className={newClassName}>
          {resources
            .map(e => {
              if (e && e.name) {
                return e.name;
              } else {
                return "";
              }
            })
            .join(", ")}
        </div>
        <div className={newClassName}>
          {humanResources.map(e => `${e.firstname} ${e.lastname}`).join(", ")}
        </div>
        <div className={newClassName}>
          {vehicles.filter(v => v).map(e => e.licensePlate).join(", ")}
        </div>
        <div className={newClassName}>{establishment}</div>
      </Fragment>
    );
  };

  static filter(data, filterString) {
    return filterBy(
      data,
      ["name", "description", "establishment"],
      filterString
    );
  }

  setChosenCombination(combination) {
    this.props.dispatch(setChosenCombination(combination));
    window.history.pushState({}, null, `/combinations/${combination.id}`);
  }

  setNewCombination() {
    this.props.dispatch(setChosenCombination(null));
    window.history.pushState({}, null, "/combinations");
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  render() {
    let { combinations } = this.props;
    const { className, combination, write } = this.props;
    const { filter, sortKey, reverseSort } = this.state;

    combinations = List.filter(combinations, filter);

    const selected = combination ? ["id", combination.id] : null;

    return (
      <Fragment>
        <div className={className}>
          <TitleBar
            title="Gespanne"
            onBtnClick={this.setNewCombination}
            showControls={write}
          />

          <input
            className="form-control mt-3"
            placeholder="Filter..."
            value={filter}
            onChange={this.setFilter}
          />

          <SimpleTable
            className="mt-3"
            headRenderer={this.headRenderer}
            colRenderer={this.colRenderer}
            selected={selected}
            sortKey={sortKey}
            reverseSort={reverseSort}
            data={combinations}
            onRowSelected={this.setChosenCombination}
            onColSelected={this.setSortKey}
          />
        </div>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  establishment: PropTypes.object,
  combination: PropTypes.object,
  combinations: PropTypes.array,
  write: PropTypes.bool
};

export default List;

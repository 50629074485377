import React, { Component } from "react";
import PropTypes from "prop-types";

import "./style.css";

import cog7 from "./cog_7.svg";
import cog10 from "./cog_10.svg";

class LoadingIndicator extends Component {
  render() {
    const { className, show, loadingMessage } = this.props;

    if (show) {
      return (
        <div className={className}>
          <div className="loi-wrapper">
            <div className="loi-container">
              <div className="loi-cogs">
                <img
                  className="loi-cog-7"
                  alt=""
                  src={cog7}
                  width="22"
                  height="22"
                />
                <img
                  className="loi-cog-10"
                  alt=""
                  src={cog10}
                  width="24"
                  height="24"
                />
              </div>
              <span className="loi-message">{loadingMessage}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  loadingMessage: PropTypes.string
};

export default LoadingIndicator;

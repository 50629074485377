import gql from 'graphql-tag';

export default (client, id) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteCombination($id: ID) {
          deleteCombination(id: $id) {
            success
          }
        }
      `,
      variables: { id }
    })
    .then(response => {
      if (response.data.deleteCombination.success === true) {
        dispatch({
          type: 'COMBINATION_DELETED',
          id
        });
        window.history.pushState({}, null, '/combinations');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteCombination.success
        });
      }
    });
};

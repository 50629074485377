import Immutable from "immutable";
import moment from "moment";

const initialState = Immutable.Map({
  start: moment()
    .startOf("day")
    .subtract(2, "days"),
  end: moment()
    .endOf("day")
    .add(27, "days"),
  resources: [],
  vehicles: [],
  combinations: [],
  transfers: [],
  orders: [],
  resourcesLoaded: false,
  vehiclesLoaded: false,
  combinationsLoaded: false,
  transfersLoaded: false,
  ordersLoaded: false,
  loading: false,
  conflictingResources: []
});

export default (state = initialState, action) => {
  if (action.type === "SET_GANTT_DATE") {
    return state.set("start", action.start).set("end", action.end);
  }

  if (action.type === "SET_DISPOSITION_LOADING") {
    return state.set("loading", action.data);
  }

  if (action.type === "RESERVATIONS_LOADING") {
    const key = `${action.data.toLowerCase()}Loaded`;
    return state.set(key, false);
  }

  if (action.type === "RESOURCES_WITH_RESERVATIONS_FOUND") {
    const resources = action.data.map(elem => ({
      ...elem,
      names: elem.humanResources
        .map(human => `${human.firstname} ${human.lastname}`)
        .join(" / "),
      type: "RESOURCE"
    }));
    return state.set("resources", resources).set("resourcesLoaded", true);
  }

  if (action.type === "VEHICLES_WITH_RESERVATIONS_FOUND") {
    const vehicles = action.data.map(elem => ({
      ...elem,
      name: `${elem.brand} ${elem.type}`,
      names: elem.humanResources
        .map(human => `${human.firstname} ${human.lastname}`)
        .join(" / "),
      type: "VEHICLE"
    }));
    return state.set("vehicles", vehicles).set("vehiclesLoaded", true);
  }

  if (action.type === "TRANSFERS_FOR_PERIOD_FOUND") {
    return state.set("transfers", action.data).set("transfersLoaded", true);
  }

  if (action.type === "ORDERS_FOR_PERIOD_FOUND") {
    return state.set("orders", action.data).set("ordersLoaded", true);
  }

  if (action.type === "COMBINATIONS_WITH_RESERVATIONS_FOUND") {
    const combinations = action.data.map(elem => ({
      ...elem,
      type: "COMBINATION",
      category: "Gespanne"
    }));
    return state
      .set("combinations", combinations)
      .set("combinationsLoaded", true);
  }

  if (action.type === "RESERVATION_ADDED") {
    // TODO: prevent adding reservations that are outside of the start and end date.
    if (!action.data.type) return state;

    const field = `${action.data.type.toLowerCase()}s`;

    const elements = state.get(field);
    const { resourceId } = action;
    const nextElements = elements.map(elem => {
      if (elem.id === resourceId) {
        return {
          ...elem,
          reservations: elem.reservations.concat(action.data)
        };
      }
      return elem;
    });

    return state.set(field, nextElements);
  }

  if (action.type === "RESERVATION_UPDATED") {
    const { id, resourceId, resourceType, oldResourceId } = action;
    if (!resourceType) return state;

    const field = `${resourceType.toLowerCase()}s`;

    const elements = state.get(field);
    let nextElements = elements;

    if (oldResourceId && resourceId !== oldResourceId) {
      nextElements = elements.map(elem => {
        if (elem.id === resourceId) {
          return {
            ...elem,
            reservations: elem.reservations.concat(action.data)
          };
        }
        if (elem.id === oldResourceId) {
          return {
            ...elem,
            reservations: elem.reservations.filter(
              reservation => reservation.id !== id
            )
          };
        }
        return elem;
      });
    } else {
      nextElements = elements.map(elem => {
        if (elem.id === resourceId) {
          return {
            ...elem,
            reservations: elem.reservations.map(reservation => {
              if (reservation.id === id) {
                return action.data;
              }
              return reservation;
            })
          };
        }
        return elem;
      });
    }

    return state.set(field, nextElements);
  }

  if (action.type === "RESERVATION_DELETED") {
    const { id, resourceId, type } = action.data;

    if (!type) return state;

    const field = `${type.toLowerCase()}s`;

    const elements = state.get(field);
    const nextElements = elements.map(elem => {
      if (elem.id === resourceId) {
        return {
          ...elem,
          reservations: elem.reservations.filter(
            reservation => reservation.id !== id
          )
        };
      }
      return elem;
    });
    return state.set(field, nextElements);
  }

  if (action.type === "RESERVATION_UPDATED_MODAL") {
    const { id, resource } = action.data;
    if (!action.key) return state;

    const field = `${action.key.toLowerCase()}s`;
    const elements = state.get(field);
    const nextElements = elements.map(elem => {
      if (elem.id === resource.id) {
        return {
          ...elem,
          reservations: elem.reservations.map(reservation => {
            if (reservation.id === id) {
              reservation.from = action.data.from;
              reservation.to = action.data.to;
              return reservation;
            }
            return reservation;
          })
        };
      }
      return elem;
    });
    return state.set(field, nextElements);
  }

  if (action.type === "RESERVATION_DELETED") {
    const { id, resourceId, type } = action.data;

    if (!type) return state;

    const field = `${type.toLowerCase()}s`;

    const elements = state.get(field);
    const nextElements = elements.map(elem => {
      if (elem.id === resourceId) {
        return {
          ...elem,
          reservations: elem.reservations.filter(
            reservation => reservation.id !== id
          )
        };
      }
      return elem;
    });
    return state.set(field, nextElements);
  }

  if (action.type === "RESERVATION_SOS_ADDED") {
    const { resourceId, resourceType, reservationId } = action;

    if (!resourceType) return state;

    const field = `${resourceType.toLowerCase()}s`;

    const elements = state.get(field);
    const nextElements = elements.map(elem => {
      if (elem.id === resourceId) {
        return {
          ...elem,
          reservations: elem.reservations.map(reservation => {
            if (reservation.id === reservationId) {
              return {
                ...reservation,
                scopeOfServices: reservation.scopeOfServices.concat(action.data)
              };
            }
            return reservation;
          })
        };
      }
      return elem;
    });

    return state.set(field, nextElements);
  }

  if (action.type === "DISPO_ORDER_UPDATED") {
    const orders = state.get("orders");
    const resources = state.get("resources");
    const vehicles = state.get("vehicles");
    const combinations = state.get("combinations");
    const { id } = action.data;
    let nextOrders = orders;
    let nextResources = resources;
    let nextVehicles = vehicles;
    let nextCombinations = combinations;

    if (action.data.subcontractor) {
      const filterFunc = e => !(e.order && e.order.id === id);
      const mapFunc = e => {
        e.reservations = e.reservations.filter(filterFunc);
        return e;
      };
      nextResources = resources.map(mapFunc);
      nextVehicles = vehicles.map(mapFunc);
      nextCombinations = combinations.map(mapFunc);

      const existingOrder = orders.find(o => o.id === id);
      if (!existingOrder) {
        orders.push(action.data);
      } else {
        nextOrders = orders.map(order => {
          if (order.id === id) {
            return action.data;
          }
          return order;
        });
      }
    }

    return state
      .set("orders", nextOrders)
      .set("resources", nextResources)
      .set("vehicles", nextVehicles)
      .set("combinations", nextCombinations);
  }

  if (action.type === "SET_CONFLICTING_RESOURCES") {
    return state.set("conflictingResources", action.data);
  }

  return state;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    const { dispatch } = this.props;

    dispatch({ type: 'SET_ERROR', data: null });
  }

  render() {
    const { error } = this.props;

    if (error) {
      return (
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Fehler</h5>

                <button type="button" className="close" onClick={this.dismiss}>
                  <span>&times;</span>
                </button>
              </div>

              <div className="modal-body">{error}</div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.dismiss}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

ErrorModal.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  error: state.main.get('error')
}))(ErrorModal);

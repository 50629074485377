import gql from 'graphql-tag';

export default (
  client,
  humanResourceId,
  permission,
  setAll = false
) => dispatch => {
  dispatch({
    type: 'TOGGLE_WRITE_PERMISSION',
    id: humanResourceId,
    name: permission.name,
    actualWrite: permission.write
  });

  let query;
  if (permission.id) {
    query = gql`
      mutation updatePermission(
        $id: ID
        $human_resource_id: ID
        $name: String
        $read: Boolean
        $write: Boolean
      ) {
        updatePermission(
          id: $id
          human_resource_id: $human_resource_id
          name: $name
          read: $read
          write: $write
        ) {
          id
          name
          read
          write
        }
      }
    `;
  } else {
    query = gql`
      mutation createPermission(
        $human_resource_id: ID
        $name: String
        $read: Boolean
        $write: Boolean
      ) {
        createPermission(
          human_resource_id: $human_resource_id
          name: $name
          read: $read
          write: $write
        ) {
          id
          name
          read
          write
        }
      }
    `;
  }
  client
    .mutate({
      mutation: query,
      variables: {
        id: permission.id,
        human_resource_id: humanResourceId,
        name: permission.name,
        read: permission.read || setAll,
        write: !permission.write || setAll
      }
    })
    .then(response => {
      if (!permission.id) {
        dispatch({
          type: 'UPDATE_PERMISSION',
          id: response.data.createPermission.id,
          name: permission.name,
          humanResourceId
        });
      }
    });
};

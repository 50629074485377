import Immutable from 'immutable';
import { startOfHour } from '../util/dateHelper';

const initialState = Immutable.Map({
  workspaces: [
    { id: '1', label: 'L-AP-1', color: '#2fb433' },
    { id: '2', label: 'L-AP-2', color: '#e5b635' },
    { id: '3', label: 'L-AP-3', color: '#e27628' },
    { id: '4', label: 'L-AP-4', color: '#b672cd' },
    { id: '5', label: 'L-AP-5', color: '#3f66e8' },
    { id: '6', label: 'L-AP-6', color: '#47b371' },
    { id: '7', label: 'L-AP-7', color: '#be4579' },
    { id: '8', label: 'L-AP-8', color: '#39beb4' },
    { id: '16', label: 'D-AP-1', color: '#28a745' },
    { id: '17', label: 'D-AP-2', color: '#4597d2' },
    { id: '18', label: 'D-AP-3', color: '#ffc107' },
    { id: '19', label: 'D-AP-4', color: '#a1d823d4' },
    { id: '20', label: 'D-AP-5', color: '#dc3545' },
    { id: '9', label: 'Schlosserei', color: '#1c3372' },
    { id: '13', label: 'PKW-Hebebühne', color: '#666666' },
    { id: '10', label: 'Lager', color: '#666666' },
    { id: '11', label: 'Grubenhalle', color: '#506378' },
    { id: '12', label: 'Waschhalle', color: '#666666' },
    { id: '14', label: 'Sonstiges', color: '#666666' },
    { id: '15', label: 'Außen-/Vororttermin', color: '#583f1b' }
  ],
  tasks: [],
  archivedTasks: [],
  columns: {
    column0: { id: 'column0', title: 'Eingang', taskIds: [] },
    column1: { id: 'column1', title: 'Geplant', taskIds: [] },
    column2: { id: 'column2', title: 'In Arbeit', taskIds: [] },
    column3: { id: 'column3', title: 'Abnahme / Kontrolle', taskIds: [] },
    column100: { id: 'column100', title: 'Auslieferung', taskIds: [] }
  },
  currentDate: startOfHour(new Date())
});

function mapTask(task, workspaces) {
  const workspaceIds = JSON.parse(task.workspaces);

  return {
    ...task,
    workspaces: workspaceIds
      .map(id => workspaces.find(workspace => workspace.id === id.toString()))
      .filter(workspace => Boolean(workspace)),
    startDate: task.startDate ? new Date(task.startDate) : null,
    dueDate: task.dueDate ? new Date(task.dueDate) : null
  };
}

export default (state = initialState, action) => {
  if (action.type === 'TASKS_FOUND') {
    const workspaces = state.get('workspaces');
    const columns = state.get('columns');

    const { archived } = action.data;

    const nextColumns = { ...columns };
    if (!archived)
      Object.keys(nextColumns).forEach(key => (nextColumns[key].taskIds = []));

    const tasks = action.data.tasks.map(task => {
      if (!archived) {
        const column = `column${task.column}`;
        nextColumns[column].taskIds.push(task.id);
      }

      return mapTask(task, workspaces);
    });

    return state
      .set(archived ? 'archivedTasks' : 'tasks', tasks)
      .set('columns', nextColumns);
  }

  if (action.type === 'TASK_ADDED') {
    const workspaces = state.get('workspaces');
    const tasks = state.get('tasks');
    const columns = state.get('columns');

    const nextColumns = { ...columns };

    const task = action.data;

    const column = `column${task.column}`;
    nextColumns[column].taskIds = nextColumns[column].taskIds
      .filter(id => id !== 'new')
      .concat(task.id);

    const nextTask = mapTask(task, workspaces);

    const nextTasks = tasks.filter(t => t.id !== 'new').concat(nextTask);

    return state.set('tasks', nextTasks).set('columns', nextColumns);
  }

  if (action.type === 'TASK_UPDATED') {
    const workspaces = state.get('workspaces');
    const tasks = state.get('tasks');

    const nextTask = mapTask(action.data, workspaces);

    if (action.keepEditable) {
      nextTask.edit = true;
    }

    const nextTasks = tasks.map(t => {
      if (t.id === nextTask.id) return nextTask;
      return t;
    });

    return state.set('tasks', nextTasks);
  }

  if (action.type === 'TASK_RESERVATION_ADDED') {
    const tasks = state.get('tasks');

    const { id, reservation } = action.data;

    const nextTasks = tasks.map(t => {
      if (t.id === id) return { ...t, reservation };
      return t;
    });

    return state.set('tasks', nextTasks);
  }

  if (action.type === 'TASK_DELETED') {
    const tasks = state.get('tasks');
    const columns = state.get('columns');

    const nextColumns = { ...columns };

    const id = action.data.toString();

    const nextTasks = tasks.filter(task => task.id !== id);

    Object.keys(nextColumns).forEach(key => {
      const column = columns[key];
      column.taskIds = column.taskIds.filter(taskId => taskId !== id);
    });

    return state.set('tasks', nextTasks).set('columns', nextColumns);
  }

  if (action.type === 'SET_TASKS') {
    return state.set('tasks', action.data);
  }

  if (action.type === 'SET_COLUMNS') {
    return state.set('columns', action.data);
  }

  if (action.type === 'SET_TASKS_AND_COLUMNS') {
    return state
      .set('tasks', action.data.tasks)
      .set('columns', action.data.columns);
  }

  if (action.type === 'RESERVATION_ADDED') {
    const tasks = state.get('tasks');

    let nextTasks = tasks;
    const reservation = action.data;

    if (reservation.status.startsWith('WS_')) {
      const parts = reservation.status.split('_');

      if (parts.length > 1) {
        const taskId = parts[1];
        nextTasks = tasks.map(task => {
          if (task.id === taskId) return { ...task, reservation };
          return task;
        });
      }
    }

    return state.set('tasks', nextTasks);
  }

  if (action.type === 'RESERVATION_DELETED') {
    const tasks = state.get('tasks');

    const { id } = action.data;

    const nextTasks = tasks.map(task => {
      if (task.reservation && task.reservation.id === id)
        return { ...task, reservation: null };
      return task;
    });

    return state.set('tasks', nextTasks);
  }

  return state;
};

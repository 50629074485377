import gql from "graphql-tag";
import * as moment from "moment";
import { OrderOverview, OrderMaxiOverview } from "./fragments";

export default (client, from, to, maxi = false) => dispatch => {
  dispatch({ type: "SET_ORDERS_LOADING" });

  const ORDERS_OVERVIEW = gql`
    query getOrdersInRange($from: Date!, $to: Date!) {
      getOrdersInRange(from: $from, to: $to) {
        ...OrderOverview
      }
    }
    ${OrderOverview}
  `;

  const ORDERS_MAXI_OVERVIEW = gql`
    query getOrdersInRange(
      $from: Date!
      $to: Date!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      getOrdersInRange(from: $from, to: $to) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  const start = moment.utc(from);
  client
    .query({
      query: maxi ? ORDERS_MAXI_OVERVIEW : ORDERS_OVERVIEW,
      variables: {
        from: from.toISOString(),
        to: to.toISOString(),
        employeeFilter: { month: start.month() + 2, year: start.year() }
      }
    })
    .then(response => {
      dispatch({ type: "ORDERS_FOUND", data: response.data.getOrdersInRange });
    });
};

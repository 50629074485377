import gql from "graphql-tag";

export default client => dispatch => {
  client
    .query({
      fetchPolicy: "network-only",
      query: gql`
        query {
          getDisposHumanResource {
            id
            order_id
            humanResource {
              id
            }
            from
            to
          }
        }
      `
    })
    .then(response => {
      dispatch({
        type: "DISPOS_HR_FOUND",
        data: response.data.getDisposHumanResource
      });
    });
};

import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferAllowances(
    $id: ID!
    $allowances: String!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferAllowances(id: $id, allowances: $allowances) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, allowances) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      allowances: JSON.stringify(allowances)
    }
  });

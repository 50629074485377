import gql from 'graphql-tag';

export default (client, id) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteCompany($id: ID) {
          deleteCompany(id: $id) {
            success
          }
        }
      `,
      variables: { id }
    })
    .then(response => {
      if (response.data.deleteCompany.success === true) {
        dispatch({
          type: 'COMPANY_DELETED',
          id
        });
        window.history.pushState({}, null, '/companies');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteCompany.success
        });
      }
    });
};

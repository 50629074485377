import Immutable from 'immutable';

// function binarySearch(list, value) {
//   // initial values for start, middle and end
//   let start = 0;
//   let stop = list.length - 1;
//   let middle = Math.floor((start + stop) / 2);
//
//   // While the middle is not what we're looking for and the list does not have a single item
//   while (parseInt(list[middle].id, 10) !== value && start < stop) {
//     if (value < parseInt(list[middle].id, 10)) {
//       stop = middle - 1;
//     } else {
//       start = middle + 1;
//     }
//
//     // recalculate middle on every iteration
//     middle = Math.floor((start + stop) / 2);
//   }
//
//   // if the current middle item is what we're looking for return it's index, else return -1
//   return parseInt(list[middle].id, 10) !== value ? null : list[middle];
// }

const initialState = Immutable.Map({
  contacts: [],
  companies: [],
  chosenContact: null,
  chosenCompany: null,
  importStatus: '',
  importProgress: 0.0
});

export default (state = initialState, action) => {
  if (action.type === 'CONTACTS_FOUND') {
    return state.set('contacts', action.data);
  }
  if (action.type === 'SET_CHOSEN_CONTACT') {
    const companies = state.get('companies');
    const contact = action.data;

    let chosenCompany = null;
    if (companies && contact.company_id) {
      chosenCompany =
        companies.find(
          company => company.id === contact.company_id.toString()
        ) || null;
    } else if(contact.company) {
      chosenCompany = contact.company;
    }

    return state
      .set('chosenContact', contact)
      .set('chosenCompany', chosenCompany);
  }
  if (action.type === 'CONTACT_ADDED') {
    const contacts = state.get('contacts');
    return state
      .set('contacts', contacts.concat(action.data))
      .set('chosenContact', action.data);
  }
  if (action.type === 'CONTACT_UPDATED') {
    const contacts = state.get('contacts');

    const contactId = action.contactId.toString();

    const nextContacts = contacts.map(contact => {
      if(contact.id === contactId)
        return action.data;
      return contact;
    });

    return state
      .set('contacts', nextContacts)
      .set('chosenContact', action.data);
  }
  if (action.type === 'CONTACT_DELETED') {
    const contacts = state.get('contacts');
    const contactId = action.contactId.toString();
    const otherContacts = contacts.filter(contact => contact.id !== contactId);
    return state
      .set('contacts', [].concat(...otherContacts))
      .set('chosenContact', null);
  }
  if (action.type === 'SET_NEW_CONTACT') {
    return state.set('chosenContact', null).set('chosenCompany', null);
  }
  if (action.type === 'COMPANIES_FOUND') {
    const contacts = state.get('contacts');
    const chosenContact = state.get('chosenContact');
    const companies = action.data;

    if (contacts && contacts.length > 0) {
      const nextContacts = contacts.map(contact => {
        let company = null;
        if (contact.company_id) {
          company =
            companies.find(
              company => contact.company_id.toString() === company.id
            ) || null;
        }

        return {
          ...contact,
          company
        };
      });

      let nextChosenCompany = null;
      if (chosenContact && chosenContact.company_id) {
        nextChosenCompany =
          companies.find(
            company => company.id === chosenContact.company_id.toString()
          ) || null;
      }

      return state
        .set('companies', companies)
        .set('contacts', nextContacts)
        .set('chosenCompany', nextChosenCompany);
    }

    return state.set('companies', companies);
  }
  if (action.type === 'SET_CHOSEN_COMPANY') {
    return state.set('chosenCompany', action.data);
  }
  if (action.type === 'SET_NEW_COMPANY') {
    return state.set('chosenCompany', null);
  }
  if (action.type === 'COMPANY_ADDED') {
    const companies = state.get('companies');
    const newCompanies = action.data;
    return state
      .set('companies', [].concat(...companies, newCompanies))
      .set('newCompanies', newCompanies)
      .set('chosenCompany', action.data);
  }

  if (action.type === 'COMPANY_UPDATED') {
    const companies = state.get('companies');
    const nextCompanies = companies.map(company => {
      if (company.id === action.data.id) {
        return action.data;
      }
      return company;
    });

    return state
      .set('companies', nextCompanies)
      .set('chosenCompany', action.data);
  }

  if (action.type === 'COMPANY_DELETED') {
    const companies = state.get('companies');
    const nextCompanies = companies.filter(company => company.id !== action.id);
    return state.set('companies', nextCompanies).set('chosenCompany', null);
  }

  if (action.type === 'IMPORT_STATUS_UPDATED') {
    return state
      .set('importStatus', `${action.status}: ${action.name}`)
      .set('importProgress', action.progress);
  }

  return state;
};

import gql from "graphql-tag";
import { TransferData } from "./fragments";

export default (client, id, transfer) => dispatch => {
  console.log("updatelkfhjfköajf öskljh");
  console.log(transfer.sourceOrder);
  client
    .mutate({
      mutation: gql`
        mutation updateTransfer(
          $id: ID
          $vehicle_id: ID
          $combination_id: ID
          $human_resource_id: ID
          $source_order: String
          $source_date: String
          $source_contact: String
          $source_contact_kutter: String
          $sourceNotes: String
          $sourceLocation: String
          $sourceLat: String
          $sourceLng: String
          $additionalMachine: String
          $destination_order: String
          $destination_date: String
          $destination_contact: String
          $destination_contact_kutter: String
          $destinationNotes: String
          $destinationLocation: String
          $destinationLat: String
          $destinationLng: String
          $resources: String
        ) {
          updateTransfer(
            id: $id
            vehicle_id: $vehicle_id
            combination_id: $combination_id
            human_resource_id: $human_resource_id
            source_order: $source_order
            source_date: $source_date
            source_contact: $source_contact
            source_contact_kutter: $source_contact_kutter
            sourceNotes: $sourceNotes
            sourceLocation: $sourceLocation
            sourceLat: $sourceLat
            sourceLng: $sourceLng
            additionalMachine: $additionalMachine
            destination_order: $destination_order
            destination_date: $destination_date
            destination_contact: $destination_contact
            destination_contact_kutter: $destination_contact_kutter
            destinationNotes: $destinationNotes
            destinationLocation: $destinationLocation
            destinationLat: $destinationLat
            destinationLng: $destinationLng
            resources: $resources
          ) {
            ...TransferData
          }
        }
        ${TransferData}
      `,
      variables: {
        id,
        vehicle_id: transfer.vehicle ? transfer.vehicle.id : null,
        combination_id: transfer.combination ? transfer.combination.id : null,
        human_resource_id: transfer.humanResource.id,
        source_order: transfer.sourceOrder,
        source_date: transfer.sourceDate.toISOString(),
        source_contact: transfer.sourceContact,
        source_contact_kutter: transfer.sourceContactKutter,
        sourceNotes: transfer.sourceNotes,
        sourceLocation: transfer.sourceLocation,
        sourceLat: transfer.sourceLat,
        sourceLng: transfer.sourceLng,
        additionalMachine: transfer.additionalMachine,
        destination_order: transfer.destinationOrder,
        destination_date: transfer.destinationDate.toISOString(),
        destination_contact: transfer.destinationContact,
        destination_contact_kutter: transfer.destinationContactKutter,
        destinationNotes: transfer.destinationNotes,
        destinationLocation: transfer.destinationLocation,
        destinationLat: transfer.destinationLat,
        destinationLng: transfer.destinationLng,
        resources: transfer.resources
      }
    })
    .then(response => {
      console.log(response);
      dispatch({
        type: "TRANSFER_UPDATED",
        data: response.data.updateTransfer
      });
    });
};

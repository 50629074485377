import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { routerReducer } from 'react-router-redux';
import { persistState } from 'redux-devtools';
import thunk from 'redux-thunk';

import absences from './reducers/absences';
import combinations from './reducers/combinations';
import contacts from './reducers/contacts';
import costCenters from './reducers/costCenters';
import disposition from './reducers/disposition';
import humanResources from './reducers/humanResources';
import main from './reducers/main';
import orders from './reducers/orders';
import permissions from './reducers/permissions';
import resources from './reducers/resources';
import transfers from './reducers/transfers';
import vehicles from './reducers/vehicles';
import workshop from './reducers/workshop';

const appReducers = combineReducers({
  main,
  absences,
  combinations,
  contacts,
  costCenters,
  disposition,
  humanResources,
  orders,
  permissions,
  resources,
  transfers,
  vehicles,
  workshop,
  routing: routerReducer
});

// middleware
const logger = createLogger();
const createStoreWithMiddleware = compose(
  applyMiddleware(thunk, logger),
  persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)),
  window.devToolsExtension ? window.devToolsExtension() : f => f
)(createStore);

// store
const store = createStoreWithMiddleware(appReducers);

export default store;

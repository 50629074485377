import gql from "graphql-tag";

export default (client, dropzone) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createDropzone(
          $order_id: ID
          $company: String
          $address: String
          $contact_name: String
          $contact_phone: String
          $date: String
          $ag: Boolean
        ) {
          createDropzone(
            order_id: $order_id
            company: $company
            address: $address
            contact_name: $contact_name
            contact_phone: $contact_phone
            date: $date
            ag: $ag
          ) {
            id
            company
            address
            contactName: contact_name
            contactPhone: contact_phone
            date
            ag
            data {
              material
              contaminated
            }
          }
        }
      `,
      variables: {
        order_id: dropzone.orderId,
        company: dropzone.company,
        address: dropzone.address,
        contact_name: dropzone.contactName,
        contact_phone: dropzone.contactPhone,
        date: dropzone.date,
        ag: dropzone.ag
      }
    })
    .then(response => {
      dispatch({
        type: "DROPZONE_ADDED",
        data: response.data.createDropzone
      });
    });
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function List(props) {
  const {
    children,
    style,
    showControls,
    title,
    titleComponent,
    btnText,
    // btnClassName,
    onBtnClick,
    handleImport
  } = props;

  const year = new Date().getFullYear() - 1;


  return (
    <div className="row no-gutters list-title-bar" style={style}>
      <div className="col-auto pt-1 pr-3">
        {titleComponent || <span className="h4">{title}</span>}
      </div>
      {children || (
        <div className="col">
          {showControls ? (
            <Fragment>
              <button
                type="button"
                onClick={onBtnClick}
                className={"btn btn-primary btn-sm float-right"}>
                {btnText}
              </button>
            </Fragment>
          ) : null}
        </div>
      )}
    </div>
  );
}

List.defaultProps = {
  style: null,
  showControls: false,
  title: '',
  btnText: 'hinzufügen',
  btnClassName: 'btn btn-outline-primary btn-sm float-right',
  onBtnClick: null
};

List.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.object,
  showControls: PropTypes.bool,
  title: PropTypes.string,
  titleComponent: PropTypes.object,
  btnText: PropTypes.string,
  btnClassName: PropTypes.string,
  onBtnClick: PropTypes.func,
  handleImport: PropTypes.func
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import XLSX from "xlsx";

import gql from "graphql-tag";
import Absences from "../Widgets/absences";
import Orders from "../Widgets/orders";
import DropZone from "../HumanResources/DropZone";
import { generateProjectNumberInterval } from "../Accounting/list";

const columnMapping = {
  lfd__nr1: "Lfd. Nr",
  projekt_nr_2: "Projekt-Nr.",
  angeb_nr_: "Angeb-Nr.",
  sap_status: "Sap Status",
  adresse: "Adresse",
  datum: "Datum",
  sub__dat_: "Sub. Dat.",
  druck_summe: "Druck-Summe",
  per__nr__personalname: "Per.-Nr. Personalname",
  zu_h_nden: "Zu Händen",
  link: "",
  text: "",
  status: ""
};

const reverseColumnMapping = Object.fromEntries(
  Object.entries(columnMapping).map(entry => {
    const returnEntry = entry;
    const temp = returnEntry[0];
    returnEntry[0] = returnEntry[1];
    returnEntry[1] = temp;
    return returnEntry;
  })
);

const formatDate = dateString => {
  const parts = dateString.match(/(\d+)/g);

  const date = new Date();

  date.setFullYear(parts[2]);
  date.setMonth(parts[1] - 1);
  date.setDate(parts[0]);

  return date.toISOString().slice(0, 10);
};

const rowToData = row => {
  return Object.entries(row).reduce((acc, val) => {
    const key = reverseColumnMapping[val[0]];
    const value = {};
    if (key) {
      if (key === "datum" || key === "sub__dat_") {
        // eslint-disable-next-line no-param-reassign
        val[1] = { date: formatDate(val[1]) };
      }
      if (key === "sap_status") {
        // eslint-disable-next-line no-param-reassign
        val[1] = { label: val[1] };
      }
      if (key === "druck_summe") {
        // eslint-disable-next-line no-param-reassign
        val[1] = val[1].replace(" €", "").replace(",", "");
      }

      // eslint-disable-next-line prefer-destructuring
      value[key] = val[1];
    }
    return { ...acc, ...value };
  }, {});
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      percentage: null,
      rows: 0,
      finished: 0
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.getSerialNumbers = this.getSerialNumbers.bind(this);
  }

  async getSerialNumbers(boardId) {
    const { mondayClient } = this.props;
    const groups = await mondayClient
      .query({
        query: gql`
          query boards($boardId: [Int]) {
            boards(ids: $boardId) {
              groups {
                id
                title

                items {
                  id
                }
              }
            }
          }
        `,
        variables: {
          boardId
        },
        fetchPolicy: "network-only"
      })
      .then(resp => resp.data.boards[0].groups);

    const items = await Promise.all(
      groups.map(group =>
        Promise.all(
          group.items.map(item =>
            mondayClient
              .query({
                query: gql`
                  query items($ids: [Int]) {
                    items(ids: $ids) {
                      id
                      column_values {
                        text
                      }
                    }
                  }
                `,
                variables: {
                  ids: [parseInt(item.id, 10)]
                },
                fetchPolicy: "network-only"
              })
              .then(resp => resp.data.items)
          )
        ).then(array => array.reduce((acc, val) => [...acc, ...val], []))
      )
    );

    const serialNumbers = items
      .flat()
      .map(elem => parseInt(elem.column_values[0].text, 10));

    return [...new Set(serialNumbers)];
  }

  async handleFileUpload(files) {
    const { mondayClient } = this.props;
    const boardId = 464121804;
    const existingItems = await this.getSerialNumbers(boardId);

    const sheet = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workBook = XLSX.read(data, { type: "array" });
        resolve(
          XLSX.utils
            .sheet_to_json(workBook.Sheets[workBook.SheetNames[0]], {
              raw: false
            })
            .filter(
              row =>
                row["Sap Status"] === "C: versandt" &&
                !existingItems.includes(parseInt(row["Lfd. Nr"], 10))
            )
        );
      };
      reader.readAsArrayBuffer(files[0]);
    });

    this.setState({ rows: sheet.length });

    const addPromises = sheet.map(row => {
      const projectNumber = row["Angeb-Nr."];
      const fileopenerUrl = `fileopener:K:\\Kutter Leipzig\\Angebote - Projekte\\${generateProjectNumberInterval(
        projectNumber
      )}\\${projectNumber}\\1. Angebotsphase`;
      const itemName = row["Projekt-Bezeichnung"];
      const itemData = rowToData(row);
      itemData.ablage = fileopenerUrl;

      return mondayClient.mutate({
        mutation: gql`
          mutation create_item(
            $board_id: Int!
            $group_id: String
            $item_name: String
            $column_values: JSON
          ) {
            create_item(
              board_id: $board_id
              group_id: $group_id
              item_name: $item_name
              column_values: $column_values
            ) {
              id
            }
          }
        `,
        variables: {
          board_id: boardId,
          group_id: "topics",
          item_name: itemName,
          column_values: JSON.stringify(itemData)
        }
      });
    });

    await Promise.all(addPromises);
  }

  render() {
    const { permissions, loginName } = this.props;
    const { loading } = this.state;

    const showOfferTracking =
      Boolean(permissions.find(p => p.name === "offer_tracking" && p.read)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row mt-3 mx-1">
            <div className="col-6 mt-3">
              <Absences />
            </div>
            <div className="col-6 mt-3">
              <Orders />
              {showOfferTracking ? (
                <div className="card mt-3 w-50">
                  <div className="card-header text-center">
                    Upload Angebotsnachverfolgung
                  </div>
                  <div className="card-body wgt-body p-0">
                    <div className="small p-3">
                      <DropZone
                        accept=".xlsx, .xls, .csv"
                        onDrop={files => {
                          this.setState({ loading: true });
                          this.handleFileUpload(files)
                            .then(() => {
                              this.setState({ loading: false });
                            })
                            .catch(err => console.error(err));
                        }}>
                        {() => {
                          return (
                            <Fragment>
                              <div
                                className="border border-secondary rounded text-center text-secondary mt-2 py-5"
                                style={{
                                  border: "1px dashed"
                                }}>
                                <h2>Drop File here</h2>
                              </div>
                              {loading ? (
                                <div className="border border-success rounded mt-3">
                                  <div
                                    className="bg-success text-center text-white"
                                    style={{
                                      width: `100%`
                                    }}>
                                    lädt
                                  </div>
                                </div>
                              ) : null}
                            </Fragment>
                          );
                        }}
                      </DropZone>
                      <button
                        className="btn btn-success mt-3 mb-3 float-right"
                        onClick={() => {
                          window.location =
                            "https://kutter.monday.com/boards/464121804";
                        }}>
                        Zu den Angeboten
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  mondayClient: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  mondayClient: state.main.get("mondayClient"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);

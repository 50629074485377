import gql from "graphql-tag";
import { TransferDataWithEmployeeHours } from "./fragments";

const QUERY = gql`
  mutation deleteTransferAllowance(
    $id: ID!
    $allowance: String!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    deleteTransferAllowance(id: $id, allowance: $allowance) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, allowance) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      allowance
    }
  });

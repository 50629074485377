import gql from 'graphql-tag';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription deleteOrder($user: String!) {
          deleteOrder(user: $user) {
            id
          }
        }
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { deleteOrder } = data;
        dispatch({
          type: 'ORDER_DELETED',
          orderId: deleteOrder.id
        });
      }
    });
};

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GET_MODULES, GET_NEWS, SET_NEWS_READ } from './queries';

import './style.css';
import Card from './card';
import LoadingIndicator from '../LoadingIndicator/cogs';

export default class MessageCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      news: [],
      loaded: false,
      loading: false,
      expandedId: '',
      filter: '',
      module: null,
      modules: []
    };

    this.request = this.request.bind(this);
    this.loadNews = this.loadNews.bind(this);
    this.handleRead = this.handleRead.bind(this);

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.closeLargeView = this.closeLargeView.bind(this);

    this.handleExpand = this.handleExpand.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);
  }

  componentDidMount() {
    const { user, isLargeView } = this.props;

    if (user !== '') {
      this.loadNews(isLargeView);
    }

    this.request(GET_MODULES).then(data => {
      if (data) {
        const { modules } = data.data;
        if (modules) {
          this.setState({ modules });
        }
      }
    }).catch(err => console.error(err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, isLargeView } = this.props;
    const { loaded, loading } = this.state;

    if (prevProps.isLargeView !== isLargeView) {
      this.loadNews(isLargeView);
      this.setState({ news: [], expandedId: '' });
    } else if (user !== '' && !loaded && !loading) {
      this.loadNews(isLargeView);
    }
  }

  request(query, variables = null) {
    const { url } = this.props;

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        operationName: null,
        query,
        variables
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(responseData => {
        if (responseData.errors) {
          console.warn(responseData.errors);
          return null;
        }
        if (responseData.data) {
          return responseData;
        }
        return null;
      });
  }

  loadNews(showReadContents = false) {
    const { user } = this.props;

    this.setState({ loading: true });

    this.request(GET_NEWS, {
      where: {
        AND: [{ status: 'PUBLISHED' }]
      },
      first: null,
      skip: 0,
      orderBy: null
    }).then(data => {
      if (data) {
        let { contents } = data.data;
        if (!showReadContents) {
          contents = contents.filter(
            content =>
              content.readContents.filter(rc => rc.userId === user).length === 0
          );
        }
        this.setState({
          news: contents,
          loaded: true,
          loading: false
        });
      }
    }).catch(err => console.error(err));
  }

  handleRead(data) {
    const { user } = this.props;

    const variables = {
      data: {
        userId: user,
        status: data.status,
        content: { connect: { id: data.id } }
      }
    };

    this.request(SET_NEWS_READ, variables).then(data => {
      if (data) {
        const { news } = this.state;
        this.setState({ news: news.filter(elem => elem.id !== data.data.createReadContent.content.id) });
      }
    });
  }

  handleOutsideClick(e) {
    if (e.target === e.currentTarget) this.closeLargeView();
  }

  closeLargeView() {
    const { dispatch } = this.props;

    dispatch({ type: 'SHOW_DOCUMENTATION', data: false });
  }

  handleExpand(id) {
    const { expandedId } = this.state;
    this.setState({ expandedId: expandedId === id ? '' : id });
  }

  handleFilterChange(e) {
    const { value } = e.currentTarget;

    this.setState({ filter: value });
  }

  handleModuleChange(value) {
    this.setState({ module: value });
  }

  render() {
    const { path, isLargeView } = this.props;
    const { news, loading, expandedId, filter, module, modules } = this.state;

    const name = path.substr(1);

    let filteredNews = news;

    if (isLargeView) {
      if (module) {
        filteredNews = news.filter(elem =>
          elem.modules.find(m => m.clearName === module.clearName)
        );
      }
    } else {
      filteredNews = news.filter(elem =>
        elem.modules.find(m => m.clearName === name)
      );
    }

    if (filter && filter.length > 2) {
      const regex = new RegExp(filter.toUpperCase(), 'gmi');

      const counts = [];
      filteredNews = filteredNews
        .map(n => {
          const {
            headline,
            description: { html }
          } = n;
          let count = 0;

          const newHeadline = headline.replace(regex, match => {
            count += 1;
            return `<span class="bg-warning rounded">${match}</span>`;
          });

          const newHtml = html.replace(regex, match => {
            count += 1;
            return `<span class="bg-warning rounded">${match}</span>`;
          });

          counts.push(count);
          return {
            ...n,
            headline: newHeadline,
            description: { html: newHtml }
          };
        })
        .filter((n, i) => counts[i] > 0);
    }

    // TODO: Prevent read and expand buttons in large view.

    let component = (
      <div className="mc-wrapper">
        <div className="mc-container">
          {filteredNews.map(elem => (
            <Card
              key={elem.id}
              data={elem}
              expanded={expandedId === elem.id || isLargeView}
              showButtons={!isLargeView}
              onExpand={this.handleExpand}
              onRead={this.handleRead}
            />
          ))}
        </div>
      </div>
    );

    if (isLargeView) {
      component = (
        <div className="mc-bg" onClick={this.handleOutsideClick}>
          <div className="mc-filter-container">
            <div className="row">
              <div className="col">
                <Select
                  isClearable={true}
                  placeholder="Modul..."
                  getOptionValue={o => o.id}
                  getOptionLabel={o => o.name}
                  noOptionsMessage={() => 'keine Übereinstimmung'}
                  value={module}
                  options={modules}
                  onChange={this.handleModuleChange}
                />
              </div>

              <div className="col">
                <input
                  type="text"
                  placeholder="Suchen..."
                  className="form-control"
                  value={filter}
                  onChange={this.handleFilterChange}
                />
              </div>

              <div className="col-auto pl-0">
                <button
                  type="button"
                  className="btn btn-lg py-1"
                  onClick={this.closeLargeView}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>

            {loading ? (
              <div className="d-flex justify-content-center">
                <LoadingIndicator className="mt-2" />
              </div>
            ) : null}
          </div>
          {component}
        </div>
      );
    }

    if (news) {
      return ReactDOM.createPortal(component, document.body);
    }
    return null;
  }
}

MessageCards.propTypes = {
  dispatch: PropTypes.func,
  url: PropTypes.string,
  authorization: PropTypes.string,
  path: PropTypes.string,
  user: PropTypes.string,
  isLargeView: PropTypes.bool
};

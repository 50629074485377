import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { toDateString, toTimeString } from "../../util/dateHelper";
import { filterBy, flattenObject } from "../../util/filterBy";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";

import setChosenTransfer from "../../actions/setChosenTransfer";

class List extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      filter: "",
      sortKey: "sourceDate",
      reverseSort: true
    };

    this.state = this.initState;

    this.listMapping = [
      { key: "vehicle", label: "Umsetzer", alternateKey: "combination" },
      { key: "humanResource", label: "Mitarbeiter" },
      { key: "resources", label: "Maschinen" },
      { key: "sourceOrder", label: "Von Baustelle" },
      { key: "sourceDate", label: "Abholdatum" },
      { key: "destinationOrder", label: "Zur Baustelle" },
      { key: "destinationDate", label: "Lieferdatum" }
    ];

    const getAddress = order => {
      if (order.building_address && order.building_address !== "") {
        if (order.lat !== "" && order.lng !== "")
          if (order.building_address)
            return `In der Nähe von ${order.building_address}`;
          else return `${order.lat} ${order.lng}`;
        return order.building_address;
      } else {
        return order.costCenterAddress;
      }
    };

    this.dataRenderers = {
      vehicle: elem => {
        if (!elem) return null;
        return elem.description
          ? `${elem.description} ${elem.establishment}`
          : `${elem.brand} ${elem.type} / ${elem.licensePlate}`;
      },
      humanResource: elem => `${elem.firstname} ${elem.lastname}`,
      resources: elem =>
        elem
          .map(r => `${r.name} / ${r.number ? r.number : "Sonstige"}`)
          .join(", "),
      sourceOrder: elem => {
        if (elem.id === "__new__") return elem.label;
        const address = getAddress(elem);
        return (
          <Fragment>
            <a
              href={
                (elem.type === "COST_CENTER" ? "/cost_center/" : "/order/")+ elem.id
              }
            >
              #{elem.id}
            </a>
            <br />
            <span style={address !== "" && address ? {} : { color: "#D30132" }}>
              {address !== "" && address ? address : "Keine Addresse vorhanden"}
            </span>
          </Fragment>
        );
      },
      sourceDate: elem => {
        const date = new Date(elem);
        return `${toDateString(date)} - ${toTimeString(date)} Uhr`;
      },
      destinationOrder: elem => {
        if (elem.id === "__new__") return elem.label;
        const address = getAddress(elem);
        return (
          <Fragment>
            <a
              href={
                (elem.type === "COST_CENTER" ? "/cost_center/" : "/order/") +
                elem.id
              }
            >
              #{elem.id}
            </a>
            <br />
            <span style={address !== "" && address ? {} : { color: "#D30132" }}>
              {address !== "" && address ? address : "Keine Addresse vorhanden"}
            </span>
          </Fragment>
        );
      },
      destinationDate: elem => {
        const date = new Date(elem);
        return `${toDateString(date)} - ${toTimeString(date)} Uhr`;
      }
    };

    this.setChosenTransfer = this.setChosenTransfer.bind(this);
    this.setNewTransfer = this.setNewTransfer.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
  }

  static filter(data, filterString) {
    return filterBy(
      data,
      [
        "costCenter.id",
        "date",
        "vehicle.type",
        "vehicle.brand",
        "vehicle.licensePlate",
        "combination.description",
        "combination.establishment",
        "humanResource.firstname",
        "humanResource.lastname",
        "resource.name",
        "resource.number",
        "sourceOrder.id",
        "sourceOrder.building_address",
        "sourceOrder.label",
        "destinationOrder.id",
        "destinationOrder.building_address",
        "destinationOrder.label"
      ],
      filterString,
      flattenObject
    );
  }

  setChosenTransfer(transfer) {
    const { dispatch, transfers } = this.props;
    dispatch(
      setChosenTransfer(transfers.find(trans => trans.id === transfer.id))
    );
    window.history.pushState({}, null, `/transfers/${transfer.id}`);
  }

  setNewTransfer() {
    this.props.dispatch(setChosenTransfer(null));
    window.history.pushState({}, null, "/transfers");
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  render() {
    let { transfers } = this.props;
    const { className, transfer, write } = this.props;
    const { filter, sortKey, reverseSort } = this.state;

    transfers = List.filter(transfers, filter);

    const selected = transfer ? ["id", transfer.id] : null;

    // data manipulation for table... just add the needed values
    if (transfers) {
      transfers = transfers.map(t => {
        if (
          t.sourceOrder &&
          t.sourceOrder.type !== "__new__" &&
          (!t.sourceOrder.building_address ||
            t.sourceOrder.building_address === "")
        ) {
          t.sourceOrder["costCenterAddress"] = t.sourceLocation;
          t.sourceOrder.type = "COST_CENTER";
          return t;
        }
        if (
          t.destinationOrder &&
          t.destinationOrder.type !== "__new__" &&
          (!t.destinationOrder.building_address ||
            t.destinationOrder.building_address === "")
        ) {
          t.destinationOrder["costCenterAddress"] = t.sourceLocation;
          t.destinationOrder.type = "COST_CENTER";
          return t;
        }
        return t;
      });
    }

    return (
      <Fragment>
        <div className={className}>
          <TitleBar
            title="Transporte"
            onBtnClick={this.setNewTransfer}
            showControls={write}
          />

          <input
            className="form-control mt-3"
            placeholder="Filter..."
            value={filter}
            onChange={this.setFilter}
          />

          <SimpleTable
            className="mt-3"
            mapping={this.listMapping}
            selected={selected}
            sortKey={sortKey}
            reverseSort={reverseSort}
            data={transfers}
            dataRenderers={this.dataRenderers}
            onRowSelected={this.setChosenTransfer}
            onColSelected={this.setSortKey}
          />
        </div>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  className: PropTypes.string,
  establishment: PropTypes.object,
  transfer: PropTypes.object,
  transfers: PropTypes.array,
  write: PropTypes.bool
};

export default List;

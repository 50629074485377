import gql from 'graphql-tag';

export default (client, id, userId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteAbsence($id: ID) {
          deleteAbsence(id: $id) {
            success
          }
        }
      `,
      variables: { id }
    })
    .then(response => {
      if (response.data.deleteAbsence.success === true) {
        dispatch({
          type: 'ABSENCE_DELETED',
          id,
          userId
        });
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteAbsence.success
        });
      }
    });
};

import gql from 'graphql-tag';
import getLoginName from '../getLoginName';

export default (client, orderId, costCenterId) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  const query = gql`
    mutation updateOrder(
      $requestUser: String!
      $orderId: ID
      $costCenterId: ID
      $start_date_time: String
      $start_meeting_time: String
      $operational_period_start: String
      $operational_period_finish: String
      $description: String
      $type: Int
    ) {
      updateOrder(
        requestUser: $requestUser
        id: $orderId
        cost_center_id: $costCenterId
        start_date_time: $start_date_time
        start_meeting_time: $start_meeting_time
        operational_period_start: $operational_period_start
        operational_period_finish: $operational_period_finish
        description: $description
        type: $type
      ) {
        id
      }
    }
  `;
  client
    .mutate({
      mutation: query,
      variables: {
        requestUser,
        orderId,
        costCenterId
      }
    })
    .then(response => {});
};

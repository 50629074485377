import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';

export default (client, id) => dispatch => {
  whenModified(client, 'contact', () =>
    client
      .query({
        query: gql`
          query {
            getContacts {
              id
              salutation
              lastname
              firstname
              phone
              email
              fax
              mobile
              position
              notice
              company {
                id
                name
              }
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({ type: 'CONTACTS_FOUND', data: response.data.getContacts });
        if (id !== undefined && id !== '') {
          const contact = response.data.getContacts.find(obj => obj.id === id);
          if (contact !== undefined) {
            dispatch({ type: 'SET_CHOSEN_CONTACT', data: contact });
          }
        }
      })
  );
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import TransporterInput from "./transporterInput";

import createTransporter from "../../../actions/createTransporter";
import updateTransporterData from "../../../actions/updateTransporterData";
import deleteTransporter from "../../../actions/deleteTransporter";
import updateTransporter from "../../../actions/updateTransporter";
import ButtonCombination from "../../../util/ButtonCombination";

export default class Transporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      vehicle: "Sattelzug",
      time: "",
      amountTransfer: 0,
      amountTransferUnit: "€/T",
      editIndex: -1,
      editAmount: null,
      editVehicle: null,
      editTime: null,
      editAmountTransfer: null,
      editAmountTransferUnit: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.addData = this.addData.bind(this);
    this.addEditData = this.addEditData.bind(this);
    this.removeData = this.removeData.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAmountUnitChange = this.handleAmountUnitChange.bind(this);
    this.handleEditAmountUnitChange = this.handleEditAmountUnitChange.bind(
      this
    );
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.enableEdit = this.enableEdit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "amount") {
      if (!value.match(/^\d*$/)) return;
    }
    this.setState({ [name]: value });
  }

  addData() {
    const { client, dispatch, transporter } = this.props;
    const {
      amount,
      vehicle,
      time,
      amountTransfer,
      amountTransferUnit
    } = this.state;
    if (amount !== "" && time && time !== "") {
      const nextData = transporter.data.concat({
        amount,
        vehicle,
        time,
        amountTransfer,
        amountTransferUnit
      });
      dispatch(updateTransporterData(client, transporter.id, nextData));
      this.setState({
        amount: "",
        time: "",
        amountTransfer: 0,
        amountTransferUnit: "€/T",
        vehicle: "Sattelzug"
      });
    }
  }

  addEditData() {
    const { client, dispatch, transporter } = this.props;
    const {
      editIndex,
      editAmount,
      editVehicle,
      editTime,
      editAmountTransfer,
      editAmountTransferUnit
    } = this.state;

    if (transporter.data.length >= editIndex) {
      let nextData = transporter.data;
      const {
        amount,
        vehicle,
        time,
        amountTransfer,
        amountTransferUnit
      } = nextData[editIndex];
      const newAmount = editAmount ? editAmount : amount;
      const newVehicle = editVehicle ? editVehicle : vehicle;
      const newTime = editTime ? editTime : time;
      const newAmountTransfer = editAmountTransfer
        ? editAmountTransfer
        : amountTransfer;
      const newAmountTransferUnit = editAmountTransferUnit
        ? editAmountTransferUnit
        : amountTransferUnit;
      nextData[editIndex] = {
        amount: newAmount,
        vehicle: newVehicle,
        time: newTime,
        amountTransfer: newAmountTransfer,
        amountTransferUnit: newAmountTransferUnit
      };
      dispatch(updateTransporterData(client, transporter.id, nextData));
      this.setState({
        editIndex: -1,
        editAmount: null,
        editVehicle: null,
        editTime: null,
        editAmountTransfer: null,
        editAmountTransferUnit: null
      });
    }
  }

  enableEdit(index) {
    this.setState({
      editIndex: index
    });
  }

  handleEdit() {
    this.addEditData();
    this.setState({
      editIndex: -1
    });
  }

  handleEditCancel() {
    this.setState({
      editIndex: -1,
      editAmount: null,
      editVehicle: null,
      editTime: null,
      editAmountTransfer: null,
      editAmountTransferUnit: null
    });
  }

  removeData(index) {
    const { client, dispatch, transporter } = this.props;
    const nextData = transporter.data.filter((elem, i) => i !== index);
    dispatch(updateTransporterData(client, transporter.id, nextData));
  }

  handleCreate(data) {
    const { client, dispatch, orderId } = this.props;

    data.orderId = parseInt(orderId, 10);
    dispatch(createTransporter(client, data));
  }

  handleUpdate(id, data) {
    const { client, dispatch, orderId } = this.props;

    data.orderId = parseInt(orderId, 10);
    dispatch(updateTransporter(client, id, data));
  }

  handleRemove(id) {
    const { client, dispatch } = this.props;
    dispatch(deleteTransporter(client, id));
  }

  handleAmountUnitChange(e) {
    this.setState({ amountTransferUnit: e.target.dataset.unit });
  }

  handleEditAmountUnitChange(e) {
    this.setState({ editAmountTransferUnit: e.target.dataset.unit });
  }

  handleQuantityChange(e) {
    this.setState({
      amountTransfer: e.target.value
    });
  }

  render() {
    const { client, dispatch, transporter, disabled } = this.props;
    const {
      amount,
      vehicle,
      time,
      amountTransfer,
      amountTransferUnit,
      editIndex,
      editAmount,
      editVehicle,
      editTime,
      editAmountTransfer,
      editAmountTransferUnit
    } = this.state;
    const { data, isNew } = transporter;

    return (
      <div className="col-12 mt-3">
        <div className="row small border-bottom">
          <TransporterInput
            client={client}
            dispatch={dispatch}
            data={transporter}
            disabled={disabled}
            onCreate={this.handleCreate}
            onUpdate={this.handleUpdate}
            onRemove={this.handleRemove}
          />
        </div>

        {!isNew ? (
          <div className="row small bg-white border-bottom pt-3">
            {data.map((elem, index) => (
              <Fragment key={index}>
                <div className="form-group col-1">
                  <label>Anzahl</label>
                  <input
                    type="text"
                    className="form-control"
                    name="editAmount"
                    onChange={this.handleInputChange}
                    value={
                      editIndex === index && editAmount
                        ? editAmount
                        : elem.amount
                    }
                    disabled={editIndex !== index}
                  />
                </div>

                <div className="form-group col-3">
                  <label>Fahrzeugart</label>
                  {editIndex === index ? (
                    <select
                      className="form-control"
                      name="editVehicle"
                      value={editVehicle ? editVehicle : elem.vehicle}
                      onChange={this.handleInputChange}
                    >
                      <option value="Sattelzug">Sattelzug</option>
                      <option value="2-Achs-LKW">2-Achs-LKW</option>
                      <option value="2-Achs-LKW mit Hänger">
                        2-Achs-LKW mit Hänger
                      </option>
                      <option value="3-Achs-LKW">3-Achs-LKW</option>
                      <option value="3-Achs-LKW mit Hänger">
                        3-Achs-LKW mit Hänger
                      </option>
                      <option value="4-Achs-LKW">4-Achs-LKW</option>
                      <option value="Selbstlader">Selbstlader</option>
                      <option value="Container LKW">Container LKW</option>
                      <option value="Allrad-Sattelzug">Allrad-Sattelzug</option>
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      value={elem.vehicle}
                      disabled
                    />
                  )}
                </div>

                <div className="form-group col-3">
                  <div>
                    <label>
                      Gebühren ( in&nbsp;
                      <span
                        className={`badge badge-${
                          editIndex === index && editAmountTransferUnit
                            ? editAmountTransferUnit === "€/T"
                              ? "primary"
                              : "secondary"
                            : elem.amountTransferUnit === "€/T"
                            ? "primary"
                            : "secondary"
                        }`}
                        data-unit="€/T"
                        onClick={e => this.handleEditAmountUnitChange(e)}
                      >
                        €/T
                      </span>
                      &nbsp;/&nbsp;
                      <span
                        className={`badge badge-${
                          editIndex === index && editAmountTransferUnit
                            ? editAmountTransferUnit === "€/h"
                              ? "primary"
                              : "secondary"
                            : elem.amountTransferUnit === "€/h"
                            ? "primary"
                            : "secondary"
                        }`}
                        data-unit="€/h"
                        onClick={e => this.handleEditAmountUnitChange(e)}
                      >
                        €/h
                      </span>
                      )
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="number"
                          className="form-control"
                          name="editAmountTransfer"
                          value={
                            editIndex === index && editAmountTransfer
                              ? editAmountTransfer
                              : elem.amountTransfer
                          }
                          onChange={this.handleInputChange}
                          min="0"
                          disabled={index !== editIndex}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-3">
                  <label>Stellzeit</label>
                  <input
                    type="time"
                    className="form-control"
                    name="editTime"
                    value={
                      editTime && editIndex === index ? editTime : elem.time
                    }
                    onChange={this.handleInputChange}
                    disabled={editIndex !== index}
                  />
                </div>

                <div className="form-group col-2 d-flex align-items-end justify-content-end">
                  <ButtonCombination
                    handleEdit={() => this.handleEdit()}
                    enableEdit={() => this.enableEdit(index)}
                    handleDelete={() => this.removeData(index)}
                    handleEditCancel={() => this.handleEditCancel()}
                    handleDeleteCancel={() => this.handleEditCancel()}
                  />
                </div>
              </Fragment>
            ))}

            {disabled ? null : (
              <Fragment>
                <div className="form-group col-1">
                  <label>Anzahl</label>
                  <input
                    type="text"
                    className="form-control"
                    name="amount"
                    value={amount}
                    onChange={this.handleInputChange}
                    disabled={disabled}
                  />
                </div>

                <div className="form-group col-3">
                  <label>Fahrzeugart</label>
                  <select
                    className="form-control"
                    name="vehicle"
                    value={vehicle}
                    onChange={this.handleInputChange}
                    disabled={disabled}
                  >
                    <option value="Sattelzug">Sattelzug</option>
                    <option value="2-Achs-LKW">2-Achs-LKW</option>
                    <option value="2-Achs-LKW mit Hänger">
                      2-Achs-LKW mit Hänger
                    </option>
                    <option value="3-Achs-LKW">3-Achs-LKW</option>
                    <option value="3-Achs-LKW mit Hänger">
                      3-Achs-LKW mit Hänger
                    </option>
                    <option value="4-Achs-LKW">4-Achs-LKW</option>
                    <option value="Selbstlader">Selbstlader</option>
                    <option value="Container LKW">Container LKW</option>
                    <option value="Allrad-Sattelzug">Allrad-Sattelzug</option>
                  </select>
                </div>

                <div className="form-group col-3">
                  <div>
                    <label>
                      Gebühren ( in&nbsp;
                      <span
                        className={`badge badge-${
                          amountTransferUnit === "€/T" ? "primary" : "secondary"
                        }`}
                        data-unit="€/T"
                        onClick={e => this.handleAmountUnitChange(e)}
                      >
                        €/T
                      </span>
                      &nbsp;/&nbsp;
                      <span
                        className={`badge badge-${
                          amountTransferUnit === "€/h" ? "primary" : "secondary"
                        }`}
                        data-unit="€/h"
                        onClick={e => this.handleAmountUnitChange(e)}
                      >
                        €/h
                      </span>
                      )
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="number"
                          className="form-control"
                          name="amount"
                          value={amountTransfer}
                          min="0"
                          onChange={e => this.handleQuantityChange(e)}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-3">
                  <label>Stellzeit</label>
                  <input
                    type="time"
                    className="form-control"
                    name="time"
                    value={time}
                    onChange={this.handleInputChange}
                    disabled={disabled}
                  />
                </div>

                <div className="form-group col-2 d-flex align-items-end justify-content-end">
                  <button
                    className="btn btn-secondary btn-square form-control"
                    onClick={this.addData}
                    disabled={disabled}
                  >
                    Ok
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

Transporter.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  orderId: PropTypes.string,
  transporter: PropTypes.object,
  disabled: PropTypes.bool,
  onAddData: PropTypes.func
};

import gql from "graphql-tag";
import getLoginName from "../getLoginName";
import createDispoHumanResourceResource from "./createDispoHumanResourceResource";
import getOrder from "./getOrder";

export default (
  client,
  orderId,
  humanResources,
  resources,
  startDate,
  endDate
) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem("klout"));
  const requestUser = getLoginName(localStorageItems);
  const createDispoHumanResource = gql`
    mutation createDispoHumanResource(
      $requestUser: String!
      $order_id: ID
      $human_resource_id: ID
      $from: String
      $to: String
      $note: String
    ) {
      createDispoHumanResource(
        requestUser: $requestUser
        order_id: $order_id
        human_resource_id: $human_resource_id
        from: $from
        to: $to
        note: $note
      ) {
        id
        humanResource {
          id
          lastname
          firstname
        }
        from
        to
      }
    }
  `;
  humanResources.forEach(humanResource => {
    client
      .mutate({
        mutation: createDispoHumanResource,
        variables: {
          requestUser,
          order_id: orderId,
          human_resource_id: humanResource.id
            ? humanResource.id
            : humanResource.humanResource.id,
          from: humanResource.from ? humanResource.from : startDate,
          to: humanResource.to ? humanResource.to : endDate,
          note: humanResource.note
        }
      })
      .then(response => {
        dispatch({
          type: "ADDED_HUMAN_RESOURCE_TO_ORDER",
          data: response.data.createDispoHumanResource
        });
        resources
          .forEach(r => {
            if (r.humanResources) {
              r.humanResources.forEach(hr => {
                if (hr.id === humanResource.id) {
                  dispatch(
                    createDispoHumanResourceResource(
                      client,
                      response.data.createDispoHumanResource.id,
                      r.id,
                      orderId
                    )
                  );
                }
              });
            }
          })

      });
  });
};

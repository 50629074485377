import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';

export default (client, id) => dispatch => {
  whenModified(client, 'vehicle', () =>
    client
      .query({
        query: gql`
          query {
            getVehicles {
              id
              brand
              type
              licensePlate
              image
              establishment
              category
              comment
              implementation
              number
              status
              leavingDate
              humanResources {
                id
                lastname
                firstname
              }
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({ type: 'VEHICLES_FOUND', data: response.data.getVehicles });
        if (id !== undefined && id !== '') {
          const vehicle = response.data.getVehicles.find(obj => obj.id === id);
          if (vehicle !== undefined) {
            dispatch({ type: 'SET_CHOSEN_VEHICLE', data: vehicle });
          }
        }
      })
  );
};

/*
    Expects an object with a name and additional attributes to generate a contact object.
 */
export default function prepareContact(data) {
  const name = data.name.trim();
  const index = name.lastIndexOf(' ');

  // Split the string into first- and lastname.
  let firstname = name.substr(0, index);
  let lastname = name.substr(index + 1);
  let salutation = null;

  // Test if one of the strings contains a salutation of some sort.
  if (firstname.startsWith('Herr ')) {
    salutation = 'Herr';
    firstname = firstname.substr(5);
  } else if (firstname.startsWith('Hr.')) {
    salutation = 'Herr';
    firstname = firstname.substr(3).trim();
  } else if (firstname.startsWith('Frau ')) {
    salutation = 'Frau';
    firstname = firstname.substr(5);
  } else if (firstname.startsWith('Fr.')) {
    salutation = 'Frau';
    firstname = firstname.substr(3).trim();
  }

  if (lastname.startsWith('Herr ')) {
    salutation = 'Herr';
    lastname = lastname.substr(5);
  } else if (lastname.startsWith('Hr.')) {
    salutation = 'Herr';
    lastname = lastname.substr(3).trim();
  } else if (lastname.startsWith('Frau ')) {
    salutation = 'Frau';
    lastname = lastname.substr(5);
  } else if (lastname.startsWith('Fr.')) {
    salutation = 'Frau';
    lastname = lastname.substr(3).trim();
  }

  return {
    salutation,
    lastname,
    firstname,
    phone: '',
    position: data.position,
    mobile: data.phone,
    email: '',
    fax: ''
  };
}

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CreatableSelect from "react-select/lib/Creatable";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import prepareContact from "../../../util/prepareContact";
import createCompanyAndContacts from "../../../actions/createCompanyAndContacts";
import createContactWithCompanyName from "../../../actions/createContactWithCompanyName";
import { faTrash, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DropzoneInput extends Component {
  constructor(props) {
    super(props);
    const { data } = props;

    this.state = {
      ...DropzoneInput.getState(data),
      deleteMode: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.loadOptions = this.loadOptions.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);

    this.create = this.create.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    this.getContacts(data.company);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.setState(DropzoneInput.getState(data));
    }
  }

  static getState(data) {
    return {
      company: {
        label: data.company,
        value: data.company
      },
      address: data.address || "",
      contact: {
        label: data.contactName,
        value: data.contactName,
        mobile: data.contactPhone
      },
      contactPhone: data.contactPhone || "",
      date: data.date,
      contacts: [{ label: data.contactName, value: data.contactName }],
      isAltered: false,
      ag: data.ag
    };
  }

  static isValidNewCompanyOption(inputValue, selectValue, selectOptions) {
    if (inputValue !== "") {
      const testValue = inputValue.toUpperCase();
      for (let i = 0; i < selectOptions.length; i += 1)
        if (
          selectOptions[i].label
            .toUpperCase()
            .trim()
            .localeCompare(testValue) === 0
        )
          return false;
      return true;
    }

    return false;
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, isAltered: true });
  }

  handleCheck(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked, isAltered: true });
  }

  loadOptions(inputValue) {
    return this.props.client
      .query({
        query: gql`
          query getCompaniesByName($name: String) {
            getCompanies(name: $name) {
              id
              name
              adress
            }
          }
        `,
        variables: {
          name: inputValue
        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        const data = response.data.getCompanies;
        return data.map(d => ({
          ...d,
          value: d.id,
          label: d.name
        }));
      });
  }

  getContacts(customer) {
    this.props.client
      .query({
        query: gql`
          query getContactsByCompanyName($name: String) {
            getContactsByCompanyName(name: $name) {
              id
              lastname
              firstname
              position
              mobile
            }
          }
        `,
        variables: {
          name: customer
        }
      })
      .then(response => {
        const { contacts } = this.state;

        const companyContacts = response.data.getContactsByCompanyName.map(
          contact => {
            let label =
              contact.firstname !== ""
                ? `${contact.firstname} ${contact.lastname}`
                : contact.lastname;
            if (contact.firstname === "" && contact.lastname === "")
              label = contact.position;
            return {
              value: contact.id,
              label,
              mobile: contact.mobile
            };
          }
        );

        const nextContacts = companyContacts.concat(
          contacts.filter(contact => contact.__isNew__)
        );

        this.setState({ contacts: nextContacts });
      });
  }

  handleCompanyChange(option, actionMeta) {
    if (actionMeta.action !== "create-option" && option && option.id) {
      this.props.client
        .query({
          query: gql`
            query getContactsByCompany($id: ID) {
              getContacts(id: $id) {
                id
                lastname
                firstname
                position
                mobile
                phone
              }
            }
          `,
          variables: {
            id: option.id
          }
        })
        .then(response => {
          const { contacts } = this.state;

          const companyContacts = response.data.getContacts.map(contact => {
            let label =
              contact.firstname !== ""
                ? `${contact.firstname} ${contact.lastname}`
                : contact.lastname;
            if (contact.firstname === "" && contact.lastname === "")
              label = contact.position;
            return {
              value: contact.id,
              label,
              mobile: contact.mobile,
              phone: contact.phone
            };
          });

          const nextContacts = companyContacts.concat(
            contacts.filter(contact => contact.__isNew__)
          );

          this.setState({ contacts: nextContacts });
        });
    }

    if (option && option.adress !== "")
      this.setState({ address: option.adress });
    this.setState({ company: option, isAltered: true });
  }

  handleContactChange(value, actionMeta) {
    const { contacts } = this.state;

    if (actionMeta.action === "create-option")
      this.setState({ contacts: [...contacts, value] });
    else if (value) {
      const contactPhone = value.mobile !== "" ? value.mobile : value.phone;
      if (contactPhone) this.setState({ contactPhone });
    }

    this.setState({ contact: value, isAltered: true });
  }

  create() {
    const { client, dispatch, data, onCreate, onUpdate } = this.props;
    const {
      company,
      address,
      contact,
      contactPhone,
      date,
      isAltered,
      ag
    } = this.state;

    if (!company || !contact) return;

    const dropzone = {
      orderId: null,
      company: company.label,
      address,
      contactName: contact.label,
      contactPhone,
      date,
      ag
    };

    const contacts = [];
    if (contact.__isNew__) {
      contacts.push(
        prepareContact({
          name: contact.label,
          position: "",
          phone: contactPhone
        })
      );
    }

    if (company.__isNew__) {
      const companyObj = {
        name: company.label,
        adress: address,
        zipcode: "",
        city: "",
        phone: "",
        fax: "",
        email: ""
      };

      dispatch(createCompanyAndContacts(client, companyObj, contacts));
    } else {
      contacts.forEach(elem =>
        dispatch(
          createContactWithCompanyName(
            client,
            elem.salutation,
            elem.lastname,
            elem.firstname,
            elem.phone,
            elem.position,
            elem.mobile,
            elem.email,
            elem.fax,
            company.label
          )
        )
      );
    }

    if (!data.isNew && isAltered) onUpdate(data.id, dropzone);
    else onCreate(dropzone);
  }

  remove() {
    const { data, onRemove } = this.props;
    onRemove(data.id);
  }

  render() {
    const { data, disabled } = this.props;
    const {
      company,
      address,
      contact,
      contactPhone,
      date,
      contacts,
      isAltered,
      ag
    } = this.state;
    const { isNew } = data;

    return (
      <Fragment>
        <div className="form-group col-3">
          <label>Kippstelle</label>
          <AsyncCreatableSelect
            isClearable
            defaultOptions
            isDisabled={disabled}
            loadOptions={this.loadOptions}
            formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
            value={company}
            isValidNewOption={DropzoneInput.isValidNewCompanyOption}
            onChange={this.handleCompanyChange}
          />
        </div>

        <div className="form-group col-8">
          <label>Adresse</label>
          <input
            type="text"
            className="form-control"
            name="address"
            value={address}
            onChange={this.handleInputChange}
            disabled={disabled}
          />
        </div>

        <div className="form-group col-1 d-flex align-items-end justify-content-end">
          {isNew || isAltered ? (
            <button
              className="btn btn-success btn-square form-control"
              onClick={this.create}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          ) : (
            <div className="d-flex">
              <button
                className="btn btn-danger btn-square form-control"
                onClick={this.remove}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          )}
        </div>

        <div className="form-group col-3">
          <label>&nbsp;</label>
          <div className="form-check">
            <label className="form-check-label" htmlFor="ag">
              Über AG
            </label>
            <input
              type="checkbox"
              className="ml-2 form-check-inline"
              id="ag"
              name="ag"
              checked={ag}
              onChange={this.handleCheck}
            />
          </div>
        </div>

        <div className="form-group col-3">
          <label>Ansprechpartner</label>
          <CreatableSelect
            isClearable
            isDisabled={disabled}
            placeholder=""
            noOptionsMessage={() => "Name Eingeben"}
            noResultsMessage={() => "Keine Übereinstimmung"}
            value={contact}
            options={contacts}
            formatCreateLabel={inputValue => `Anlegen: ${inputValue}`}
            onChange={this.handleContactChange}
          />
        </div>

        <div className="form-group col-2">
          <label>Telefonnummer</label>
          <input
            type="text"
            className="form-control"
            name="contactPhone"
            value={contactPhone}
            onChange={this.handleInputChange}
            disabled={disabled}
          />
        </div>

        <div className="form-group col-2">
          <label>Kippzeit bis</label>
          <input
            type="time"
            className="form-control"
            name="date"
            value={date}
            onChange={this.handleInputChange}
            disabled={disabled}
          />
        </div>
      </Fragment>
    );
  }
}

DropzoneInput.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func
};

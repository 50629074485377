import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

import GoogleMapReact from "google-map-react";

const Marker = () => (
  <FontAwesomeIcon
    style={{
      position: "relative",
      top: -32,
      left: -12,
      fontSize: 32,
      color: "#D30132"
    }}
    icon={faMapMarkerAlt}
  />
);

const doGeocode = (address, geocoder) => {
  return new Promise(resolve => {
    geocoder.geocode({ address }, (reslocs, status) => {
      if (status === "OK") {
        const foundloc = reslocs[0].geometry.location;
        const lat = foundloc.lat();
        const lng = foundloc.lng();
        resolve({ lat, lng });
      }
    });
  });
};

class Map extends Component {
  constructor(props) {
    super(props);

    this.initCenter = {
      lat: 51.3396955,
      lng: 12.3730747
    };

    this.state = {
      geocoder: null,
      center: this.initCenter,
      lat: 0,
      lng: 0
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleApiLoaded = this.handleApiLoaded.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { address, coordinates, onAddressChange } = this.props;
    const { geocoder } = this.state;

    const addressChanged = prevProps.address !== address;
    const coordinatesChanged = prevProps.coordinates !== coordinates;
    const geocoderChanged = !prevState.geocoder && geocoder;

    if (geocoder && (geocoderChanged || addressChanged || coordinatesChanged)) {
      if (coordinates) {
        const lat = parseFloat(coordinates.lat);
        const lng = parseFloat(coordinates.lng);
        this.setState({ center: { lat, lng }, lat, lng });
      } else if (geocoder) {
        if (address === "") {
          this.setState({ center: this.initCenter, lat: 0, lng: 0 });
        } else {
          doGeocode(address, geocoder).then(coords => {
            onAddressChange(coords.lat, coords.lng);
            this.setState({ ...coords, center: coords });
          });
        }
      }
    }
  }

  handleChange(e) {
    this.setState({ center: e.center });
  }

  handleApiLoaded(map, maps) {
    const geocoder = new maps.Geocoder();
    this.setState({ geocoder });
  }

  handleClick(e) {
    const { onAddressChange } = this.props;
    // const address = `LAT:${e.lat}LNG:${e.lng}`;
    onAddressChange(e.lat, e.lng);
    this.setState({ lat: e.lat, lng: e.lng });
  }

  render() {
    const { zoom, locked, isOverlayVisible } = this.props;
    const { center, lat, lng } = this.state;

    const overlayClassName = `w-100 h-100 bg-secondary position-relative${
      isOverlayVisible ? " opacity-25" : " opacity-0"
    }`;

    return (
      <Fragment>
        <GoogleMapReact
          bootstrapURLKeys={{
            // key: 'AIzaSyCqYcowuiDz9FACmQxDAodn5tR_0i8cIl4',
            key: "AIzaSyDXfC6IijZ14DbIxp_SrnSO40NF4NgzO1o",
            language: "de",
            region: "de"
          }}
          center={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          options={{ gestureHandling: "cooperative" }}
          onClick={this.handleClick}
          onChange={this.handleChange}
        >
          <Marker lat={lat} lng={lng} text="My Marker" />
        </GoogleMapReact>
        {locked ? (
          <div className={overlayClassName} style={{ top: -500 }} />
        ) : null}
      </Fragment>
    );
  }
}

Map.defaultProps = {
  zoom: 11,
  locked: false,
  isOverlayVisible: true
};

Map.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  address: PropTypes.string,
  coordinates: PropTypes.object,
  zoom: PropTypes.number,
  locked: PropTypes.bool,
  isOverlayVisible: PropTypes.bool,
  onAddressChange: PropTypes.func
};

export default Map;

import gql from 'graphql-tag';
import { CombinationData } from './fragments';

export default (client, id, combination) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateCombination(
          $id: ID
          $name: String
          $establishment: String
          $description: String
          $resources: String
          $human_resources: String
          $vehicles: String
          $status: String
          $leavingDate: Date
        ) {
          updateCombination(
            id: $id
            name: $name
            establishment: $establishment
            description: $description
            resources: $resources
            human_resources: $human_resources
            vehicles: $vehicles
            status: $status
            leavingDate: $leavingDate
          ) {
            ...CombinationData
          }
        }
        ${CombinationData}
      `,
      variables: {
        id,
        name: combination.name,
        establishment: combination.establishment,
        description: combination.description,
        status: combination.status,
        leavingDate: combination.leavingDate,
        resources: combination.resources,
        human_resources: combination.humanResources,
        vehicles: combination.vehicles
      }
    })
    .then(response => {
      dispatch({
        type: 'COMBINATION_UPDATED',
        data: response.data.updateCombination
      });
    });
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


import getResources from "../../../actions/getResources";
import getUsers from "../../../actions/getUsers";
import getVehicles from "../../../actions/getVehicles";

import ResourceSelect from "./resourceSelect";
import List from "./list";

class Resources extends Component {
  constructor(props) {
    super(props);

    props.dispatch(getResources(props.client));
    props.dispatch(getUsers(props.client));
    props.dispatch(getVehicles(props.client));
  }

  render() {
    const {
      order,
      resources,
      humanResources,
      vehicles,
      combinations,
      locked,
      onAction,
      onActionNoOrder,
      handleResourceNoteChange,
      handleResourceHumanResourceVehicleDateChange,
      handleHumanNoteChange,
      handleVehicleNoteChange,
      opStartDate,
      opFinishDate,
      opStartTime,
      opFinishTime
    } = this.props;

    // Get time period of the order if one was chosen.
    let operationalPeriodStart = null;
    let operationalPeriodFinish = null;
    if (order) {
      if (order.operationalPeriodStart)
        operationalPeriodStart = order.operationalPeriodStart;
      if (order.operationalPeriodFinish)
        operationalPeriodFinish = order.operationalPeriodFinish;
    }

    let orderId = null;
    let selectedResources = [];
    let selectedHumanResources = [];
    let selectedVehicles = [];
    if (order) {
      orderId = order.id;
      selectedResources = order.resources || [];
      selectedHumanResources = order.humanResources || [];
      selectedVehicles = order.vehicles || [];
    }

    return (
      <div className="row">
        <div className="col-sm-12 hidden-print">
          <ResourceSelect
            disabled={locked}
            chosenOrderId={orderId}
            startDate={operationalPeriodStart}
            endDate={operationalPeriodFinish}
            resources={resources}
            humanResources={humanResources}
            vehicles={vehicles}
            combinations={combinations}
            onActionNoOrder={onActionNoOrder}
            opStartTime={opStartTime}
            opFinishTime={opFinishTime}
          />
        </div>

        <div className="col-sm-12">
          <List
            disabled={locked}
            orderId={orderId}
            startDate={operationalPeriodStart}
            endDate={operationalPeriodFinish}
            resources={selectedResources}
            humanResources={selectedHumanResources}
            vehicles={selectedVehicles}
            onActionNoOrder={onActionNoOrder}
            handleResourceNoteChange={handleResourceNoteChange}
            handleResourceHumanResourceVehicleDateChange={
              handleResourceHumanResourceVehicleDateChange
            }
            handleVehicleNoteChange={handleVehicleNoteChange}
            handleHumanNoteChange={handleHumanNoteChange}
            opStartDate={opStartDate}
            opFinishDate={opFinishDate}
          />
        </div>
      </div>
    );
  }
}

Resources.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  order: PropTypes.object,
  locked: PropTypes.bool,
  resources: PropTypes.array,
  combinations: PropTypes.array,
  humanResources: PropTypes.array,
  vehicles: PropTypes.array,
  onActionNoOrder: PropTypes.func,
  handleResourceNoteChange: PropTypes.func,
  handleResourceHumanResourceVehicleDateChange: PropTypes.func,
  handleHumanNoteChange: PropTypes.func,
  handleVehicleNoteChange: PropTypes.func,
  opStartDate: PropTypes.string,
  opFinishDate: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  resources: state.resources.get("resources"),
  combinations: state.combinations.get("combinations"),
  humanResources: state.humanResources.get("users"),
  vehicles: state.vehicles.get("vehicles")
}))(Resources);

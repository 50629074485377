// National german holidays and holidays in saxony.
import moment from "moment";
import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";

const holidays = [
  { month: 1, day: 1, fixed: true, description: "Neujahrstag" },
  { month: 5, day: 1, fixed: true, description: "Tag der Arbeit" },
  { month: 10, day: 3, fixed: true, description: "Tag der Deutschen Einheit" },
  { month: 10, day: 31, fixed: true, description: "Reformationstag" },
  { month: 12, day: 25, fixed: true, description: "1. Weihnachtsfeiertag" },
  { month: 12, day: 26, fixed: true, description: "2. Weihnachtsfeiertag" }
];

export const holidays2 = {
  2018: {
    "2018-01-01": "Neujahr",
    "2018-03-30": "Karfreitag",
    "2018-04-01": "Ostersonntag",
    "2018-04-02": "Ostermontag",
    "2018-05-01": "Tag der Arbeit",
    "2018-05-10": "Christi Himmelfahrt",
    "2018-05-31": "Fronleichnam",
    "2018-06-20": "Pfingstsontag",
    "2018-06-21": "Pfingstmontag",
    "2018-10-03": "Tag der deutschen Einheit",
    "2018-10-31": "Reformationstag",
    "2018-11-21": "Buß- und Bettag",
    "2018-12-25": "1. Weihnachtsfeiertag",
    "2018-12-26": "2. Weihnachtsfeiertag"
  },
  2019: {
    "2019-01-01": "Neujahr",
    "2019-04-19": "Karfreitag",
    "2019-04-21": "Ostersonntag",
    "2019-04-22": "Ostermontag",
    "2019-05-01": "Tag der Arbeit",
    "2019-05-30": "Christi Himmelfahrt",
    "2019-06-09": "Pfingstsontag",
    "2019-06-10": "Pfingstmontag",
    "2019-06-20": "Fronleichnam",
    "2019-10-03": "Tag der deutschen Einheit",
    "2019-10-31": "Reformationstag",
    "2019-11-20": "Buß- und Bettag",
    "2019-12-25": "1. Weihnachtsfeiertag",
    "2019-12-26": "2. Weihnachtsfeiertag"
  },
  2020: {
    "2020-01-01": "Neujahr",
    "2020-04-10": "Karfreitag",
    "2020-04-12": "Ostersonntag",
    "2020-04-13": "Ostermontag",
    "2020-05-01": "Tag der Arbeit",
    "2020-05-21": "Christi Himmelfahrt",
    "2020-05-31": "Pfingstsontag",
    "2020-06-01": "Pfingstmontag",
    "2020-06-11": "Fronleichnam",
    "2020-10-03": "Tag der deutschen Einheit",
    "2020-10-31": "Reformationstag",
    "2020-11-18": "Buß- und Bettag",
    "2020-12-25": "1. Weihnachtsfeiertag",
    "2020-12-26": "2. Weihnachtsfeiertag"
  },
  2021: {
    "2021-01-01": "Neujahr",
    "2021-04-02": "Karfreitag",
    "2021-04-04": "Ostersonntag",
    "2021-04-05": "Ostermontag",
    "2021-05-01": "Tag der Arbeit",
    "2021-05-13": "Christi Himmelfahrt",
    "2021-05-23": "Pfingstsontag",
    "2021-05-24": "Pfingstmontag",
    "2021-06-03": "Fronleichnam",
    "2021-10-03": "Tag der deutschen Einheit",
    "2021-10-31": "Reformationstag",
    "2021-11-17": "Buß- und Bettag",
    "2021-12-25": "1. Weihnachtsfeiertag",
    "2021-12-26": "2. Weihnachtsfeiertag"
  },
  2022: {
    "2022-01-01": "Neujahr",
    "2022-04-15": "Karfreitag",
    "2022-04-17": "Ostersonntag",
    "2022-04-18": "Ostermontag",
    "2022-05-01": "Tag der Arbeit",
    "2022-05-26": "Christi Himmelfahrt",
    "2022-06-05": "Pfingstsontag",
    "2022-06-06": "Pfingstmontag",
    "2022-06-16": "Fronleichnam",
    "2022-10-03": "Tag der deutschen Einheit",
    "2022-10-31": "Reformationstag",
    "2022-11-16": "Buß- und Bettag",
    "2022-12-25": "1. Weihnachtsfeiertag",
    "2022-12-26": "2. Weihnachtsfeiertag"
  },
  2023: {
    "2023-01-01": "Neujahr",
    "2023-04-07": "Karfreitag",
    "2023-04-10": "Ostermontag",
    "2023-05-01": "Tag der Arbeit",
    "2023-05-18": "Christi Himmelfahrt",
    "2023-05-29": "Pfingstmontag",
    "2023-06-08": "Fronleichnam",
    "2023-10-03": "Tag der deutschen Einheit",
    "2023-10-31": "Reformationstag",
    "2023-11-15": "Buß- und Bettag",
    "2023-12-25": "1. Weihnachtsfeiertag",
    "2023-12-26": "2. Weihnachtsfeiertag"
  },
  2024: {
    "2024-01-01": "Neujahr",
    "2024-03-29": "Karfreitag",
    "2024-04-01": "Ostermontag",
    "2024-05-01": "Tag der Arbeit",
    "2024-05-09": "Christi Himmelfahrt",
    "2024-05-20": "Pfingstmontag",
    "2024-05-30": "Fronleichnam",
    "2024-10-03": "Tag der deutschen Einheit",
    "2024-10-31": "Reformationstag",
    "2024-11-20": "Buß- und Bettag",
    "2024-12-25": "1. Weihnachtsfeiertag",
    "2024-12-26": "2. Weihnachtsfeiertag"
  }
};

const months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember"
];

const daysShort = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

const pad = num => `00${num}`.slice(-2);

// FORMATTING //
/* export function toISODateString(date) {
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(
    date.getUTCSeconds()
  )}`;
} */

export function toISODateOnlyString(date) {
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )}`;
}

export function toDateOnlyString(date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
}

export function toDateTimeString(date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

export function toDateString(date) {
  return `${pad(date.getDate())}.${pad(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;
}

export function toTimeString(date) {
  return `${pad(date.getHours())}.${pad(date.getMinutes())}`;
}

export function monthToString(month) {
  return months[month];
}

export function monthStringToMonth(monthString) {
  return months.indexOf(monthString) + 1;
}

// MANIPULATE
export function addYears(date, years) {
  const d = new Date(date);
  d.setFullYear(d.getFullYear() + years);
  return d;
}

export function addDays(date, days) {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
}

export function addHours(date, hours) {
  const d = new Date(date);
  d.setHours(d.getHours() + hours);
  return d;
}

export function startOfDay(date) {
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function endOfDay(date) {
  const d = new Date(date);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  return d;
}

export function startOfHour(date) {
  const d = new Date(date);
  d.setHours(d.getHours());
  d.setMinutes(0);
  d.setSeconds(0);
  return d;
}

// GETTERS
export function getWeekNumber(date) {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

export function getWorkingDays(from, to) {
  let workdays = 0;
  let day = new Date(
    from.getFullYear(),
    from.getMonth(),
    from.getDate(),
    0,
    0,
    0,
    0
  );

  const toTime = new Date(
    to.getFullYear(),
    to.getMonth(),
    to.getDate(),
    0,
    0,
    0,
    1
  ).getTime();

  while (day.getTime() <= toTime) {
    if (!isHoliday(day) && day.getDay() % 6 !== 0) {
      workdays += 1;
    }
    day = addDays(day, 1);
  }
  return workdays;
}

// MISC //
export function isHoliday(date) {
  const month = date.getMonth() - 1;
  const day = date.getDate();

  for (let i = 0; i < holidays.length; i += 1) {
    const holiday = holidays[i];
    if (holiday.fixed) {
      if (holiday.month === month && holiday.day === day) return { ...holiday };
    }
  }

  return null;
}

export function dateToMonthRange(date) {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    0
  );
  return [startDate, endDate];
}

export function dayToDayShort(day) {
  return daysShort[day];
}

export function getTimeRange(startDate, endDate) {
  if (startDate && endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    if (start.isSame(end, "day")) {
      return {
        startDate: start.format("DD.MM."),
        endDate: "...",
        startTime: start.format("HH:mm"),
        endTime: end.format("HH:mm")
      };
    }
    return {
      startDate: start.format("DD.MM."),
      endDate: end.format("DD.MM."),
      startTime: start.format("HH:mm"),
      endTime: end.format("HH:mm")
    };
  }
}

export class GetTimePercentage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled,
      type: props.type,
      instance: props.instance,
      startDate: props.startDate,
      endDate: props.endDate,
      startDateInstance: props.startDateInstance,
      endDateInstance: props.endDateInstance,
      handleChange: props.handleChange,
      handleDateChange: props.handleDateChange
    };
  }

  componentDidMount() {
    const {
      startDate,
      endDate,
      startDateInstance,
      endDateInstance
    } = this.state;

    this.calculateDisplay(
      startDate,
      endDate,
      startDateInstance,
      endDateInstance
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          disabled: this.props.disabled,
          type: this.props.type,
          instance: this.props.instance,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          startDateInstance: this.props.startDateInstance,
          endDateInstance: this.props.endDateInstance
        },
        () => {
          const {
            startDate,
            endDate,
            startDateInstance,
            endDateInstance
          } = this.state;

          this.calculateDisplay(
            startDate,
            endDate,
            startDateInstance,
            endDateInstance
          );
        }
      );
    }
  }

  calculateDisplay(startDate, endDate, startDateInstance, endDateInstance) {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);

      const startInstance = startDateInstance
        ? moment(startDateInstance)
        : start;
      const endInstance = endDateInstance ? moment(endDateInstance) : end;
      const overflow = start.isAfter(startDateInstance, "days")
        ? end.isBefore(endDateInstance, "days")
          ? " machineBoxGradientOutside"
          : " machineBoxGradientLeft"
        : end.isBefore(endDateInstance, "days")
        ? " machineBoxGradientRight"
        : " ";

      let diff1 = Math.abs(start.diff(end, "days")) + 1;
      let diff2 = 1;
      let diff3 = 1;
      let width = 0;
      let left = 0;

      if (start.isSameOrBefore(startInstance)) {
        diff2 = Math.abs(startInstance.diff(endInstance, "days")) + 1;
        diff3 = Math.abs(startInstance.diff(start, "days"));
        left = (diff3 / diff1) * 100;
        width = (diff2 / diff1) * 100;
        if (left + width > 100) {
          width = 100 - left;
          if (left > 100) {
            width = 0;
          }
        }
      } else {
        if (start.isBefore(endInstance)) {
          diff2 = Math.abs(start.diff(endInstance, "days")) + 1;
          left = 0;
          width = (diff2 / diff1) * 100;
          width = width > 100 ? 100 : width;
        }
      }
      const borderLeftRadius = left === 0;
      const borderRightRadius = left + width === 100;

      const dateString = getTimeRange(
        startDateInstance ? startDateInstance : start,
        endDateInstance ? endDateInstance : end
      );

      this.setState({
        dateString,
        width,
        left,
        borderLeftRadius,
        borderRightRadius,
        overflow
      });
    }
  }

  render() {
    const {
      disabled,
      type,
      instance,
      dateString,
      width,
      left,
      borderLeftRadius,
      borderRightRadius,
      overflow,
      datePicker1,
      datePicker2,
      startDateInstance,
      endDateInstance,
      handleChange,
      handleDateChange
    } = this.state;

    if (dateString) {
      return (
        <Fragment>
          {(datePicker1 || datePicker2) && !disabled && (
            <div
              style={{
                zIndex: 10,
                display: "block",
                position: "absolute",
                top: -150,
                left: -400
              }}
            >
              <DatePicker
                selected={
                  datePicker1
                    ? new Date(startDateInstance ? startDateInstance : "")
                    : new Date(endDateInstance ? endDateInstance : "")
                }
                onChange={e => {
                  this.setState(
                    datePicker1
                      ? { startDateInstance: e }
                      : { endDateInstance: e },
                    () => {
                      const {
                        startDate,
                        endDate,
                        startDateInstance,
                        endDateInstance
                      } = this.state;

                      this.calculateDisplay(
                        startDate,
                        endDate,
                        startDateInstance,
                        endDateInstance
                      );
                    }
                  );
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                inline
                onClickOutside={() => {
                  this.setState({ datePicker1: false, datePicker2: false });
                  handleChange(
                    [startDateInstance, endDateInstance],
                    instance,
                    type
                  );
                  handleDateChange(instance, type);
                }}
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
          )}
          <div
            className="py-0 px-2"
            style={{ display: "flex", flexFlow: "column" }}
          >
            <table
              style={{ fontSize: "11px", width: "100%", textAlign: "center" }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>
                    <span
                      className="text-center m-0"
                      style={{
                        fontSize: "12px",
                        width: "100%"
                      }}
                    >
                      <p
                        className={disabled ? "mb-0 mx-1" : "mb-0 mx-1 dateField"}
                        style={
                          datePicker1
                            ? {
                                color: "#007bff",
                                display: "inline"
                              }
                            : { display: "inline" }
                        }
                        onClick={() => {
                          if (!disabled) {
                            this.setState({ datePicker1: true });
                          }
                        }}
                      >
                        {dateString.startDate}
                      </p>
                    </span>
                  </td>
                  <td style={{ width: "50%" }}>
                    <span
                      className="text-center m-0"
                      style={{
                        fontSize: "12px",
                        width: "100%"
                      }}
                    >
                      <p
                          className={disabled ? "mb-0 mx-1" : "mb-0 mx-1 dateField"}
                          style={
                          datePicker2
                            ? {
                                color: "#007bff",
                                display: "inline"
                              }
                            : { display: "inline" }
                        }
                        onClick={() => {
                          if (!disabled) {
                            this.setState({ datePicker2: true });
                          }
                        }}
                      >
                        {dateString.endDate}
                      </p>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{dateString.startTime}</td>
                  <td>{dateString.endTime}</td>
                </tr>
              </tbody>
            </table>
            <div style={{ flexGrow: "1", height: "10px" }}>
              <div className="machineRangeBox">
                <div
                  className={"machineBox " + overflow}
                  style={{
                    width: width + "%",
                    marginLeft: left + "%",
                    borderTopLeftRadius: borderLeftRadius ? " 5px" : "0px",
                    borderBottomLeftRadius: borderLeftRadius ? " 5px" : "0px",
                    borderTopRightRadius: borderRightRadius ? "5px" : "0px",
                    borderBottomRightRadius: borderRightRadius ? "5px" : "0px"
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

GetTimePercentage.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  instance: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startDateInstance: PropTypes.string,
  endDateInstance: PropTypes.string,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import {
  faExpand,
  faCompress,
  faCheckCircle
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Card extends Component {
  constructor(props) {
    super(props);

    this.handleRead = this.handleRead.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleRead() {
    const { data, onRead } = this.props;
    onRead(data);
  }

  handleExpand() {
    const { data, onExpand } = this.props;
    onExpand(data.id);
  }

  render() {
    const { data, expanded, showButtons } = this.props;

    const { issueType } = data;
    let issueClassName = 'mc-card-footer-news-badge badge badge-pill ';

    switch (issueType) {
      case 'Improvement':
        issueClassName += 'badge-success';
        break;
      case 'Feature':
        issueClassName += 'badge-primary';
        break;
      case 'Bug':
        issueClassName += 'badge-danger';
        break;
      default:
        issueClassName += 'badge-secondary';
    }

    return (
      <AnimateHeight
        duration={300}
        height={expanded ? 'auto' : 180}
        className="mc-card">
        <div className={`mc-card-width${expanded ? '-expanded' : ''}`}>
          {showButtons ? (
          <div className="mc-card-buttons">
            {expanded ? (
              <button className="mc-btn-no-style" onClick={this.handleRead}>
                <FontAwesomeIcon
                  className="mc-card-btn mr-2"
                  icon={faCheckCircle}
                />
              </button>
            ) : null}
            <button className="mc-btn-no-style" onClick={this.handleExpand}>
              <FontAwesomeIcon
                className="mc-card-btn"
                icon={expanded ? faCompress : faExpand}
              />
            </button>
          </div>) :null}
          <div
            className="font-weight-bold small mb-2"
            dangerouslySetInnerHTML={{ __html: data.headline }}
          />
          <div
            className="mc-card-content small"
            dangerouslySetInnerHTML={{ __html: data.description.html }}
          />
          <div className="mc-card-footer">
            {data.modules.map(module => (
              <span
                key={module.name}
                className="badge badge-outline-primary mr-2">
                {module.name}
              </span>
            ))}
            <span className={issueClassName}>{issueType}</span>
          </div>
        </div>
      </AnimateHeight>
    );
  }
}

Card.propTypes = {
  data: PropTypes.object,
  expanded: PropTypes.bool,
  showButtons: PropTypes.bool,
  onExpand: PropTypes.func,
  onRead: PropTypes.func
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { faUserCircle, faSignOut } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EstablishmentSelect from "./establishmentSelect";
import CustomLink from "./CustomLink";

import logo from "../../images/logo_0.png";

import removeItemFromLocalStorage from "../../actions/removeItemFromLocalStorage";

class Header extends Component {
  static openDashboard() {
    window.location = "/";
  }

  static renderLinks(routes, actionHandler) {
    const path = `/${window.location.pathname.split("/")[1]}`;

    return (
      <Fragment>
        {routes.map(route => {
          if (route.icon) {
            if (route.path || route.action) {
              const active = route.path === path;

              return (
                <CustomLink
                  key={route.path}
                  active={active}
                  onAction={actionHandler}
                  {...route}
                />
              );
            }
          } else {
            if (route.path) {
              return null;
            }
            return <div key={route.key} className="header-separator" />;
          }
          return null;
        })}
      </Fragment>
    );
  }

  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.handleEstablishmentSelect = this.handleEstablishmentSelect.bind(this);
    this.dispatchAction = this.dispatchAction.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
  }

  updateSize = () => {
    this.setState({ width: window.innerWidth });
  };

  logout() {
    const { dispatch } = this.props;

    dispatch(removeItemFromLocalStorage("klout"));
  }

  handleEstablishmentSelect(value) {
    const { dispatch } = this.props;

    window.location.reload();
    dispatch({ type: "SET_ESTABLISHMENT", data: value });
  }

  dispatchAction(action) {
    const { dispatch } = this.props;

    if (action.length > 1) {
      dispatch({ type: action[0], data: action[1] });
    } else {
      dispatch({ type: action[0] });
    }
  }

  render() {
    const { routes, loginName, establishment, establishments } = this.props;
    const { left, right } = routes;

    return (
      <div className="header hidden-print">
        <div className="float-left">
          <img src={logo} alt="Kutter Logo" />
          <div className="home-link-area" onClick={Header.openDashboard} />
          <EstablishmentSelect
            value={establishment}
            options={establishments}
            onSelect={this.handleEstablishmentSelect}
          />
        </div>
        {window.innerWidth > 1710 && (
          <nav className="header-menu-left">
            {Header.renderLinks(left, this.dispatchAction)}
          </nav>
        )}
        <div className="header-menu-user-container">
          <div className="header-menu-user">
            <div className="header-menu-user-account">
              <div>
                <FontAwesomeIcon icon={faUserCircle} />
                <div className="text-center">{loginName}</div>
              </div>
            </div>
            <div className="header-menu-user-logout">
              <a href="/" onClick={this.logout} title={"Logout"}>
                <FontAwesomeIcon className="text-white" icon={faSignOut} />
              </a>
            </div>
          </div>
        </div>
        {window.innerWidth > 1710 && (
          <nav className="header-menu-right">
            {Header.renderLinks(right, this.dispatchAction)}
          </nav>
        )}
        {window.innerWidth <= 1710 && (
          <nav className="header-menu-together">
            {Header.renderLinks(left, this.dispatchAction)}
            {Header.renderLinks(right, this.dispatchAction)}
          </nav>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  loginName: PropTypes.string,
  routes: PropTypes.object,
  establishment: PropTypes.object,
  establishments: PropTypes.array
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  loginName: state.main.get("loginName"),
  routes: state.main.get("routes"),
  establishment: state.main.get("establishment"),
  establishments: state.main.get("establishments")
}))(Header);

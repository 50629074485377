import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTimes,
  faTrash
} from "@fortawesome/pro-solid-svg-icons";
import {
  FILESTACK_API_KEY_NEW,
  FILESTACK_POLICY_NEW,
  FILESTACK_SIGNATURE_NEW
} from "../Workshop/filestackCredentials";

const UploadedFileElement = ({ handle, handleDelete }) => {
  const [preClicked, setPreClicked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onClickDelete = useCallback(() => {
    setIsDeleting(true);
    handleDelete();
  }, [handleDelete]);

  if (isDeleting) {
    return (
      <div className="m-1 p-1 d-inline-block spin">
        <FontAwesomeIcon icon={faSpinner} />
      </div>
    );
  }

  return (
    <div className="m-1 p-1 d-inline-block border">
      <a
        href={`https://cdn.filestackcontent.com/${FILESTACK_API_KEY_NEW}/security=policy:${FILESTACK_POLICY_NEW},signature:${FILESTACK_SIGNATURE_NEW}/${handle}`}
        target="_blank"
        rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFileAlt} style={{color: "#28a745"}}/>
      </a>
      {!preClicked ? (
        <div className="cursor-pointer" onClick={() => setPreClicked(true)}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      ) : (
        <Fragment>
          <div className="cursor-pointer" onClick={() => setPreClicked(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="cursor-pointer" onClick={onClickDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

UploadedFileElement.propTypes = {
  handle: PropTypes.string,
  handleDelete: PropTypes.func
};

export default UploadedFileElement;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

class TimePeriodHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      backgroundStyle: { backgroundPosition: '0px 0px' },
      selectedDay: -1,
      selectedStartDay: -1,
      selectedEndDay: -1
    };

    this.selectDay = this.selectDay.bind(this);
    this.startDaySelection = this.startDaySelection.bind(this);
    this.doDaySelection = this.doDaySelection.bind(this);
    this.endDaySelection = this.endDaySelection.bind(this);
  }

  static defaultProps = {
    dayWidth: 80,
    hourWidth: 20
  };

  static dayNames = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];

  static monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  static getDerivedStateFromProps(props, state) {
    if (
      state.startDate !== props.startDate ||
      state.showHours !== props.showHours
    ) {
      let endOfWeek = moment(props.startDate);
      endOfWeek = endOfWeek.endOf('isoWeek');
      props.startDate.week();
      const hourFactor = props.showHours ? 12 : 1;
      return {
        backgroundStyle: {
          backgroundPosition: `${(endOfWeek.diff(props.startDate, 'days') + 1) *
            props.dayWidth *
            hourFactor}px 0px`
        },
        startDate: props.startDate
      };
    }
    return null;
  }

  static getDayName(day) {
    return TimePeriodHead.dayNames[day];
  }

  static getMonthName(month) {
    return TimePeriodHead.monthNames[month];
  }

  getDaysInWeek(week) {
    if (week === this.props.startDate.isoWeek()) {
      let endOfWeek = moment(this.props.startDate);
      endOfWeek = endOfWeek.endOf('isoWeek');
      return endOfWeek.diff(this.props.startDate, 'days') + 1;
    } else if (week === this.props.endDate.isoWeek()) {
      let startOfWeek = moment(this.props.endDate);
      startOfWeek = startOfWeek.startOf('isoWeek');
      return this.props.endDate.diff(startOfWeek, 'days') + 1;
    }
    return 7;
  }

  getDaysInMonth(month) {
    if (month === this.props.startDate.month()) {
      let endOfMonth = moment(this.props.startDate);
      endOfMonth = endOfMonth.endOf('month');
      return endOfMonth.diff(this.props.startDate, 'days') + 1;
    } else if (month === this.props.endDate.month()) {
      let startOfMonth = moment(this.props.endDate);
      startOfMonth = startOfMonth.startOf('month');
      return this.props.endDate.diff(startOfMonth, 'days') + 1;
    }
    return moment()
      .month(month)
      .daysInMonth();
  }

  static getDayArray(startDate, endDate) {
    const dayArray = [];
    const actualDay = moment(startDate);
    const diffDays = endDate.diff(startDate, 'days') + 1;
    for (let i = 0; i < diffDays; i += 1) {
      dayArray.push({
        number: actualDay.date(),
        name: TimePeriodHead.getDayName(actualDay.isoWeekday() - 1)
      });
      actualDay.add(1, 'day');
    }
    return dayArray;
  }

  selectDay(e) {
    this.setState({ selectedDay: parseInt(e.target.dataset.index, 10) });
  }

  startDaySelection(e) {
    const { selectedStartDay, selectedEndDay } = this.state;
    const selectedDay = parseInt(e.target.dataset.index, 10);
    if (
      selectedStartDay === selectedEndDay &&
      selectedStartDay === selectedDay
    ) {
      this.setState({
        selectedDay: -1,
        selectedStartDay: -1,
        selectedEndDay: -1
      });
      this.props.onSelection(-1, -1);
    } else {
      this.setState({ selectedDay });
    }
  }

  doDaySelection(e) {
    if (this.state.selectedDay < 0) return;

    let selectedStartDay = this.state.selectedDay;
    let selectedEndDay = parseInt(e.target.dataset.index, 10);
    if (selectedStartDay > selectedEndDay) {
      const temp = selectedStartDay;
      selectedStartDay = selectedEndDay;
      selectedEndDay = temp;
    }
    this.setState({ selectedStartDay, selectedEndDay });

    this.props.onSelection(selectedStartDay, selectedEndDay);
  }

  endDaySelection(e) {
    if (this.state.selectedDay < 0) return;

    let selectedStartDay = this.state.selectedDay;
    let selectedEndDay = parseInt(e.target.dataset.index, 10);
    if (selectedStartDay > selectedEndDay) {
      const temp = selectedStartDay;
      selectedStartDay = selectedEndDay;
      selectedEndDay = temp;
    }
    this.setState({ selectedDay: -1, selectedStartDay, selectedEndDay });

    this.props.onSelection(selectedStartDay, selectedEndDay);
  }

  render() {
    const { showHours, width, dayWidth, hourWidth } = this.props;
    const { selectedStartDay, selectedEndDay } = this.state;
    const monthInfos = [];
    const weekInfos = [];
    const allDays = TimePeriodHead.getDayArray(
      this.props.startDate,
      this.props.endDate
    );
    const hours = [];

    for (let i = 0; i < allDays.length * 12; i += 1) {
      hours.push(((2 * i) % 24));
    }

    let monthEndDate = this.props.endDate.month();
    if (this.props.endDate.month() < this.props.startDate.month()) {
      monthEndDate = this.props.endDate.month() + 12;
    }
    for (let i = this.props.startDate.month(); i <= monthEndDate; i += 1) {
      let index = i;
      if (index >= 12) {
        index -= 12;
      }
      monthInfos.push({
        month: TimePeriodHead.getMonthName(index),
        days: this.getDaysInMonth(index)
      });
    }

    let weekEndDate = this.props.endDate.isoWeek();
    if (this.props.endDate.isoWeek() < this.props.startDate.isoWeek()) {
      weekEndDate = this.props.endDate.isoWeek() + 52;
    }

    for (let i = this.props.startDate.isoWeek(); i <= weekEndDate; i += 1) {
      let index = i;
      if (index > 52) {
        index -= 52;
      }
      weekInfos.push({
        kw: index,
        days: this.getDaysInWeek(index)
      });
    }

    return (
      <div
        className="gantt-time-period-head no-select"
        style={{ minWidth: width, maxWidth: width }}>
        <div
          id={`${showHours ? 'weeks' : 'months'}`}
          className="gantt-time-period-head-line">
          {showHours
            ? weekInfos.map((week, i) => (
                <div
                  key={`${i}week${week.kw}`}
                  className="week"
                  style={{ width: week.days * hourWidth * 12 }}>
                  KW {week.kw}
                </div>
              ))
            : monthInfos.map((month, i) => (
                <div
                  key={`${i}month${month}`}
                  className="month"
                  style={{ width: month.days * dayWidth }}>
                  {month.month}
                </div>
              ))}
        </div>
        <div
          id={`${showHours ? 'days' : 'weeks'}`}
          className="gantt-time-period-head-line">
          {showHours
            ? allDays.map((day, i) => (
                <div
                  key={`${i}day${day.number}`}
                  className="day-80"
                  style={{ width: hourWidth * 12 }}>
                  {day.name} {day.number}.
                </div>
              ))
            : weekInfos.map((week, i) => (
                <div
                  key={`${i}week${week.kw}`}
                  className="week"
                  style={{ width: week.days * dayWidth }}>
                  {week.days > 1 && <span>KW </span>}
                  {week.kw}
                </div>
              ))}
        </div>
        <div
          id={`${showHours ? 'hours' : 'days'}`}
          className="gantt-time-period-head-line">
          {showHours
            ? hours.map((hour, i) => (
                <div
                  key={`${i}hour`}
                  className="hour"
                  style={{ width: hourWidth }}>
                  {hour}
                </div>
              ))
            : allDays.map((day, i) => {
                const isSelected = i >= selectedStartDay && i <= selectedEndDay;
                return (
                  <div
                    key={`${i}day${day.number}`}
                    data-index={i}
                    className={`day-80 ${isSelected ? 'day-80-selected' : ''}`}
                    style={{ width: dayWidth }}
                    onMouseDown={this.startDaySelection}
                    onMouseMove={this.doDaySelection}
                    onMouseUp={this.endDaySelection}>
                    {day.number}
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

TimePeriodHead.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showHours: PropTypes.bool,
  width: PropTypes.number,
  dayWidth: PropTypes.number,
  hourWidth: PropTypes.number,
  onSelection: PropTypes.func
};

export default TimePeriodHead;

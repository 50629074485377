import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gql from "graphql-tag";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEuroSign } from "@fortawesome/pro-solid-svg-icons";

import "./style.css";

import { addDays, endOfDay, startOfDay } from "../../util/dateHelper";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: []
    };

    this.getOrders = this.getOrders.bind(this);
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders() {
    const { client, establishment } = this.props;

    const from = startOfDay(new Date()).toISOString();
    const to = endOfDay(addDays(from, 3)).toISOString();

    const query = gql`
      query getOrdersInRange($from: Date!, $to: Date!) {
        getOrdersInRange(from: $from, to: $to) {
          id
          buildingAddress: building_address
          operationalPeriodStart: operational_period_start
          operationalPeriodFinish: operational_period_finish
          constructionManagerName: construction_manager_name
          certifiedForemanName: certified_foreman_name
          description
          status
          costCenter: cost_center {
            id
            value
            buildingProject: building_project
            establishment
          }
          humanResources {
            id
            humanResource {
              id
              firstname
              lastname
              absences(start: $from, end: $to, useOrderTime: true) {
                from
                to
              }
            }
          }
        }
      }
    `;

    client
      .query({
        query,
        fetchPolicy: "network-only",
        variables: {
          from,
          to
        }
      })
      .then(response => {
        const data = response.data.getOrdersInRange;

        const orders = data
          .filter(order => {
            const orderEstablishment = order.costCenter
              ? order.costCenter.establishment
              : order.establishment;
            if (establishment) {
              return establishment.value === orderEstablishment;
            }
            return true;
          })
          .map(order => {
            const {
              humanResources,
              operationalPeriodStart,
              operationalPeriodFinish
            } = order;
            const start = startOfDay(
              new Date(operationalPeriodStart)
            ).getTime();
            const end = endOfDay(new Date(operationalPeriodFinish)).getTime();
            let conflicts = false;

            humanResources.forEach(hr => {
              const { absences } = hr.humanResource;
              absences.forEach(absence => {
                const from = startOfDay(new Date(absence.from)).getTime();
                const to = endOfDay(new Date(absence.to)).getTime();

                if (from <= start && to >= end) {
                  conflicts = true;
                }
              });
            });

            return {
              ...order,
              conflicts,
              color: order.costCenter
                ? order.status > 0
                  ? "green"
                  : "white"
                : "yellow"
            };
          })
          .sort((a, b) => {
            const startA = new Date(a.operationalPeriodStart);
            const startB = new Date(b.operationalPeriodStart);
            return startB.getTime() - startA.getTime();
          });

        this.setState({ orders });
      });
  }

  render() {
    const { orders } = this.state;

    return (
      <div className="card wgt-container">
        <div className="card-header text-center">
          <span className="h4">Abrufe</span>
        </div>
        <div className="card-body wgt-body p-0" style={{ height: "46.5vh" }}>
          <table className="table small m-0">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  ID
                </th>
                <th scope="col">KS</th>
                <th scope="col">Auftraggeber</th>
                <th scope="col">Bauleiter</th>
                <th scope="col">Polier</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order.id} className={`owgt-bg-${order.color}`}>
                  <th
                    scope="row"
                    className={`text-center owgt-th-bg-${order.color} ${
                      order.conflicts ? "owgt-bg-conflict" : ""
                    }`}>
                    <a className="link-inherit" href={`/order/${order.id}`}>
                      {order.id}
                    </a>
                  </th>
                  <td>
                    {order.costCenter ? (
                      <a
                        className="link-inherit"
                        href={`/cost_center/${order.costCenter.id}`}>
                        {order.costCenter.value}
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {order.costCenter
                      ? order.costCenter.buildingProject
                      : order.description}
                  </td>
                  <td>{order.constructionManagerName}</td>
                  <td>{order.certifiedForemanName}</td>
                  <td>
                    {order.status > 0 ? (
                      <FontAwesomeIcon
                        icon={order.status === 2 ? faEuroSign : faCheckCircle}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Orders.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  establishment: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment")
}))(Orders);

import React from 'react';
import PropTypes from 'prop-types';

const DisplayRow = props => {
  const { className } = props;
  let { value } = props;
  value = value === '' ? '-' : value;

  return (
    <div className={className}>
      <label className="label">{props.label}</label>
      <br />
      <span className="value large">{value}</span>
      <div className="separator" />
    </div>
  );
};

DisplayRow.defaultProps = {
  className: 'col-12 mb-2'
};

DisplayRow.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default DisplayRow;

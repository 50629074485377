import React, { Component, Fragment } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Overview from './overview';
import Input from './input';
import Display from './display';

import createAbsence from '../../../actions/createAbsence';
import updateAbsence from '../../../actions/updateAbsence';
import deleteAbsence from '../../../actions/deleteAbsence';
import {getWorkingDays} from "../../../util/dateHelper";
import {groupBy} from "../../../util/groupBy";

const getAbsenceOverview = (absences, year) => {
  const absencesInCurrentYear = absences
      .map(absence => {
        const from = new Date(absence.from);
        const to = new Date(absence.to);
        const fromYear = from.getFullYear();
        const toYear = to.getFullYear();
        if (fromYear === year && toYear === year) {
          return absence;
        }

        if (fromYear === year) {
          const startOfYear = new Date(year + 1, 0, 1, 0, 0, 0, 0);
          const days = getWorkingDays(startOfYear, to);
          return { ...absence, days: absence.days - days };
        }

        if (toYear === year) {
          const endOfYear = new Date(year - 1, 11, 31, 0, 0, 0, 0);
          const days = getWorkingDays(from, endOfYear);
          return { ...absence, days: absence.days - days };
        }
        return null;
      })
      .filter(o => Boolean(o));

  const groupedAbsences = groupBy(absencesInCurrentYear, 'type');
  const overview = {};

  Object.keys(groupedAbsences).forEach(key => {
    const value = groupedAbsences[key];
    overview[key] = value.reduce((acc, val) => {
      acc += val.days;
      return acc;
    }, 0);
  });

  return overview;
}

class Absences extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.initState = {
      year: today.getFullYear(),
      month: today.getMonth(),
      inputData: {
        type: 'Urlaub',
        startDate: '',
        endDate: ''
      },
      editData: {
        type: 'Urlaub',
        startDate: '',
        endDate: ''
      },
      editId: '',
      locked: !props.write
    };

    this.state = this.initState;

    this.handleInputDataChange = this.handleInputDataChange.bind(this);

    this.createAbsence = this.createAbsence.bind(this);
    this.updateAbsence = this.updateAbsence.bind(this);
    this.deleteAbsence = this.deleteAbsence.bind(this);

    this.editAbsence = this.editAbsence.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  handleInputDataChange(data) {
    const { editId } = this.state;
    if (editId === '') this.setState({ inputData: data });
    else this.setState({ editData: data });
  }

  createAbsence() {
    const { dispatch, client, userId } = this.props;
    const { inputData } = this.state;
    const { type, startDate, endDate } = inputData;
    dispatch(createAbsence(client, userId, type, startDate, endDate, true));
    this.setState(this.initState);
  }

  updateAbsence() {
    const { dispatch, client, userId } = this.props;
    const { editId, editData } = this.state;
    if (editId !== '') {
      const { type, startDate, endDate } = editData;
      dispatch(updateAbsence(client, editId, userId, type, startDate, endDate));
    }
    this.setState(this.initState);
  }

  deleteAbsence(e) {
    const { dispatch, client, userId } = this.props;
    if (userId) {
      const { id } = e.currentTarget.dataset;
      dispatch(deleteAbsence(client, id, userId));
    }
  }

  editAbsence(e) {
    const { absences } = this.props;
    const { id } = e.currentTarget.dataset;

    const absence = absences.find(elem => elem.id === id);
    if (absence) {
      const editData = {
        type: absence.type,
        startDate: moment.utc(absence.from).format('YYYY-MM-DD'),
        endDate: moment.utc(absence.to).format('YYYY-MM-DD')
      };
      this.setState({ editId: id, editData });
    }
  }

  cancelEdit() {
    this.setState(this.initState);
  }

  render() {
    const { user } = this.props;
    const { inputData, editData, editId, locked } = this.state;
    // const today = new Date();

    let vacationDemand = null;

    const absenceTypes = this.props.absenceTypes
      .filter(type => {
        // TODO uncomment the following to remove "Resturlaub" to be choosen
        //
        // if (type.label === 'Resturlaub') {
        //   if (vacationDemand && this.state.year === today.getFullYear()) {
        //     return vacationDemand.vacationPlusDecay >= today.getMonth();
        //   }
        //   return false;
        // }
        return true;
      })
      .map(type => type.label);

    const absenceGroups = absenceTypes.map(type => ({ type, absences: [] }));
    absenceGroups.push({ type: '', absences: [] });

    let absences = [];

    if (this.props.absences) {
      this.props.absences.forEach(absence => {
        const group = absenceGroups.find(g => g.type === absence.type);

        if (group) {
          group.absences.push(absence);
        } else {
          absenceGroups[absenceGroups.length - 1].absences.push(absence);
        }
      });

      absenceGroups.forEach(group => {
        group.absences.sort((a, b) => {
          const aDate = new Date(a.from);
          const bDate = new Date(b.from);

          return bDate.getTime() - aDate.getTime();
        });
      });

      absences = absenceGroups.reduce(
        (acc, val) => acc.concat(val.absences),
        []
      );
    }

    const yearAbsences = absences.filter(
      absence => new Date(absence.to).getFullYear() === this.state.year
    );

    // let currentOverview = {};
    //
    // if (user && user.vacationDemands) {
    //   vacationDemand = user.vacationDemands.find(
    //       vd => vd.year === this.state.year
    //   );
    //   // TODO modify "Resturlaub"
    //   if(vacationDemand) {
    //     let newVacationPlus = 0;
    //     const daysOffPerYear = {};
    //     user.vacationDemands.sort((a, b) => {
    //       if(a.year > b.year) {
    //         return 1;
    //       } else {
    //         return -1;
    //       }
    //     }).forEach((vd, index) => {
    //       if (index === 0) {
    //         newVacationPlus += vd.vacationPlus;
    //       }
    //       const yearString = vd.year;
    //       daysOffPerYear[yearString] = overview;
    //       if (vd.year === this.state.year) {
    //         currentOverview = overview;
    //       }
    //     })
    //     for (const [key, value] of Object.entries(daysOffPerYear)) {
    //       if(key < this.state.year) {
    //         newVacationPlus += 30 - (value.Resturlaub ? value.Resturlaub : 0) - (value.Urlaub ? value.Urlaub : 0);
    //       }
    //     }
    //     vacationDemand.vacationPlus = newVacationPlus;
    //   }
    // }

    const overview = getAbsenceOverview(absences, this.state.year);
    return (
      <Fragment>
        <h4 className="mb-0">Abwesenheit</h4>
        <Overview
          absences={absences}
          position={user.position}
          vacationDemand={vacationDemand}
          year={this.state.year}
          setYear={year =>
            this.setState({
              year
            })
          }
          overview={overview}
        />
        <div className="col-md-12 mt-3 p-3">
          <div className="row border employee-absence-head">
            <div className="col-md-3 bg-white p-2 border-right border-left">
              Typ
            </div>
            <div className="col-md-3 bg-white p-2 border-right">von</div>
            <div className="col-md-3 bg-white p-2 border-right">bis</div>
            <div className="col-md-3 bg-white p-2">Anzahl</div>
          </div>
          <Input
            data={inputData}
            locked={locked || editId !== ''}
            absenceTypes={absenceTypes}
            onDataChange={this.handleInputDataChange}
            onSave={this.createAbsence}
          />
          {yearAbsences.map(absence => {
            if (absence.id === editId) {
              return (
                <Input
                  key={absence.id}
                  data={editData}
                  editMode
                  locked={locked}
                  absenceTypes={absenceTypes}
                  onDataChange={this.handleInputDataChange}
                  onCancel={this.cancelEdit}
                  onSave={this.updateAbsence}
                />
              );
            }
            return (
              <Display
                key={absence.id}
                absence={absence}
                disabled={locked}
                onEdit={this.editAbsence}
                onDelete={this.deleteAbsence}
              />
            );
          })}
        </div>
        <div style={{ height: 85 }} />
      </Fragment>
    );
  }
}

Absences.propTypes = {
  dispatch: Proptypes.func,
  absences: Proptypes.array,
  user: Proptypes.object,
  userId: Proptypes.number,
  client: Proptypes.object,
  absenceTypes: Proptypes.array,
  write: Proptypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client'),
  absenceTypes: state.humanResources.get('absenceTypes')
}))(Absences);

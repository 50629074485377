import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faArrowsAlt,
  faCheck,
  faPlay
} from "@fortawesome/pro-solid-svg-icons";

class OrderBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.openOrder = this.openOrder.bind(this);

    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);

    this.resizeStart = this.resizeStart.bind(this);

    this.openContextMenu = this.openContextMenu.bind(this);
  }

  static preventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  openOrder(e) {
    e.preventDefault();
    e.stopPropagation();

    const { model, editMode } = this.props;

    if (editMode) return;

    const { order } = model;
    if (order) {
      window.location = `/order/${order.id}`;
    }
  }

  unlock() {
    const { model, view, index, onResizeUnlock } = this.props;
    onResizeUnlock(model, view, index);
  }

  lock() {
    const { onResizeLock } = this.props;
    onResizeLock();
  }

  resizeStart(e) {
    const { onResizeStart } = this.props;
    const { side } = e.currentTarget.dataset;
    onResizeStart(e, side);
  }

  openContextMenu(e) {
    OrderBox.preventEvent(e);

    const { onContextMenu } = this.props;
    onContextMenu(e, this, "AT_ORDER");
  }

  render() {
    const {
      marginTop,
      model,
      view,
      containerWidth,
      editMode,
      selectMode,
      isSelected,
      isValid,
      onResizeEnd,
      hasEb,
      hasIntern,
      hasProjectNumber
    } = this.props;

    let className = "gantt-reservation-box-at-order";
    if (model.order) {
      const { cost_center: costCenter } = model.order;
      if (costCenter) {
        if (costCenter.establishment && costCenter.establishment === "Duben") {
          className = "gantt-reservation-box-complete-cyan";
        } else {
          className = "gantt-reservation-box-complete-green";
        }
      }
    }

    if (!isValid) {
      className = "gantt-reservation-box-invalid";
    }

    if (selectMode) className += " opacity-50";

    if (isSelected) className = "gantt-reservation-box-selection blinking";

    const toolsEnabled = view.width > 16;
    const showText = view.width > 60;

    let centerControlStyle = null;
    let toolsStyle = null;
    let tagStyle = null;

    if (view.offset < 0) centerControlStyle = { left: "calc(100% - 19px)" };
    else if (view.offset + view.width > containerWidth) {
      centerControlStyle = { left: 3 };
      toolsStyle = { left: 0, right: "auto", borderWidth: "0 1px 0 0" };
      tagStyle = { marginLeft: 15 };
    }

    let contactName = "";
    let customer = "";
    if (model.order) {
      if (model.order.kutterContact) {
        const contact = model.order.kutterContact;
        contactName = contact.firstname.charAt(0) + contact.lastname.charAt(0);
      }

      if (model.order.cost_center) {
        customer = model.order.cost_center.customer;
      }

      let tooltipStyle = { height: 32 };
      if (view.info.length > 0) tooltipStyle = null;

      const isPublic = model.order && model.order.status === 1;
      return (
        <React.Fragment>
          <div
            className={className}
            style={{
              marginTop: 4 + marginTop,
              left: view.offset,
              width: view.width,
              zIndex: editMode ? 3 : null
            }}
            onMouseDown={OrderBox.preventEvent}
            onMouseUp={OrderBox.preventEvent}
            onContextMenu={this.openContextMenu}
          >
            {view.hasActualStartTime && !view.hasActualEndTime && (
              <FontAwesomeIcon
                className="small"
                icon={faPlay}
                style={{
                  position: "absolute",
                  left: -10,
                  top: 1,
                  width: 10,
                  height: 10
                }}
              />
            )}
            {view.actualWidth && view.actualWidth < view.width && (
              <div className="stroke-red" style={{ left: 20 }} />
            )}
            <div className="gantt-reservation-box-content">
              {editMode ? (
                <div className="box-controls" onMouseUp={onResizeEnd}>
                  <div
                    className="left-control"
                    data-side="left"
                    onMouseDown={this.resizeStart}
                  />
                  <button
                    className="btn-no-style center-control"
                    style={centerControlStyle}
                    onClick={this.lock}
                    disabled={!isValid}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                  <div
                    className="right-control"
                    data-side="right"
                    onMouseDown={this.resizeStart}
                  />
                </div>
              ) : (
                <Fragment>
                  <div>
                    {showText ? (
                      <span>
                        {contactName !== "" ? (
                          <Fragment>
                            <span className="info-tag" style={tagStyle}>
                              {contactName}
                            </span>{" "}
                          </Fragment>
                        ) : null}
                        <span className="box-status">{view.status}</span>
                        {hasEb && <div className="circle bg-success" />}
                        {!hasEb && hasProjectNumber && (
                          <div className="circle bg-warning" />
                        )}
                        {hasIntern && <div className="circle bg-primary" />}
                      </span>
                    ) : null}
                  </div>
                  {isPublic && toolsEnabled ? (
                    <div
                      className="gantt-reservation-box-tools"
                      style={toolsStyle}
                    >
                      <button
                        className="btn-no-style gantt-reservation-box-tools-icon"
                        disabled={true}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={
                              view.hasActualEndTime
                                ? { position: "absolute", top: 7, right: 2 }
                                : {}
                            }
                          />
                          {view.hasActualEndTime && (
                            <React.Fragment>
                              <br />
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={
                                  view.hasActualEndTime
                                    ? {
                                        position: "absolute",
                                        top: 13,
                                        right: 2
                                      }
                                    : {}
                                }
                              />
                            </React.Fragment>
                          )}
                          {hasEb && <div className="circle bg-success" />}
                          {!hasEb && hasProjectNumber && (
                            <div className="circle bg-warning" />
                          )}
                          {hasIntern && <div className="circle bg-primary" />}
                        </span>
                      </button>
                    </div>
                  ) : null}
                </Fragment>
              )}
            </div>
            {editMode ? (
              <div
                className="box-drag-control"
                data-side="middle"
                onMouseDown={this.resizeStart}
                onMouseUp={onResizeEnd}
              />
            ) : (
              <div
                className="gantt-reservation-box-tooltip"
                style={tooltipStyle}
              >
                {contactName !== "" ? (
                  <span className="gantt-reservation-box-tooltip-tag">
                    {contactName}
                  </span>
                ) : null}
                {view.status} | {customer}
                <div style={{ height: 6 }} />
                {view.info.map((elem, i) => (
                  <Fragment key={i}>
                    <span>{elem}</span>
                    <br />
                  </Fragment>
                ))}
              </div>
            )}
          </div>
          {view.actualWidth && view.actualWidth > view.width && (
            <div
              className="gantt-reservation-box-complete-delay"
              style={{
                marginTop: 4 + marginTop,
                left: view.offset + view.width,
                width: view.actualWidth - view.width,
                zIndex: editMode ? 3 : null
              }}
              onMouseDown={OrderBox.preventEvent}
              onMouseUp={OrderBox.preventEvent}
              onContextMenu={this.openContextMenu}
            />
          )}
        </React.Fragment>
      );
    }
  }
}

OrderBox.propTypes = {
  marginTop: PropTypes.number,
  model: PropTypes.object,
  view: PropTypes.object,
  containerWidth: PropTypes.number,
  index: PropTypes.number,
  editMode: PropTypes.bool,
  selectMode: PropTypes.bool,
  isResizable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isValid: PropTypes.bool,
  onResizeLock: PropTypes.func,
  onResizeUnlock: PropTypes.func,
  onResize: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResizeEnd: PropTypes.func,
  onContextMenu: PropTypes.func,
  hasEb: PropTypes.bool,
  hasProjectNumber: PropTypes.bool,
  hasIntern: PropTypes.bool,
  hasActualStartTime: PropTypes.bool
};

export default OrderBox;

import React, { Component } from "react";
import {
  faPen,
  faTrash,
  faCheck,
  faTimes
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ButtonCombination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 0,
      handleEdit: props.handleEdit,
      enableEdit: props.enableEdit,
      handleEditCancel: props.handleEditCancel,
      handleDelete: props.handleDelete,
      handleDeleteCancel: props.handleDeleteCancel
    };
  }

  render() {
    let button1Icon = null;
    let button1Color = "";
    let button1Func = null;
    let button2Icon = null;
    let button2Color = "";
    let button2Func = null;

    switch (this.state.state) {
      case 0:
        button1Icon = faPen;
        button1Color = " btn-success ";
        button1Func = () => {
          this.setState({ state: 2 });
          this.state.enableEdit();
        };
        button2Icon = faTrash;
        button2Color = " btn-danger ";
        button2Func = () => {
          this.setState({ state: 1 });
        };
        break;
      case 1:
        button1Icon = faTimes;
        button1Color = " btn-danger ";
        button1Func = () => {
          this.setState({ state: 0 });
          this.state.handleDeleteCancel();
        };
        button2Icon = faCheck;
        button2Color = " btn-success ";
        button2Func = () => {
          this.setState({ state: 0 });
          this.state.handleDelete();
        };
        break;
      case 2:
        button1Icon = faTimes;
        button1Color = " btn-danger ";
        button1Func = () => {
          this.setState({ state: 0 });
          this.state.handleEditCancel();
        };
        button2Icon = faCheck;
        button2Color = " btn-success ";
        button2Func = () => {
          this.state.handleEdit();
          this.setState({ state: 0 });
        };
        break;
    }
    return (
      <div className="row" style={{ position: "relative" }}>
        <div className="col-6" style={{ height: "100%", width: "100%" }}>
          <div className={"btn " + button1Color} onClick={button1Func}>
            <FontAwesomeIcon icon={button1Icon} />
          </div>
        </div>
        <div className="col-6" style={{ height: "100%", width: "100%" }}>
          <div className={"btn " + button2Color} onClick={button2Func}>
            <FontAwesomeIcon icon={button2Icon} />
          </div>
        </div>
      </div>
    );
  }
}

import gql from 'graphql-tag';
import getLoginName from '../getLoginName';
import getOrder from "./getOrder";

export default (client, humanResourceId, orderId) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  const deleteDispoHumanResource = gql`
    mutation deleteDispoHumanResource($requestUser: String!, $id: ID) {
      deleteDispoHumanResource(requestUser: $requestUser, id: $id) {
        success
      }
    }
  `;

  client
    .mutate({
      mutation: deleteDispoHumanResource,
      variables: {
        requestUser,
        id: humanResourceId
      }
    })
    .then(response => {
      dispatch({
        type: 'REMOVED_HUMAN_RESOURCE_FROM_ORDER',
        data: {
          id: humanResourceId,
          success: response.data.deleteDispoHumanResource
        }
      });
      dispatch(getOrder(client, orderId));
    });
};

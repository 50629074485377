import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';

import './style.css';

import {
  addDays,
  endOfDay,
  startOfDay,
  toDateString
} from '../../util/dateHelper';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      absences: []
    };

    this.getAbsences = this.getAbsences.bind(this);
  }

  componentDidMount() {
    this.getAbsences();
  }

  getAbsences() {
    const { client } = this.props;

    const from = startOfDay(new Date()).toISOString();
    const to = endOfDay(addDays(from, 3)).toISOString();

    const query = gql`
      query getAbsencesInRange($from: Date!, $to: Date!) {
        getAbsencesInRange(from: $from, to: $to) {
          id
          from
          to
          type
          humanResource {
            id
            firstname
            lastname
            orders(from: $from, to: $to) {
              id
              operationalPeriodStart: operational_period_start
              operationalPeriodFinish: operational_period_finish
            }
          }
        }
      }
    `;

    client
      .query({
        query,
        fetchPolicy: 'network-only',
        variables: {
          from,
          to
        }
      })
      .then(response => {
        const data = response.data.getAbsencesInRange;

        const date = startOfDay(new Date()).getTime();

        const absences = data
          .map(absence => {
            const absenceFrom = startOfDay(new Date(absence.from)).getTime();
            let absenceTo = endOfDay(new Date(absence.to)).getTime();

            const conflicts = absence.humanResource.orders
              .filter(order => {
                const start = startOfDay(
                  new Date(order.operationalPeriodStart)
                ).getTime();
                const end = endOfDay(
                  new Date(order.operationalPeriodFinish)
                ).getTime();

                return absenceFrom <= start && absenceTo >= end;
              })
              .map(order => order.id);

            absenceTo = startOfDay(new Date(absence.to)).getTime();

            const absenceWidth =
              (absenceTo - absenceFrom) / (24 * 60 * 60 * 100) + 10;
            let leftWidth = 0;
            let leftOffset = 0;
            let rightWidth = 0;
            let rightOffset = 0;

            if (date > absenceFrom && date <= absenceTo) {
              // split the bar
              leftWidth = (date - absenceFrom) / (24 * 60 * 60 * 100);
              rightWidth = absenceWidth - leftWidth;
            } else if (date <= absenceFrom) {
              // put the bar to the right
              rightOffset = (absenceFrom - date) / (24 * 60 * 60 * 100);
              rightWidth = absenceWidth;
            } else {
              // put the bar to the left
              leftOffset = (date - absenceTo) / (24 * 60 * 60 * 100) - 10;
              leftWidth = absenceWidth;
            }

            return {
              ...absence,
              fromString: toDateString(new Date(absence.from)),
              toString: toDateString(new Date(absence.to)),
              conflicts,
              bars: {
                leftOffset,
                leftWidth,
                rightOffset,
                rightWidth
              }
            };
          })
          .sort((a, b) => {
            const startA = new Date(a.from);
            const startB = new Date(b.from);
            return startB.getTime() - startA.getTime();
          });

        this.setState({ absences });
      });
  }

  render() {
    const { absences } = this.state;

    return (
      <div className="card wgt-container">
        <div className="card-header text-center">
          <span className="h4">Abwesenheiten</span>
        </div>
        <div className="card-body wgt-body p-0">
          <table className="table small m-0">
            <tbody>
              {absences.map(absence => (
                <tr
                  key={absence.id}
                  className={absence.conflicts.length > 0 ? 'awgt-bg-red' : ''}>
                  <td>
                    <a
                      className="link-inherit"
                      href={`/human_resources/${absence.humanResource.id}`}>
                      {`${absence.humanResource.firstname} ${
                        absence.humanResource.lastname
                      }`}
                    </a>
                  </td>
                  <td>{absence.fromString}</td>
                  <td>{absence.toString}</td>
                  <td>{absence.type}</td>
                  <td className="awgt-absence-bar-container border-right">
                    <div
                      className="awgt-absence-bar-left"
                      style={{
                        right: absence.bars.leftOffset,
                        width: absence.bars.leftWidth
                      }}
                    />
                    <div className="awgt-fadeout-left" />
                  </td>
                  <td
                    className="awgt-absence-bar-container"
                    style={{ width: 100 }}>
                    <div
                      className={`awgt-absence-bar${absence.bars.rightOffset > 0 ? '' : '-right'}`}
                      style={{
                        left: absence.bars.rightOffset,
                        width: absence.bars.rightWidth
                      }}
                    />
                    <div className="awgt-fadeout-right" />
                  </td>
                  <td className="text-center">
                    {absence.conflicts.map(conflict => (
                      <a
                        key={conflict}
                        className="rounded bg-white px-2 py-1 mx-1"
                        href={`/order/${conflict}`}>
                        {conflict}
                      </a>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Orders.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client')
}))(Orders);

import gql from "graphql-tag";
import { OrderMaxiOverview } from "./fragments";
import * as moment from "moment";

export default (
  client,
  ks,
  customer,
  constructionProject,
  humanResource
) => dispatch => {
  dispatch({ type: "SET_ORDERS_LOADING" });

  const ORDERS_WITH_FILTER = gql`
    query getOpenOrdersWithFilter(
      $ks: String!
      $customer: String!
      $constructionProject: String!
      $humanResource: String!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      getOpenOrdersWithFilter(
        ks: $ks
        customer: $customer
        constructionProject: $constructionProject
        humanResource: $humanResource
      ) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  client
    .query({
      query: ORDERS_WITH_FILTER,
      variables: {
        ks,
        customer,
        constructionProject,
        humanResource,
      },
      fetchPolicy: "network-only"
    })
    .then(response => {
      dispatch({
        type: "ORDERS_FOUND",
        data: response.data.getOpenOrdersWithFilter
      });
    });
};

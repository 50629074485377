import gql from 'graphql-tag';
import moment from 'moment';

const dbTableInfo = {};

export const whenModified = (client, table, callback, override) => {
  client
    .query({
      query: gql`
        query getTableInfo($name: String!) {
          getTableInfo(name: $name) {
            name
            modifiedAt
          }
        }
      `,
      variables: {
        name: table
      },
      fetchPolicy: 'network-only'
    })
    .then(response => {
      const data = response.data.getTableInfo;

      if (override) {
        callback();
        return;
      }

      if (data) {
        const { name, modifiedAt } = data;

        if (dbTableInfo[name]) {
          const lastDate = dbTableInfo[name];
          const nextDate = moment.utc(modifiedAt);

          if (nextDate.isAfter(lastDate)) callback();

          dbTableInfo[name] = nextDate;
        } else {
          callback();
          Object.assign(dbTableInfo, { [name]: moment.utc(modifiedAt) });
        }
      }
    });
};

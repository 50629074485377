import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

export default class CustomLink extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleAction = this.handleAction.bind(this);
  }

  handleAction() {
    const { action, onAction } = this.props;

    onAction(action);
  }

  render() {
    const { name, icon, path } = this.props;

    if (name === 'Loki') {
      return (
        <a
          className="navlink mx-2 px-2 py-1"
          href={path}
          target="_blank"
          rel="noopener noreferrer">
          <div>{icon}</div>
          <div className="navlink-text">{name}</div>
        </a>
      );
    }

    if (name === 'Mitarbeiter') {
      return (
        <a
          className={`${
            this.props.active ? 'rounded bg-dark' : ''
          } navlink mx-2 px-2 py-1`}
          href={path}
          rel="noopener noreferrer">
          <div>{icon}</div>
          <div className="navlink-text">{name}</div>
        </a>
      );
    }

    if (path) {
      return (
        <NavLink
          to={path}
          className="navlink mx-2 px-2 py-1"
          activeClassName="rounded bg-dark">
          <div>{icon}</div>
          <div className="navlink-text">{name}</div>
        </NavLink>
      );
    }
    return (
      <button
        type="button"
        className="navlink-btn navlink mx-2 px-2 py-1"
        onClick={this.handleAction}>
        <div>{icon}</div>
        <div className="navlink-text">{name}</div>
      </button>
    );
  }
}

CustomLink.propTypes = {
  dispatch: PropTypes.func,
  name: PropTypes.string,
  icon: PropTypes.object,
  path: PropTypes.string,
  action: PropTypes.array,
  active: PropTypes.bool,
  onAction: PropTypes.func
};

/*
  Takes a list of objects and filters them by the given filter string.
  One must supply a list, that defines which keys are filtered.
  Optionally a pre processing function can be defined.
  This is used to transform the objects before filtering them.
 */
export function filterBy(objects, keys, filter, preprocessor = o => o) {
  const filterStr = filter.trim().toUpperCase();
  if (filterStr === '') return objects;

  return objects.filter(obj => {
    const values = keys
      .map(key => preprocessor(obj)[key])
      .filter(e => Boolean(e));
    return values.reduce(
      (acc, val) =>
        acc ||
        val
          .toString()
          .toUpperCase()
          .indexOf(filterStr) > -1,
      false
    );
  });
}

/*
  Tests one object for filter matches on the given keys.
 */
export function matches(object, keys, filter, preprocessor = o => o) {
  const filterStr = filter.trim().toUpperCase();
  if (filterStr === '') return true;

  const values = keys
    .map(key => preprocessor(object)[key])
    .filter(e => Boolean(e));

  return values.reduce(
    (acc, val) =>
      acc ||
      val
        .toString()
        .toUpperCase()
        .indexOf(filterStr) > -1,
    false
  );
}

/*
  Takes an object and flattens its structure. The keys of nested objects will be concatenated.
 */
export function flattenObject(object) {
  const result = {};

  for (const i in object) {
    // eslint-disable-next-line
    if (!object.hasOwnProperty(i)) continue;

    const value = object[i];
    if (typeof value === 'object' && value !== null) {
      const flatObject = flattenObject(value);
      for (const j in flatObject) {
        // eslint-disable-next-line
        if (!flatObject.hasOwnProperty(j)) continue;

        result[`${i}.${j}`] = flatObject[j];
      }
    } else {
      result[i] = value;
    }
  }
  return result;
}

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';

class ResourceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceOptions: [],
      humanResourceOptions: [],
      vehicleOptions: [],
      resourceOptionValue: null,
      vehicleOptionValue: null,
      humanResourceOptionValue: null
    };

    this.changeSelectedResource = this.changeSelectedResource.bind(this);
    this.changeSelectedVehicle = this.changeSelectedVehicle.bind(this);
    this.changeSelectedHumanResource = this.changeSelectedHumanResource.bind(
      this
    );

    this.clearOptions = this.clearOptions.bind(this);
    this.addResources = this.addResources.bind(this);
  }

  static CustomResourceSingleValue = props => (
    <components.SingleValue {...props}>
      <span>{props.data.name}</span>
      <span className="text-black-50">
        {' '}
        {props.data.number} / {props.data.category}
      </span>
    </components.SingleValue>
  );

  static CustomResourceOption = props => (
    <components.Option {...props}>
      <span>{props.data.name}</span>
      <span className="text-black-50 float-right">
        {props.data.number} / {props.data.category}
      </span>
    </components.Option>
  );

  static CustomHumanResourceOption = props => (
    <components.Option {...props}>
      <span>{props.data.label}</span>
      <span className="text-black-50 float-right">{props.data.position}</span>
    </components.Option>
  );

  static selectFilter = (option, filterString) =>
    option.data.keyword.indexOf(filterString.toLowerCase()) > -1;

  static humanResourceSelectStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#56A6FC22'
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: '#56A6FC'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#56A6FC',
      ':hover': {
        backgroundColor: '#56A6FC',
        color: 'white'
      }
    })
  };

  static getDerivedStateFromProps(props, state) {
    const nextState = {
      chosenOrderId: props.chosenOrderId,
      resourceOptions: [],
      humanResourceOptions: [],
      vehicleOptions: [],
      resourceOptionValue: null,
      vehicleOptionValue: null,
      humanResourceOptionValue: null
    };

    if (state.chosenOrderId === props.chosenOrderId) {
      nextState.resourceOptionValue = state.resourceOptionValue;
      nextState.vehicleOptionValue = state.vehicleOptionValue;
      nextState.humanResourceOptionValue = state.humanResourceOptionValue;
    } else {
      nextState.resourceOptionValue = null;
      nextState.vehicleOptionValue = null;
      nextState.humanResourceOptionValue = null;
    }

    const { resources, vehicles, humanResources } = props;

    let resourceOptions = [];
    let vehicleOptions = [];

    if (resources) {
      resourceOptions = resources
        .filter(resource => resource.status === 'Aktiv')
        .map(resource => ({
          id: resource.id,
          name: resource.name,
          category: resource.category,
          number: resource.number,
          keyword: `${resource.name}_${resource.category}_${resource.number}`.toLowerCase(),
          humanResources: resource.humanResources.map(humanResource => ({
            id: humanResource.id
          }))
        }));
    }
    nextState.resourceOptions = resourceOptions;

    if (vehicles) {
      vehicleOptions = vehicles
        .filter(vehicle => vehicle.status === 'Aktiv')
        .map(vehicle => ({
          id: vehicle.id,
          name: `${vehicle.brand} ${vehicle.type}`,
          category: vehicle.category,
          number: vehicle.licensePlate,
          brand: vehicle.brand,
          type: vehicle.type,
          licensePlate: vehicle.licensePlate,
          keyword: `${vehicle.brand} ${vehicle.type}_${vehicle.licensePlate}_${vehicle.category}`.toLowerCase(),
          humanResources: vehicle.humanResources.map(humanResource => ({
            id: humanResource.id
          }))
        }));
    }
    nextState.vehicleOptions = vehicleOptions;

    if (humanResources) {
      nextState.humanResourceOptions = humanResources
        .filter(hr => hr.status === 'Aktiv')
        .map(humanResource => ({
          id: humanResource.id,
          firstname: humanResource.firstname,
          lastname: humanResource.lastname,
          label: `${humanResource.firstname} ${humanResource.lastname}`,
          position: humanResource.position,
          keyword: `${humanResource.firstname}_${humanResource.lastname}_${humanResource.position}`.toLowerCase()
        }));
    }

    return nextState;
  }

  changeSelectedResource(newOption, { action }) {
    if (action === 'pop-value') return;

    const { humanResourceOptions } = this.state;
    const ids = newOption.humanResources.map(resource => resource.id);

    const preselectedHumanResources = [];
    ids.forEach(id => {
      const option = humanResourceOptions.find(elem => elem.id === id);
      if (option) preselectedHumanResources.push(option);
    });

    this.setState({
      resourceOptionValue: newOption,
      humanResourceOptionValue: preselectedHumanResources
    });
  }

  changeSelectedVehicle(newOption, { action }) {
    if (action === 'pop-value') return;

    const { humanResourceOptions } = this.state;
    const ids = newOption.humanResources.map(resource => resource.id);

    const preselectedHumanResources = [];
    ids.forEach(id => {
      const option = humanResourceOptions.find(elem => elem.id === id);
      if (option) preselectedHumanResources.push(option);
    });

    this.setState({
      vehicleOptionValue: newOption,
      humanResourceOptionValue: preselectedHumanResources
    });
  }

  changeSelectedHumanResource(newOption) {
    this.setState({ humanResourceOptionValue: newOption });
  }

  clearOptions() {
    this.setState({
      resourceOptionValue: null,
      vehicleOptionValue: null,
      humanResourceOptionValue: null
    });
  }

  addResources() {
    const { onAdd } = this.props;
    const selectedResource = this.state.resourceOptionValue;
    const selectedVehicle = this.state.vehicleOptionValue;
    const selectedHumanResources = this.state.humanResourceOptionValue;

    const data = {
      resources: [],
      humanResources: [],
      vehicles: []
    };

    if (selectedResource) {
      data.resources = [selectedResource];
    }
    if (selectedVehicle) {
      data.vehicles = [selectedVehicle];
    }
    if (selectedHumanResources) {
      data.humanResources = selectedHumanResources;
    }

    onAdd(data);
    this.clearOptions();
  }

  render() {
    const { disabled } = this.props;
    const {
      resourceOptions,
      vehicleOptions,
      humanResourceOptions,
      resourceOptionValue,
      vehicleOptionValue,
      humanResourceOptionValue
    } = this.state;

    return (
      <Fragment>
        <div className="form-group col-12 pt-3 border-top">
          <Select
            isClearable={false}
            isDisabled={disabled}
            value={resourceOptionValue}
            onChange={this.changeSelectedResource}
            placeholder="Maschine wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{
              Option: ResourceSelect.CustomResourceOption,
              SingleValue: ResourceSelect.CustomResourceSingleValue
            }}
            options={resourceOptions}
            filterOption={ResourceSelect.selectFilter}
          />
        </div>
        <div className="form-group col-12">
          <Select
            isClearable={false}
            isDisabled={disabled}
            value={vehicleOptionValue}
            onChange={this.changeSelectedVehicle}
            placeholder="Fahrzeug wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{
              Option: ResourceSelect.CustomResourceOption,
              SingleValue: ResourceSelect.CustomResourceSingleValue
            }}
            options={vehicleOptions}
            filterOption={ResourceSelect.selectFilter}
          />
        </div>
        <div className="form-group col-12">
          <Select
            isMulti
            isSearchable
            isClearable={false}
            isDisabled={disabled}
            styles={ResourceSelect.humanResourceSelectStyles}
            value={humanResourceOptionValue}
            onChange={this.changeSelectedHumanResource}
            placeholder="Mitarbeiter wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{ Option: ResourceSelect.CustomHumanResourceOption }}
            options={humanResourceOptions}
            filterOption={ResourceSelect.selectFilter}
          />
        </div>
        <div className="form-group col-12">
          <div className="row">
            <div className="col-4">
              <button
                className="btn btn-outline-primary w-100"
                onClick={this.clearOptions}
                disabled={disabled}>
                leeren
              </button>
            </div>
            <div className="col-4" />
            <div className="col-4">
              <button
                className="btn btn-primary w-100"
                onClick={this.addResources}
                disabled={disabled}>
                hinzufügen
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ResourceSelect.propTypes = {
  resources: PropTypes.array,
  humanResources: PropTypes.array,
  vehicles: PropTypes.array,
  disabled: PropTypes.bool,
  onAdd: PropTypes.func
};

export default ResourceSelect;

import Immutable from 'immutable';

const initialState = Immutable.Map({
  combinations: [],
  chosenCombination: null
});

export default (state = initialState, action) => {
  if (action.type === 'COMBINATIONS_FOUND') {
    return state.set('combinations', action.data);
  }

  if (action.type === 'SET_CHOSEN_COMBINATION') {
    return state.set('chosenCombination', action.data);
  }

  if (action.type === 'COMBINATION_ADDED') {
    const combinations = state.get('combinations');
    return state
      .set('combinations', combinations.concat(action.data))
      .set('chosenCombination', action.data);
  }

  if (action.type === 'COMBINATION_UPDATED') {
    const combinations = state.get('combinations');

    const nextCombinations = combinations.map(combination => {
      if (combination.id === action.data.id) {
        return action.data;
      }
      return combination;
    });

    return state
      .set('combinations', nextCombinations)
      .set('chosenCombination', action.data);
  }

  if (action.type === 'COMBINATION_DELETED') {
    const combinations = state.get('combinations');

    const nextCombinations = combinations.filter(
      combination => combination.id !== action.id
    );
    return state
      .set('combinations', nextCombinations)
      .set('chosenCombination', null);
  }

  return state;
};

import React, { Component } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

class Row extends Component {
  constructor(props) {
    super(props);

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleOtherChange = this.handleOtherChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleDepthRangeChange = this.handleDepthRangeChange.bind(this);
    this.handleAmountUnitChange = this.handleAmountUnitChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleTypeChange(e) {
    const { data, onTypeChange } = this.props;
    const { name } = e.target;
    onTypeChange(data.id, name);
  }

  handleOtherChange(e) {
    const { data, onOtherChange } = this.props;
    const { value } = e.target;
    onOtherChange(data.id, value);
  }

  handleQuantityChange(e) {
    const { data, onQuantityChange } = this.props;
    const { name, value } = e.target;
    onQuantityChange(data.id, name, value);
  }

  handleDepthRangeChange(e) {
    const { data, onQuantityChange } = this.props;
    const { checked } = e.target;
    onQuantityChange(data.id, "depthTo", checked ? 0 : null);
  }

  handleAmountUnitChange(e) {
    const { data, disabled, onValueChange } = this.props;
    if (disabled) return;

    const { unit } = e.target.dataset;
    onValueChange(data.id, "amountUnit", unit);
  }

  handleDelete(e) {
    const { data, onDelete } = this.props;
    onDelete(data.id);
  }

  render() {
    const { data, disabled } = this.props;
    const { width, depth, depthTo, amount, amountUnit } = data;

    const { scopeOfServiceTyps: types } = data;

    const checkboxes = {
      "fräsen und laden": false,
      "fräsen ohne laden": false,
      zertrümmern: false,
      schneiden: false,
      Asphalt: false,
      Beton: false,
      Homogenisieren: false,
      regie: false,
      entspannen: false,
      Einzelflächen: false,
      kehren: false,
      Aufmaß: false,
      stemmen: false,
      verbessern: false,
      verfestigen: false,
      nacharbeiten: false,
      "OTHER:": false
    };
    let otherValue = "";

    types.forEach(type => {
      if (checkboxes.hasOwnProperty(type.value)) {
        checkboxes[type.value] = true;
      }
      if (type.value.startsWith("OTHER:")) {
        otherValue = type.value.substr(6);
        checkboxes["OTHER:"] = true;
      }
    });

    const depthRangeEnabled = depthTo !== undefined && depthTo !== null;

    return (
      <div className="row">
        <div className="col-11">
          <div className="row">
            <div className="form-group col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  checked={checkboxes["fräsen und laden"]}
                  onChange={this.handleTypeChange}
                  name="fräsen und laden"
                  id={`fräsen und laden${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`fräsen und laden${data.id}`}>
                  fräsen <strong>und</strong> laden
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes["fräsen ohne laden"]}
                  name="fräsen ohne laden"
                  id={`fräsen ohne laden${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`fräsen ohne laden${data.id}`}>
                  fräsen <strong>ohne</strong> laden
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.stemmen}
                  name="stemmen"
                  id={`stemmen${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`stemmen${data.id}`}>
                  stemmen
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.verbessern}
                  name="verbessern"
                  id={`verbessern${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`verbessern${data.id}`}>
                  verbesssern
                </label>
              </div>
            </div>
            <div className="form-group col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes["zertrümmern"]}
                  name="zertrümmern"
                  id={`zertrümmern${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`zertrümmern${data.id}`}>
                  zertrümmern
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.schneiden}
                  name="schneiden"
                  id={`schneiden${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`schneiden${data.id}`}>
                  schneiden
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.kehren}
                  name="kehren"
                  id={`kehren${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`kehren${data.id}`}>
                  kehren
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.Aufmaß}
                  name="Aufmaß"
                  id={`Aufmaß${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`Aufmaß${data.id}`}>
                  Aufmaß
                </label>
              </div>
            </div>
            <div className="form-group col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.Asphalt}
                  name="Asphalt"
                  id={`Asphalt${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`Asphalt${data.id}`}>
                  Asphalt
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.Beton}
                  name="Beton"
                  id={`Beton${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`Beton${data.id}`}>
                  Beton
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.regie}
                  name="regie"
                  id={`regie${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`regie${data.id}`}>
                  Regie
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.Homogenisieren}
                  name="Homogenisieren"
                  id={`Homogenisieren${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`Homogenisieren${data.id}`}>
                  Homogenisieren
                </label>
              </div>
            </div>
            <div className="form-group col-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.entspannen}
                  name="entspannen"
                  id={`entspannen${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`entspannen${data.id}`}>
                  entspannen
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes["Einzelflächen"]}
                  name="Einzelflächen"
                  id={`Einzelflächen${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`Einzelflächen${data.id}`}>
                  Einzelflächen
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.verfestigen}
                  name="verfestigen"
                  id={`verfestigen${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`verfestigen${data.id}`}>
                  verfestigen
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes.nacharbeiten}
                  name="nacharbeiten"
                  id={`nacharbeiten${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`nacharbeiten${data.id}`}>
                  nacharbeiten
                </label>
              </div>
            </div>
            <div className="form-group col-auto">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={disabled}
                  onChange={this.handleTypeChange}
                  checked={checkboxes["OTHER:"]}
                  name="OTHER:"
                  id={`OTHER:${data.id}`}
                />
                <label
                  className="form-check-label small"
                  htmlFor={`OTHER:${data.id}`}>
                  Sonstiges
                </label>
              </div>
            </div>
            <div className="col form-group">
              <input
                className="form-control form-control-sm"
                type="text"
                value={otherValue}
                disabled={disabled || !checkboxes["OTHER:"]}
                onChange={this.handleOtherChange}
              />
            </div>
          </div>
        </div>
        <div className="col-1">
          <button
            className="btn btn-outline-secondary btn-sm"
            style={{ width: 32 }}
            onClick={this.handleDelete}
            disabled={disabled}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="form-group col-4">
          <div>
            <label className="form-check-label small">Breite (ca. in m)</label>
            <input
              type="number"
              className="form-control"
              name="width"
              value={width}
              min="0"
              onChange={this.handleQuantityChange}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-group col-4">
          <div>
            <label className="form-check-label small w-100">
              Tiefe (ca. in cm)
              <input
                className=" float-right mt-1 ml-2"
                type="checkbox"
                disabled={disabled}
                onChange={this.handleDepthRangeChange}
                checked={depthRangeEnabled}
                name="depthRange"
              />
              <span className="float-right">von - bis</span>
            </label>
            <div className="row">
              <div className={`col-${depthRangeEnabled ? "6" : "12"}`}>
                <input
                  type="number"
                  className="form-control"
                  name="depth"
                  value={depth}
                  min="0"
                  onChange={this.handleQuantityChange}
                  disabled={disabled}
                />
              </div>
              {depthRangeEnabled ? (
                <div className="col-6">
                  <input
                    type="number"
                    className="form-control"
                    name="depthTo"
                    value={depthTo}
                    min="0"
                    onChange={this.handleQuantityChange}
                    disabled={disabled}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group col-4">
          <div>
            <label className="form-check-label small">
              Menge (ca. in&nbsp;
              <span
                className={`badge badge-${
                  amountUnit === "qm" ? "primary" : "secondary"
                }`}
                data-unit="qm"
                onClick={this.handleAmountUnitChange}>
                qm
              </span>
              &nbsp;/&nbsp;
              <span
                className={`badge badge-${
                  amountUnit === "cbm" ? "primary" : "secondary"
                }`}
                data-unit="cbm"
                onClick={this.handleAmountUnitChange}>
                cbm
              </span>
              )
            </label>
            <div className="row">
              <div className="col-12">
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  value={amount}
                  min="0"
                  onChange={this.handleQuantityChange}
                  disabled={disabled}
                />
              </div>
              {/* <div className="col-4"> */}
              {/* <select className="form-control"> */}
              {/* <option value="qm">qm</option> */}
              {/* <option value="cbm">cbm</option> */}
              {/* </select> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Row.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onTypeChange: PropTypes.func,
  onOtherChange: PropTypes.func,
  onQuantityChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onDelete: PropTypes.func
};

export default Row;

import gql from 'graphql-tag';

import createContact from './createContact';

export default (
  client,
  company,
  contacts = null
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createCompany(
          $name: String
          $adress: String
          $zipcode: String
          $city: String
          $phone: String
          $fax: String
          $email: String
        ) {
          createCompany(
            name: $name
            adress: $adress
            zipcode: $zipcode
            city: $city
            phone: $phone
            fax: $fax
            email: $email
          ) {
            id
            name
            adress
            zipcode
            city
            phone
            fax
            email
          }
        }
      `,
      variables: {
        name: company.name,
        adress: company.adress,
        zipcode: company.zipcode,
        city: company.city,
        phone: company.phone,
        fax: company.fax,
        email: company.email
      }
    })
    .then(response => {
      const companyId = response.data.createCompany.id;
      if (contacts) {
        contacts.forEach(contact =>
          dispatch(
            createContact(
              client,
              contact.salutation,
              contact.lastname,
              contact.firstname,
              contact.phone,
              contact.position,
              contact.mobile,
              contact.email,
              contact.fax,
              companyId
            )
          )
        );
      }
    });
};

import gql from 'graphql-tag';
import { CostCenterFrameData } from './fragments';

export default (client, frame) => dispatch => {
  const query = gql`
    mutation createCostCenterFrame(
      $from: Int
      $to: Int
      $establishment: String
    ) {
      createCostCenterFrame(
        from: $from
        to: $to
        establishment: $establishment
      ) {
        ...CostCenterFrameData
      }
    }
    ${CostCenterFrameData}
  `;
  client
    .mutate({
      mutation: query,
      variables: {
        from: frame.from,
        to: frame.to,
        establishment: frame.establishment
      }
    })
    .then(response => {
      dispatch({
        type: 'COST_CENTER_FRAME_ADDED',
        data: response.data.createCostCenterFrame
      });
    });
};

import gql from 'graphql-tag';
import { OrderOverview } from './fragments';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription createOrder($user: String!) {
          createOrder(user: $user) {
            id
            order {
              ...OrderOverview
            }
          }
        }
        ${OrderOverview}
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { createOrder } = data;
        dispatch({
          type: 'ORDER_ADDED',
          data: createOrder.order,
          preventUpdate: true
        });
      }
    });
};

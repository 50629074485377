import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './style.css';

class Modal extends Component {
  static defaultProps = {
    submitEnabled: true,
    showControls: true,
    titleText: 'Message',
    closeBtnText: 'Close',
    submitBtnText: 'Save changes',
    onRender: () => null
  };

  constructor() {
    super();
    this.state = {
      shouldRender: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) {
      this.setState({ shouldRender: true });
    } else if (prevProps.show && !this.props.show) {
      setTimeout(() => this.setState({ shouldRender: false }), 300);
    }

    if (!prevState.shouldRender && this.state.shouldRender)
      this.props.onRender();
  }

  static preventEvent(e) {
    e.stopPropagation();
  }

  render() {
    const {
      children,
      show,
      submitEnabled,
      showControls,
      titleText,
      closeBtnText,
      submitBtnText,
      modalDialogStyle,
      modalContentStyle,
      onClose,
      onSubmit
    } = this.props;
    const { shouldRender } = this.state;

    if (shouldRender) {
      return ReactDOM.createPortal(
        <CSSTransition
          in={show}
          appear={true}
          timeout={500}
          classNames="fade-opacity"
          onMouseDown={Modal.preventEvent}
          onMouseMove={Modal.preventEvent}
          onMouseUp={Modal.preventEvent}
          onClick={Modal.preventEvent}>
          <div className="c-modal-container">
            <CSSTransition
              in={show}
              appear={true}
              timeout={500}
              classNames="fade-top">
              <div className="c-modal-wrapper">
                <div
                  className="modal-dialog modal-lg shadow"
                  style={modalDialogStyle}
                  role="document">
                  <div className="modal-content" style={modalContentStyle}>
                    <div className="modal-header">
                      <h5 className="modal-title">{titleText}</h5>
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">{children}</div>
                    {showControls ? (
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={onClose}>
                          {closeBtnText}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={!submitEnabled}
                          onClick={onSubmit}>
                          {submitBtnText}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </CSSTransition>,
        document.body
      );
    }
    return null;
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  // Controlling
  show: PropTypes.bool,
  submitEnabled: PropTypes.bool,
  showControls: PropTypes.bool,
  // Text
  titleText: PropTypes.string,
  closeBtnText: PropTypes.string,
  submitBtnText: PropTypes.string,
  // Styling
  modalDialogStyle: PropTypes.object,
  modalContentStyle: PropTypes.object,
  // Callbacks
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onRender: PropTypes.func
};

export default Modal;

import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferAccountingState(
    $id: ID!
    $accountingState: Boolean!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferAccountingState(id: $id, accountingState: $accountingState) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, accountingState) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      accountingState
    }
  });

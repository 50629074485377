import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferAllowanceValid(
    $id: ID!
    $allowanceValid: Boolean!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferAllowanceValid(id: $id, allowanceValid: $allowanceValid) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, value) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      allowanceValid: value
    }
  });

import gql from 'graphql-tag';
import { WorkshopTaskData } from './fragments';
import { startOfDay, endOfDay } from '../util/dateHelper';

import createReservation from './createReservation';
import store from '../store';

export default (client, task, date) => dispatch => {
  const start = date ? startOfDay(date).toISOString() : null;
  const end = date ? endOfDay(date).toISOString() : null;
  const user = store.getState().main.get('loginName');

  let startDate = null;
  if (task.startDate) {
    if (typeof task.startDate === 'string' && task.startDate !== '') {
      startDate = new Date(task.startDate).toISOString();
    } else {
      startDate = task.startDate.toISOString();
    }
  }

  let dueDate = null;
  if (task.dueDate) {
    if (typeof task.dueDate === 'string' && task.dueDate !== '') {
      dueDate = new Date(task.dueDate).toISOString();
    } else {
      dueDate = task.dueDate.toISOString();
    }
  }

  client
    .mutate({
      mutation: gql`
        mutation createWorkshopTask(
          $creator: String!
          $resourceType: String
          $resource_id: ID
          $description: String
          $workspaces: String
          $externalWorkshop: String
          $externalMachine: String
          $workers: String
          $startDate: String
          $dueDate: String
          $column: Int
          $start: Date
          $end: Date
          $establishment: Establishment
        ) {
          createWorkshopTask(
            creator: $creator
            resourceType: $resourceType
            resource_id: $resource_id
            description: $description
            workspaces: $workspaces
            externalWorkshop: $externalWorkshop
            externalMachine: $externalMachine
            workers: $workers
            startDate: $startDate
            dueDate: $dueDate
            column: $column
            establishment: $establishment
          ) {
            ...WorkshopTaskData
          }
        }
        ${WorkshopTaskData}
      `,
      variables: {
        creator: user,
        resourceType: task.resourceType,
        resource_id: task.resource ? task.resource.id : null,
        description: task.description,
        workspaces: JSON.stringify(
          task.workspaces.map(workspace => parseInt(workspace.id, 10))
        ),
        externalWorkshop: task.externalWorkshop,
        externalMachine: task.externalMachine,
        workers: JSON.stringify(
          task.workers.map(worker => parseInt(worker.id, 10))
        ),
        startDate,
        dueDate,
        column: 0,
        start,
        end,
        establishment: task.establishment
      }
    })
    .then(response => {
      const data = response.data.createWorkshopTask;

      dispatch({
        type: 'TASK_ADDED',
        data
      });

      if (startDate && dueDate) {
        createReservation(
          dispatch,
          client,
          data.resource.id,
          null,
          data.resourceType,
          startDate,
          dueDate,
          `WS_${data.id}_${data.description}`,
          []
        );
      }
    });
};

import React from 'react';
import PropTypes from 'prop-types';

const SectionHeading = props => (
  <div className="col-12 my-3">
    <h4 className="border-bottom-data-h2 mb-0">{props.text}</h4>
  </div>
);

SectionHeading.propTypes = {
  text: PropTypes.string
};

export default SectionHeading;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy, reverse } from 'lodash';

import './style.css';

class SimpleTable extends Component {
  constructor(props) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  static defaultProps = {
    hoverClassName: 'st-row-hover',
    mapping: [],
    selected: null,
    reverseSort: false,
    doubleClick: false,
    onRowSelected: () => {},
    onColSelected: () => {},
    headRenderer: null,
    colRenderer: null
  };

  static prepareDataRenderers(mapping, dataRenderers) {
    const preparedDataRederers = mapping.map(elem => {
      const { key } = elem;
      if (key === '__this__') {
        return obj => obj;
      }
      return obj => obj[key];
    });

    if (dataRenderers) {
      Object.keys(dataRenderers).forEach(key => {
        const index = mapping.findIndex(elem => elem.key === key);
        const { alternateKey } = mapping.find(elem => elem.key === key);

        if (index >= 0) {
          if (key === '__this__') {
            preparedDataRederers[index] = dataRenderers[key];
          } else if (alternateKey) {
            preparedDataRederers[index] = obj => {
              if (!obj[key]) {
                return dataRenderers[key](obj[alternateKey]);
              }
              return dataRenderers[key](obj[key]);
            };
          } else {
            preparedDataRederers[index] = obj => dataRenderers[key](obj[key]);
          }
        }
      });
    }

    return preparedDataRederers;
  }

  handleRowClick(e, elem) {
    const { onRowSelected } = this.props;
    if (!e.isPropagationStopped()) {
      onRowSelected(elem);
    }
  }

  render() {
    const {
      className,
      hoverClassName,
      mapping,
      viewRange,
      selected,
      sortKey,
      reverseSort,
      data,
      dataRenderers,
      headRenderer,
      colRenderer,
      doubleClick,
      onColSelected
    } = this.props;

    let renderData = data;

    // Sort first.
    if (sortKey) {
      renderData = sortBy(renderData, [sortKey]);

      if (reverseSort) renderData = reverse(renderData);
    }

    // Then get the range.
    if (viewRange && viewRange.length === 2)
      renderData = data.slice(viewRange[0], viewRange[1] - viewRange[0]);

    const preparedDataRenderers = SimpleTable.prepareDataRenderers(
      mapping,
      dataRenderers
    );

    const rowClassName =
      hoverClassName === '' ? 'row' : `row ${hoverClassName}`;

    let items = null;
    if (colRenderer) {
      items = renderData.map((elem, i) => {
        let thisRowClassName = rowClassName;
        if (selected && elem[selected[0]] === selected[1])
          thisRowClassName += ' st-row-selected';
        return (
          <div
            key={elem.id}
            className={thisRowClassName}
            onClick={doubleClick ? null : e => this.handleRowClick(e, elem)}
            onDoubleClick={
              doubleClick ? e => this.handleRowClick(e, elem) : null
            }>
            {colRenderer(elem, 'col border-bottom border-right p-2')}
          </div>
        );
      });
    } else {
      items = renderData.map((elem, i) => {
        let thisRowClassName = rowClassName;
        if (selected && elem[selected[0]] === selected[1])
          thisRowClassName += ' st-row-selected';
        return (
          <div
            key={i}
            className={thisRowClassName}
            onClick={e => this.handleRowClick(e, elem)}>
            {mapping.map((m, j) => (
              <div
                key={`${i}${j}`}
                className="col border-bottom border-right p-2">
                {preparedDataRenderers[j](elem)}
              </div>
            ))}
          </div>
        );
      });
    }

    return (
      <div className={className}>
        <div className="row">
          <div className="col-md-12 small">
            <div className="row border-left border-top border-bottom st-thead">
              {headRenderer
                ? headRenderer('col bg-light p-2 border-right border-bottom')
                : mapping.map(elem => (
                    <div
                      key={elem.key}
                      className="col bg-light p-2 border-right border-bottom"
                      onClick={() => onColSelected(elem.key)}>
                      {elem.label}
                    </div>
                  ))}
            </div>
            <div className="st-tbody border-left">{items}</div>
          </div>
        </div>
      </div>
    );
  }
}

SimpleTable.propTypes = {
  className: PropTypes.string,
  hoverClassName: PropTypes.string,
  mapping: PropTypes.array,
  viewRange: PropTypes.array,
  selected: PropTypes.array,
  sortKey: PropTypes.string,
  reverseSort: PropTypes.bool,
  data: PropTypes.array,
  dataRenderers: PropTypes.object,
  headRenderer: PropTypes.func,
  colRenderer: PropTypes.func,
  doubleClick: PropTypes.bool,
  onRowSelected: PropTypes.func,
  onRowDblClick: PropTypes.func,
  onColSelected: PropTypes.func
};

export default SimpleTable;

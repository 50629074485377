import gql from "graphql-tag";
import { OrderMaxiOverview } from "./fragments";

const Query = gql`
  mutation deleteOrderInvoice(
    $id: ID!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    deleteOrderInvoice(id: $id) {
      ...OrderMaxiOverview
    }
  }
  ${OrderMaxiOverview}
`;

export default (client, id) => {
  return client
    .mutate({
      mutation: Query,
      variables: {
        id
      }
    })
    .then(response => {
      console.log(response);
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { faUser, faTruckMoving, faCar } from "@fortawesome/pro-solid-svg-icons";
import {
  faCheckCircle,
  faCircle,
  faArrowRight,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import deleteReservation from "../../../actions/deleteReservation";
import deleteHumanResourceFromOrder from "../../../actions/deleteHumanResourceFromOrder";
import { GetTimePercentage } from "../../../util/dateHelper";
import createDispoHumanResourceResource from "../../../actions/createDispoHumanResourceResource";
import getOrder from "../../../actions/getOrder";
import deleteDispoHumanResourceResource from "../../../actions/deleteDispoHumanResourceResource";
import { faHistory } from "@fortawesome/pro-light-svg-icons";

class List extends Component {
  constructor() {
    super();
    this.state = {
      humanResources: [],
      resources: [],
      vehicles: [],
      machineTimeAdjust: [],
      vehicleTimeAdjust: [],
      humanResourceTimeAdjust: [],
      startTime: "",
      endTime: ""
    };

    this.removeResource = this.removeResource.bind(this);
    this.removeHumanResource = this.removeHumanResource.bind(this);
    this.removeVehicle = this.removeVehicle.bind(this);
    this.setResourceHumanResourceTime = this.setResourceHumanResourceTime.bind(
      this
    );
    this.addResourceHumanResource = this.addResourceHumanResource.bind(this);
    this.startTimeChange = this.startTimeChange.bind(this);
    this.endTimeChange = this.endTimeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const {
      startDate,
      endDate,
      humanResources,
      vehicles,
      resources
    } = this.props;
    const nextHumanResources = List.mapHumanResources(
      humanResources,
      startDate,
      endDate
    );
    vehicles.forEach(v => (v.check = false));
    resources.forEach(r => (r.check = false));
    this.setState({ humanResources: nextHumanResources, vehicles, resources });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      startDate,
      endDate,
      humanResources,
      resources,
      vehicles,
      disabled
    } = this.props;
    if (prevProps.humanResources !== humanResources) {
      const nextHumanResources = List.mapHumanResources(
        humanResources,
        startDate,
        endDate
      );
      vehicles.forEach(v => (v.check = false));
      resources.forEach(r => (r.check = false));
      this.setState({
        humanResources: nextHumanResources
      });
    }
    if (prevProps.resources !== resources) {
      this.setState({ resources });
    }
    if (prevProps.vehicles !== vehicles) {
      this.setState({ vehicles });
    }
    if (!prevProps.disabled && disabled) {
      this.setState({
        machineTimeAdjust: [],
        vehicleTimeAdjust: [],
        humanResourceTimeAdjust: []
      });
    }
  }

  static mapHumanResources(humanResources, startDate, endDate) {
    return humanResources.map(hr => ({
      resourceId: hr.id,
      id: hr.humanResource.id,
      name:
        hr.humanResource.label ||
        `${hr.humanResource.firstname} ${hr.humanResource.lastname}`,
      color: List.getColorFromAbsences(
        hr.humanResource.absences,
        startDate,
        endDate
      ),
      additional: List.getCutTime(
        hr.humanResource.absences,
        startDate,
        endDate
      ),
      note: hr.note ? hr.note : "",
      from: hr.from ? hr.from : "",
      to: hr.to ? hr.to : "",
      resource_ids: hr.resource_ids ? hr.resource_ids : [],
      check: false
    }));
  }

  static getColorFromAbsences(absences, startDate, endDate) {
    let color = "#FFFFFF00";

    if (absences && startDate && endDate) {
      let isPartiallyAbsent = false;
      let isFullyAbsent = false;

      const start = moment(startDate);
      const end = moment(endDate);
      absences.forEach(absence => {
        const from = moment(absence.from);
        const to = moment(absence.to);

        isPartiallyAbsent =
          isPartiallyAbsent ||
          from.isBetween(start, end) ||
          to.isBetween(start, end);
        isFullyAbsent =
          isFullyAbsent ||
          (from.valueOf() <= start.valueOf() && to.valueOf() >= end.valueOf());
      });

      if (isPartiallyAbsent) color = "#FFAB0044";
      if (isFullyAbsent) color = "#DD202044";
    }

    return color;
  }

  static getCutTime(absences, startDate, endDate) {
    let text = "";
    if (absences && startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      absences.forEach(absence => {
        const from = moment(absence.from);
        const to = moment(absence.to);
        if (from.isBetween(start, end)) {
          text = ` (${from.format("DD.MM.")} - ${end.format("DD.MM.YYYY")})`;
        }
        if (to.isBetween(start, end)) {
          text = ` (${start.format("DD.MM")} - ${to.format("DD.MM.YYYY")})`;
        }
        if (from.isBefore(start) && to.isAfter(end)) {
          text = ` (${start.format("DD.MM.")} - ${end.format("DD.MM.YYYY")})`;
        }
        if (
          text.length > 0 &&
          (from.isSame(to, "day") || start.isSame(end, "day"))
        ) {
          text = ` (${start.format("DD.MM.YYYY")})`;
        }
      });
    }
    return text;
  }

  addResourceHumanResource = hr => {
    const { resources, vehicles } = this.state;
    const { orderId, dispatch, client } = this.props;
    resources.forEach(r => {
      if (
        r.check &&
        !hr.resource_ids.find(
          instance => instance.resource_id === r.resource.id
        )
      ) {
        dispatch(
          createDispoHumanResourceResource(
            client,
            hr.resourceId,
            r.resource.id,
            orderId
          )
        );
      }
    });
    vehicles.forEach(v => {
      if (
        v.check &&
        !hr.resource_ids.find(
          instance => instance.resource_id === v.resource.id
        )
      ) {
        dispatch(
          createDispoHumanResourceResource(
            client,
            hr.resourceId,
            v.resource.id,
            orderId
          )
        );
      }
    });
    dispatch(getOrder(client, orderId));
  };

  setResourceHumanResourceTime = r => {
    const { humanResources, resources, vehicles } = this.state;
    const { handleResourceHumanResourceVehicleDateChange } = this.props;
    humanResources.forEach(hr => {
      if (hr.resource_ids.find(id => id.resource_id === r.resource.id)) {
        let from = r.from;
        let to = r.to;
        hr.resource_ids.forEach(id => {
          resources.forEach(r => {
            if (r.resource.id === id.resource_id) {
              const rFrom = moment(r.from);
              const rTo = moment(r.to);
              if (rFrom.isBefore(from)) {
                from = rFrom;
              }
              if (rTo.isAfter(to)) {
                to = rTo;
              }
            }
          });
          vehicles.forEach(v => {
            if (v.resource.id === id.resource_id) {
              const vFrom = moment(v.from);
              const vTo = moment(v.to);
              if (vFrom.isBefore(from)) {
                from = vFrom;
              }
              if (vTo.isAfter(to)) {
                to = vTo;
              }
            }
          });
        });
        handleResourceHumanResourceVehicleDateChange([from, to], hr, "staff");
      }
    });
  };

  handleDateChange(instance, type) {
    if (instance && type) {
      const {
        machineTimeAdjust,
        vehicleTimeAdjust,
        humanResourceTimeAdjust
      } = this.state;
      switch (type) {
        case "machine":
          if (!machineTimeAdjust.includes(instance.id))
            this.setState({
              machineTimeAdjust: machineTimeAdjust.concat(instance.id)
            });
          break;
        case "vehicle":
          if (!vehicleTimeAdjust.includes(instance.id))
            this.setState({
              vehicleTimeAdjust: vehicleTimeAdjust.concat(instance.id)
            });
          break;
        case "staff":
          if (!humanResourceTimeAdjust.includes(instance.resourceId))
            this.setState({
              humanResourceTimeAdjust: humanResourceTimeAdjust.concat(
                instance.resourceId
              )
            });
          break;
        default:
          break;
      }
    }
  }

  startTimeChange(e) {
    if (e) {
      this.setState({ startTime: e.target.value });
    }
  }

  endTimeChange(e) {
    if (e) {
      this.setState({ endTime: e.target.value });
    }
  }

  removeResource(event, reservationId, resourceId, disabled) {
    if (disabled) return;
    event.preventDefault();

    const { client, dispatch, onActionNoOrder, orderId } = this.props;
    if (orderId) {
      dispatch(
        deleteReservation(
          client,
          reservationId,
          resourceId,
          "RESOURCE",
          orderId
        )
      );
    } else {
      onActionNoOrder("list", resourceId, "RESOURCE");
    }
  }

  removeVehicle(event, reservationId, resourceId, disabled) {
    if (disabled) return;
    event.preventDefault();

    const { client, dispatch, onActionNoOrder, orderId } = this.props;
    if (orderId) {
      dispatch(
        deleteReservation(client, reservationId, resourceId, "VEHICLE", orderId)
      );
    } else {
      onActionNoOrder("list", resourceId, "VEHICLE");
    }
  }

  removeHumanResource(event, humanResource, disabled) {
    if (disabled) return;
    event.preventDefault();

    const { client, dispatch, onActionNoOrder, orderId } = this.props;
    if (orderId) {
      dispatch(
        deleteHumanResourceFromOrder(client, humanResource.resourceId, orderId)
      );
    } else {
      onActionNoOrder("list", humanResource.id, "HUMAN_RESOURCE");
    }
  }

  render() {
    const {
      disabled,
      handleResourceNoteChange,
      handleResourceHumanResourceVehicleDateChange,
      handleHumanNoteChange,
      handleVehicleNoteChange,
      opStartDate,
      opFinishDate,
      dispatch,
      orderId,
      client
    } = this.props;
    const {
      humanResources,
      resources,
      vehicles,
      machineTimeAdjust,
      vehicleTimeAdjust,
      humanResourceTimeAdjust
    } = this.state;

    return (
      <Fragment>
        <ul className="list-group">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <b>Maschinen</b>
            <FontAwesomeIcon
              className="fa-w-20 text-black-50"
              icon={faTruckMoving}
            />
          </li>
          {resources.map((r, index) => (
            <li
              key={index + r.id}
              className="list-group-item d-flex justify-content-between align-items-center py-1 px-4"
            >
              <div className="row no-gutters" style={{ width: "100%" }}>
                <div className="col-4 py-2">
                  <span
                    className="rounded"
                    style={{
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      background: r.color
                    }}
                    title={
                      r.resource
                        ? `${r.resource.name} / ${r.resource.number}`
                        : ""
                    }
                  >
                    {!disabled &&
                      humanResources.length > 0 &&
                      (r.check ? (
                        <FontAwesomeIcon
                          className="fa-w-20 text-black-50"
                          icon={faCheckCircle}
                          onClick={() => {
                            const newArr = resources;
                            resources.forEach(res => {
                              if (res.id === r.id) {
                                res.check = false;
                              }
                            });
                            this.setState({ resources: newArr });
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fa-w-20 text-black-50"
                          icon={faCircle}
                          onClick={() => {
                            const newArr = resources;
                            resources.forEach(res => {
                              if (res.id === r.id) {
                                res.check = true;
                              }
                            });
                            this.setState({ resources: newArr });
                          }}
                        />
                      ))}
                    {humanResources
                      .map(hr => {
                        const id = hr.resource_ids.map(id => {
                          return !!(
                            r.resource && id.resource_id === r.resource.id
                          );
                        });
                        return id && id.length > 0 && id.includes(true);
                      })
                      .includes(true) ? (
                      <FontAwesomeIcon
                        className="fa-w-20 text-black-50"
                        icon={faUser}
                      />
                    ) : (
                      <span
                        style={{ display: "inline-block", minWidth: "20px" }}
                      />
                    )}
                    {r.resource
                      ? `${r.resource.name} / ${r.resource.number}`
                      : ""}
                  </span>
                </div>
                <div className="col-5 py-0">
                  <input
                    type="text"
                    className="w-100 h-100 form-control"
                    value={r.note ? r.note : ""}
                    onChange={e =>
                      handleResourceNoteChange(
                        e,
                        r.id ? r.id : "",
                        r.resource.id
                      )
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-2 py-0">
                  <GetTimePercentage
                    disabled={disabled}
                    type={"machine"}
                    instance={r}
                    startDate={opStartDate}
                    endDate={opFinishDate}
                    startDateInstance={r.from}
                    endDateInstance={r.to}
                    handleChange={handleResourceHumanResourceVehicleDateChange}
                    handleDateChange={this.handleDateChange}
                  />
                </div>
                <div className="col-1 py-2 pr-1">
                  {machineTimeAdjust.includes(r.id) && (
                    <FontAwesomeIcon
                      className={`fa-btn-secondary${
                        disabled ? "-disabled" : ""
                      } hidden-print`}
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "50px"
                      }}
                      icon={faHistory}
                      title={"Zeiten anpassen"}
                      onClick={() => {
                        this.setResourceHumanResourceTime(r);
                        this.setState({
                          machineTimeAdjust: machineTimeAdjust.map(
                            id => id !== r.id
                          )
                        });
                      }}
                    />
                  )}
                  <FontAwesomeIcon
                    className={`fa-btn-secondary${
                      disabled ? "-disabled" : ""
                    } hidden-print`}
                    style={{ position: "absolute", top: "12px", right: "-2px" }}
                    icon={faTimesCircle}
                    onClick={e =>
                      this.removeResource(e, r.id, r.resource.id, disabled)
                    }
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ul className="list-group mt-3">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <b>Fahrzeuge</b>
            <FontAwesomeIcon className="fa-w-20 text-black-50" icon={faCar} />
          </li>
          {vehicles.map((v, index) => (
            <li
              key={index + v.id + v.resource.id}
              className="list-group-item d-flex justify-content-between align-items-center px-4 py-1"
            >
              <div className="row no-gutters" style={{ width: "100%" }}>
                <div className="col-4 py-2">
                  <span
                    className="rounded"
                    style={{
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      background: v.color
                    }}
                    title={
                      v.resource
                        ? `${v.resource.brand} ${v.resource.type} / ${v.resource.licensePlate}`
                        : ""
                    }
                  >
                    {!disabled &&
                      humanResources.length > 0 &&
                      (v.check ? (
                        <FontAwesomeIcon
                          className="fa-w-20 text-black-50"
                          icon={faCheckCircle}
                          onClick={() => {
                            const newArr = vehicles;
                            vehicles.forEach(ves => {
                              if (ves.id === v.id) {
                                ves.check = false;
                              }
                            });
                            this.setState({ vehicles: newArr });
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="fa-w-20 text-black-50"
                          icon={faCircle}
                          onClick={() => {
                            const newArr = vehicles;
                            vehicles.forEach(ves => {
                              if (ves.id === v.id) {
                                ves.check = true;
                              }
                            });
                            this.setState({ vehicles: newArr });
                          }}
                        />
                      ))}
                    {humanResources
                      .map(hr => {
                        const id = hr.resource_ids.map(id => {
                          return !!(
                            v.resource && id.resource_id === v.resource.id
                          );
                        });
                        return id && id.length > 0 && id.includes(true);
                      })
                      .includes(true) ? (
                      <FontAwesomeIcon
                        className="fa-w-20 text-black-50"
                        icon={faUser}
                      />
                    ) : (
                      <span
                        style={{ display: "inline-block", minWidth: "20px" }}
                      />
                    )}
                    {v.resource
                      ? `${v.resource.brand} ${v.resource.type} / ${v.resource.licensePlate}`
                      : ""}
                  </span>
                </div>
                <div className="col-5 py-0">
                  <input
                    type="text"
                    className="w-100 h-100 form-control"
                    value={v.note ? v.note : ""}
                    onChange={e =>
                      handleVehicleNoteChange(
                        e,
                        v.id ? v.id : "",
                        v.resource.id
                      )
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-2 py-0">
                  <GetTimePercentage
                    disabled={disabled}
                    type={"vehicle"}
                    instance={v}
                    startDate={opStartDate}
                    endDate={opFinishDate}
                    startDateInstance={v.from}
                    endDateInstance={v.to}
                    handleChange={handleResourceHumanResourceVehicleDateChange}
                    handleDateChange={this.handleDateChange}
                  />
                </div>
                <div className="col-1 py-2 pr-1">
                  {vehicleTimeAdjust.includes(v.id) && (
                    <FontAwesomeIcon
                      className={`fa-btn-secondary${
                        disabled ? "-disabled" : ""
                      } hidden-print`}
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "50px"
                      }}
                      icon={faHistory}
                      title={"Zeiten anpassen"}
                      onClick={() => {
                        this.setResourceHumanResourceTime(v);
                        this.setState({
                          vehicleTimeAdjust: vehicleTimeAdjust.map(
                            id => id !== v.id
                          )
                        });
                      }}
                    />
                  )}
                  <FontAwesomeIcon
                    className={`fa-btn-secondary${
                      disabled ? "-disabled" : ""
                    } hidden-print`}
                    style={{ position: "absolute", top: "12px", right: "-2px" }}
                    icon={faTimesCircle}
                    onClick={e =>
                      this.removeVehicle(e, v.id, v.resource.id, disabled)
                    }
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ul className="list-group mt-3">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <b>Mitarbeiter</b>
            <FontAwesomeIcon className="fa-w-20 text-black-50" icon={faUser} />
          </li>
          {humanResources.map((hr, index) => (
            <li
              key={index + hr.id + hr.resourceId}
              className="list-group-item d-flex justify-content-between align-items-center px-4 py-1"
            >
              <div className="row no-gutters" style={{ width: "100%" }}>
                <div className="col-4 py-2">
                  <span
                    className="rounded"
                    style={{
                      overflow: "hidden",
                      display: "block",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:
                        hr.color.substring(hr.color.length - 2) === "00"
                          ? "#000000"
                          : hr.color.substring(0, hr.color.length - 2)
                    }}
                    title={hr.name + hr.additional}
                  >
                    {!disabled && (
                      <div
                        className="mx-2 btn btn-primary btn-sm"
                        title="Maschine zuweisen"
                        onClick={() => {
                          this.addResourceHumanResource(hr);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </div>
                    )}
                    {hr.name + hr.additional}
                  </span>
                </div>
                <div className="col-5 py-0">
                  <input
                    type="text"
                    className="w-100 h-100 form-control"
                    value={hr.note}
                    onChange={e =>
                      handleHumanNoteChange(
                        e,
                        hr.resourceId ? hr.resourceId : "",
                        hr.id
                      )
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-2 py-0">
                  <GetTimePercentage
                    disabled={disabled}
                    type={"staff"}
                    instance={hr}
                    startDate={opStartDate}
                    endDate={opFinishDate}
                    startDateInstance={hr.from}
                    endDateInstance={hr.to}
                    handleChange={handleResourceHumanResourceVehicleDateChange}
                    handleDateChange={this.handleDateChange}
                  />
                </div>
                <div className="col-1 py-2 pr-1">
                  {humanResourceTimeAdjust.includes(hr.resourceId) && (
                    <FontAwesomeIcon
                      className={`fa-btn-secondary${
                        disabled ? "-disabled" : ""
                      } hidden-print`}
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "50px"
                      }}
                      icon={faHistory}
                      title={"Zeiten anpassen"}
                      onClick={() => {
                        hr.resource_ids.forEach(instance => {
                          if (orderId === instance.orderId) {
                            const resource = resources.find(
                              r => r.resource.id === instance.resource_id
                            );
                            const vehicle = vehicles.find(
                              v => v.resource.id === instance.resource_id
                            );
                            if (resource) {
                              const date = [hr.from, hr.to];
                              handleResourceHumanResourceVehicleDateChange(
                                date,
                                resource,
                                "machine"
                              );
                            }
                            if (vehicle) {
                              handleResourceHumanResourceVehicleDateChange(
                                [hr.from, hr.to],
                                vehicle,
                                "vehicle"
                              );
                            }
                          }
                        });
                        this.setState({
                          humanResourceTimeAdjust: humanResourceTimeAdjust.map(
                            id => id !== hr.resourceId
                          )
                        });
                      }}
                    />
                  )}

                  <FontAwesomeIcon
                    className={`fa-btn-secondary${
                      disabled ? "-disabled" : ""
                    } hidden-print`}
                    style={{ position: "absolute", top: "12px", right: "-2px" }}
                    icon={faTimesCircle}
                    title={"löschen"}
                    onClick={e => this.removeHumanResource(e, hr, disabled)}
                  />
                </div>
                {hr.resource_ids.length > 0 && (
                  <div className="col-12 pt-2">
                    <div className="border-top">
                      <div style={{ maxWidth: "100%" }}>
                        {hr.resource_ids.map(rs => {
                          if (rs.orderId === orderId) {
                            const r = resources.find(
                              r => r.resource.id === rs.resource_id
                            );
                            const v = vehicles.find(
                              v => v.resource.id === rs.resource_id
                            );

                            if (v) {
                              return (
                                <span
                                  className="badge badge-secondary m-1"
                                  style={{ display: "inline-block" }}
                                  key={
                                    v.id +
                                    v.resource.brand +
                                    v.resource.type +
                                    v.resource.licensePlate +
                                    "badgeV" +
                                    rs.id +
                                    hr.id
                                  }
                                  onMouseEnter={() => {
                                    const newArr = vehicles;
                                    vehicles.forEach(ves => {
                                      if (ves.id === v.id) {
                                        ves.hover = hr.resourceId;
                                      }
                                    });
                                    this.setState({ vehicles: newArr });
                                  }}
                                  onMouseLeave={() => {
                                    const newArr = vehicles;
                                    vehicles.forEach(ves => {
                                      if (ves.id === v.id) {
                                        ves.hover = "";
                                      }
                                    });
                                    this.setState({ vehicles: newArr });
                                  }}
                                >
                                  {v.resource
                                    ? `${v.resource.brand} ${v.resource.type} / ${v.resource.licensePlate}`
                                    : ""}
                                  {!disabled && v.hover === hr.resourceId && (
                                    <FontAwesomeIcon
                                      className="ml-2 my-0 crossHover"
                                      style={{
                                        maxHeight: "100%",
                                        height: "inherit",
                                        padding: ""
                                      }}
                                      icon={faTimes}
                                      onClick={() => {
                                        const id = hr.resource_ids.find(
                                          instance =>
                                            instance.resource_id ===
                                            v.resource.id
                                        );
                                        if (id) {
                                          dispatch(
                                            deleteDispoHumanResourceResource(
                                              client,
                                              id.id
                                            )
                                          );
                                          dispatch(getOrder(client, orderId));
                                        }
                                      }}
                                    />
                                  )}
                                </span>
                              );
                            }
                            if (r) {
                              return (
                                <span
                                  className="badge badge-secondary m-1"
                                  style={{
                                    display: "inline-block"
                                  }}
                                  key={
                                    r.resource.name +
                                    r.resource.number +
                                    "badgeR" +
                                    rs.id +
                                    hr.id
                                  }
                                  onMouseEnter={() => {
                                    const newArr = resources;
                                    resources.forEach(res => {
                                      if (res.id === r.id) {
                                        res.hover = hr.resourceId;
                                      }
                                    });
                                    this.setState({ resources: newArr });
                                  }}
                                  onMouseLeave={() => {
                                    const newArr = resources;
                                    resources.forEach(res => {
                                      if (res.id === r.id) {
                                        res.hover = "";
                                      }
                                    });
                                    this.setState({ resources: newArr });
                                  }}
                                >
                                  {r.resource
                                    ? `${r.resource.name} / ${r.resource.number}`
                                    : ""}
                                  {!disabled && r.hover === hr.resourceId && (
                                    <FontAwesomeIcon
                                      className="ml-2 my-0 crossHover"
                                      style={{
                                        maxHeight: "100%",
                                        height: "inherit",
                                        padding: ""
                                      }}
                                      icon={faTimes}
                                      onClick={() => {
                                        const id = hr.resource_ids.find(
                                          instance =>
                                            instance.resource_id ===
                                            r.resource.id
                                        );
                                        if (id) {
                                          dispatch(
                                            deleteDispoHumanResourceResource(
                                              client,
                                              id.id
                                            )
                                          );
                                          dispatch(getOrder(client, orderId));
                                        }
                                      }}
                                    />
                                  )}
                                </span>
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  disabled: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resources: PropTypes.array,
  humanResources: PropTypes.array,
  vehicles: PropTypes.array,
  orderId: PropTypes.string,
  onAction: PropTypes.func,
  onActionNoOrder: PropTypes.func,
  handleResourceNoteChange: PropTypes.func,
  handleResourceHumanResourceVehicleDateChange: PropTypes.func,
  handleVehicleNoteChange: PropTypes.func,
  handleHumanNoteChange: PropTypes.func,
  handleConnectResourceHuman: PropTypes.func,
  opStartDate: PropTypes.string,
  opFinishDate: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client")
}))(List);

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import * as fileStack from "filestack-js";
import {
  FILESTACK_API_KEY, FILESTACK_API_KEY_NEW,
  FILESTACK_POLICY, FILESTACK_POLICY_NEW,
  FILESTACK_SIGNATURE, FILESTACK_SIGNATURE_NEW
} from "../Workshop/filestackCredentials";

const DropZone = ({
  className,
  style,
  children,
  onDrop,
  multi,
  accept,
  isFileStack
}) => {
  const [progress, setProgress] = useState();
  const [totalProgressObject, setTotalProgressObject] = useState({});
  const clientNew = useMemo(
    () =>
      fileStack.init(FILESTACK_API_KEY_NEW, {
        security: {
          policy: FILESTACK_POLICY_NEW,
          signature: FILESTACK_SIGNATURE_NEW
        }
      }),
    []
  );
  const onDropFunc = useCallback(
    droppedFiles => {
      if (isFileStack) {
        if (multi) {
          const filePromise = droppedFiles.map(file =>
            clientNew.upload(file, {
              progressInterval: 100,
              onProgress: evt =>
                setTotalProgressObject({
                  ...totalProgressObject,
                  [file.name]: evt.totalPercent
                })
            })
          );
          Promise.all(filePromise).then(resp => {
            setTotalProgressObject({});
            onDrop(resp.map(r => r.handle));
          });
        } else {
          clientNew
            .upload(droppedFiles[0], {
              progressInterval: 100,
              onProgress: evt => setProgress(evt.totalPercent)
            })
            .then(res => {
              setProgress();
              onDrop(res.handle);
            })
            .catch(err => console.error(err));
        }
      } else {
        onDrop(droppedFiles);
      }
    },
    [clientNew, isFileStack, multi, onDrop, totalProgressObject]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropFunc });

  let totalProgress = 0;
  if (multi) {
    const totalProgressEntries = Object.entries(totalProgressObject);
    totalProgress =
      totalProgressEntries
        .map(entry => entry[1])
        .reduce((acc, val) => acc + val, 0) / totalProgressEntries.length;

    if (Number.isNaN(totalProgress)) {
      totalProgress = 0;
    }
  }

  return (
    <div {...getRootProps()} className={className} style={style}>
      <input {...getInputProps()} accept={accept} />
      {children(multi ? totalProgress : progress)}
    </div>
  );
};

DropZone.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  accept: PropTypes.string,
  children: PropTypes.func,
  onDrop: PropTypes.func,
  multi: PropTypes.bool,
  isFileStack: PropTypes.bool
};

export default DropZone;

import gql from "graphql-tag";
import { HumanResourceData } from "./fragments";

export default (
  client,
  firstname,
  lastname,
  staff_id,
  establishment,
  phone,
  position,
  street,
  city,
  zipcode,
  username,
  status,
  reason,
  leavingDate
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createHumanResource(
          $firstname: String
          $lastname: String
          $staff_id: String
          $establishment: String
          $phone: String
          $position: String
          $street: String
          $city: String
          $zipcode: String
          $username: String
          $status: String
          $reason: String
          $leavingDate: Date
        ) {
          createHumanResource(
            firstname: $firstname
            lastname: $lastname
            staff_id: $staff_id
            establishment: $establishment
            phone: $phone
            position: $position
            street: $street
            city: $city
            zipcode: $zipcode
            username: $username
            status: $status
            reason: $reason
            leavingDate: $leavingDate
          ) {
            ...HumanResourceData
          }
        }
        ${HumanResourceData}
      `,
      variables: {
        firstname,
        lastname,
        staff_id,
        establishment,
        phone,
        position,
        street,
        city,
        zipcode,
        username,
        status,
        reason,
        leavingDate
      }
    })
    .then(response => {
      dispatch({
        type: "HUMAN_RESOURCE_ADDED",
        data: response.data.createHumanResource
      });
      window.history.pushState(
        {},
        null,
        `/human_resources/${response.data.createHumanResource.id}`
      );
    });
};

import Immutable from 'immutable';

const initialState = Immutable.Map({
  humanResources: [],
  modules: {
    Kontakte: 'contacts',
    Transport: 'transfers',
    Abrufe: 'order',
    'A_Abtransport und Verkippung': 'disposal',
    'A_Abruf kaufm. prüfen': 'order_check',
    'A_Abruf veröffentlichen': 'order_publish',
    Disposition: 'disposition',
    Kostenstelle: 'cost_center',
    'Kostenstellen-rahmen': 'cost_center_frame',
    Werkstatt: 'workshop',
    Maschinen: 'resources',
    Fahrzeuge: 'vehicles',
    Gespanne: 'combinations',
    Mitarbeiter: 'human_resources',
    Abwesenheiten: 'absences',
    Berechtigungen: 'permissions',
    Loki: 'Loki',
    Abrechnung: 'accounting',
    'D_Angebots-nachverfolgung': 'offer_tracking'
  }
});

export default (state = initialState, action) => {
  if (action.type === 'PERMISSIONS_FOUND') {
    return state.set('humanResources', action.data);
  }
  if (action.type === 'TOGGLE_READ_PERMISSION') {
    const humanResources = state.get('humanResources');
    const otherHumanResources = humanResources.filter(
      hr => hr.id !== action.id
    );
    let actualHumanResource = humanResources.filter(
      hr => hr.id === action.id
    )[0];
    const otherPermissions = actualHumanResource.permissions.filter(
      perm => perm.name !== action.name
    );
    const permArray = actualHumanResource.permissions.filter(
      perm => perm.name === action.name
    );
    let perm;
    if (permArray.length > 0) {
      perm = Object.assign({}, permArray[0]);
      perm.read = !action.actualRead;
      perm.write = action.actualRead === false ? false : perm.write;
    } else {
      perm = {
        name: action.name,
        read: !action.actualRead,
        write: false
      };
    }
    actualHumanResource = Object.assign({}, actualHumanResource);
    actualHumanResource.permissions = [].concat(...otherPermissions, perm);
    const updatedHumanResources = [].concat(
      ...otherHumanResources,
      actualHumanResource
    );
    return state.set('humanResources', updatedHumanResources);
  }
  if (action.type === 'TOGGLE_WRITE_PERMISSION') {
    const humanResources = state.get('humanResources');
    const otherHumanResources = humanResources.filter(
      hr => hr.id !== action.id
    );
    let actualHumanResource = humanResources.filter(
      hr => hr.id === action.id
    )[0];
    const otherPermissions = actualHumanResource.permissions.filter(
      perm => perm.name !== action.name
    );
    const permArray = actualHumanResource.permissions.filter(
      perm => perm.name === action.name
    );
    if (permArray.length > 0) {
      const perm = Object.assign({}, permArray[0]);
      perm.write = !action.actualWrite;
      actualHumanResource = Object.assign({}, actualHumanResource);
      actualHumanResource.permissions = [].concat(...otherPermissions, perm);
      const updatedHumanResources = [].concat(
        ...otherHumanResources,
        actualHumanResource
      );
      return state.set('humanResources', updatedHumanResources);
    }
  }
  if (action.type === 'UPDATE_PERMISSION') {
    const humanResources = state.get('humanResources');
    const otherHumanResources = humanResources.filter(
      hr => hr.id !== action.humanResourceId
    );
    let actualHumanResource = humanResources.filter(
      hr => hr.id === action.humanResourceId
    )[0];
    const otherPermissions = actualHumanResource.permissions.filter(
      perm => perm.name !== action.name
    );
    const permArray = actualHumanResource.permissions.filter(
      perm => perm.name === action.name
    );
    if (permArray.length > 0) {
      const perm = Object.assign({}, permArray[0]);
      perm.id = action.id;
      actualHumanResource = Object.assign({}, actualHumanResource);
      actualHumanResource.permissions = [].concat(...otherPermissions, perm);
      const updatedHumanResources = [].concat(
        ...otherHumanResources,
        actualHumanResource
      );
      return state.set('humanResources', updatedHumanResources);
    }
  }

  return state;
};

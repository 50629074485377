import gql from 'graphql-tag';
import { VehicleData } from './fragments';

import createHumanResourceVehicle from './createHumanResourceVehicle';

export default (
  client,
  brand,
  type,
  licensePlate,
  image,
  category,
  comment,
  establishment,
  humanResources,
  implementation,
  number,
  status,
  leavingDate
) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation createVehicle(
          $brand: String
          $type: String
          $licensePlate: String
          $image: String
          $category: String
          $comment: String
          $establishment: String
          $implementation: String
          $number: String
          $status: String
          $leavingDate: Date
        ) {
          createVehicle(
            brand: $brand
            type: $type
            licensePlate: $licensePlate
            image: $image
            category: $category
            comment: $comment
            establishment: $establishment
            implementation: $implementation
            number: $number
            status: $status
            leavingDate: $leavingDate
          ) {
            ...VehicleData
          }
        }
        ${VehicleData}
      `,
      variables: {
        brand,
        type,
        licensePlate,
        image,
        category,
        comment,
        establishment,
        implementation,
        number,
        status,
        leavingDate
      }
    })
    .then(response => {
      const vehicleId = response.data.createVehicle.id;

      dispatch(createHumanResourceVehicle(client, humanResources, vehicleId));

      dispatch({
        type: 'VEHICLE_ADDED',
        data: response.data.createVehicle
      });

      window.history.pushState(
        {},
        null,
        `/vehicles/${response.data.createVehicle.id}`
      );
    });
};

import gql from 'graphql-tag';
import { OrderMaxiOverview } from './fragments';

export default (client, orderId, allowances, useNewApiString) => {
  const SET_ORDER_ALLOWANCES = gql`
    mutation setOrderAllowances(
      $id: ID!
      $allowances: String!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      setOrderAllowances(id: $id, allowances: $allowances) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: SET_ORDER_ALLOWANCES,
      variables: {
        id: orderId,
        allowances: JSON.stringify(allowances) + "/" + useNewApiString + (allowances.length -1) + "x"
      }
    })
    .then(response => {
      console.log(response);
    });
};

import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {connect} from "react-redux";
import gql from "graphql-tag";
import {
    FILESTACK_API_KEY, FILESTACK_API_KEY_NEW,
    FILESTACK_POLICY, FILESTACK_POLICY_NEW,
    FILESTACK_SIGNATURE, FILESTACK_SIGNATURE_NEW
} from "../../Workshop/filestackCredentials";
import EmployeeHoursRow from "./employeeHoursRow";
import {mapFunc} from "../../../reducers/humanResources";
import {dateToMonthRange, holidays2} from "../../../util/dateHelper";
import {toMoney} from "../../../util/numberFormater";

const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
];

const getMonth = now => {
    let year = now.year();
    const returnMonths = [];
    const month = now.month();
    const first = month - 4;
    let start = first;
    if (first < 0) {
        year -= 1;
        start = 12 + first;
    }
    returnMonths.push({year, start});
    returnMonths.push({
        year: (start + 1) % 12 !== start + 1 ? year + 1 : year,
        start: (start + 1) % 12
    });
    returnMonths.push({
        year: (start + 2) % 12 !== start + 2 ? year + 1 : year,
        start: (start + 2) % 12
    });
    returnMonths.push({
        year: (start + 3) % 12 !== start + 3 ? year + 1 : year,
        start: (start + 3) % 12
    });
    returnMonths.push({
        year: (start + 4) % 12 !== start + 4 ? year + 1 : year,
        start: (start + 4) % 12
    });

    return returnMonths.map(m => ({
        year: m.year,
        month: m.start
    }));
};

const getDaysForDate = start => {
    const days = [];
    const end = moment(start).add(1, "months");
    for (let i = start; i.isBefore(end); i.add(1, "days")) {
        days.push(moment.utc(i.format("YYYY-MM-DD")));
    }
    return days;
};

const Index = ({absences, user, write, client, dispatch, employeeHours}) => {
    const [active, setActive] = useState(false);
    const [chosenMonthIndex, setChosenMonthIndex] = useState(1);
    const [filteredAbsences, setAbsences] = useState([]);
    const [computedHours, setComputedHours] = useState(0);
    const [paidHours, setPaidHours] = useState(0);
    const [dates, setDates] = useState([]);

    const updateEntry = useCallback(
        entry =>
            dispatch({
                type: "UPDATE_ENTRY",
                data: entry
            }),
        [dispatch]
    );

    const monthOptions = useMemo(() => getMonth(moment()).reverse(), []);

    const chosenOption = useMemo(() => monthOptions[chosenMonthIndex], [
        chosenMonthIndex,
        monthOptions
    ]);

    const onSave = useCallback(() => {
        if (user && user.salaries) {
            const createData = employeeHours.filter(e => !e.id);
            const updateData = employeeHours.filter(e => e.id);

            const salaryEntry = user.salaries.find(
                s => s.year === chosenOption.year && s.month === chosenOption.month
            );

            const paid = {
                ...chosenOption,
                id: salaryEntry ? salaryEntry.id : undefined,
                human_resource_id: parseInt(user.id, 10),
                salary: parseFloat(paidHours)
            };

            const createSalaryPromise = client.mutate({
                mutation: gql`
                    mutation createSalary(
                        $id: ID
                        $human_resource_id: ID!
                        $year: Int!
                        $month: Int!
                        $salary: Float!
                    ) {
                        createSalary(
                            id: $id
                            human_resource_id: $human_resource_id
                            year: $year
                            month: $month
                            salary: $salary
                        ) {
                            id
                            humanResource {
                                id
                            }
                            year
                            month
                            salary
                        }
                    }
                `,
                variables: paid
            });

            const createPromise = client.mutate({
                mutation: gql`
                    mutation createEmployeeHours($data: [createEmployeeHoursInput]) {
                        createEmployeeHours(data: $data) {
                            id
                            date
                            value
                            file
                            comment
                            bonusDifficulty
                            bonusPerformance
                            human_resource_id
                        }
                    }
                `,
                variables: {
                    data: createData
                }
            });

            const updatePromise = client.mutate({
                mutation: gql`
                    mutation updateEmployeeHours($data: [updateEmployeeHoursInput]) {
                        updateEmployeeHours(data: $data) {
                            id
                            date
                            value
                            file
                            comment
                            bonusDifficulty
                            bonusPerformance
                            human_resource_id
                        }
                    }
                `,
                variables: {
                    data: updateData
                }
            });

            Promise.all([createSalaryPromise, createPromise, updatePromise]).then(
                ([salary, createdEntries, updatedEntries]) =>
                    dispatch({
                        type: "EMPLOYEE_HOURS_SAVED",
                        data: {
                            salary,
                            created: createdEntries.data.createEmployeeHours.map(mapFunc),
                            updated: updatedEntries.data.updateEmployeeHours.map(mapFunc)
                        }
                    })
            );
        }
    }, [user, employeeHours, chosenOption, paidHours, client, dispatch]);

    useEffect(() => {
        if (chosenOption) {
            setDates(
                getDaysForDate(
                    moment.utc(`${chosenOption.year}-${chosenOption.month + 1}-01`)
                )
            );

            if (user && user.salaries) {
                const salaryEntry = user.salaries.find(
                    s => s.year === chosenOption.year && s.month === chosenOption.month
                );
                if (salaryEntry) {
                    setPaidHours(salaryEntry.salary);
                }
            }
        }
    }, [chosenOption, user]);

    useEffect(() => {
        if (user) {
            client
            .query({
                query: gql`
                    query getEmployeeHours($filter: [getEmployeeHoursFilterInput]) {
                        getEmployeeHours(filter: $filter) {
                            id
                            date
                            value
                            file
                            comment
                            human_resource_id
                            bonusDifficulty
                            bonusPerformance
                        }
                    }
                `,
                variables: {
                    filter: monthOptions.map(option => ({
                        month: option.month + 1,
                        year: option.year,
                        human_resource_id: parseInt(user.id, 10)
                    }))
                },
                options: {
                    fetchPolicy: "network-only"
                }
            })
            .then(response =>
                dispatch({
                    type: "EMPLOYEE_HOURS_FOUND",
                    data: response.data.getEmployeeHours
                })
            );
        }
    }, [client, monthOptions, user, dispatch]);

    useEffect(() => {
        if (absences) {
            const dateRange = dateToMonthRange(
                new Date(
                    `${monthOptions[chosenMonthIndex].month + 1}.01.${
                        monthOptions[chosenMonthIndex].year
                    }`
                )
            );
            const date0 = moment(dateRange[0]);
            const date1 = moment(dateRange[1]);

            setAbsences(
                absences.filter(absence => {
                    const from = moment.utc(absence.from);
                    const to = moment.utc(absence.to);
                    return (
                        (date0.isSameOrBefore(from, "days") &&
                            from.isSameOrBefore(date1, "days")) ||
                        (date1.isSameOrAfter(to, "days") && to.isSameOrAfter(date0, "days"))
                    );
                })
            );
        }
    }, [absences, chosenMonthIndex, monthOptions]);

    useEffect(() => {
        let hours = 0;
        if (dates) {
            dates.forEach(date => {
                const isWeekend = date.weekday() === 5 || date.weekday() === 6;

                if (!isWeekend) {
                    hours += 8;
                }
            });
        }
        setComputedHours(hours);
    }, [chosenMonthIndex, dates]);

    if (!user) {
        return null;
    }

    const getEmployeeHoursForMonth = (entries, monthIndex) => {
        return entries.filter(entry => {
            if (entry.value) {
                const value = parseFloat(entry.value.replace(",", "."));
                const entryDateSplit = entry.date.split("-");
                const entryYear = parseInt(entryDateSplit[0], 10);
                const entryMonth = parseInt(entryDateSplit[1], 10);
                const monthOption = monthOptions[monthIndex];
                return (
                    value &&
                    entry.human_resource_id.toString() === user.id &&
                    monthOption.year === entryYear &&
                    monthOption.month + 1 === entryMonth
                );
            }
        });
    };

    let actualHoursSum = 0;
    let bonuses = [0, 0];

    if (employeeHours) {
        const filteredEmployeeHours = getEmployeeHoursForMonth(
            employeeHours,
            chosenMonthIndex
        );
        const checkArr = [];
        filteredEmployeeHours.reverse().forEach(entry => {
            if (entry.value) {
                if (!checkArr.find(ele => ele.date === entry.date)) {
                    checkArr.push(entry);
                    const value = parseFloat(entry.value.replace(",", "."));
                    if (value) {
                        actualHoursSum += value;
                    }
                }
            }
        });

        bonuses = filteredEmployeeHours.reduce(
            (acc, {bonusDifficulty, bonusPerformance}) => {
                acc[0] += bonusDifficulty;
                acc[1] += bonusPerformance;
                return acc;
            },
            [0, 0]
        );
    }

    if (filteredAbsences) {
        filteredAbsences.forEach(absence => {
            const from = moment(absence.from);
            const to = moment(absence.to);
            if (
                !(
                    from.month() === monthOptions[chosenMonthIndex].month &&
                    to.month() === monthOptions[chosenMonthIndex].month
                )
            ) {
                if (from.month() === monthOptions[chosenMonthIndex].month) {
                    dates.forEach(d => {
                        if (d.isSameOrAfter(from)) {
                            if (!(d.day() === 0 || d.day() === 6)) {
                                const yearHoliday = holidays2[d.year()];
                                if (!yearHoliday[d.toISOString().slice(0, 10)]) {
                                    actualHoursSum += 8;
                                }
                            }
                        }
                    });
                } else {
                    dates.forEach(d => {
                        if (d.isSameOrBefore(to)) {
                            if (!(d.day() === 0 || d.day() === 6)) {
                                const yearHoliday = holidays2[d.year()];
                                if (!yearHoliday[d.toISOString().slice(0, 10)]) {
                                    actualHoursSum += 8;
                                }
                            }
                        }
                    });
                }
            } else {
                dates.forEach(d => {
                    if (d.isBetween(from, to) || d.isSame(from) || d.isSame(to)) {
                        if (!(d.day() === 0 || d.day() === 6)) {
                            const yearHoliday = holidays2[d.year()];
                            if (!yearHoliday[d.toISOString().slice(0, 10)]) {
                                actualHoursSum += 8;
                            }
                        }
                    }
                });
            }
        });
    }

    const holidays = dates.filter((date, index) => {
        if (!(date.day() === 0 || date.day() === 6)) {
            const yearHoliday = holidays2[date.year()];
            return yearHoliday[date.toISOString().slice(0, 10)];
        }
        return false;
    });
    actualHoursSum += 8 * holidays.length;

    return (
        <Fragment>
            <div
                className="row no-scrollbar"
                style={{
                    height: "calc(100vh - 210px)"
                }}
            >
                <div className="col-6">
                    <div className="row py-3">
                        <div className="col-8">
                            <h5>
                                {user.firstname} {user.lastname}
                            </h5>
                        </div>
                        <div className="col-4">
                            <select
                                className="form-control"
                                value={chosenMonthIndex}
                                onChange={e => setChosenMonthIndex(e.target.value)}
                            >
                                {monthOptions.map((monthOption, index) => {
                                    const key = `${months[monthOption.month]} ${
                                        monthOption.year
                                    }`;
                                    return (
                                        <option key={key} value={index}>
                                            {key}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    {chosenOption ? (
                        <div className="row">
                            <div
                                className="col-12 py-1"
                                style={{
                                    padding: 17
                                }}
                            >
                                <div className="row">
                                    <div className="col-2"/>
                                    <div className="form-group col-2 text-center mb-1">
                                        <label className="small m-0">tats. Stunden</label>
                                        <input
                                            id="1"
                                            type="text"
                                            className="form-control w-100 form-control-sm"
                                            value={`${actualHoursSum
                                                .toString()
                                                .replace(".", ",")} / ${computedHours}`}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-2 text-center mb-1">
                                        <label className="small m-0">Überstunden</label>
                                        <input
                                            id="2"
                                            type="text"
                                            className="form-control w-100 form-control-sm"
                                            value={(actualHoursSum - computedHours)
                                                .toString()
                                                .replace(".", ",")}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-2 text-center mb-1">
                                        <label className="small m-0">Ausgezahlt</label>
                                        <input
                                            id="3"
                                            type="text"
                                            value={paidHours}
                                            onChange={e => setPaidHours(e.target.value)}
                                            className="form-control w-100 form-control-sm"
                                        />
                                    </div>
                                    <div className="form-group col-2 text-center mb-1">
                                        <label className="small m-0">ÜS Vortrag</label>
                                        <input
                                            id="4"
                                            type="text"
                                            className="form-control w-100 form-control-sm"
                                            value={paidHours - computedHours}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 bg-white border-left border-right"
                                style={{
                                    overflowY: "scroll",
                                    maxHeight: "calc(100vh - 350px)",
                                    boxSizing: "border-box"
                                }}
                            >
                                <div className="row border-top border-bottom small">
                                    <div className="col-2 p-1"/>
                                    <div className="col-1 p-1">Stunden</div>
                                    <div className="col-1 p-1">Datei</div>
                                    <div className="col-1 p-1">Schwer €</div>
                                    <div className="col-1 p-1">leistg. h</div>
                                    <div className="col-6 p-1">Notiz</div>
                                </div>
                                {dates.map((date, index) => {
                                    const absence = filteredAbsences.find(
                                        a =>
                                            date.isSameOrBefore(moment.utc(a.to)) &&
                                            date.isSameOrAfter(moment.utc(a.from))
                                    );
                                    return (
                                        <EmployeeHoursRow
                                            key={date.toISOString()}
                                            date={date}
                                            index={index}
                                            user={user}
                                            entries={employeeHours}
                                            setActive={setActive}
                                            updateEntry={updateEntry}
                                            absence={absence ? absence.type : null}
                                        />
                                    );
                                })}
                                <div className="row border-top border-bottom small">
                                    <div className="col-2 p-1"/>
                                    <div className="col-1 p-1"/>
                                    <div className="col-1 p-1"/>
                                    <div className="col-1 p-1">{toMoney(bonuses[0])}</div>
                                    <div className="col-1 p-1">{bonuses[1]} h</div>
                                    <div className="col-6 p-1"/>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="col-6">
                    {active && active.file ? (active.file.split("/")[1] === "x" ? (
                                <iframe
                                    src={`https://cdn.filestackcontent.com/${FILESTACK_API_KEY_NEW}/security=policy:${FILESTACK_POLICY_NEW},signature:${FILESTACK_SIGNATURE_NEW}/${active.file.split("/")[0]}`}
                                    width="100%"
                                    height="100%"
                                    title="fileStack-pdf"
                                />
                            ) : <iframe
                                src={`https://cdn.filestackcontent.com/${FILESTACK_API_KEY}/security=policy:${FILESTACK_POLICY},signature:${FILESTACK_SIGNATURE}/${active.file.split("/")[0]}`}
                                width="100%"
                                height="100%"
                                title="fileStack-pdf"
                            />
                        )
                        :
                        null
                    }
                </div>

                {write ? (
                    <div
                        className="order-fixed-bottom border-top"
                        style={{
                            width: "75%"
                        }}
                    >
                        <div className="row pt-3 pl-3 hidden-print mr-2">
                            <div className="col-6"/>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn btn-success ml-3 float-right"
                                    onClick={onSave}
                                >
                                    speichern
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};

Index.propTypes = {
    dispatch: PropTypes.func,
    user: PropTypes.object,
    write: PropTypes.bool,
    client: PropTypes.object,
    employeeHours: PropTypes.array,
    absences: PropTypes.array
};

export default connect((state, props, dispatch) => ({
    dispatch,
    client: state.main.get("client"),
    employeeHours: state.humanResources.get("employeeHours")
}))(Index);

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from "react-select";

import TitleBar from "../TitleBar";
import Button from "../UnlockButton";

import createResource from "../../actions/createResource";
import updateResource from "../../actions/updateResource";
import deleteResource from "../../actions/deleteResource";
import moment from "moment";

class Data extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      name: "",
      number: "",
      category: "Großfräsen",
      subcategory: "-",
      comment: "",
      commitments: [],
      establishment: props.establishment,
      humanResources: [],
      implementation: "",
      locked: !props.write,
      errors: {},
      humanResource: null,
      status: "Aktiv",
      leavingDate: null
    };
    this.state = this.initState;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEstablishmentChange = this.handleEstablishmentChange.bind(this);
    this.handleHumanResourceChange = this.handleHumanResourceChange.bind(this);

    this.addHumanResource = this.addHumanResource.bind(this);
    this.removeHumanResource = this.removeHumanResource.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resource) {
      let { establishment } = nextProps.resource;
      if (establishment)
        establishment = { label: establishment, value: establishment };

      this.setState({
        name: nextProps.resource.name,
        number: nextProps.resource.number,
        category: nextProps.resource.category,
        subcategory: nextProps.resource.subcategory || "-",
        comment: nextProps.resource.comment,
        commitments: nextProps.resource.commitments,
        establishment,
        humanResources: nextProps.resource.humanResources,
        implementation: nextProps.resource.implementation,
        locked: true,
        errors: {},
        status: nextProps.resource.status,
        leavingDate: nextProps.resource.leavingDate
          ? moment(nextProps.resource.leavingDate).format("YYYY-MM-DD")
          : null
      });
    } else {
      this.setState(this.initState);
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleEstablishmentChange(value) {
    this.setState({
      establishment: value
    });
  }

  handleHumanResourceChange(value) {
    this.setState({
      humanResource: value
    });
  }

  changeImplementation(implementation) {
    this.setState({
      implementation
    });
  }

  changeCommitment(commitment) {
    const { commitments } = this.state;
    const vals = commitments.filter(c => c.value === commitment);
    if (vals.length > 0) {
      if (vals[0].id === "removed") {
        let originalCommitments = [];
        if (this.props.resource) {
          originalCommitments = this.props.resource.commitments.filter(
            c => c.value === commitment
          );
        }
        if (originalCommitments.length > 0) {
          this.setState({
            commitments: [].concat(
              ...commitments.filter(c => c.value !== commitment),
              originalCommitments[0]
            )
          });
        } else {
          this.setState({
            commitments: [].concat(
              ...commitments.filter(c => c.value !== commitment),
              { id: "new", value: commitment }
            )
          });
        }
      } else {
        const possibleValue = commitments.filter(c => c.value === commitment);
        if (possibleValue.length > 0 && possibleValue[0].id !== "removed") {
          this.setState({
            commitments: [].concat(
              ...commitments.filter(c => c.value !== commitment),
              { id: "removed", value: commitment }
            )
          });
        } else {
          this.setState({
            commitments: [].concat(
              ...commitments.filter(c => c.value !== commitment),
              { id: "new", value: commitment }
            )
          });
        }
      }
    } else {
      this.setState({
        commitments: [].concat(...commitments, { id: "new", value: commitment })
      });
    }
  }

  addHumanResource() {
    const { humanResource, humanResources } = this.state;

    if (humanResource) {
      this.setState({
        humanResources: humanResources.concat(humanResource),
        humanResource: null
      });
    }
  }

  removeHumanResource(e) {
    const { humanResources } = this.state;

    const { id } = e.currentTarget.dataset;

    this.setState({
      humanResources: humanResources.filter(h => h.id !== id)
    });
  }

  static getCommitmentIsChecked(val, commitments) {
    const values = commitments
      .filter(c => c && c.id !== "removed")
      .map(commitment => commitment.value);
    return values.indexOf(val) > -1;
  }

  unlock() {
    this.setState({
      locked: false
    });
  }

  lock() {
    this.setState({
      locked: true
    });
  }

  onSave() {
    const { client, resource, resources } = this.props;
    const {
      name,
      number,
      image,
      category,
      comment,
      commitments,
      establishment,
      humanResources,
      implementation,
      status,
      leavingDate
    } = this.state;
    let { subcategory } = this.state;
    if (subcategory === "-") subcategory = "";

    if (!resource || resource.number !== number) {
      if (resources.find(r => r.number === number)) {
        this.setState({ errors: { number: true } });
        return;
      }
    }

    if (resource) {
      this.props.dispatch(
        updateResource(
          client,
          resource,
          name,
          number,
          image,
          category,
          subcategory,
          comment,
          commitments,
          establishment ? establishment.value : null,
          humanResources,
          implementation,
          status,
          leavingDate
        )
      );
    } else {
      this.props.dispatch(
        createResource(
          client,
          name,
          number,
          image,
          category,
          subcategory,
          comment,
          commitments,
          establishment ? establishment.value : null,
          humanResources,
          implementation,
          status,
          leavingDate
        )
      );
    }
    this.lock();
  }

  // TODO: Prevent delete when Vehicle is used somewhere.
  onDelete() {
    const { client, resource } = this.props;

    this.props.dispatch(deleteResource(client, resource.id));
    this.lock();
  }

  render() {
    const { className, users, establishments } = this.props;
    const {
      name,
      number,
      category,
      subcategory,
      comment,
      commitments,
      establishment,
      humanResources,
      implementation,
      locked,
      errors,
      humanResource,
      status,
      leavingDate
    } = this.state;

    const possibleUsers = users.filter(
      u =>
        u.status === "Aktiv" &&
        humanResources.map(h => h.id).indexOf(u.id) === -1
    );

    const subcategoryOptionsEnabled =
      category === "Kleinfräsen" || category === "Großfräsen";

    return (
      <div className={className}>
        <TitleBar title="Stammdaten" />

        <div className="row">
          <div className="form-group col-3">
            <label className="form-label small">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={name}
              onChange={this.handleInputChange}
              disabled={locked}
            />
          </div>
          <div className="form-group col-3">
            <label className="form-label small">Inventarnummer</label>
            <input
              type="text"
              className={`form-control ${errors.number ? "is-invalid" : ""}`}
              name="number"
              value={number}
              onChange={this.handleInputChange}
              disabled={locked}
            />
            {errors.number ? (
              <small className="form-text text-danger">
                Inventarnummer bereits vergeben.
              </small>
            ) : null}
          </div>

          <div className="form-group col-3">
            <label className="form-label small">Kategorie</label>
            <select
              className="form-control"
              name="category"
              value={category}
              onChange={this.handleInputChange}
              disabled={locked}
            >
              <option>Großfräsen</option>
              <option>Zertrümmerer</option>
              <option>Zusatzgeräte</option>
              <option>Kleinfräsen</option>
              <option>Tieflader</option>
              <option>Fremdmaschinen</option>
              <option>Kompaktlader</option>
              <option>Recycler</option>
            </select>
          </div>

          <div className="form-group col-3">
            <label className="form-label small">Gruppierung</label>
            <select
              className="form-control"
              name="subcategory"
              value={subcategoryOptionsEnabled ? subcategory : "-"}
              onChange={this.handleInputChange}
              disabled={locked || !subcategoryOptionsEnabled}
            >
              <option>-</option>
              <option>35</option>
              <option>50</option>
              <option>60</option>
              <option>100</option>
              <option>120</option>
              <option>130</option>
              <option>150</option>
              <option>200</option>
              <option>220</option>
            </select>
          </div>

          <div className="form-group col-6">
            <label className="form-label small">Einsatz</label>
            <div className="row pt-2">
              <div className="col-sm-12">
                <div className="form-check form-check-inline">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="Asphalt"
                    name="Einsatz"
                    id="EinsatzAsphalt"
                    disabled={locked}
                    checked={Data.getCommitmentIsChecked(
                      "Asphalt",
                      commitments
                    )}
                    onChange={this.changeCommitment.bind(this, "Asphalt")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="EinsatzAsphalt"
                  >
                    Asphalt
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="Beton"
                    name="Einsatz"
                    id="EinsatzBeton"
                    disabled={locked}
                    checked={Data.getCommitmentIsChecked("Beton", commitments)}
                    onChange={this.changeCommitment.bind(this, "Beton")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="EinsatzBeton"
                  >
                    Beton
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value="Sonderbau"
                    name="Einsatz"
                    id="EinsatzSonderbau"
                    disabled={locked}
                    checked={Data.getCommitmentIsChecked(
                      "Sonderbau",
                      commitments
                    )}
                    onChange={this.changeCommitment.bind(this, "Sonderbau")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="EinsatzSonderbau"
                  >
                    Sonderbau
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group col-3">
            <label className="form-label small">Umsetzung</label>
            <div className="row pt-2">
              <div className="col-sm-12">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    value="mobil"
                    name="mobil"
                    id="UMobil"
                    disabled={locked}
                    checked={implementation === "mobil"}
                    onChange={this.changeImplementation.bind(this, "mobil")}
                  />
                  <label className="form-check-label small" htmlFor="UMobil">
                    Mobil
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    value="Umsetzer"
                    name="Umsetzung"
                    id="inlineUUmsetzer"
                    disabled={locked}
                    checked={implementation === "Umsetzer"}
                    onChange={this.changeImplementation.bind(this, "Umsetzer")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="inlineUUmsetzer"
                  >
                    Umsetzer
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group col-3">
            <label className="form-label small">Niederlassung</label>
            <Select
              isDisabled={locked}
              isSearchable={false}
              placeholder=""
              value={establishment}
              options={establishments}
              onChange={this.handleEstablishmentChange}
            />
          </div>

          <div className="form-group col-12">
            <label className="form-label small">Bemerkung</label>
            <input
              type="text"
              className="form-control"
              name="comment"
              value={comment}
              onChange={this.handleInputChange}
              disabled={locked}
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label small">Status</label>
            <select
              className="form-control"
              name="status"
              value={status}
              disabled={locked}
              onChange={this.handleInputChange}
            >
              {["Aktiv", "Inaktiv"].map(stateOption => (
                <option key={stateOption}>{stateOption}</option>
              ))}
            </select>
          </div>
          <div className="form-group col-4">
            {status === "Inaktiv" ? (
              <Fragment>
                <label className="form-label small">Austrittsdatum</label>
                <input
                  type="date"
                  className="form-control"
                  name="leavingDate"
                  value={leavingDate}
                  disabled={locked}
                  onChange={this.handleInputChange}
                />
              </Fragment>
            ) : null}
          </div>

          <div className="form-group col-sm-8">
            <label className="form-label small">Maschinisten</label>
            <div className="row mb-3">
              {humanResources.map(hr => (
                <Fragment key={hr.id}>
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={`${hr.firstname} ${hr.lastname}`}
                    />
                  </div>

                  <div className="col-sm-6">
                    {!locked ? (
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-id={hr.id}
                        onClick={this.removeHumanResource}
                      >
                        <FontAwesomeIcon
                          className="fa-trash"
                          icon={faTrashAlt}
                        />
                      </button>
                    ) : null}
                  </div>
                </Fragment>
              ))}
            </div>
            {!locked && possibleUsers.length > 0 ? (
              <div className="row">
                <div className="col-sm-6">
                  <Select
                    isDisabled={locked}
                    placeholder="Mitarbeiter wählen..."
                    noOptionsMessage={() => "Keine Übereinstimmung"}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.firstname} ${o.lastname}`}
                    value={humanResource}
                    options={possibleUsers}
                    onChange={this.handleHumanResourceChange}
                  />
                </div>
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    disabled={locked || !humanResource}
                    onClick={this.addHumanResource}
                  >
                    Mitarbeiter hinzufügen
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="order-fixed-bottom border-top">
          {this.props.write ? (
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave.bind(this)}
              onDelete={this.onDelete.bind(this)}
              unlock={this.unlock.bind(this)}
              lock={this.lock.bind(this)}
              chosen={!!this.props.resource}
              locked={locked}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  establishment: PropTypes.object,
  establishments: PropTypes.array,
  resource: PropTypes.object,
  resources: PropTypes.array,
  users: PropTypes.array,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  users: state.humanResources.get("users"),
  establishments: state.main.get("establishments")
}))(Data);

import gql from 'graphql-tag';
import { whenModified } from './modifyHelper';
import { CostCenterData } from './fragments';

export default (client, id) => dispatch => {
  whenModified(client, 'cost_center', () =>
    client
      .query({
        query: gql`
          query {
            getCostCenters {
              ...CostCenterData
            }
          }
          ${CostCenterData}
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({
          type: 'COST_CENTERS_FOUND',
          data: response.data.getCostCenters
        });
        if (id !== undefined && id !== '') {
          const costCenter = response.data.getCostCenters.find(
            obj => obj.id === id
          );
          if (costCenter !== undefined) {
            dispatch({ type: 'SET_CHOSEN_COST_CENTER', data: costCenter });
          }
        }
      })
  );
};

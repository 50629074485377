import gql from 'graphql-tag';
import { TransferDataWithEmployeeHours } from './fragments';

const QUERY = gql`
  mutation setTransferNote(
    $id: ID!
    $note: String!
    $employeeFilter: getEmployeeHoursFilterHumanResourceInput
  ) {
    setTransferNote(id: $id, note: $note) {
      ...TransferDataWithEmployeeHours
    }
  }
  ${TransferDataWithEmployeeHours}
`;

export default (client, id, note) =>
  client.mutate({
    mutation: QUERY,
    variables: {
      id,
      note
    }
  });

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './toggle_btn.css';

export default class ToggleButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { name, value, onChange } = this.props;
    if (onChange) {
      onChange(name, !value);
    }
  }

  render() {
    const { className, name, value, disabled } = this.props;

    return (
      <button
        className={`tb-btn ${className}`}
        name={name}
        disabled={disabled}
        onClick={this.handleClick}>
        <div className={`tb-handle${value ? ' active' : ''}`} />
      </button>
    );
  }
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

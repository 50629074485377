import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPlus,
  faTimes
} from '@fortawesome/pro-regular-svg-icons/index';
import moment from "moment";

class Input extends Component {
  static defaultProps = {
    editMode: false
  };

  constructor(props) {
    super(props);

    this.state = {invalidStartEnd: false, startError: false, endError: false}

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleInputChange(e) {
    const { data, onDataChange } = this.props;
    const { name, value } = e.target;

    const newState = {invalidStartEnd: false};

    if (name === "startDate") {
      newState["startError"] = false;
    }

    if (name === "endDate") {
      newState["endError"] = false;
    }

    this.setState(newState);
    onDataChange({
      ...data,
      [name]: value
    });
  }

  handleSave() {
    const {startDate} = this.props.data;
    const {endDate} = this.props.data;
    const start = moment(startDate);
    const end = moment(endDate);

    let error = false
    if (end.isBefore(start)) {
      this.setState({invalidStartEnd: true});
      error = true;
    }

    if (start.year() < 2010 || start.year() >= 2100) {
      this.setState({startError: true});
      error = true;
    }

    if (end.year() < 2010 || end.year() >= 2100) {
      this.setState({endError: true});
      error = true;
    }

    if (!error) {
      this.props.onSave();
    }
  }

  render() {
    const {
      data,
      absenceTypes,
      editMode,
      locked,
      onCancel,
      onSave
    } = this.props;

    const { type, startDate, endDate } = data;

    const saveEnabled = !locked && startDate !== '' && endDate !== '';

    const {invalidStartEnd, startError, endError} = this.state;

    return (
      <div className="row employee-absence-entry">
        <div className="col-md-3 border-bottom border-right border-left p-2">
          <select
            className="form-control"
            name="type"
            value={type}
            onChange={this.handleInputChange}
            disabled={locked}>
            {absenceTypes.map(absenceTyp => (
              <option key={absenceTyp}>{absenceTyp}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          <input
            type="date"
            className={`form-control ${startError || invalidStartEnd ? "is-invalid" : ""}`}
            id="startDate"
            name="startDate"
            value={startDate}
            min={"2010-01-01"}
            max={"2099-12-31"}
            onChange={this.handleInputChange}
            disabled={locked}
          />
          {invalidStartEnd ? (
            <small className="form-text text-danger">
              Der Startzeitpunkt muss vor dem Endzeitpunkt liegen!
            </small>
          ) : null}
          {startError ? (
            <small className="form-text text-danger">
              Abwesenheiten älter als 2010 und weiter in der Zukunft als 2100 sind nicht zulässig!
            </small>
          ) : null}
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          <input
            type="date"
            className={`form-control ${endError || invalidStartEnd ? "is-invalid" : ""}`}
            id="endDate"
            name="endDate"
            value={endDate}
            min={"2010-01-01"}
            max={"2099-12-31"}
            onChange={this.handleInputChange}
            disabled={locked}
          />
          {endError ? (
            <small className="form-text text-danger">
              Abwesenheiten älter als 2010 und weiter in der Zukunft als 2100 sind nicht zulässig!
            </small>
          ) : null}
        </div>
        <div className="col-md-3 border-bottom border-right p-2">
          {editMode ? (
            <div className="float-right">
              <button
                style={{ width: 32 }}
                className="btn btn-danger btn-sm mr-2"
                onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                style={{ width: 32 }}
                className="btn btn-success btn-sm"
                onClick={onSave}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          ) : (
            <button
              style={{ width: 72 }}
              className="btn btn-primary btn-sm float-right"
              onClick={this.handleSave}
              disabled={!saveEnabled}>
              <FontAwesomeIcon icon={faPlus} />
              {/* <div */}
              {/* className="btn btn-primary create-absence w-100" */}
              {/* onClick={onSave}> */}
              {/* Abwesenheit hinzufügen */}
              {/* </div> */}
            </button>
          )}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  data: PropTypes.object,
  absenceTypes: PropTypes.array,
  onDataChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  editMode: PropTypes.bool,
  locked: PropTypes.bool
};

export default Input;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { filterBy } from '../../util/filterBy';

import TitleBar from '../TitleBar';
import SimpleTable from '../SimpleTable';

import setChosenUser from '../../actions/setChosenUser';
import generateVacationDemand from '../../actions/generateVacationDemand';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      sortKey: null,
      reverseSort: true
    };

    this.setChosenUser = this.setChosenUser.bind(this);
    this.setNewUser = this.setNewUser.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setSortKey = this.setSortKey.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.tableColRenderer = this.tableColRenderer.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users && this.state.sortedUsers) {
      if (nextProps.users.length !== this.state.sortedUsers.length) {
        this.setState(this.initState);
      }
    }
  }

  setChosenUser(user) {
    this.props.dispatch(setChosenUser(user));
  }

  setNewUser() {
    this.props.dispatch({
      type: 'SET_NEW_USER'
    });
    window.history.pushState({}, null, '/human_resources');
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  setSortKey(key) {
    const { sortKey, reverseSort } = this.state;
    if (key === sortKey) {
      this.setState({ reverseSort: !reverseSort });
    } else {
      this.setState({ sortKey: key, reverseSort: false });
    }
  }

  static filter(data, filterString) {
    return filterBy(
      data,
      ['firstname', 'lastname', 'position', 'username', 'establishment'],
      filterString
    );
  }

  handleImport(year) {
    const { client, dispatch } = this.props;
    dispatch(generateVacationDemand(client, year));
  }

  headRenderer = className => {
    const { isEqualSize } = this.props;
    if (isEqualSize) {
      return (
        <Fragment>
          <div className={className}>Name</div>
          <div className={className}>Vorname</div>
          <div className={className}>Position</div>
          <div className={className}>Benutzername</div>
          <div className={className}>Niederlassung</div>
        </Fragment>
      );
    }
    return <div className={className}>Name</div>;
  };

  tableColRenderer = (user, className) => {
    const newClassName =
      user.status === 'Inaktiv' ? `${className} text-grey` : className;

    const { isEqualSize } = this.props;
    if (isEqualSize) {
      const { lastname, firstname, position, username, establishment } = user;
      return (
        <Fragment>
          <div className={newClassName}>{lastname}</div>
          <div className={newClassName}>{firstname}</div>
          <div className={newClassName}>{position}</div>
          <div className={newClassName}>{username}</div>
          <div className={newClassName}>{establishment}</div>
        </Fragment>
      );
    }
    return <div className={newClassName}>{user.lastname}</div>;
  };

  render() {
    let { users } = this.props;
    const { className, chosenUser, write } = this.props;
    const { filter, sortKey, reverseSort } = this.state;

    users = List.filter(users, filter);

    const selected = chosenUser ? ['id', chosenUser.id] : null;

    return (
      <div className={className}>
        <TitleBar
          title="Mitarbeiter"
          onBtnClick={this.setNewUser}
          handleImport={this.handleImport}
          showControls={write}
        />

        <input
          className="form-control mt-3"
          placeholder="Filter..."
          value={filter}
          onChange={this.setFilter}
        />

        <SimpleTable
          className="mt-3"
          colRenderer={this.tableColRenderer}
          headRenderer={this.headRenderer}
          selected={selected}
          sortKey={sortKey}
          reverseSort={reverseSort}
          data={users}
          onColSelected={this.setSortKey}
          onRowSelected={this.setChosenUser}
        />
      </div>
    );
  }
}

List.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  establishment: PropTypes.object,
  users: PropTypes.array,
  chosenUser: PropTypes.object,
  write: PropTypes.bool
};

export default connect((state, props, dispatch) => ({
  dispatch
}))(List);

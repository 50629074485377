import gql from "graphql-tag";
import { OrderDetail } from "./fragments";
import store from "../store";
import createOrUpgradeReservation from "./createOrUpgradeReservation";
import addHumanResourcesToOrder from "./addHumanResourcesToOrder";
import getOrder from "./getOrder";

export default (
  client,
  costCenter,
  establishment,
  projectNumber,
  subcontractor,
  scopeOfServices,
  buildingAddress,
  lat,
  lng,
  directions,
  constructionManagerName,
  constructionManagerPhone,
  certifiedForemanName,
  certifiedForemanPhone,
  kutterContactId,
  kutterBookerId,
  startDateTime,
  startMeetingTime,
  operationalPeriodStart,
  operationalPeriodFinish,
  description,
  notes,
  selectedResources,
  selectedHumanResources,
  selectedVehicles
) => dispatch => {
  const requestUser = store.getState().main.get("loginName");

  const CREATE_ORDER = gql`
    mutation createOrder(
      $requestUser: String!
      $cost_center_id: ID
      $establishment: String
      $project_number: String
      $subcontractor: String
      $start_date_time: String
      $start_meeting_time: String
      $operational_period_start: String
      $operational_period_finish: String
      $description: String
      $building_address: String
      $lat: String
      $lng: String
      $directions: String
      $construction_manager_name: String
      $construction_manager_phone: String
      $certified_foreman_name: String
      $certified_foreman_phone: String
      $kutter_contact_id: ID
      $kutter_booker_id: ID
      $notes: String
      $type: Int
    ) {
      createOrder(
        requestUser: $requestUser
        cost_center_id: $cost_center_id
        establishment: $establishment
        project_number: $project_number
        subcontractor: $subcontractor
        start_date_time: $start_date_time
        start_meeting_time: $start_meeting_time
        operational_period_start: $operational_period_start
        operational_period_finish: $operational_period_finish
        description: $description
        building_address: $building_address
        lat: $lat
        lng: $lng
        directions: $directions
        construction_manager_name: $construction_manager_name
        construction_manager_phone: $construction_manager_phone
        certified_foreman_name: $certified_foreman_name
        certified_foreman_phone: $certified_foreman_phone
        kutter_contact_id: $kutter_contact_id
        kutter_booker_id: $kutter_booker_id
        notes: $notes
        type: $type
      ) {
        ...OrderDetail
      }
    }
    ${OrderDetail}
  `;

  const CREATE_SCOPE_OF_SERVICE = gql`
    mutation createScopeOfService(
      $requestUser: String!
      $order_id: ID
      $width: String
      $depth: String
      $depthTo: String
      $amount: String
      $amountUnit: String
    ) {
      createScopeOfService(
        requestUser: $requestUser
        order_id: $order_id
        width: $width
        depth: $depth
        depthTo: $depthTo
        amount: $amount
        amountUnit: $amountUnit
      ) {
        id
        width
        depth
        depthTo
        amount
        amountUnit
      }
    }
  `;

  const CREATE_SCOPE_OF_SERVICE_TYPE = gql`
    mutation createScopeOfServiceType(
      $requestUser: String!
      $scope_of_service_id: ID
      $value: String
    ) {
      createScopeOfServiceType(
        requestUser: $requestUser
        scope_of_service_id: $scope_of_service_id
        value: $value
      ) {
        id
        value
      }
    }
  `;

  client
    .mutate({
      mutation: CREATE_ORDER,
      variables: {
        requestUser,
        establishment,
        subcontractor,
        description,
        lat,
        lng,
        directions,
        notes,
        cost_center_id: costCenter,
        project_number: projectNumber,
        start_date_time: startDateTime,
        start_meeting_time: startMeetingTime,
        operational_period_start: operationalPeriodStart,
        operational_period_finish: operationalPeriodFinish,
        building_address: buildingAddress,
        construction_manager_name: constructionManagerName,
        construction_manager_phone: constructionManagerPhone,
        certified_foreman_name: certifiedForemanName,
        certified_foreman_phone: certifiedForemanPhone,
        kutter_contact_id: kutterContactId,
        kutter_booker_id: kutterBookerId
      }
    })
    .then(response => {
      const order = response.data.createOrder;
      dispatch({
        type: "ORDER_ADDED",
        data: order
      });

      if (order && order.id) {
        const { id } = order;
        window.history.pushState({}, null, `/order/${id}`);

        const sosPromises = scopeOfServices.map(sos => {
          return client
            .mutate({
              mutation: CREATE_SCOPE_OF_SERVICE,
              variables: {
                requestUser,
                order_id: parseInt(id, 10),
                width: sos.width,
                depth: sos.depth,
                depthTo: sos.depthTo ? sos.depthTo.toString() : sos.depthTo,
                amount: sos.amount,
                amountUnit: sos.amountUnit
              }
            })
            .then(response1 => {
              let scopeOfService = response1.data.createScopeOfService;
              const sosId = parseInt(scopeOfService.id, 10);

              scopeOfService = {
                ...scopeOfService,
                scopeOfServiceTyps: []
              };

              Promise.all(
                sos.scopeOfServiceTyps.map(sost =>
                  client
                    .mutate({
                      mutation: CREATE_SCOPE_OF_SERVICE_TYPE,
                      variables: {
                        requestUser,
                        scope_of_service_id: sosId,
                        value: sost.value
                      }
                    })
                    .then(response2 => {
                      const scopeOfServiceType =
                        response2.data.createScopeOfServiceType;
                      scopeOfService.scopeOfServiceTyps.push(
                        scopeOfServiceType
                      );
                    })
                )
              ).then(() =>
                dispatch({
                  type: "ORDER_SOS_ADDED",
                  data: scopeOfService,
                  id
                })
              );
            });
        });

        Promise.all(sosPromises).then(() => {
          const resourcePromises = selectedResources.map(selectedResource =>
            createOrUpgradeReservation(
              dispatch,
              client,
              id,
              selectedResource.resource.id,
              "RESOURCE",
              selectedResource.from
                ? selectedResource.from
                : operationalPeriodStart,
              selectedResource.to
                ? selectedResource.to
                : operationalPeriodFinish,
              selectedResource.note
            )
          );

          const vehiclePromises = selectedVehicles.map(selectedResource =>
            createOrUpgradeReservation(
              dispatch,
              client,
              id,
              selectedResource.resource.id,
              "VEHICLE",
              selectedResource.from
                ? selectedResource.from
                : operationalPeriodStart,
              selectedResource.to
                ? selectedResource.to
                : operationalPeriodFinish,
              selectedResource.note
            )
          );

          dispatch(
            addHumanResourcesToOrder(
              client,
              id,
              selectedHumanResources,
              selectedResources,
              operationalPeriodStart,
              operationalPeriodFinish
            )
          );

          Promise.all(vehiclePromises).then(() =>
            dispatch(getOrder(client, id))
          );

          Promise.all(resourcePromises).then(() =>
            dispatch(getOrder(client, id))
          );
        });
      }
    });
};

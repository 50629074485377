import gql from 'graphql-tag';

export default (client, contactId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteContact($contactId: ID) {
          deleteContact(id: $contactId) {
            success
          }
        }
      `,
      variables: { contactId }
    })
    .then(response => {
      if (response.data.deleteContact.success === true) {
        dispatch({
          type: 'CONTACT_DELETED',
          contactId
        });
        window.history.pushState({}, null, '/contacts');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteContact.success
        });
      }
    });
};

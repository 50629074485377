import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './style.css';

class ContextMenu extends Component {
  constructor() {
    super();
    this.state = {
      shouldRender: false,
      offsetY: 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { show, y, bounds } = this.props;
    if (!prevProps.show && show) {
      this.setState({ shouldRender: true });
    } else if (prevProps.show && !show) {
      setTimeout(() => this.setState({ shouldRender: false }), 200);
    }

    if (!prevState.shouldRender && this.state.shouldRender) {
      const contentHeight = document.getElementById('contextMenuContent')
        .clientHeight;

      if (y + contentHeight > bounds[1]) {
        this.setState({ offsetY: -contentHeight });
      } else {
        this.setState({ offsetY: 0 });
      }
    }
  }

  static preventEvent(e) {
    e.stopPropagation();
  }

  render() {
    const { children, show, x, y } = this.props;
    const { shouldRender, offsetY } = this.state;

    if (shouldRender) {
      return (
        <CSSTransition
          in={show}
          appear={true}
          timeout={200}
          classNames="fade-opacity">
          <div
            id="contextMenuContent"
            className="position-absolute rounded shadow bg-white"
            style={{ top: y + offsetY, left: x }}
            onMouseDown={ContextMenu.preventEvent}
            onMouseMove={ContextMenu.preventEvent}
            onMouseUp={ContextMenu.preventEvent}>
            {children}
          </div>
        </CSSTransition>
      );
    }
    return null;
  }
}

ContextMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  show: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  bounds: PropTypes.array
};

export default ContextMenu;

import gql from 'graphql-tag';

const getError = errors => {
  let returnError = errors[0];
  errors.forEach((succ, i) => {
    if (i === 0) {
      returnError = '';
    } else {
      returnError += ' ';
    }
    if (
      succ.indexOf(
        'Cannot delete or update a parent row: a foreign key constraint fails'
      ) > -1
    ) {
      returnError += 'Nutzer kann nicht gelöscht werden, da er an einer ';
      if (succ.match(/`([^`]*)`/g)[1].split('`')[1]) {
        const [, module] = succ.match(/`([^`]*)`/g)[1].split('`');
        switch (module) {
          case 'dispo_human_resource':
            returnError += 'Disposition verwendet wird.';
            break;
          case 'human_resource_resource':
            returnError += 'Maschine verwendet wird.';
            break;
          default:
            break;
        }
      }
    }
  });
  return returnError;
};

export default (client, userId) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteHumanResource($userId: ID) {
          deleteHumanResource(id: $userId) {
            success
          }
        }
      `,
      variables: { userId }
    })
    .then(response => {
      if (response.data.deleteHumanResource.success === true) {
        dispatch({
          type: 'HUMAN_RESOURCE_DELETED',
          userId
        });
        window.history.pushState({}, null, '/human_resources');
      } else {
        const { success } = response.data.deleteHumanResource;
        dispatch({
          type: 'SET_ERROR',
          data: getError(success)
        });
      }
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { Draggable } from "react-beautiful-dnd";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select, { components } from "react-select";

import ReactFilestack from "filestack-react";
import {
  FILESTACK_API_KEY_NEW,
  FILESTACK_POLICY_NEW,
  FILESTACK_SIGNATURE_NEW
} from "./filestackCredentials";

import LoadingIndicator from "../LoadingIndicator/small";
import PersistSelect from "./persistSelect";

import { matches } from "../../util/filterBy";
import {
  toDateTimeString,
  addHours,
  addDays,
  startOfDay,
  endOfDay
} from "../../util/dateHelper";

class EditTask extends Component {
  constructor(props) {
    super(props);

    const { task, humanResources, currentDate, establishment } = props;

    let resource = null;
    if (task.resource) {
      resource = {
        ...task.resource,
        id: (task.resourceType === "RESOURCE" ? "R" : "V") + task.resource.id
      };
    } else if (
      task.resourceType === "NONE" ||
      task.resourceType === "EXTERNAL"
    ) {
      resource = {
        id: "R_OTHER",
        name: "Sonstiges",
        number: "Werkstattarbeiten"
      };
    }

    this.state = {
      resource,
      description: task.description || "",
      workspaces: task.workspaces,
      externalWorkshop: task.externalWorkshop || "",
      externalMachine: task.externalMachine || "",
      workers: task.workers,
      startDate: task.startDate ? toDateTimeString(task.startDate) : "",
      dueDate: toDateTimeString(
        task.dueDate || addHours(addDays(startOfDay(new Date()), 5), 20)
      ),
      files: task.files || [],
      humanResourceOptions: EditTask.getHumanResourceOptions(
        humanResources,
        currentDate,
        establishment ? establishment.value : null
      ),
      errors: {},
      loadingReservations: false,
      reservationConflicts: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResourceChange = this.handleResourceChange.bind(this);
    this.handleHumanResourceChange = this.handleHumanResourceChange.bind(this);
    this.handleWorkspaceChange = this.handleWorkspaceChange.bind(this);
    this.handleExternalWorkshopChange = this.handleExternalWorkshopChange.bind(
      this
    );
    this.handleExternalMachineChange = this.handleExternalMachineChange.bind(
      this
    );

    this.handleAddFile = this.handleAddFile.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);

    this.checkConflicts = this.checkConflicts.bind(this);

    this.validate = this.validate.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  static getHumanResourceOptions(humanResources, date, establishment) {
    const options = humanResources
      .filter(hr => hr.status === "Aktiv")
      .map(hr => {
        const isAbsentToday =
          hr.absences.filter(absence => {
            const { from, to } = absence;
            const fromDate = startOfDay(new Date(from)).getTime();
            const toDate = endOfDay(new Date(to)).getTime();

            return fromDate <= date.getTime() && toDate >= date.getTime();
          }).length > 0;

        return { ...hr, isAbsentToday };
      });

    return [
      {
        label: "Schlosser",
        options: options.filter(user => {
          const isSchlosser = user.position === "Schlosser";
          if (!establishment) {
            return isSchlosser;
          }

          return isSchlosser && user.establishment === establishment;
        })
      },
      {
        label: "Maschinisten",
        options: options.filter(user => {
          const isMaschinist = user.position === "Maschinist";
          if (!establishment) {
            return isMaschinist;
          }

          return isMaschinist && user.establishment === establishment;
        })
      },
      {
        label: "Umsetzer",
        options: options.filter(user => {
          const isUmsetzer = user.position === "Umsetzer";
          if (!establishment) {
            return isUmsetzer;
          }

          return isUmsetzer && user.establishment === establishment;
        })
      }
    ];
  }

  static CustomResourceSingleValue = props => (
    <components.SingleValue {...props}>
      {props.data.id.startsWith("R") ? (
        <Fragment>
          <span>{props.data.name}</span>
          <span className="text-black-50"> {props.data.number}</span>
        </Fragment>
      ) : (
        <Fragment>
          <span>
            {props.data.type} {props.data.brand}
          </span>
          <span className="text-black-50">
            {" "}
            {props.data.number} / {props.data.licensePlate}
          </span>
        </Fragment>
      )}
    </components.SingleValue>
  );

  static CustomResourceOption = props => (
    <components.Option {...props}>
      {props.data.id.startsWith("R") ? (
        <Fragment>
          <span>{props.data.name}</span>
          <span className="text-black-50 float-right">{props.data.number}</span>
        </Fragment>
      ) : (
        <Fragment>
          <span>
            {props.data.type} {props.data.brand}
          </span>
          <span className="text-black-50 float-right">
            {props.data.number} / {props.data.licensePlate}
          </span>
        </Fragment>
      )}
    </components.Option>
  );

  static CustomHumanResourceMultiValueLabel = props => (
    <components.MultiValueLabel {...props}>
      {props.data.lastname}
    </components.MultiValueLabel>
  );

  static CustomHumanResourceOption = props => (
    <components.Option {...props}>
      <span>
        {props.data.firstname} {props.data.lastname}
      </span>
      <span className="text-black-50 float-right">{props.data.position}</span>
    </components.Option>
  );

  static workspaceSelectStyles = {
    option: (base, { data, isFocused, isSelected }) => ({
      ...base,
      color: isSelected || isFocused ? "#ffffff" : "#000000",
      background: isSelected ? data.color : isFocused ? `${data.color}aa` : null
    }),
    multiValue: (base, { data }) => ({
      ...base,
      color: "#ffffff",
      background: `${data.color}aa`
    }),
    multiValueLabel: (base, { data }) => ({
      ...base,
      color: "#ffffff"
    }),
    multiValueRemove: (base, { data }) => ({
      ...base,
      color: "#ffffff",
      ":hover": {
        background: data.color
      }
    })
  };

  static humanResourceSelectStyles = {
    option: (base, { data }) => ({
      ...base,
      color: data.isAbsentToday ? "#dc3545" : base.color
    }),
    multiValue: (base, { data }) => ({
      ...base,
      color: data.isAbsentToday ? "#ffffff" : base.color,
      background: data.isAbsentToday ? `#dc3545` : base.backgroundColor
    }),
    multiValueLabel: (base, { data }) => ({
      ...base,
      color: data.isAbsentToday ? "#ffffff" : base.color
    })
  };

  static resourceSelectFilter = (option, filterString) => {
    const filter = filterString.toUpperCase();

    const { data } = option;
    if (data.id.startsWith("R")) {
      return matches(data, ["name", "number", "category"], filter);
    }
    return matches(
      data,
      ["type", "branc", "number", "category", "licensePlate"],
      filter
    );
  };

  static humanResourceSelectFilter = (option, filterString) => {
    const filter = filterString.toUpperCase();

    return matches(option.data, ["firstname", "lastname"], filter);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { humanResources, currentDate, establishment } = this.props;

    if (prevProps.humanResources !== humanResources) {
      this.setState({
        humanResourceOptions: EditTask.getHumanResourceOptions(
          humanResources,
          currentDate,
          establishment ? establishment.value : null
        )
      });
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value }, () => {
      if (name === "startDate" || name === "dueDate") {
        this.checkConflicts();
      }
    });
  }

  handleResourceChange(value) {
    this.setState({ resource: value }, this.checkConflicts);
  }

  handleHumanResourceChange(value) {
    this.setState({ workers: value });
  }

  handleWorkspaceChange(value) {
    if (value && value.find(v => v.id === "15")) {
      this.setState({ workspaces: value.filter(v => v.id === "15") });
    } else {
      this.setState({ workspaces: value });
    }
  }

  handleExternalWorkshopChange(value) {
    this.setState({ externalWorkshop: value ? value.label : "" });
  }

  handleExternalMachineChange(value) {
    this.setState({ externalMachine: value ? value.label : "" });
  }

  handleAddFile(result) {
    const { task, onUpdateTask } = this.props;

    const files = result.filesUploaded.map(file => ({
      filename: file.filename,
      handle: file.handle,
      url: file.url
    }));

    if (task && task.id !== "new" && files.length > 0) {
      let allFiles = task.files || [];
      allFiles = allFiles.concat(files);

      onUpdateTask({ ...task, files: allFiles }, true);
      this.setState({ files: allFiles });
    }
  }

  handleRemoveFile(response, handle) {
    // If the status code is 404 the file was not found. remove it anyway, to prevent orphaned file linkages.
    if (
      response.statusCode === 200 ||
      (response.response && response.response.statusCode === 404)
    ) {
      const { task, onUpdateTask } = this.props;

      if (task && task.id !== "new") {
        const nextFiles = task.files.filter(file => file.handle !== handle);

        this.setState({ files: nextFiles });
        onUpdateTask({ ...task, files: nextFiles }, true);
      }
    }
  }

  checkConflicts() {
    const { resource, startDate, dueDate } = this.state;
    const { client } = this.props;

    if (!resource || !(startDate || dueDate)) {
      this.setState({ loadingReservations: false, reservationConflicts: null });
      return;
    }

    let from = null;
    let to = null;

    if (!startDate) {
      const date = new Date(dueDate);
      from = date;
      to = date;
    } else {
      from = new Date(startDate);
      to = new Date(dueDate);
    }

    const id = parseInt(resource.id.substr(1), 10);
    const type = resource.id.startsWith("R") ? "RESOURCE" : "VEHICLE";

    this.setState({ loadingReservations: true });

    try {
      from = from.toISOString();
    } catch (e) {}

    try {
      to = to.toISOString();
    } catch (e) {}

    client
      .query({
        query: gql`
          query getReservationsInRange(
            $resource_id: ID
            $type: String
            $from: String
            $to: String
          ) {
            getReservationsInRange(
              resource_id: $resource_id
              type: $type
              from: $from
              to: $to
            ) {
              id
              from
              to
              status
            }
          }
        `,
        variables: {
          resource_id: id,
          type,
          from,
          to
        }
      })
      .then(response => {
        const data = response.data.getReservationsInRange;

        if (data) {
          this.setState({
            loadingReservations: false,
            reservationConflicts: data.length === 0 ? null : data
          });
        } else {
          this.setState({
            loadingReservations: false,
            reservationConflicts: null
          });
        }
      });
  }

  validate() {
    const { task } = this.props;
    const { resource, description, startDate, dueDate } = this.state;
    const errors = {};

    if (!resource) errors.resource = true;
    // if (!workspaces || workspaces.length === 0) errors.workspaces = true;
    // if (!workers || workers.length === 0) errors.workers = true;

    if (description.trim() === "") errors.description = true;

    // const dateLimit = startOfDay(new Date());
    //
    // if (task.id === 'new' && startDate) {
    //   const date = new Date(startDate);
    //   if (date.getTime() < dateLimit.getTime()) errors.startDate = true;
    // }

    // if (!dueDate || dueDate === '') errors.dueDate = true;
    // if (dueDate) {
    //   const date = new Date(dueDate);
    //   if (date.getTime() < dateLimit.getTime()) errors.dueDate = true;
    // }

    if (startDate && startDate !== "" && dueDate && dueDate !== "") {
      const start = new Date(startDate);
      const end = new Date(dueDate);
      if (start.getTime() >= end.getTime()) {
        errors.startDate = true;
        errors.dueDate = true;
      }
    }

    this.setState({ errors });

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleSave() {
    const { onAddTask, onUpdateTask, establishment } = this.props;
    let { resource } = this.state;
    const {
      description,
      workspaces,
      externalWorkshop,
      externalMachine,
      workers,
      startDate,
      dueDate,
      files
    } = this.state;

    if (!this.validate()) return;

    let resourceType = "NONE";
    let hasExternalMachine = false;
    if (resource.id === "R_OTHER") {
      resource = null;
      hasExternalMachine = true;
      resourceType = "EXTERNAL";
    } else {
      resourceType = resource.id.startsWith("R") ? "RESOURCE" : "VEHICLE";
      resource.id = resource.id.substring(1);
    }

    const hasExternalWorkshop =
      workspaces && workspaces.find(ws => ws.id === "14");

    const task = {
      id: this.props.task.id,
      resourceType,
      resource,
      description: description.trim(),
      workspaces: workspaces || [],
      externalWorkshop: hasExternalWorkshop ? externalWorkshop.trim() : "",
      externalMachine: hasExternalMachine ? externalMachine.trim() : "",
      workers: workers || [],
      startDate: startDate !== "" ? startDate : null,
      dueDate: dueDate !== "" ? dueDate : null,
      files,
      establishment: establishment ? establishment.value : "Leipzig"
    };

    if (this.props.task.edit) {
      onUpdateTask(task, false);
    } else {
      onAddTask(task);
    }
  }

  render() {
    const {
      task,
      index,
      resources,
      vehicles,
      onCancel,
      establishment
    } = this.props;
    const workspaceOptions = this.props.workspaceOptions.filter(wo => {
      if (!establishment) {
        return true;
      }
      switch (establishment.value) {
        case "Leipzig":
          return !wo.label.includes("D-AP-");
        case "Duben":
          return wo.label.includes("D-AP-");
        default:
          return true;
      }
    });

    const {
      resource,
      description,
      workspaces,
      externalWorkshop,
      externalMachine,
      workers,
      startDate,
      dueDate,
      files,
      humanResourceOptions,
      errors,
      loadingReservations,
      reservationConflicts
    } = this.state;

    const resourceOptions = [
      ...resources.map(r => ({ ...r, id: `R${r.id}` })),
      ...vehicles.map(v => ({ ...v, id: `V${v.id}` }))
    ]
      .filter(option => option.status === "Aktiv")
      .concat({
        id: "R_OTHER",
        name: "Sonstiges",
        number: "Werkstattarbeiten"
      });

    EditTask.workspaceSelectStyles.control = base => ({
      ...base,
      borderColor: errors.workspaces ? "#dc3545" : base.borderColor
    });

    EditTask.humanResourceSelectStyles.control = base => ({
      ...base,
      borderColor: errors.workers ? "#dc3545" : base.borderColor
    });

    const showExternalWorkspaceInput =
      workspaces && workspaces.find(ws => ws.id === "14");

    const showExternalMachineInput = resource && resource.id === "R_OTHER";

    const hideWorkspaceOptions =
      workspaces && workspaces.length === 1 && workspaces[0].id === "15";

    return (
      <Draggable draggableId={task.id} index={index} isDragDisabled={true}>
        {provided => (
          <div
            className="cb-column-task bg-white border rounded p-2 mb-2 shadow-sm"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}>
            <div className="font-weight-bold p-1 mb-1">
              {task.edit ? "Bearbeiten" : "Anlegen"}
            </div>

            <div className="small p-1">
              <div className="form-group mb-2">
                <label className="form-check-label">Maschine/Fahrzeug</label>
                <Select
                  autoFocus
                  isSearchable
                  placeholder=""
                  noOptionsMessage={() => "Keine Übereinstimmung"}
                  getOptionValue={option => option.id}
                  styles={
                    errors.resource
                      ? {
                          control: base => ({ ...base, borderColor: "#dc3545" })
                        }
                      : {}
                  }
                  components={{
                    Option: EditTask.CustomResourceOption,
                    SingleValue: EditTask.CustomResourceSingleValue
                  }}
                  filterOption={EditTask.resourceSelectFilter}
                  value={resource}
                  options={resourceOptions.filter(ro => {
                    if (!establishment) {
                      return true;
                    }
                    return ro.establishment === establishment.value;
                  })}
                  onChange={this.handleResourceChange}
                />
              </div>

              {showExternalMachineInput ? (
                <div className="form-group mb-2">
                  <label className="form-check-label">Weitere Maschinen</label>
                  <PersistSelect
                    type="externalMachine"
                    workshop={externalMachine}
                    onChange={this.handleExternalMachineChange}
                  />
                </div>
              ) : null}

              <div className="form-group mb-2">
                <label className="form-check-label">Beschreibung</label>
                <textarea
                  className={`form-control${
                    errors.description ? " is-invalid" : ""
                  }`}
                  rows="2"
                  name="description"
                  value={description}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group mb-2">
                <label className="form-check-label">
                  Arbeitsplatz/Werkstatt
                </label>
                <Select
                  isMulti
                  isSearchable
                  placeholder=""
                  noOptionsMessage={() => "Keine Übereinstimmung"}
                  getOptionValue={option => option.id}
                  styles={EditTask.workspaceSelectStyles}
                  value={workspaces}
                  options={hideWorkspaceOptions ? [] : workspaceOptions}
                  onChange={this.handleWorkspaceChange}
                />
              </div>

              {showExternalWorkspaceInput ? (
                <div className="form-group mb-2">
                  <label className="form-check-label">Externe Werkstatt</label>
                  <PersistSelect
                    type="externalWorkshop"
                    workshop={externalWorkshop}
                    onChange={this.handleExternalWorkshopChange}
                  />
                </div>
              ) : null}

              <div className="form-group mb-2">
                <label className="form-check-label">Mitarbeiter</label>
                <Select
                  isMulti
                  isSearchable
                  placeholder=""
                  noOptionsMessage={() => "Keine Übereinstimmung"}
                  getOptionValue={option => option.id}
                  styles={EditTask.humanResourceSelectStyles}
                  components={{
                    Option: EditTask.CustomHumanResourceOption,
                    MultiValueLabel: EditTask.CustomHumanResourceMultiValueLabel
                  }}
                  filterOption={EditTask.humanResourceSelectFilter}
                  value={workers}
                  options={humanResourceOptions}
                  onChange={this.handleHumanResourceChange}
                />
              </div>

              <div className="form-group mb-2">
                <label className="form-check-label">Arbeitsbeginn</label>
                <input
                  type="datetime-local"
                  className={`form-control${
                    errors.startDate ? " is-invalid" : ""
                  }`}
                  name="startDate"
                  value={startDate}
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group mb-2">
                <label className="form-check-label">Termin</label>
                <input
                  type="datetime-local"
                  className={`form-control${
                    errors.dueDate ? " is-invalid" : ""
                  }`}
                  name="dueDate"
                  value={dueDate}
                  onChange={this.handleInputChange}
                />
              </div>

              <LoadingIndicator show={loadingReservations} />

              {reservationConflicts ? (
                <div className="text-danger">
                  In diesem Zeitraum gibt es bereits Reservierungen.
                </div>
              ) : null}

              {task.id !== "new" ? (
                <div className="form-group mb-3">
                  <label className="form-check-label w-100">
                    Dateien
                    <ReactFilestack
                      apikey={FILESTACK_API_KEY_NEW}
                      action="pick"
                      clientOptions={{
                        security: {
                          policy: FILESTACK_POLICY_NEW,
                          signature: FILESTACK_SIGNATURE_NEW
                        }
                      }}
                      onSuccess={this.handleAddFile}
                      customRender={({ onPick }) => (
                        <button
                          className="btn-no-style cb-add-file-btn float-right"
                          onClick={onPick}>
                          anhängen
                        </button>
                      )}
                    />
                  </label>
                  {files.map(file => (
                    <div key={file.handle}>
                      <a
                        className=""
                        href={`${file.url}?policy=${FILESTACK_POLICY_NEW}&signature=${FILESTACK_SIGNATURE_NEW}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {file.filename}
                      </a>
                      <ReactFilestack
                        apikey={FILESTACK_API_KEY_NEW}
                        mode="remove"
                        security={{
                          policy: FILESTACK_POLICY_NEW,
                          signature: FILESTACK_SIGNATURE_NEW
                        }}
                        options={{
                          handle: file.handle
                        }}
                        onSuccess={response =>
                          this.handleRemoveFile(response, file.handle)
                        }
                        onError={response =>
                          this.handleRemoveFile(response, file.handle)
                        }
                        render={({ onPick }) => (
                          <button
                            className="btn-no-style cb-file-remove-btn float-right"
                            onClick={onPick}>
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        )}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-black-50 mb-2">
                  Dateien können nur an angelegte Tasks angehängt werden.
                </div>
              )}

              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-danger flex-grow-1 mr-1"
                  data-id={task.id}
                  onClick={onCancel}>
                  abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-success flex-grow-1 ml-1"
                  onClick={this.handleSave}>
                  speichern
                </button>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

EditTask.propTypes = {
  client: PropTypes.object,
  establishment: PropTypes.object,
  task: PropTypes.object,
  index: PropTypes.number,
  resources: PropTypes.array,
  vehicles: PropTypes.array,
  humanResources: PropTypes.array,
  workspaceOptions: PropTypes.array,
  currentDate: PropTypes.object,
  onAddTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
  onCancel: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  resources: state.resources.get("resources"),
  vehicles: state.vehicles.get("vehicles"),
  humanResources: state.humanResources.get("users"),
  workspaceOptions: state.workshop.get("workspaces"),
  currentDate: state.workshop.get("currentDate")
}))(EditTask);

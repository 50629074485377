export const GET_MODULES = `
  {
    modules {
      id
      name
      clearName
      status
    }
  }
`;

export const GET_NEWS = `
  query content(
    $first: Int
    $skip: Int
    $where: ContentWhereInput
    $orderBy: ContentOrderByInput
  ) {
    contents (
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
    ) {
      status
      updatedAt
      createdAt
      id
      headline
      description
      modules {
        name
        clearName
      }
      readContents {
        userId
      }
      issueType
    }
  }
`;

export const SET_NEWS_READ = `
  mutation createReadContent($data: ReadContentCreateInput!) {
    createReadContent(data: $data) {
      userId
      status
      id
      content {
        id
      }
    }
  }
`;

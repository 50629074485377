import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';

const InnerComponent = ({ data }) => {
  if (data.id === '__new__') {
    return <div className="small">{data.label}</div>;
  }

  switch (data.type) {
    case 'ORDER': {
      const {
        id,
        cost_center: costCenter,
        building_address: buildingAddress,
        lat,
        lng,
        description
      } = data;

      let addrStr = buildingAddress || 'keine Adresse';

      if (lat !== '' && lng !== '') {
        if (buildingAddress && buildingAddress !== '') {
          addrStr = `In der Nähe von: ${buildingAddress} (${lat}, ${lng})`;
        } else {
          addrStr = `(${lat}, ${lng})`;
        }
      }

      let costCenterComponent = null;

      if (costCenter) {
        let infoText = costCenter.customer || '';
        infoText += '/ ';
        infoText += costCenter.building_project || '';

        const establishment =
          costCenter.establishment && costCenter.establishment !== ''
            ? costCenter.establishment.substr(0, 2).toUpperCase()
            : 'kA';

        costCenterComponent = (
          <div className="small">
            <span
              className={`cost-center-select-badge-${establishment.toLowerCase()}`}>
              {establishment}
            </span>
            <span
              className={costCenter.is_continuance ? 'font-weight-bold' : ''}>
              {costCenter.value}
            </span>
            {' | '}
            {infoText}
          </div>
        );
      } else {
        costCenterComponent = (
          <div className="small">
            <span className={`cost-center-select-badge-ka`}>anderes</span>
            <strong>{id}</strong> {description}
          </div>
        );
      }

      return (
        <Fragment>
          {costCenterComponent}
          <div className="text-black-50 small mt-1">{addrStr}</div>
        </Fragment>
      );
    }
    case 'COST_CENTER': {
      const {
        value,
        customer,
        establishment,
        building_project: buildingProject,
        is_continuance: isContinuance
      } = data;

      let infoText = customer || '';
      infoText += '/ ';
      infoText += buildingProject || '';

      const costCenterEstablishment =
        establishment && establishment !== ''
          ? establishment.substr(0, 2).toUpperCase()
          : 'kA';

      return (
        <div className="small">
          <span
            className={`cost-center-select-badge-${costCenterEstablishment.toLowerCase()}`}>
            {costCenterEstablishment}
          </span>
          <span
            className={`cost-center-select-badge-wa alert-warning`}>
            Kostenstelle
          </span>
          <span className={isContinuance ? 'font-weight-bold' : ''}>
            {value}
          </span>
          {' | '}
          {infoText}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

InnerComponent.propTypes = {
  data: PropTypes.object
};

export const CustomSingleValue = ({ data, ...rest }) => (
  <components.SingleValue data={data} {...rest}>
    <InnerComponent data={data} />
  </components.SingleValue>
);

CustomSingleValue.propTypes = {
  data: PropTypes.object
};

export const CustomOption = ({ data, ...rest }) => (
  <components.Option data={data} {...rest}>
    <InnerComponent data={data} />
  </components.Option>
);

CustomOption.propTypes = {
  data: PropTypes.object
};

import gql from "graphql-tag";

export default (client, id, data) => dispatch => {
  const query = `
    mutation updateDropzone(
      $id: ID
      $data: String
    ) {
      updateDropzone(
        id: $id
        data: $data
      ) {
        id
        company
        address
        contactName: contact_name
        contactPhone: contact_phone
        date
        data {
          material
          contaminated
          amountDropZone
          amountDropZoneUnit
        }
      }
    }
  `;
  client
    .mutate({
      mutation: gql`
        ${query}
      `,
      variables: {
        id,
        data: JSON.stringify(data)
      }
    })
    .then(response => {
      dispatch({
        type: "DROPZONE_UPDATED",
        data: response.data.updateDropzone
      });
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

import GoogleMapReact from "google-map-react";

export const MarkerRed = () => (
  <FontAwesomeIcon
    style={{
      position: "relative",
      top: -32,
      left: -12,
      fontSize: 32,
      color: "#D30132"
    }}
    icon={faMapMarkerAlt}
  />
);

export const MarkerBlue = () => (
  <FontAwesomeIcon
    style={{
      position: "relative",
      top: -32,
      left: -12,
      fontSize: 32,
      color: "#0414d3"
    }}
    icon={faMapMarkerAlt}
  />
);

const doGeocode = (address, geocoder) => {
  return new Promise(resolve => {
    geocoder.geocode({ address }, (reslocs, status) => {
      if (status === "OK") {
        const foundloc = reslocs[0].geometry.location;
        const lat = foundloc.lat();
        const lng = foundloc.lng();
        resolve({ lat, lng });
      }
    });
  });
};

const calculateCenter = (lat1, lng1, lat2, lng2) => {
  if (lat1 && lat2 && lng1 && lng2) {
    return {
      lat: (lat1 + lat2) / 2,
      lng: (lng1 + lng2) / 2
    };
  } else if (lat1 && lng1) {
    return {
      lat: lat1,
      lng: lng1
    };
  } else if (lat2 && lng2) {
    return {
      lat: lat1,
      lng: lng1
    };
  }
};

const calculateZoom = (lat1, lng1, lat2, lng2) => {
  if (lat1 && lat2 && lng1 && lng2) {
    const distance = Math.sqrt(
      Math.pow(lat1 - lat2, 2) + Math.pow(lng1 - lng2, 2)
    );
    // calculated function that maps the distance on the zoom
    return distance ? 7.4185 * Math.pow(distance, -0.1053) : 15;
  } else {
    return 15;
  }
};

class Map2 extends Component {
  constructor(props) {
    super(props);

    this.initCenter = {
      lat: 51.3396955,
      lng: 12.3730747
    };

    this.state = {
      geocoder: null,
      center: this.initCenter,
      zoom: 12,
      sourceLat: 0,
      sourceLng: 0,
      destinationLat: 0,
      destinationLng: 0
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleApiLoaded = this.handleApiLoaded.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      sourceAddress,
      destinationAddress,
      sourceCoordinates,
      destinationCoordinates,
      onSourceAddressChange,
      onDestinationAddressChange
    } = this.props;
    const {
      geocoder,
      sourceLat,
      sourceLng,
      destinationLat,
      destinationLng
    } = this.state;

    const addressChanged =
      prevProps.sourceAddress !== sourceAddress ||
      prevProps.destinationAddress !== destinationAddress;
    const coordinatesChanged =
      prevProps.sourceCoordinates !== sourceCoordinates ||
      prevProps.destinationCoordinates !== destinationCoordinates;
    const geocoderChanged = !prevState.geocoder && geocoder;

    if (geocoder && (geocoderChanged || addressChanged || coordinatesChanged)) {
      if (sourceCoordinates) {
        const sourceLatNew = parseFloat(sourceCoordinates.sourceLat);
        const sourceLngNew = parseFloat(sourceCoordinates.sourceLng);
        this.setState({
          center: calculateCenter(
            sourceLatNew,
            sourceLngNew,
            destinationLat,
            destinationLng
          ),
          zoom: calculateZoom(
            sourceLatNew,
            sourceLngNew,
            destinationLat,
            destinationLng
          ),
          sourceLat: sourceLatNew,
          sourceLng: sourceLngNew
        });
      } else if (geocoder) {
        if (sourceAddress === "") {
          this.setState({
            sourceLat: 0,
            sourceLng: 0
          });
        } else {
          doGeocode(sourceAddress, geocoder).then(coords => {
            onSourceAddressChange(coords.lat, coords.lng);
            this.setState({
              ...coords,
              center: calculateCenter(
                coords.lat,
                coords.lng,
                destinationLat,
                destinationLng
              ),
              zoom: calculateZoom(
                coords.lat,
                coords.lng,
                destinationLat,
                destinationLng
              )
            });
          });
        }
      }
      if (destinationCoordinates) {
        const destinationLatNew = parseFloat(
          destinationCoordinates.destinationLat
        );
        const destinationLngNew = parseFloat(
          destinationCoordinates.destinationLng
        );
        this.setState({
          center: calculateCenter(
            destinationLatNew,
            destinationLngNew,
            sourceLat,
            sourceLng
          ),
          zoom: calculateZoom(
            destinationLatNew,
            destinationLngNew,
            sourceLat,
            sourceLng
          ),
          destinationLat: destinationLatNew,
          destinationLng: destinationLngNew
        });
      } else if (geocoder) {
        if (destinationAddress === "") {
          this.setState({
            destinationLat: 0,
            destinationLng: 0
          });
        } else {
          doGeocode(destinationAddress, geocoder).then(coords => {
            onDestinationAddressChange(coords.lat, coords.lng);

            this.setState({
              ...coords,
              center: calculateCenter(
                coords.lat,
                coords.lng,
                sourceLat,
                sourceLng
              ),
              zoom: calculateZoom(coords.lat, coords.lng, sourceLat, sourceLng)
            });
          });
        }
      }
    }
  }

  handleChange(e) {
    this.setState({ center: e.center });
  }

  handleApiLoaded(map, maps) {
    const geocoder = new maps.Geocoder();
    this.setState({ geocoder });
  }

  render() {
    const { locked, isOverlayVisible } = this.props;
    const {
      center,
      sourceLat,
      sourceLng,
      destinationLat,
      destinationLng,
      zoom
    } = this.state;

    const overlayClassName = `w-100 h-100 bg-secondary position-relative${
      isOverlayVisible ? " opacity-25" : " opacity-0"
    }`;

    return (
      <Fragment>
        <GoogleMapReact
          bootstrapURLKeys={{
            // key: 'AIzaSyCqYcowuiDz9FACmQxDAodn5tR_0i8cIl4',
            key: "AIzaSyDXfC6IijZ14DbIxp_SrnSO40NF4NgzO1o",
            language: "de",
            region: "de"
          }}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
          options={{ gestureHandling: "cooperative" }}
          onChange={this.handleChange}
        >
          {sourceLat && (
            <MarkerBlue lat={sourceLat} lng={sourceLng} text="My Marker" />
          )}
          {destinationLat && (
            <MarkerRed
              lat={destinationLat}
              lng={destinationLng}
              text="My Marker"
            />
          )}
        </GoogleMapReact>
        {locked ? (
          <div className={overlayClassName} style={{ top: -500 }} />
        ) : null}
      </Fragment>
    );
  }
}

Map2.defaultProps = {
  zoom: 11,
  locked: false,
  isOverlayVisible: true
};

Map2.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  sourceAddress: PropTypes.string,
  destinationAddress: PropTypes.string,
  sourceCoordinates: PropTypes.object,
  destinationCoordinates: PropTypes.object,
  zoom: PropTypes.number,
  locked: PropTypes.bool,
  isOverlayVisible: PropTypes.bool,
  onAddressChange: PropTypes.func,
  onSourceAddressChange: PropTypes.func,
  onDestinationAddressChange: PropTypes.func
};

export default Map2;

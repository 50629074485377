import gql from 'graphql-tag';

export default (client, id) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation deleteTransporter($id: ID) {
          deleteTransporter(id: $id) {
            success
          }
        }
      `,
      variables: {
        id
      }
    })
    .then(response => {
      dispatch({
        type: 'TRANSPORTER_REMOVED',
        success: response.data.deleteTransporter,
        id
      });
    });
};

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { filterBy } from "../../util/filterBy";

import TitleBar from "../TitleBar";
import SimpleTable from "../SimpleTable";
import Data from "./data";

import getCostCenterFrames from "../../actions/getCostCenterFrames";
import getCostCenters from "../../actions/getCostCenters";
import setChosenCostCenter from "../../actions/setChosenCostCenter";

class App extends Component {
  constructor(props) {
    super(props);
    document.title = "Kostenstelle";

    this.state = {
      filter: ""
    };

    const { client, dispatch, location } = props;

    const id = location.pathname.split("/")[2];
    dispatch(getCostCenterFrames(client));
    dispatch(getCostCenters(client, id));

    this.listMapping = [
      { key: "value", label: "Nummer" },
      { key: "oldValue", label: "Nummer alt" },
      { key: "project_number", label: "Projektnummer" },
      { key: "building_project", label: "Bauvorhaben" },
      { key: "customer", label: "Auftraggeber" },
      { key: "is_continuance", label: "Dauerkostenstelle" },
      { key: "establishment", label: "Niederlassung" }
    ];

    this.listDataRenderers = {
      is_continuance: elem => (
        <div className="text-center">
          {elem ? <FontAwesomeIcon icon={faCheck} /> : null}
        </div>
      )
    };

    this.setChosenCostCenter = this.setChosenCostCenter.bind(this);
    this.setNewCostCenter = this.setNewCostCenter.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  setChosenCostCenter(costCenter) {
    this.props.dispatch(setChosenCostCenter(costCenter));
  }

  setNewCostCenter() {
    this.props.dispatch({
      type: "SET_NEW_COST_CENTER"
    });
    window.history.pushState({}, null, "/cost_center");
  }

  handleFilterChange(e) {
    this.setState({ filter: e.target.value });
  }

  static filter(data, filter) {
    return filterBy(
      data,
      ["value", "oldValue", "establishment", "building_project", "customer"],
      filter
    );
  }

  render() {
    let { costCenters } = this.props;
    const {
      location,
      chosenCostCenter,
      permissions,
      loginName,
      establishment
    } = this.props;
    const { filter } = this.state;

    const hasWritePermission =
      Boolean(permissions.find(p => p.name === "cost_center" && p.write)) ||
      loginName === "klout";

    const hasFrameReadPermission =
      Boolean(
        permissions.find(p => p.name === "cost_center_frame" && p.read)
      ) || loginName === "klout";

    costCenters = App.filter(costCenters, filter);
    const selected = chosenCostCenter ? ["id", chosenCostCenter.id] : null;

    const title = (
      <Fragment>
        <span className="h4 mr-3">Kostenstellen</span>
        {hasFrameReadPermission ? (
          <a className="h4" href="/cost_center_frame">
            Kostenstellenrahmen
          </a>
        ) : null}
      </Fragment>
    );

    // Find double cost centers
    /*
    const uc = [];
    const doubles = [];
    costCenters.forEach(cc => {
      if(uc.find(c => c.value === cc.value)) {
        doubles.push(cc);
      } else {
        uc.push(cc);
      }
    });

    console.log(uc);
    console.log(doubles);
    */

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 padding-20 hidden-print">
              <TitleBar
                titleComponent={title}
                showControls={hasWritePermission}
              >
                <div className="col">
                  <button
                    type="button"
                    onClick={this.setNewCostCenter}
                    className="btn btn-outline-primary btn-sm ml-3 float-right"
                  >
                    hinzufügen
                  </button>
                </div>
              </TitleBar>

              <input
                className="form-control mt-3"
                placeholder="Filter..."
                value={filter}
                onChange={this.handleFilterChange}
              />

              <SimpleTable
                className="mt-3"
                mapping={this.listMapping}
                selected={selected}
                sortKey="value"
                reverseSort
                data={costCenters}
                dataRenderers={this.listDataRenderers}
                onRowSelected={this.setChosenCostCenter}
              />
            </div>

            <div className="col-md-6 data-panel padding-20 col-print-12 border-left border-print-none height-print-auto">
              <TitleBar title="Stammdaten" />

              <Data
                establishment={establishment}
                costCenter={chosenCostCenter}
                write={hasWritePermission}
                createMode={true}
                queryString={location.search}
                onChange={(type, value) => console.log(type, value)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  location: PropTypes.object,
  establishment: PropTypes.object,
  costCenters: PropTypes.array,
  chosenCostCenter: PropTypes.object,
  permissions: PropTypes.array,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  establishment: state.main.get("establishment"),
  costCenters: state.costCenters.get("costCenters"),
  chosenCostCenter: state.costCenters.get("chosenCostCenter"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName")
}))(App);

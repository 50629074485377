import gql from 'graphql-tag';

export default client => dispatch => {
  client
    .query({
      query: gql`
        query {
          getHumanResources {
            id
            lastname
            firstname
            username
            position
            status
            permissions {
              id
              name
              read
              write
            }
          }
        }
      `
    })
    .then(response => {
      dispatch({
        type: 'PERMISSIONS_FOUND',
        data: response.data.getHumanResources
      });
    });
};

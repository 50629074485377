import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Draggable } from "react-beautiful-dnd";

import {
  faArchive,
  faExclamationSquare,
  faPencil,
  faCheck,
  faTimes,
  faExclamationTriangle,
  faTrashAlt
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FILESTACK_POLICY_NEW,
  FILESTACK_SIGNATURE_NEW
} from "./filestackCredentials";

import { toDateString } from "../../util/dateHelper";

class Task extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmationBtn: false,
      confirmationAction: null
    };

    this.unlockConfirmationBtn = this.unlockConfirmationBtn.bind(this);
    this.lockConfirmationBtn = this.lockConfirmationBtn.bind(this);
    this.handleConfirmationBtnClick = this.handleConfirmationBtnClick.bind(
      this
    );
  }

  unlockConfirmationBtn(e) {
    const { action } = e.currentTarget.dataset;

    this.setState({ showConfirmationBtn: true, confirmationAction: action });
  }

  lockConfirmationBtn() {
    this.setState({ showConfirmationBtn: false, confirmationAction: null });
  }

  handleConfirmationBtnClick(e) {
    const { onArchive, onDelete } = this.props;
    const { confirmationAction } = this.state;

    switch (confirmationAction) {
      case "ARCHIVE":
        onArchive(e);
        break;
      case "DELETE":
        onDelete(e);
        break;
      default:
        break;
    }
  }

  render() {
    const {
      task,
      index,
      columnId,
      conflicts,
      currentDate,
      write,
      onEdit
    } = this.props;
    const { showConfirmationBtn } = this.state;

    const { resource, workspaces, startDate, dueDate } = task;

    let dateClassName = "cb-badge ";

    if (dueDate && columnId !== "column100") {
      const daysDiff =
        (dueDate.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000);

      dateClassName = "cb-badge ";
      if (daysDiff <= 0) {
        dateClassName += "cb-badge-danger";
      } else if (daysDiff <= 1) dateClassName += "cb-badge-warning";
      else dateClassName += "cb-badge-secondary";
    } else {
      dateClassName += "cb-badge-secondary";
    }

    let showExtraBtn = false;
    let extraBtnAction = "";
    let extraBtnIcon = faTrashAlt;
    if (columnId === "column0") {
      showExtraBtn = true;
      extraBtnAction = "DELETE";
      extraBtnIcon = faTrashAlt;
    } else if (columnId === "column100") {
      showExtraBtn = true;
      extraBtnAction = "ARCHIVE";
      extraBtnIcon = faArchive;
    }

    let title = "";
    switch (task.resourceType) {
      case "RESOURCE": {
        if (resource) {
          title = `${resource.name} (${resource.number} ${resource.category})`;
        }
        break;
      }
      case "VEHICLE": {
        if (resource) {
          title = `${resource.brand} ${resource.type} ${resource.licensePlate} (${resource.number} ${resource.category})`;
        }
        break;
      }
      case "EXTERNAL": {
        title = `Sonstiges (${task.externalMachine})`;
        break;
      }
      case "NONE": {
        break;
      }
      default:
        title = "Sonstiges / Werkstattarbeiten";
        break;
    }

    const files = task.files || [];

    const reservationConflicts =
      task.otherReservations && task.otherReservations.length > 0;

    const onSite =
      workspaces && workspaces.length > 0 && workspaces[0].id === "15";

    return (
      <Draggable draggableId={task.id} index={index} isDragDisabled={!write}>
        {provided => (
          <div
            className={`cb-card border rounded p-2 mb-2 shadow-sm ${
              onSite ? "bg-grey" : "bg-white"
            }`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}>
            <div className="position-relative font-weight-bold p-1 pr-3">
              {reservationConflicts ? (
                <span
                  className="text-danger mr-2"
                  title="Überschneidung mit Reservierungen">
                  <FontAwesomeIcon icon={faExclamationSquare} />
                </span>
              ) : null}
              {title}
              {write ? (
                <div className="cb-card-icons-container">
                  {showConfirmationBtn ? (
                    <Fragment>
                      <button
                        type="button"
                        className="btn-no-style cb-card-icon cb-card-icon-danger float-right"
                        onClick={this.lockConfirmationBtn}>
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                      <button
                        type="button"
                        className="btn-no-style cb-card-icon cb-card-icon-success"
                        data-id={task.id}
                        onClick={this.handleConfirmationBtnClick}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {showExtraBtn ? (
                        <button
                          type="button"
                          className="btn-no-style cb-card-icon cb-card-icon-secondary float-right"
                          data-action={extraBtnAction}
                          onClick={this.unlockConfirmationBtn}>
                          <FontAwesomeIcon icon={extraBtnIcon} />
                        </button>
                      ) : null}
                      <button
                        type="button"
                        className="btn-no-style cb-card-icon cb-card-icon-secondary"
                        data-id={task.id}
                        onClick={onEdit}>
                        <FontAwesomeIcon icon={faPencil} />
                      </button>
                    </Fragment>
                  )}
                </div>
              ) : null}
            </div>

            <div className="small p-1">
              <div className="border-bottom pb-2">{task.description}</div>

              <div className="row">
                <div className="col my-2">
                  <div className="mb-1">Arbeitsplatz/Werkstatt</div>
                  <div>
                    {workspaces.map(workspace => (
                      <div
                        key={workspace.id}
                        className="cb-badge cb-badge-secondary mb-1 mr-1"
                        style={{ background: workspace.color }}>
                        {conflicts.duplicateWorkspaces &&
                        conflicts.duplicateWorkspaces.find(
                          id => id === workspace.id
                        ) ? (
                          <FontAwesomeIcon
                            className="text-danger mr-1"
                            icon={faExclamationTriangle}
                          />
                        ) : null}
                        {workspace.label !== "Sonstiges"
                          ? workspace.label
                          : task.externalWorkshop !== ""
                          ? task.externalWorkshop
                          : "Sonstiges"}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row my-2">
                <div className="col-6">
                  <div className="mb-1">Arbeitsbeginn</div>
                  <div className="cb-badge cb-badge-secondary">
                    {startDate
                      ? `${toDateString(startDate)} ${startDate.getHours()}:00`
                      : "k.A."}
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-1">Termin</div>
                  <div className={dateClassName}>
                    {dueDate
                      ? `${toDateString(dueDate)} ${dueDate.getHours()}:00`
                      : "k.A."}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">Mitarbeiter</div>
                  <div>
                    {task.workers.map(hr => (
                      <div
                        key={hr.id}
                        className="cb-badge cb-badge-light mb-1 mr-1">
                        {hr.absences.length > 0 ? (
                          <FontAwesomeIcon
                            className="text-danger mr-1"
                            icon={faExclamationTriangle}
                          />
                        ) : null}
                        {hr.lastname}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-1">Ersteller</div>
                  <div className="cb-badge cb-badge-light mb-1 mr-1">
                    {task.creator}
                  </div>
                </div>
              </div>

              {files.length > 0 ? (
                <div className="border-top py-2">
                  {files.map(file => (
                    <a
                      key={file.handle}
                      className="d-block mr-3"
                      href={`${file.url}?policy=${FILESTACK_POLICY_NEW}&signature=${FILESTACK_SIGNATURE_NEW}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {file.filename}
                    </a>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

Task.propTypes = {
  task: PropTypes.object,
  index: PropTypes.number,
  conflicts: PropTypes.object,
  columnId: PropTypes.string,
  currentDate: PropTypes.object,
  write: PropTypes.bool,
  onEdit: PropTypes.func,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  currentDate: state.workshop.get("currentDate")
}))(Task);

import gql from 'graphql-tag';

export default (client, companiesWithContacts, lonelyContacts) => dispatch => {
  const createCompany = gql`
    mutation createCompany(
      $name: String
      $adress: String
      $zipcode: String
      $city: String
      $phone: String
      $fax: String
      $email: String
    ) {
      createCompany(
        name: $name
        adress: $adress
        zipcode: $zipcode
        city: $city
        phone: $phone
        fax: $fax
        email: $email
      ) {
        id
        name
      }
    }
  `;

  const createContact = gql`
    mutation createContact(
      $salutation: String
      $lastname: String
      $firstname: String
      $phone: String
      $position: String
      $mobile: String
      $email: String
      $fax: String
      $companyId: ID
    ) {
      createContact(
        salutation: $salutation
        lastname: $lastname
        firstname: $firstname
        phone: $phone
        position: $position
        mobile: $mobile
        email: $email
        fax: $fax
        company_id: $companyId
      ) {
        id
        lastname
      }
    }
  `;

  const count = companiesWithContacts.length;

  let contactsFromCompanies = [];

  // let noCompanyCount = 0;
  // let noIdCount = 0;

  (async function loop() {
    for (let i = 0; i < companiesWithContacts.length; i += 1) {
      const company = companiesWithContacts[i];
      await client
        .mutate({
          mutation: createCompany,
          variables: {
            name: company.name,
            adress: company.address,
            zipcode: company.zipcode,
            city: company.city,
            phone: company.phone,
            fax: company.fax,
            email: company.email
          }
        })
        // eslint-disable-next-line
        .then(response => {
          dispatch({
            type: 'IMPORT_STATUS_UPDATED',
            name: response.data.createCompany.name,
            status: 'Importing companies',
            progress: (i + 1) / count
          });

          const createdCompany = response.data.createCompany;
          if (createdCompany) {
            // console.log(createdCompany);
            const { id } = createdCompany;
            // if (!id) {
            //   noIdCount += 1;
            // }
            contactsFromCompanies = contactsFromCompanies.concat(
              company.contacts.map(contact => ({...contact, reference: id}))
            );
          }
          // } else {
          //   noCompanyCount += 1;
          // }
        });
    }

    // console.log("No ids: " + noIdCount);
    // console.log("Companies not created: " + noCompanyCount);
    // console.log(contactsFromCompanies);
    // console.log(lonelyContacts.length);
    // console.log(contactsFromCompanies.length + lonelyContacts.length);

    for (let i = 0; i < contactsFromCompanies.length; i += 1) {
      const contact = contactsFromCompanies[i];
      await client
        .mutate({
          mutation: createContact,
          variables: {
            salutation: contact.salutation,
            lastname: contact.lastname,
            firstname: contact.firstname,
            phone: contact.phone,
            email: contact.email,
            fax: '',
            mobile: contact.mobile,
            position: contact.occupation,
            companyId: contact.reference
          }
        })
        // eslint-disable-next-line
        .then(response => {
          dispatch({
            type: 'IMPORT_STATUS_UPDATED',
            name: response.data.createContact.lastname,
            status: 'Importing contacts from company',
            progress: (i + 1) / contactsFromCompanies.length
          });
        });
    }

    for (let i = 0; i < lonelyContacts.length; i += 1) {
      const contact = lonelyContacts[i];
      await client
        .mutate({
          mutation: createContact,
          variables: {
            salutation: contact.salutation,
            lastname: contact.lastname,
            firstname: contact.firstname,
            phone: contact.phone,
            email: contact.email,
            fax: '',
            mobile: contact.mobile,
            position: contact.occupation,
            companyId: null
          }
        })
        .then(response => {
          dispatch({
            type: 'IMPORT_STATUS_UPDATED',
            name: response.data.createContact.lastname,
            status: 'Importing contacts without company',
            progress: (i + 1) / lonelyContacts.length
          });
        });
    }

    dispatch({
      type: 'IMPORT_STATUS_UPDATED',
      name: '',
      status: 'Done',
      progress: 1.0
    });

  })();
};

import gql from "graphql-tag";
import { ReservationData } from "./fragments";
import store from "../store";
import getOrder from "./getOrder";

export default (
  dispatch,
  client,
  resourceId,
  groupId,
  resourceType,
  from,
  to,
  orderId,
  scopeOfServices,
  note,
  contactPersonId
) => {
  const user = store.getState().main.get("loginName");

  const createReservation = gql`
    mutation createReservation(
      $user: String!
      $resource_id: ID
      $group_id: String
      $type: String
      $from: String
      $to: String
      $status: String
      $note: String
      $contactPersonId: String
    ) {
      createReservation(
        user: $user
        resource_id: $resource_id
        group_id: $group_id
        type: $type
        from: $from
        to: $to
        status: $status
        note: $note
        contactPersonId: $contactPersonId
      ) {
        ...ReservationData
      }
    }
    ${ReservationData}
  `;

  const createScopeOfService = gql`
    mutation createScopeOfService(
      $requestUser: String!
      $reservation_id: ID
      $width: String
      $depth: String
      $depthTo: String
      $amount: String
      $amountUnit: String
    ) {
      createScopeOfService(
        requestUser: $requestUser
        reservation_id: $reservation_id
        width: $width
        depth: $depth
        depthTo: $depthTo
        amount: $amount
        amountUnit: $amountUnit
      ) {
        id
        width
        depth
        depthTo
        amount
        amountUnit
      }
    }
  `;

  const createScopeOfServiceType = gql`
    mutation createScopeOfServiceType(
      $requestUser: String!
      $scope_of_service_id: ID
      $value: String
    ) {
      createScopeOfServiceType(
        requestUser: $requestUser
        scope_of_service_id: $scope_of_service_id
        value: $value
      ) {
        id
        value
      }
    }
  `;

  return client
    .mutate({
      mutation: createReservation,
      variables: {
        user,
        resource_id: resourceId,
        group_id: groupId,
        type: resourceType,
        from,
        to,
        status: orderId,
        note,
        contactPersonId
      }
    })
    .then(response => {
      let sosPromises = [];
      const reservation = response.data.createReservation;

      if (reservation && reservation.id && scopeOfServices) {
        const { id } = reservation;

        sosPromises = scopeOfServices.map(sos => {
          if (sos.width !== "" && sos.depth !== "" && sos.amount !== "") {
            return client
              .mutate({
                mutation: createScopeOfService,
                variables: {
                  requestUser: user,
                  reservation_id: parseInt(id, 10),
                  width: sos.width,
                  depth: sos.depth,
                  depthTo: sos.depthTo ? sos.depthTo.toString() : sos.depthTo,
                  amount: sos.amount,
                  amountUnit: sos.amountUnit
                }
              })
              .then(response1 => {
                let scopeOfService = response1.data.createScopeOfService;
                const sosId = parseInt(scopeOfService.id, 10);

                scopeOfService = {
                  ...scopeOfService,
                  scopeOfServiceTyps: []
                };

                Promise.all(
                  sos.scopeOfServiceTyps.map(sost =>
                    client
                      .mutate({
                        mutation: createScopeOfServiceType,
                        variables: {
                          requestUser: user,
                          scope_of_service_id: sosId,
                          value: sost.value
                        }
                      })
                      .then(response2 => {
                        const scopeOfServiceType =
                          response2.data.createScopeOfServiceType;
                        scopeOfService.scopeOfServiceTyps.push(
                          scopeOfServiceType
                        );
                      })
                  )
                ).then(() =>
                  dispatch({
                    type: "RESERVATION_SOS_ADDED",
                    data: scopeOfService,
                    resourceId,
                    resourceType,
                    reservationId: id
                  })
                );
              });
          }
          return undefined;
        });
      }

      dispatch({
        type: "RESERVATION_ADDED",
        data: reservation,
        resourceId
      });
      dispatch(getOrder(client, orderId));

      return Promise.all(sosPromises);
    });
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Select, { components } from "react-select";

import createOrUpgradeReservation from "../../../actions/createOrUpgradeReservation";
import addHumanResourcesToOrder from "../../../actions/addHumanResourcesToOrder";

class ResourceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenOrderId: props.chosenOrderId,
      resourceOptions: [],
      humanResourceOptions: [],
      vehicleOptions: [],
      combinationOptions: [],
      combinationOptionValue: null,
      resourceOptionValue: null,
      humanResourceOptionValue: null
    };

    this.changeSelectedCombination = this.changeSelectedCombination.bind(this);
    this.changeSelectedResource = this.changeSelectedResource.bind(this);
    this.changeSelectedHumanResource = this.changeSelectedHumanResource.bind(
      this
    );

    this.clearOptions = this.clearOptions.bind(this);
    this.addResources = this.addResources.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const nextState = {
      chosenOrderId: props.chosenOrderId,
      resourceOptions: [],
      humanResourceOptions: [],
      vehicleOptions: [],
      combinationOptions: [],
      combinationOptionValue: null,
      resourceOptionValue: null,
      humanResourceOptionValue: null
    };

    if (state.chosenOrderId === props.chosenOrderId) {
      nextState.resourceOptionValue = state.resourceOptionValue;
      nextState.humanResourceOptionValue = state.humanResourceOptionValue;
    } else {
      nextState.resourceOptionValue = null;
      nextState.humanResourceOptionValue = null;
    }

    const {
      resources,
      vehicles,
      humanResources,
      combinations,
      startDate,
      endDate
    } = props;

    let resourceOptions = [];

    if (resources) {
      resourceOptions = resources
        .filter(resource => resource.status === "Aktiv")
        .map(resource => ({
          id: `R${resource.id}`,
          name: resource.name,
          category: resource.category,
          number: resource.number,
          keyword: `${resource.name}_${resource.category}_${resource.number}`.toLowerCase(),
          humanResources: resource.humanResources.map(humanResource => ({
            id: humanResource.id
          }))
        }));
    }

    if (vehicles) {
      resourceOptions = resourceOptions.concat(
        vehicles
          .filter(vehicle => vehicle.status === "Aktiv")
          .map(vehicle => ({
            id: `V${vehicle.id}`,
            name: `${vehicle.brand} ${vehicle.type}`,
            category: vehicle.category,
            number: vehicle.licensePlate,
            keyword: `${vehicle.brand} ${vehicle.type}_${vehicle.licensePlate}_${vehicle.category}`.toLowerCase(),
            humanResources: vehicle.humanResources.map(humanResource => ({
              id: humanResource.id
            }))
          }))
      );
    }

    nextState.resourceOptions = resourceOptions;

    if (humanResources) {
      nextState.humanResourceOptions = humanResources
        .filter(
          humanResource =>
            humanResource.status === "Aktiv" &&
            (humanResource.position === "Umsetzer" ||
              humanResource.position === "Maschinist" ||
              humanResource.position === "Schlosser")
        )
        .map(humanResource => ({
          id: humanResource.id,
          label: `${humanResource.firstname} ${
            humanResource.lastname
          } ${ResourceSelect.getCutTime(
            humanResource.absences,
            startDate,
            endDate
          )}`,
          position: humanResource.position,
          keyword: `${humanResource.firstname}_${humanResource.lastname}_${humanResource.position}`.toLowerCase(),
          absences: humanResource.absences,
          color: ResourceSelect.getColorFromAbsences(
            humanResource.absences,
            startDate,
            endDate
          )
        }));
    }

    nextState.combinationOptions = combinations.map(combi => ({
      ...combi,
      label: combi.name
    }));

    return nextState;
  }

  static getColorFromAbsences(absences, startDate, endDate) {
    let color = "#56A6FC";

    if (absences && startDate && endDate) {
      let isPartiallyAbsent = false;
      let isFullyAbsent = false;

      const start = moment(startDate);
      const end = moment(endDate);
      absences.forEach(absence => {
        const from = moment(absence.from);
        const to = moment(absence.to);
        isPartiallyAbsent =
          isPartiallyAbsent ||
          from.isBetween(start, end) ||
          to.isBetween(start, end);
        isFullyAbsent =
          isFullyAbsent || (from.isBefore(start) && to.isAfter(end));
      });

      if (isPartiallyAbsent) color = "#FFAB00";
      if (isFullyAbsent) color = "#DD2020";
    }

    return color;
  }

  static getCutTime(absences, startDate, endDate) {
    let text = "";
    if (absences && startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      absences.forEach(absence => {
        const from = moment(absence.from);
        const to = moment(absence.to);
        if (from.isBetween(start, end)) {
          text = `(${from.format("DD.MM.")} - ${end.format("DD.MM.YYYY")})`;
        }
        if (to.isBetween(start, end)) {
          text = `(${start.format("DD.MM")} - ${to.format("DD.MM.YYYY")})`;
        }
        if (from.isBefore(start) && to.isAfter(end)) {
          text = `(${start.format("DD.MM.")} - ${end.format("DD.MM.YYYY")})`;
        }
        if (
          text.length > 0 &&
          (from.isSame(to, "day") || start.isSame(end, "day"))
        ) {
          text = `(${start.format("DD.MM.YYYY")})`;
        }
      });
    }
    return text;
  }

  changeSelectedCombination(newOption, { action }) {
    if (action === "pop-value") {
      return;
    }

    const { humanResourceOptions, resourceOptions } = this.state;

    const humnaResourceIds = newOption.humanResources.map(r => r.id);
    const vehicleIds = newOption.vehicles.map(v => v.id);
    const resourceIds = newOption.resources.map(r => r.id);

    const preselectedHumanResources = [];
    humnaResourceIds.forEach(id => {
      const option = humanResourceOptions.find(elem => elem.id === id);
      if (option) {
        preselectedHumanResources.push(option);
      }
    });

    const preselectedVehicles = [];
    vehicleIds.forEach(id => {
      const option = resourceOptions.find(elem => elem.id === `V${id}`);
      if (option) {
        preselectedVehicles.push(option);
      }
    });

    const preselectedResources = [];
    resourceIds.forEach(id => {
      const option = resourceOptions.find(elem => elem.id === `R${id}`);
      if (option) {
        preselectedResources.push(option);
      }
    });

    this.setState({
      combinationOptionValue: newOption,
      resourceOptionValue: [...preselectedResources, ...preselectedVehicles],
      humanResourceOptionValue: preselectedHumanResources
    });
  }

  changeSelectedResource(newOption, { action }) {
    if (action === "pop-value") {
      return;
    }

    const { humanResourceOptions } = this.state;
    const ids = [
      ...new Set(
        newOption.flatMap(option =>
          option.humanResources.map(resource => resource.id)
        )
      )
    ];

    const preselectedHumanResources = [];
    ids.forEach(id => {
      const option = humanResourceOptions.find(elem => elem.id === id);
      if (option) preselectedHumanResources.push(option);
    });

    const newState = {
      resourceOptionValue: newOption
    };

    if (action !== "remove-value") {
      newState.humanResourceOptionValue = preselectedHumanResources;
    }

    this.setState(newState);
  }

  changeSelectedHumanResource(newOption) {
    this.setState({ humanResourceOptionValue: newOption });
  }

  clearOptions() {
    this.setState({
      combinationOptionValue: null,
      resourceOptionValue: null,
      humanResourceOptionValue: null
    });
  }

  addResources() {
    const {
      client,
      startDate,
      endDate,
      dispatch,
      onActionNoOrder,
      resources,
      opStartTime,
      opFinishTime
    } = this.props;
    const { chosenOrderId } = this.state;
    const selectedResources = this.state.resourceOptionValue;
    const selectedHumanResources = this.state.humanResourceOptionValue;
    // TODO: either use locale times or utc times, then substract 2
    //  check

    const start = moment(startDate); // moment.utc(startDate);
    const end = moment(endDate); // moment.utc(endDate);



    let [startOpHour, startOpMinutes]  = opStartTime.split(":")
    let [finishOpHour, finishOpMinutes] = opFinishTime.split(":")
    //startOpHour = parseInt(startOpHour) - 2
    //finishOpHour = parseInt(finishOpHour) - 2

    start.set({ hour: parseInt(startOpHour), minutes: parseInt(startOpMinutes) });
    end.set({ hour: parseInt(finishOpHour), minutes: parseInt(finishOpMinutes) });

    if (chosenOrderId) {
      if (selectedResources) {
        // Check if this is a resource or vehicle.
        selectedResources.forEach(resource => {
          const selectedResource = resource;
          const { id } = selectedResource;
          if (id.charAt(0) === "R") {
            selectedResource.id = selectedResource.id.substring(1);
            createOrUpgradeReservation(
              dispatch,
              client,
              chosenOrderId,
              selectedResource.id,
              "RESOURCE",
              start,
              end
            );
          } else {
            selectedResource.id = selectedResource.id.substring(1);
            createOrUpgradeReservation(
              dispatch,
              client,
              chosenOrderId,
              selectedResource.id,
              "VEHICLE",
              start,
              end
            );
          }
        });
      }
      if (selectedHumanResources) {
        dispatch(
          addHumanResourcesToOrder(
            client,
            chosenOrderId,
            selectedHumanResources,
            resources,
            start,
            end
          )
        );
      }
    } else {
      onActionNoOrder(
        "select",
        selectedResources,
        selectedHumanResources,
        resources
      );
    }
    this.clearOptions();
  }

  render() {
    const { disabled } = this.props;
    const {
      resourceOptions,
      humanResourceOptions,
      combinationOptions,
      resourceOptionValue,
      humanResourceOptionValue,
      combinationOptionValue
    } = this.state;

    const CustomCombinationOption = props => (
      <components.Option {...props}>
        <span>{props.data.name}</span>
      </components.Option>
    );

    const CustomResourceMultiValue = props => (
      <components.MultiValue {...props}>
        <span>{props.data.name}</span>
        <span className="text-black-50 float-right">
          {props.data.number} / {props.data.category}
        </span>
      </components.MultiValue>
    );

    const CustomResourceOption = props => (
      <components.Option {...props}>
        <span>{props.data.name}</span>
        <span className="text-black-50 float-right">
          {props.data.number} / {props.data.category}
        </span>
      </components.Option>
    );

    const CustomHumanResourceOption = props => (
      <components.Option {...props}>
        <span>{props.data.label}</span>
        <span className="text-black-50 float-right">{props.data.position}</span>
      </components.Option>
    );

    const selectFilter = (option, filterString) =>
      option.data.keyword.indexOf(filterString.toLowerCase()) > -1;

    const humanResourceSelectStyles = {
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: `${data.color}22`
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          backgroundColor: data.color,
          color: "white"
        }
      })
    };

    return (
      <div className="form-group row">
        <div className="col-sm-12">
          <Select
            isClearable={false}
            isDisabled={disabled}
            value={combinationOptionValue}
            onChange={this.changeSelectedCombination}
            placeholder="Gespann wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{ Option: CustomCombinationOption }}
            options={combinationOptions}
          />
        </div>
        <div className="col-sm-12 mt-3">
          <Select
            isMulti
            isClearable={false}
            isDisabled={disabled}
            value={resourceOptionValue}
            onChange={this.changeSelectedResource}
            placeholder="Maschine wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{
              Option: CustomResourceOption,
              MultiValue: CustomResourceMultiValue
            }}
            options={resourceOptions}
            filterOption={selectFilter}
          />
        </div>
        <div className="col-sm-12 mt-3">
          <Select
            isMulti
            isSearchable
            isClearable={false}
            isDisabled={disabled}
            styles={humanResourceSelectStyles}
            value={humanResourceOptionValue}
            onChange={this.changeSelectedHumanResource}
            placeholder="Mitarbeiter wählen..."
            noResultsText="keine Übereinstimmung"
            getOptionValue={option => option.id}
            components={{ Option: CustomHumanResourceOption }}
            options={humanResourceOptions}
            filterOption={selectFilter}
          />
        </div>
        <div className="col-sm-12 mt-3">
          <div className="row">
            <div className="col-sm-4">
              <button
                className="btn btn-outline-primary w-100"
                onClick={this.clearOptions}
                disabled={disabled}
              >
                leeren
              </button>
            </div>
            <div className="col-sm-4" />
            <div className="col-sm-4">
              <button
                className="btn btn-primary w-100"
                onClick={this.addResources}
                disabled={disabled}
              >
                hinzufügen
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResourceSelect.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  disabled: PropTypes.bool,
  chosenOrderId: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resources: PropTypes.array,
  humanResources: PropTypes.array,
  vehicles: PropTypes.array,
  combinations: PropTypes.array,
  onActionNoOrder: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client")
}))(ResourceSelect);

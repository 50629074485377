import gql from "graphql-tag";
import { whenModified } from "./modifyHelper";

export default (client, id) => dispatch => {
    whenModified(client, "absence", () =>
        client
        .query({
            query: gql`
                query {
                    getAbsences {
                        id
                        from
                        to
                        type
                        days
                        humanResource {
                            id
                            lastname
                            firstname
                            position
                        }
                    }
                }
            `,
            fetchPolicy: "network-only"
        })
        .then(response => {
            dispatch({ type: "ABSENCES_FOUND", data: response.data.getAbsences });
            if (id !== undefined && id !== "") {
                const absence = response.data.getAbsences.find(obj => obj.id === id);
                if (absence !== undefined) {
                    dispatch({ type: "SET_CHOSEN_ABSENCE", data: absence });
                }
            }
        }),
    true);
};

import gql from 'graphql-tag';
import { OrderDetail } from './fragments';
import getLoginName from '../getLoginName';

export default (client, id, status, preventUpdate) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  client
    .mutate({
      mutation: gql`
        mutation updateOrderStatus(
          $requestUser: String!
          $id: ID
          $status: Int
        ) {
          updateOrderStatus(
            requestUser: $requestUser
            id: $id
            status: $status
          ) {
            ...OrderDetail
          }
        }
        ${OrderDetail}
      `,
      variables: {
        requestUser,
        id,
        status
      }
    })
    .then(response => {
      dispatch({
        type: 'ORDER_UPDATED',
        data: response.data.updateOrderStatus,
        preventUpdate
      });
    });
};

import gql from 'graphql-tag';

export default (client, commitments, resourceId) => dispatch => {
  Promise.all(
    commitments.map(commitment =>
      client.mutate({
        mutation: gql`
          mutation createCommitment($resourceId: ID, $value: String) {
            createCommitment(resource_id: $resourceId, value: $value) {
              id
              value
            }
          }
        `,
        variables: {
          resourceId,
          value: commitment.value
        }
      })
    )
  ).then(responses => {
    responses.map(response => {
      dispatch({
        type: 'COMMITMENT_CREATED',
        data: response.data.createCommitment,
        resourceId
      });
      return response;
    });
  });
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import Select from 'react-select';

const InnerComponent = ({ data }) => {
  const { firstname, lastname, phone, mobile } = data;
  const number = mobile && mobile !== '' ? mobile : phone || '';

  return (
    <Fragment>
      <span>
        {firstname} {lastname}
      </span>
      <span className="text-black-50">
        {' | '}
        {number}
      </span>
    </Fragment>
  );
};

InnerComponent.propTypes = {
  data: PropTypes.object
};

export default class HumanResourceSelect extends Component {
  static CustomSingleValue = props => (
    <components.SingleValue {...props}>
      <InnerComponent data={props.data} />
    </components.SingleValue>
  );

  static CustomOption = props => {
    const { firstname, lastname, position, vehicles } = props.data;

    return (
      <components.Option {...props}>
        <span>
          {firstname} {lastname}
        </span>
        <span className="text-black-50"> {position}</span>
        {vehicles ? (
          <div className="small text-muted">
            {vehicles
              .map(v => `${v.brand} ${v.type} (${v.licensePlate})`)
              .join(', ')}
          </div>
        ) : null}
      </components.Option>
    );
  };

  static filter = (option, filterString) => {
    const { data } = option;

    // eslint-disable-next-line
    if (data.__isNew__) return true;

    const testString = `${data.firstname} ${data.lastname}_${data.position}`.toLowerCase();

    return testString.indexOf(filterString.toLowerCase()) > -1;
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, { action }) {
    const { onChange } = this.props;

    if (action !== 'pop-value') onChange(value);
  }

  render() {
    const { value, options, allowCreation, error, disabled } = this.props;

    const styles = {
      control: base => ({
        ...base,
        borderColor: error ? '#dc3545' : base.borderColor
      })
    };

    return (
      <Select
        isClearable
        isDisabled={disabled}
        placeholder="Mitarbeiter wählen..."
        noOptionsMessage={() => 'Name Eingeben'}
        getOptionValue={option => option.id}
        styles={styles}
        components={{
          Option: HumanResourceSelect.CustomOption,
          SingleValue: HumanResourceSelect.CustomSingleValue
        }}
        filterOption={HumanResourceSelect.filter}
        isValidNewOption={inputValue =>
          inputValue.trim() !== '' && allowCreation
        }
        getNewOptionData={inputValue => ({
          id: '__new__',
          label: inputValue,
          firstname: inputValue,
          lastname: '',
          position: '',
          phone: '',
          mobile: '',
          __isNew__: true
        })}
        value={value}
        options={options}
        onChange={this.handleChange}
      />
    );
  }
}

HumanResourceSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  allowCreation: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

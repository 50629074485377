import gql from 'graphql-tag';
import { ReservationData } from './fragments';

export default (client, user) => dispatch => {
  client
    .subscribe({
      query: gql`
        subscription createReservation($user: String!) {
          createReservation(user: $user) {
            resourceId
            reservation {
              ...ReservationData
            }
          }
        }
        ${ReservationData}
      `,
      variables: {
        user
      }
    })
    .subscribe({
      next({ data }) {
        const { createReservation } = data;
        dispatch({
          type: 'RESERVATION_ADDED',
          data: createReservation.reservation,
          resourceId: createReservation.resourceId
        });
      }
    });
};

import gql from 'graphql-tag';
import moment from 'moment';
import { uniqBy } from 'lodash';

import updateReservation from './updateReservation';
import validateOrderPeriod from './validateOrderPeriod';
import getLoginName from '../getLoginName';
import createReservation from './createReservation';
import deleteReservationScopeOfServices from './deleteReservationScopeOfServices';

export default (client, order, models) => async dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);

  /* const createDispoMachine = gql`
    mutation createDispoMachine(
      $requestUser: String!
      $order_id: ID
      $resource_id: ID
      $human_resource_id: ID
      $resource_from: String
      $resource_to: String
      $human_resource_from: String
      $human_resource_to: String
    ) {
      createDispoMachine(
        requestUser: $requestUser
        order_id: $order_id
        resource_id: $resource_id
        human_resource_id: $human_resource_id
        resource_from: $resource_from
        resource_to: $resource_to
        human_resource_from: $human_resource_from
        human_resource_to: $human_resource_to
      ) {
        resource {
          id
          name
        }
        humanResource {
          id
          lastname
          firstname
        }
      }
    }
  `;

  const createDispoVehicle = gql`
    mutation createDispoVehicle(
      $requestUser: String!
      $order_id: ID
      $vehicle_id: ID
      $from: String
      $to: String
    ) {
      createDispoVehicle(
        requestUser: $requestUser
        order_id: $order_id
        vehicle_id: $vehicle_id
        from: $from
        to: $to
      ) {
        id
        vehicle {
          id
          brand
          type
        }
        from
        to
      }
    }
  `; */

  const createDispoHumanResource = gql`
    mutation createDispoHumanResource(
      $requestUser: String!
      $order_id: ID
      $human_resource_id: ID
      $from: String
      $to: String
    ) {
      createDispoHumanResource(
        requestUser: $requestUser
        order_id: $order_id
        human_resource_id: $human_resource_id
        from: $from
        to: $to
      ) {
        id
        humanResource {
          id
          lastname
          firstname
        }
        from
        to
      }
    }
  `;

  dispatch({ type: 'SET_DISPOSITION_LOADING', data: true });

  await (async function loop() {
    for (let i = 0; i < models.length; i += 1) {
      const model = models[i];
      const { resource, start, end } = model;
      let { humanResources } = resource;
      const { id } = resource;

      const from = moment.utc(start).format('YYYY-MM-DD HH:mm');
      const to = moment.utc(end).format('YYYY-MM-DD HH:mm');

      await dispatch(deleteReservationScopeOfServices(client, model.id));

      await dispatch(
        updateReservation(
          client,
          model.id,
          id,
          resource.type,
          model.start,
          model.end,
          order.id
        )
      );

      if (resource.type === 'RESOURCE') {
        /* await client.mutate({
          mutation: createDispoMachine,
          variables: {
            requestUser,
            order_id: order.id,
            resource_id: id,
            human_resource_id: null,
            resource_from: from,
            resource_to: to,
            human_resource_from: from,
            human_resource_to: to
          }
        }); */
      } else if (resource.type === 'VEHICLE') {
        /* await client.mutate({
          mutation: createDispoVehicle,
          variables: {
            requestUser,
            order_id: order.id,
            vehicle_id: id,
            from,
            to
          }
        }); */
      } else if (resource.type === 'COMBINATION') {
        await resource.resources.forEach(elem => {
          humanResources = humanResources.concat(elem.humanResources);
          /* client.mutate({
            mutation: createDispoMachine,
            variables: {
              requestUser,
              order_id: order.id,
              resource_id: elem.id,
              human_resource_id: null,
              resource_from: from,
              resource_to: to,
              human_resource_from: from,
              human_resource_to: to
            }
          }); */
          createReservation(
            dispatch,
            client,
            elem.id,
            model.groupId,
            'RESOURCE',
            from,
            to,
            order.id,
            []
          );
        });

        await resource.vehicles.forEach(elem => {
          humanResources = humanResources.concat(elem.humanResources);
          /* client.mutate({
            mutation: createDispoVehicle,
            variables: {
              requestUser,
              order_id: order.id,
              vehicle_id: elem.id,
              from,
              to
            }
          }); */
          createReservation(
            dispatch,
            client,
            elem.id,
            model.groupId,
            'VEHICLE',
            from,
            to,
            order.id,
            []
          );
        });

        humanResources = uniqBy(humanResources, elem => elem.id);
      } else {
        // eslint-disable-next-line
        continue;
      }

      await humanResources.forEach(humanResource => {
        client.mutate({
          mutation: createDispoHumanResource,
          variables: {
            requestUser,
            order_id: order.id,
            human_resource_id: parseInt(humanResource.id, 10),
            from,
            to
          }
        });
      });
    }
  })();

  dispatch({ type: 'SET_DISPOSITION_LOADING', data: false });

  dispatch(validateOrderPeriod(client, order.id));
};

import gql from 'graphql-tag';
import { ResourceData } from './fragments';
import { whenModified } from './modifyHelper';

export default (client, id) => dispatch => {
  whenModified(client, 'resource', () =>
    client
      .query({
        query: gql`
          query {
            getResources {
              ...ResourceData
            }
          }
          ${ResourceData}
        `,
        fetchPolicy: 'network-only'
      })
      .then(response => {
        dispatch({ type: 'RESOURCES_FOUND', data: response.data.getResources });
        if (id !== undefined && id !== '') {
          const resource = response.data.getResources.find(
            obj => obj.id === id
          );
          if (resource !== undefined) {
            dispatch({ type: 'SET_CHOSEN_RESOURCE', data: resource });
          }
        }
      })
  );
};

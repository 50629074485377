import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import Select, { components } from "react-select";

import queryString from "query-string";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitleBar from "../TitleBar";
import Button from "../UnlockButton";

import createAbsence from "../../actions/createAbsence";
import updateAbsence from "../../actions/updateAbsence";
import deleteAbsence from "../../actions/deleteAbsence";

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = Data.getInitState(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.handleTypeInputChange = this.handleTypeInputChange.bind(this);

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.unlock = this.unlock.bind(this);
    this.lock = this.lock.bind(this);
  }

  static CustomSingleValue = props => (
    <components.SingleValue {...props}>
      <span
        className="select-circle"
        style={{ background: props.data.color }}
      />
      <span>{props.data.label}</span>
    </components.SingleValue>
  );

  static CustomOption = props => (
    <components.Option {...props}>
      <span
        className="select-circle"
        style={{ background: props.data.color }}
      />
      {props.data.label}
    </components.Option>
  );

  static CustomUserOption = props => {
    let showMark = false;
    switch (props.data.type) {
      case "PRESET": {
        showMark = true;
        break;
      }
      case "DEFAULT": {
        break;
      }
      default: {
        break;
      }
    }

    return (
      <components.Option {...props}>
        {showMark ? (
          <Fragment>
            <span className="badge badge-secondary">
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
            {` ${props.label}`}
          </Fragment>
        ) : (
          props.label
        )}
      </components.Option>
    );
  };

  static getDerivedStateFromProps(props, state) {
    let newState = state;
    if (props.absence) {
      const { absence, absenceTypes } = props;
      if (absence.id !== state.id) {
        let type = absenceTypes[0];
        if (absence) {
          const option = absenceTypes.find(elem => elem.value === absence.type);
          if (option) type = option;
        }

        newState = {
          ...newState,
          id: absence.id || "",
          humanResource: absence.humanResource,
          type,
          from: moment.utc(absence.from).format("YYYY-MM-DD") || "",
          to: moment.utc(absence.to).format("YYYY-MM-DD") || "",
          days: absence.days || "",
          locked: true,
          errors: {}
        };
      }
    } else if (state.id !== "") {
      return Data.getInitState(props);
    }

    return newState;
  }

  static getInitState(props) {
    const queryData = queryString.parse(window.location.search, {
      arrayFormat: "index",
      parseNumbers: true
    });
    const isQuery = !!Object.keys(queryData).length;

    const { absence, absenceTypes } = props;
    let type = absenceTypes[0];
    if (absence) {
      const option = absenceTypes.find(elem => elem.value === absence.type);
      if (option) type = option;
    }
    return {
      id: "",
      humanResources: isQuery ? queryData.humanResources : [],
      humanResource: null,
      type,
      from: isQuery ? queryData.from.substring(0, 10) : "",
      to: isQuery ? queryData.to.substring(0, 10) : "",
      days: "-",
      locked: !props.write,
      errors: {}
    };
  }

  handleInputChange(e) {
    const { from, to } = this.state;
    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    if (name === "from") {
      const start = moment(value);
      const end = moment(to);

      if (end.isBefore(start)) this.setState({ to: value });
    } else if (name === "to") {
      const start = moment(from);
      const end = moment(value);

      if (end.isBefore(start)) this.setState({ from: value });
    }

    if (name === "from" || name === "to")
      this.setState({ [name]: value, days: "-" });
    else this.setState({ [name]: value });
  }

  handleSelectInputChange(value) {
    const { onSelect } = this.props;

    onSelect({
      target: { value: value ? `${value.firstname} ${value.lastname}` : "" }
    });

    this.setState({ humanResource: value });
  }

  handleTypeInputChange(value) {
    this.setState({ type: value });
  }

  unlock() {
    const { absence, onSelect } = this.props;

    if (absence) {
      const { humanResource } = absence;
      const value = `${humanResource.firstname} ${humanResource.lastname}`;
      onSelect({ target: { value } });
    }

    this.setState({
      locked: false
    });
  }

  lock() {
    const { onSelect } = this.props;

    onSelect({ target: { value: "" } });

    this.setState({
      locked: true,
      errors: {}
    });
  }

  onSave() {
    const { dispatch, client, absence, absences } = this.props;

    const { humanResource, type, from, to } = this.state;

    if (!humanResource || from === "" || to === "") {
      this.lock();
      return;
    }

    const fromM = moment.utc(from);
    const toM = moment.utc(to);

    if (fromM.year() < 2010) {
      this.setState({ errors: { time: true } });
      return;
    }

    if (toM.year() < 2010) {
      this.setState({ errors: { time: true } });
      return;
    }

    // Check for intersecting absences.
    if (absences) {
      const id = absence ? absence.id : null;

      for (let i = 0; i < absences.length; i += 1) {
        const elem = absences[i];
        if (elem.id !== id) {
          const start = moment.utc(elem.from);
          const end = moment.utc(elem.to);

          if (fromM.isBefore(end) && toM.isAfter(start)) {
            this.setState({
              errors: {
                time: true,
                intersection: {
                  start: start.format("DD.MM.YYYY"),
                  end: end.format("DD.MM.YYYY")
                }
              }
            });
            return;
          }
        }
      }
    }

    if (absence && absence.id && type) {
      dispatch(
        updateAbsence(
          client,
          absence.id,
          humanResource.id,
          type.value,
          from,
          to
        )
      );
    } else {
      dispatch(createAbsence(client, humanResource.id, type.value, from, to));
    }

    this.lock();
  }

  onDelete() {
    const { dispatch, client, absence } = this.props;
    if (absence) {
      const { id, humanResource } = absence;
      if (humanResource) {
        dispatch(deleteAbsence(client, id, humanResource.id));
      }
    }
    this.lock();
  }

  static print() {
    window.print();
  }

  render() {
    const { className, absence, absenceTypes, users, write } = this.props;
    const {
      humanResource,
      type,
      from,
      to,
      days,
      locked,
      errors,
      humanResources
    } = this.state;

    const sortedUsers = users
      .sort((a, b) => {
        if (humanResources.includes(parseInt(a.id, 10))) {
          return -1;
        }
        if (humanResources.includes(parseInt(b.id, 10))) {
          return 1;
        }
        return 0;
      })
      .map(u => {
        const returnValue = { ...u };
        if (humanResources.includes(parseInt(u.id, 10))) {
          returnValue.type = "PRESET";
        } else {
          returnValue.type = "DEFAULT";
        }
        return returnValue;
      });

    return (
      <div className={className}>
        <TitleBar
          title="Stammdaten"
          btnText="drucken"
          btnClassName="btn btn-outline-secondary btn-sm float-right"
          onBtnClick={Data.print}
          showControls={absence && write}
        />

        <div className="row">
          <div className="col-6 form-group">
            <label className="small">Mitarbeiter</label>
            <Select
              isClearable
              isDisabled={locked}
              placeholder=""
              noOptionsMessage={() => "Name Eingeben"}
              noResultsMessage={() => "Keine Übereinstimmung"}
              getOptionValue={opt => opt.id}
              getOptionLabel={opt =>
                `${opt.firstname} ${opt.lastname}  (${opt.position})`
              }
              value={humanResource}
              options={sortedUsers.filter(u => u.status === "Aktiv")}
              components={{
                Option: Data.CustomUserOption
              }}
              onChange={this.handleSelectInputChange}
            />
          </div>
          <div className="col-6 form-group">
            <label className="small">Abwesenheitstyp</label>
            <Select
              placeholder=""
              isSearchable={false}
              noResultsMessage={() => "Keine Übereinstimmung"}
              components={{
                SingleValue: Data.CustomSingleValue,
                Option: Data.CustomOption
              }}
              value={type}
              options={absenceTypes}
              onChange={this.handleTypeInputChange}
              isDisabled={locked}
            />
          </div>
          <div className="col-5 form-group">
            <label className="small">Von</label>
            <input
              type="date"
              min={"2010-01-01"}
              max={"2099-12-31"}
              className={`form-control ${errors.time ? "is-invalid" : ""}`}
              name="from"
              value={from}
              onChange={this.handleInputChange}
              disabled={locked}
            />
            {errors.time ? (
              <small className="form-text text-danger">
                Abwesenheiten älter als 2010 sind nicht zulässig.
              </small>
            ) : null}
          </div>
          <div className="col-5 form-group">
            <label className="small">Bis</label>
            <input
              type="date"
              min={"2010-01-01"}
              max={"2099-12-31"}
              className={`form-control ${errors.time ? "is-invalid" : ""}`}
              name="to"
              value={to}
              onChange={this.handleInputChange}
              disabled={locked}
            />
            {errors.time ? (
              <small className="form-text text-danger">
                Abwesenheiten älter als 2010 sind nicht zulässig.
              </small>
            ) : null}
          </div>
          <div className="col-2 form-group">
            <label className="small">Werktage</label>
            <input
              type="text"
              className="form-control"
              name="days"
              value={days}
              disabled
            />
          </div>
          {errors.intersection ? (
            <div className="col-12 text-danger small">
              Überschneidung mit Abwesenheit vom {errors.intersection.start} bis{" "}
              {errors.intersection.end}
            </div>
          ) : null}
        </div>

        {write ? (
          <div className="order-fixed-bottom border-top">
            <Button
              className="row pt-3 pl-3 hidden-print mr-2"
              onSave={this.onSave}
              onDelete={this.onDelete}
              unlock={this.unlock}
              lock={this.lock}
              chosen={!!absence}
              locked={locked}
              saveEnabled={Boolean(humanResource)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Data.propTypes = {
  dispatch: PropTypes.func,
  client: PropTypes.object,
  className: PropTypes.string,
  absence: PropTypes.object,
  absences: PropTypes.array,
  absenceTypes: PropTypes.array,
  users: PropTypes.array,
  write: PropTypes.bool,
  onSelect: PropTypes.func
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client")
}))(Data);

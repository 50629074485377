import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { connect, Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import * as Sentry from "@sentry/browser";

import "bootstrap/dist/css/bootstrap.css";

import "./styles/index.scss";
import "./styles/print.css";

import store from "./store";

import Login from "./components/Login";
import Header from "./components/Header";
import ErrorModal from "./components/Util/ErrorModal";
import MessageCards from "./components/MessageCards";

import checkToken from "./actions/checkToken";
import updatePermissions from "./actions/updatePermissions";

import subscribeToCreateReservation from "./actions/subscribeToCreateReservation";
import subscribeToUpdateReservation from "./actions/subscribeToUpdateReservation";
import subscribeToDeleteReservation from "./actions/subscribeToDeleteReservation";

import subscribeToCreateOrder from "./actions/subscribeToCreateOrder";
import subscribeToUpdateOrder from "./actions/subscribeToUpdateOrder";
import subscribeToDeleteOrder from "./actions/subscribeToDeleteOrder";

// import registerServiceWorker from './registerServiceWorker';

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://8afd638a8219489bad7481c0af1132f8@sentry.io/1405689",
    release: `klout_web@${process.env.REACT_APP_VERSION}`
  });
}

// const history = syncHistoryWithStore(createBrowserHistory(), store);

const PrivateRoute = ({ component: ComponentToRender, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuth === true ? (
        <Fragment>
          <Header />
          <ComponentToRender {...props} />
        </Fragment>
      ) : (
        <Login />
      )
    }
  />
);

PrivateRoute.propTypes = {
  dispatch: PropTypes.func,
  component: PropTypes.func,
  path: PropTypes.string,
  isAuth: PropTypes.bool
};

class Routes extends Component {
  constructor(props) {
    super(props);

    const { client, dispatch, backend, loginUserId, loginName, token } = props;

    dispatch(checkToken(token, backend));
    dispatch(updatePermissions(loginUserId, token));

    dispatch(subscribeToCreateReservation(client, loginName));
    dispatch(subscribeToUpdateReservation(client, loginName));
    dispatch(subscribeToDeleteReservation(client, loginName));

    dispatch(subscribeToCreateOrder(client, loginName));
    dispatch(subscribeToUpdateOrder(client, loginName));
    dispatch(subscribeToDeleteOrder(client, loginName));
  }

  render() {
    const {
      dispatch,
      routes,
      location,
      isLogin,
      loginUserName,
      showDocumentation
    } = this.props;
    const left = routes.left.filter(route => route.path);
    const right = routes.right.filter(route => route.path);

    const combinedRoutes = left.concat(right);

    return (
      <Fragment>
        <Switch>
          {combinedRoutes.map(route => (
            <PrivateRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isAuth={isLogin}
            />
          ))}
        </Switch>

        <ErrorModal />

        <MessageCards
          dispatch={dispatch}
          url={`https://${window.location.hostname}/doku/graphql`}
          path={location.pathname}
          user={loginUserName}
          isLargeView={showDocumentation}
        />
      </Fragment>
    );
  }
}

Routes.propTypes = {
  client: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  backend: PropTypes.string,
  token: PropTypes.string,
  isLogin: PropTypes.bool,
  loginName: PropTypes.string,
  loginUserId: PropTypes.number,
  loginUserName: PropTypes.string,
  routes: PropTypes.object,
  showDocumentation: PropTypes.bool
};

const ConnectedRoutes = withRouter(
  connect((state, props, dispatch) => ({
    dispatch,
    client: state.main.get("client"),
    backend: state.main.get("backend"),
    token: state.main.get("token"),
    isLogin: state.main.get("isLogin"),
    loginName: state.main.get("loginName"),
    loginUserId: state.main.get("loginUserId"),
    loginUserName: state.main.get("loginUserName"),
    routes: state.main.get("routes"),
    showDocumentation: state.main.get("showDocumentation")
  }))(Routes)
);

const Root = () => (
  <Provider store={store}>
    <Router>
      <ConnectedRoutes />
    </Router>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

/**
 * For serviceworker debugging run a chrome instance like the following command
 * chromium-browser --user-data-dir=/tmp/foo --unsafely-treat-insecure-origin-as-secure=http://localhost:3003
 *
 */
// registerServiceWorker();

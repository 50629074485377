import Immutable from 'immutable';

const initialState = Immutable.Map({
  vehicles: [],
  chosenVehicle: null,
  brands: [],
  types: []
});

export default (state = initialState, action) => {
  if (action.type === 'VEHICLES_FOUND') {
    return state.set('vehicles', action.data);
  }

  if (action.type === 'SET_NEW_VEHICLE') {
    return state.set('chosenVehicle', null);
  }

  if (action.type === 'SET_CHOSEN_VEHICLE') {
    return state.set('chosenVehicle', action.data);
  }

  if (action.type === 'VEHICLE_ADDED') {
    const vehicles = state.get('vehicles');
    return state
      .set('vehicles', vehicles.concat(action.data))
      .set('chosenVehicle', action.data);
  }

  if (action.type === 'VEHICLE_UPDATED') {
    const vehicles = state.get('vehicles');
    const { id } = action.data;

    const nextVehicles = vehicles.map(vehicle => {
      if (vehicle.id === id) return action.data;
      return vehicle;
    });

    return state
      .set('vehicles', nextVehicles)
      .set('chosenVehicle', action.data);
  }

  if (action.type === 'VEHICLE_DELETED') {
    const vehicles = state.get('vehicles');
    const vehicleId = action.vehicleId.toString();
    const nextVehicles = vehicles.filter(vehicle => vehicle.id !== vehicleId);
    return state.set('vehicles', nextVehicles).set('chosenVehicle', null);
  }

  if (action.type === 'HUMANRESOURCEVEHICLE_CREATED') {
    const vehicles = state.get('vehicles');
    const id = action.vehicleId.toString();

    let nextVehicle = null;
    const nextVehicles = vehicles.map(vehicle => {
      if (vehicle.id === id) {
        nextVehicle = {
          ...vehicle,
          humanResources: vehicle.humanResources.concat(action.data)
        };
        return nextVehicle;
      }
      return vehicle;
    });

    return state
      .set('vehicles', nextVehicles)
      .set('chosenVehicle', nextVehicle);
  }

  if (action.type === 'HUMANRESOURCEVEHICLE_DELETED') {
    const vehicles = state.get('vehicles');
    const vehicleId = action.vehicleId.toString();
    const { ids } = action;

    let nextVehicle = null;
    const nextVehicles = vehicles.map(vehicle => {
      if (vehicle.id === vehicleId) {
        nextVehicle = {
          ...vehicle,
          humanResources: vehicle.humanResources.filter(hr => !ids.find(id => id === hr.id))
        };
        return nextVehicle;
      }
      return vehicle;
    });

    return state
      .set('vehicles', nextVehicles)
      .set('chosenVehicle', nextVehicle);
  }

  if (action.type === 'VEHICLE_BRANDS_FOUND') {
    return state.set('brands', action.data);
  }

  if (action.type === 'VEHICLE_TYPES_FOUND') {
    return state.set('types', action.data);
  }

  return state;
};

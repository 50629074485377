import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import gql from "graphql-tag";

import Select, { components } from "react-select";

import LoadingIndicator from "../LoadingIndicator/small";

class CostCenterSelect extends Component {
  static filter(option, filterString) {
    const { value, building_project, customer } = option.data;
    const arg = filterString.toUpperCase();
    let testString = value ? value.toString().toUpperCase() : "";
    testString += building_project ? `_${building_project.toUpperCase()}` : "";
    testString += customer ? `_${customer.toUpperCase()}` : "";

    return testString.indexOf(arg) > -1;
  }

  CustomSingleValue = props => {
    const className = props.data.is_continuance ? "font-weight-bold" : "";
    const establishment =
      props.data.establishment && props.data.establishment !== ""
        ? props.data.establishment.substr(0, 2).toUpperCase()
        : "kA";

    return (
      <components.SingleValue {...props}>
        <div style={{ overflow: "hidden" }}>
          <span
            className={`cost-center-select-badge-${establishment.toLowerCase()}`}
          >
            {establishment}
          </span>
          <span className={className}>{props.data.value} / </span>
          <span className="text-black-50">{props.data.building_project}</span>
        </div>
      </components.SingleValue>
    );
  };

  CustomOption = props => {
    let infoText = props.data.customer || "";
    infoText += "/ ";
    infoText += props.data.building_project || "";
    const className = props.data.is_continuance ? "font-weight-bold" : "";

    const establishment =
      props.data.establishment && props.data.establishment !== ""
        ? props.data.establishment.substr(0, 2).toUpperCase()
        : "kA";

    return (
      <components.Option {...props}>
        <div className="small clearfix">
          <span
            className={`cost-center-select-badge-${establishment.toLowerCase()}`}
          >
            {establishment}
          </span>
          <span className={className}>{props.data.value} </span>
          <span className="float-right text-black-50">{infoText}</span>
        </div>
      </components.Option>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkCostCenter = this.checkCostCenter.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) this.setState({ error: false });
  }

  handleInputChange(value, { action }) {
    const { checkUsage, onChange } = this.props;

    if (checkUsage) this.checkCostCenter(value);

    if (action !== "pop-value") onChange(value);
  }

  checkCostCenter(costCenter) {
    const { client } = this.props;
    if (costCenter && !costCenter.is_continuance) {
      this.setState({ loading: true, error: false });
      client
        .query({
          query: gql`
            query isCostCenterTaken($id: ID!) {
              isCostCenterTaken(id: $id) {
                success
              }
            }
          `,
          fetchPolicy: "network-only",
          variables: {
            id: costCenter.id
          }
        })
        .then(response => {
          setTimeout(
            () =>
              this.setState({
                loading: false,
                error: response.data.isCostCenterTaken.success
              }),
            200
          );
        });
    } else {
      this.setState({ loading: false, error: false });
    }
  }

  render() {
    const { value, options, error: externalError, disabled } = this.props;
    const { loading, error } = this.state;

    const styles = {
      control: base => ({
        ...base,
        borderColor: externalError ? "#dc3545" : base.borderColor
      })
    };

    return (
      <Fragment>
        <Select
          isClearable
          isDisabled={disabled}
          value={value}
          onChange={this.handleInputChange}
          placeholder=""
          noResultsText="keine Übereinstimmung"
          clearAllText="zurücksetzen"
          getOptionValue={option => option.id}
          styles={styles}
          components={{
            Option: this.CustomOption,
            SingleValue: this.CustomSingleValue
          }}
          options={options}
          filterOption={CostCenterSelect.filter}
        />
        {error ? (
          <small className="form-text text-danger">
            Diese Nicht-Dauerkostenstelle ist bereits vergeben
          </small>
        ) : (
          <LoadingIndicator show={loading} />
        )}
      </Fragment>
    );
  }
}

CostCenterSelect.defaultProps = {
  checkUsage: true
};

CostCenterSelect.propTypes = {
  client: PropTypes.object,
  value: PropTypes.object,
  options: PropTypes.array,
  checkUsage: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default connect(state => ({
  client: state.main.get("client")
}))(CostCenterSelect);

import request from 'superagent';

export default (token, backend) => dispatch => {
  const host = `${backend}/checkToken`;
  request.post(host, { token }).end((err, res) => {
    if (!err) {
      const data = JSON.parse(res.text);
      dispatch({ type: 'CHECK_TOKEN', verified: data.verified });
    }
  });
};

import gql from 'graphql-tag';

export default (client, id, humanResourceId, type, from, to) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateAbsence(
          $id: ID
          $human_resource_id: ID
          $from: String
          $to: String
          $type: String
        ) {
          updateAbsence(
            id: $id
            human_resource_id: $human_resource_id
            from: $from
            to: $to
            type: $type
          ) {
            id
            from
            to
            type
            days
            humanResource {
              id
              firstname
              lastname
              position
            }
          }
        }
      `,
      variables: {
        id,
        human_resource_id: humanResourceId,
        from,
        to,
        type
      }
    })
    .then(response => {
      dispatch({
        type: 'ABSENCE_UPDATED',
        data: response.data.updateAbsence
      });
    });
};

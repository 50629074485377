import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import List from './list';
import Data from './data';

import getCompanies from '../../actions/getCompanies';

class App extends Component {
  constructor(props) {
    super(props);

    const { client, dispatch, location } = props;

    document.title = 'Firmen';

    const id = location.pathname.split('/')[2];
    dispatch(getCompanies(client, null, id));
  }

  render() {
    const {
      dispatch,
      client,
      companies,
      chosenCompany,
      permissions,
      loginName
    } = this.props;

    const write =
      Boolean(permissions.find(p => p.name === 'contacts' && p.write)) ||
      loginName === 'klout';

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              dispatch={dispatch}
              companies={companies}
              company={chosenCompany}
              write={write}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              dispatch={dispatch}
              client={client}
              company={chosenCompany}
              companies={companies}
              write={write}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  companies: PropTypes.array,
  permissions: PropTypes.array,
  chosenCompany: PropTypes.object,
  client: PropTypes.object,
  location: PropTypes.object,
  loginName: PropTypes.string
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get('client'),
  companies: state.contacts.get('companies'),
  chosenCompany: state.contacts.get('chosenCompany'),
  permissions: state.main.get('permissions'),
  loginName: state.main.get('loginName')
}))(App);

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import List from "./list";
import Data from "./data";

import getCombinations from "../../actions/getCombinations";

class App extends Component {
  constructor(props) {
    super(props);

    document.title = "Gespanne";

    const id = props.location.pathname.split("/")[2];
    props.dispatch(getCombinations(props.client, id));
  }

  render() {
    const {
      dispatch,
      permissions,
      loginName,
      establishment,
      combinations,
      chosenCombination
    } = this.props;

    const write =
      Boolean(permissions.find(p => p.name === "combinations" && p.write)) ||
      loginName === "klout";

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <List
              className="col-md-6 padding-20"
              dispatch={dispatch}
              establishment={establishment}
              combination={chosenCombination}
              combinations={combinations}
              write={write}
            />
            <Data
              className="col-md-6 data-panel padding-20 border-left"
              establishment={establishment}
              combination={chosenCombination}
              write={write}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  client: PropTypes.object,
  dispatch: PropTypes.func,
  permissions: PropTypes.array,
  loginName: PropTypes.string,
  establishment: PropTypes.object,
  combinations: PropTypes.array,
  chosenCombination: PropTypes.object
};

export default connect((state, props, dispatch) => ({
  dispatch,
  client: state.main.get("client"),
  permissions: state.main.get("permissions"),
  loginName: state.main.get("loginName"),
  establishment: state.main.get("establishment"),
  combinations: state.combinations.get("combinations"),
  chosenCombination: state.combinations.get("chosenCombination")
}))(App);

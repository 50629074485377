import gql from 'graphql-tag';

export default (client, year) => dispatch =>
  client
    .mutate({
      mutation: gql`
        mutation generateVacationDemand($year: Int!) {
          generateVacationDemand(year: $year) {
            success
          }
        }
      `,
      variables: { year }
    })
    .then(response => {
      const { success } = response.data.generateVacationDemand;
      if (success) {
        console.log('Import successfull');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: `Import konnte für das Jahr ${year} nicht durchgeführt werden, da bereits einträge dafür existieren.`
        });
      }
    });

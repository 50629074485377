import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

export default class ResourceSelect extends Component {
  static CustomMultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.name}</span>
      <span className="text-black-50">
        {' '}
        {props.data.number} / {props.data.category}
      </span>
    </components.MultiValue>
  );

  static CustomOption = props => (
    <components.Option {...props}>
      <span>{props.data.name}</span>
      <span className="text-black-50 float-right">
        {props.data.number} / {props.data.category}
      </span>
    </components.Option>
  );

  static filter = (option, filterString) => {
    const { data } = option;

    // eslint-disable-next-line
    if (data.__isNew__) return true;

    const testString = `${data.name}_${data.category}_${data.number}`.toLowerCase();

    return testString.indexOf(filterString.toLowerCase()) > -1;
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, { action }) {
    const { onChange } = this.props;

    if (action !== 'pop-value') onChange(value);
  }

  render() {
    const { value, options, error, disabled } = this.props;

    const styles = {
      control: base => ({
        ...base,
        borderColor: error ? '#dc3545' : base.borderColor
      })
    };

    return (
      <CreatableSelect
        isClearable={false}
        isMulti={true}
        isDisabled={disabled}
        placeholder="Maschine wählen..."
        noOptionsMessage={() => 'Name Eingeben'}
        noResultsText="Keine Übereinstimmung"
        getOptionValue={option => option.id}
        styles={styles}
        components={{
          Option: ResourceSelect.CustomOption,
          MultiValue: ResourceSelect.CustomMultiValue
        }}
        filterOption={ResourceSelect.filter}
        isValidNewOption={inputValue => inputValue.trim() !== ''}
        getNewOptionData={inputValue => ({
          id: inputValue,
          label: inputValue,
          name: inputValue,
          number: '',
          category: 'Sonstige',
          __isNew__: true
        })}
        value={value}
        options={options}
        onChange={this.handleChange}
      />
    );
  }
}

ResourceSelect.propTypes = {
  value: PropTypes.array,
  options: PropTypes.array,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

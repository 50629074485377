import gql from 'graphql-tag';
import { OrderMaxiOverview } from './fragments';

export default (client, orderId, value) => {
  const SET_ORDER_ALLOWANCE_VALID = gql`
    mutation setOrderAllowanceValid(
      $id: ID!
      $allowanceValid: Boolean!
      $employeeFilter: getEmployeeHoursFilterHumanResourceInput
    ) {
      setOrderAllowanceValid(id: $id, allowanceValid: $allowanceValid) {
        ...OrderMaxiOverview
      }
    }
    ${OrderMaxiOverview}
  `;

  return client
    .mutate({
      mutation: SET_ORDER_ALLOWANCE_VALID,
      variables: {
        id: orderId,
        allowanceValid: value
      }
    })
    .then(response => {
      console.log(response);
    });
};

import gql from 'graphql-tag';
import getLoginName from '../getLoginName';

export default (client, orderId) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  client
    .mutate({
      mutation: gql`
        mutation deleteOrder($requestUser: String!, $orderId: ID) {
          deleteOrder(requestUser: $requestUser, id: $orderId) {
            success
          }
        }
      `,
      variables: { requestUser, orderId }
    })
    .then(response => {
      if (response.data.deleteOrder.success === true) {
        dispatch({
          type: 'ORDER_DELETED',
          orderId
        });
        window.history.pushState({}, null, '/order');
      } else {
        dispatch({
          type: 'SET_ERROR',
          data: response.data.deleteOrder.success
        });
      }
    });
};

import gql from 'graphql-tag';
import { WorkshopTaskData } from './fragments';
import {
  endOfDay,
  startOfDay,
  startOfHour,
  addHours
} from '../util/dateHelper';

import createReservation from './createReservation';
import updateReservation from './updateReservation';
import deleteReservation from './deleteReservation';

export default (
  client,
  task,
  oldTask,
  date,
  finish = false,
  fileUpdate = false
) => dispatch => {
  const start = date ? startOfDay(date).toISOString() : null;
  const end = date ? endOfDay(date).toISOString() : null;

  let startDate = null;
  if (task.startDate) {
    if (typeof task.startDate === 'string' && task.startDate !== '') {
      startDate = new Date(task.startDate).toISOString();
    } else {
      startDate = task.startDate.toISOString();
    }
  }

  let dueDate = null;
  if (task.dueDate) {
    if (typeof task.dueDate === 'string' && task.dueDate !== '') {
      dueDate = new Date(task.dueDate).toISOString();
    } else {
      dueDate = task.dueDate.toISOString();
    }
  }

  client
    .mutate({
      mutation: gql`
        mutation updateWorkshopTask(
          $id: ID!
          $resourceType: String
          $resource_id: ID
          $description: String
          $workspaces: String
          $externalWorkshop: String
          $externalMachine: String
          $workers: String
          $startDate: String
          $dueDate: String
          $files: String
          $start: Date
          $end: Date
          $establishment: Establishment
        ) {
          updateWorkshopTask(
            id: $id
            resourceType: $resourceType
            resource_id: $resource_id
            description: $description
            workspaces: $workspaces
            externalWorkshop: $externalWorkshop
            externalMachine: $externalMachine
            workers: $workers
            startDate: $startDate
            dueDate: $dueDate
            files: $files
            establishment: $establishment
          ) {
            ...WorkshopTaskData
          }
        }
        ${WorkshopTaskData}
      `,
      variables: {
        id: task.id,
        resourceType: task.resourceType,
        resource_id: task.resource ? task.resource.id : null,
        description: task.description,
        workspaces: JSON.stringify(
          task.workspaces.map(workspace => parseInt(workspace.id, 10))
        ),
        externalWorkshop: task.externalWorkshop,
        externalMachine: task.externalMachine,
        workers: JSON.stringify(
          task.workers.map(worker => parseInt(worker.id, 10))
        ),
        startDate,
        dueDate,
        files: JSON.stringify(task.files || []),
        start,
        end,
        establishment: task.establishment
      }
    })
    .then(response => {
      const data = response.data.updateWorkshopTask;

      dispatch({
        type: 'TASK_UPDATED',
        data,
        keepEditable: fileUpdate
      });

      if (!fileUpdate) {
        const endDate = addHours(startOfHour(new Date()), 1);

        const to = finish ? endDate.toISOString() : dueDate;

        if (!data.resource) return;

        if (data.reservation) {
          if (oldTask.startDate && oldTask.startDate !== '' && !startDate) {
            dispatch(
              deleteReservation(
                client,
                data.reservation.id,
                data.resource.id,
                data.resourceType
              )
            );
          } else {
            dispatch(
              updateReservation(
                client,
                data.reservation.id,
                task.resource.id,
                task.resourceType,
                startDate,
                to,
                `WS_${data.id}_${data.description}`,
                oldTask.resource.id,
                false
              )
            );
          }
        } else if (
          (!oldTask.startDate || oldTask.startDate === '') &&
          startDate
        ) {
          createReservation(
            dispatch,
            client,
            data.resource.id,
            null,
            data.resourceType,
            startDate,
            dueDate,
            `WS_${data.id}_${data.description}`,
            []
          );
        }
      }
    });
};

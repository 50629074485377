import gql from 'graphql-tag';

export default (client, id, archived) => dispatch => {
  client
    .mutate({
      mutation: gql`
        mutation updateWorkshopTaskArchiveStatus($id: ID, $archived: Boolean) {
          updateWorkshopTaskArchiveStatus(id: $id, archived: $archived) {
            id
            archived
          }
        }
      `,
      variables: {
        id,
        archived
      }
    })
    .then(response => {
      const data = response.data.updateWorkshopTaskArchiveStatus;

      if (data && data.archived) {
        dispatch({
          type: 'TASK_DELETED',
          data: id
        });
      }
    });
};

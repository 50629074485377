import gql from "graphql-tag";
import { whenModified } from "./modifyHelper";
import { HumanResourceData } from "./fragments";

export default (client, id) => dispatch => {
  whenModified(client, "human_resource", () =>
    client
      .query({
        query: gql`
          query {
            getHumanResources {
              vacationDemands {
                id
                vacation
                vacationPlus
                vacationPlusDecay
                year
              }
              ...HumanResourceData
            }
          }
          ${HumanResourceData}
        `,
        fetchPolicy: "network-only"
      })
      .then(response => {
        dispatch({
          type: "USERS_FOUND",
          data: response.data.getHumanResources
        });
        if (id !== undefined && id !== "") {
          const humanResource = response.data.getHumanResources.find(
            obj => obj.id === id
          );
          if (humanResource !== undefined) {
            dispatch({ type: "SET_CHOSEN_USER", data: humanResource });
          }
        }
      })
  );
};

import gql from 'graphql-tag';
import { uniqBy } from 'lodash';
import moment from 'moment';

import getLoginName from '../getLoginName';

import createReservation from './createReservation';
import updateReservation from './updateReservation';
import deleteReservationScopeOfServices from './deleteReservationScopeOfServices';

export default (
  client,
  costCenter,
  establishment,
  operationalPeriodStart,
  operationalPeriodFinish,
  scopeOfServices,
  models,
  subcontractor
) => dispatch => {
  const localStorageItems = JSON.parse(window.localStorage.getItem('klout'));
  const requestUser = getLoginName(localStorageItems);
  const createOrder = gql`
    mutation createOrder(
      $requestUser: String!
      $cost_center_id: ID
      $establishment: String
      $start_date_time: String
      $start_meeting_time: String
      $operational_period_start: String
      $operational_period_finish: String
      $description: String
      $subcontractor: String
      $type: Int
    ) {
      createOrder(
        requestUser: $requestUser
        cost_center_id: $cost_center_id
        establishment: $establishment
        start_date_time: $start_date_time
        start_meeting_time: $start_meeting_time
        operational_period_start: $operational_period_start
        operational_period_finish: $operational_period_finish
        description: $description
        subcontractor: $subcontractor
        type: $type
      ) {
        id
        establishment
        building_address
        lat
        lng
        directions
        construction_manager_name
        construction_manager_phone
        certified_foreman_name
        certified_foreman_phone
        subcontractor
        status
        description
        start_date_time
        start_meeting_time
        operational_period_start
        operational_period_finish
        kutterContact {
          id
          firstname
          lastname
          phone
        }
        scopeOfServices {
          id
          width
          amount
          depth
          depthTo
          amountUnit
          scopeOfServiceTyps {
            id
            value
          }
        }
        cost_center {
          id
          value
          project_number
          is_continuance
          building_project
          customer
        }
      }
    }
  `;

  /*
  const createDispoMachine = gql`
    mutation createDispoMachine(
      $requestUser: String!
      $order_id: ID
      $resource_id: ID
      $human_resource_id: ID
      $resource_from: String
      $resource_to: String
      $human_resource_from: String
      $human_resource_to: String
    ) {
      createDispoMachine(
        requestUser: $requestUser
        order_id: $order_id
        resource_id: $resource_id
        human_resource_id: $human_resource_id
        resource_from: $resource_from
        resource_to: $resource_to
        human_resource_from: $human_resource_from
        human_resource_to: $human_resource_to
      ) {
        resource {
          id
          name
        }
        humanResource {
          id
          lastname
          firstname
        }
      }
    }
  `;

  const createDispoVehicle = gql`
    mutation createDispoVehicle(
      $requestUser: String!
      $order_id: ID
      $vehicle_id: ID
      $from: String
      $to: String
    ) {
      createDispoVehicle(
        requestUser: $requestUser
        order_id: $order_id
        vehicle_id: $vehicle_id
        from: $from
        to: $to
      ) {
        id
        vehicle {
          id
          brand
          type
        }
        from
        to
      }
    }
  `;
*/

  const createDispoHumanResource = gql`
    mutation createDispoHumanResource(
      $requestUser: String!
      $order_id: ID
      $human_resource_id: ID
      $from: String
      $to: String
    ) {
      createDispoHumanResource(
        requestUser: $requestUser
        order_id: $order_id
        human_resource_id: $human_resource_id
        from: $from
        to: $to
      ) {
        id
        humanResource {
          id
          lastname
          firstname
        }
        from
        to
      }
    }
  `;

  const createScopeOfService = gql`
    mutation createScopeOfService(
      $requestUser: String!
      $order_id: ID
      $width: String
      $depth: String
      $depthTo: String
      $amount: String
      $amountUnit: String
    ) {
      createScopeOfService(
        requestUser: $requestUser
        order_id: $order_id
        width: $width
        depth: $depth
        depthTo: $depthTo
        amount: $amount
        amountUnit: $amountUnit
      ) {
        id
        width
        depth
        depthTo
        amount
        amountUnit
        scopeOfServiceTyps {
          id
          value
        }
      }
    }
  `;

  const createScopeOfServiceType = gql`
    mutation createScopeOfServiceType(
      $requestUser: String!
      $scope_of_service_id: ID
      $value: String
    ) {
      createScopeOfServiceType(
        requestUser: $requestUser
        scope_of_service_id: $scope_of_service_id
        value: $value
      ) {
        id
        value
      }
    }
  `;

  let description =
    models.length > 0 && models[0].status ? models[0].status : '';
  description = description.substring(4);

  const startDateTime = moment.utc(operationalPeriodStart);
  startDateTime.hour(7);
  const startMeetingTime = moment.utc(operationalPeriodStart);
  startMeetingTime.hour(6);
  startMeetingTime.minute(30);

  client
    .mutate({
      mutation: createOrder,
      variables: {
        requestUser,
        cost_center_id: costCenter ? costCenter.id : null,
        establishment,
        description,
        start_date_time: startDateTime,
        start_meeting_time: startMeetingTime,
        operational_period_start: operationalPeriodStart,
        operational_period_finish: operationalPeriodFinish,
        subcontractor
      }
    })
    .then(response => {
      const order = response.data.createOrder;
      if (subcontractor) {
        dispatch({
          type: 'DISPO_ORDER_UPDATED',
          data: order
        });
      } else {
        dispatch({
          type: 'ORDER_ADDED',
          data: order
        });
      }
      if (order && order.id) {
        // Save the scope of service data.
        scopeOfServices.forEach(scopeOS => {
          if (
            scopeOS.width !== '' &&
            scopeOS.depth !== '' &&
            scopeOS.amount !== ''
          ) {
            client
              .mutate({
                mutation: createScopeOfService,
                variables: {
                  requestUser,
                  order_id: parseInt(order.id, 10),
                  width: scopeOS.width.toString(),
                  depth: scopeOS.depth.toString(),
                  depthTo: scopeOS.depthTo
                    ? scopeOS.depthTo.toString()
                    : scopeOS.depthTo,
                  amount: scopeOS.amount.toString(),
                  amountUnit: scopeOS.amountUnit
                }
              })
              .then(response1 => {
                const scopeOSId = response1.data.createScopeOfService.id;
                scopeOS.scopeOfServiceTyps.forEach(scopeOST => {
                  client.mutate({
                    mutation: createScopeOfServiceType,
                    variables: {
                      requestUser,
                      scope_of_service_id: parseInt(scopeOSId, 10),
                      value: scopeOST.value
                    }
                  });
                });
              });
          }
        });

        // Save all machines and their human resources.
        (async function loop() {
          for (let i = 0; i < models.length; i += 1) {
            const model = models[i];
            const { resource, start, end } = model;
            let { humanResources } = resource;
            const { id } = resource;

            const from = moment.utc(start).format('YYYY-MM-DD HH:mm');
            const to = moment.utc(end).format('YYYY-MM-DD HH:mm');

            if (resource.type === 'RESOURCE') {
            } else if (resource.type === 'VEHICLE') {
            } else if (resource.type === 'COMBINATION') {
              resource.resources.forEach(elem => {
                humanResources = humanResources.concat(elem.humanResources);
                createReservation(
                  dispatch,
                  client,
                  elem.id,
                  model.groupId,
                  'RESOURCE',
                  from,
                  to,
                  order.id,
                  []
                );
              });

              resource.vehicles.forEach(elem => {
                humanResources = humanResources.concat(elem.humanResources);
                createReservation(
                  dispatch,
                  client,
                  elem.id,
                  model.groupId,
                  'VEHICLE',
                  from,
                  to,
                  order.id,
                  []
                );
              });

              humanResources = uniqBy(humanResources, elem => elem.id);
            } else {
              // eslint-disable-next-line
              continue;
            }

            humanResources.forEach(humanResource => {
              client.mutate({
                mutation: createDispoHumanResource,
                variables: {
                  requestUser,
                  order_id: order.id,
                  human_resource_id: parseInt(humanResource.id, 10),
                  from,
                  to
                }
              });
            });

            dispatch(deleteReservationScopeOfServices(client, model.id));

            dispatch(
              updateReservation(
                client,
                model.id,
                id,
                resource.type,
                model.start,
                model.end,
                order.id
              )
            );
          }
        })();
      }
    });
};
